<template>    
    
	<section class="w-2-property-lsi">
		<div class="container">
			<div class="progress">
				<div class="progress-bar" role="progressbar" style="width: 50%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
			<div class="step-container d-flex justify-content-between">
				<div class="step-circle"><span>1</span> Step 1</div>
				<div class="step-circle"><span>2</span> Step 2</div>
				<div class="step-circle"><span>3</span> Step 3</div>
			</div>
			
			<form @submit.prevent="submitPost" id="multi-step-form">
				<div class="step step-1">										
					<div class="form-group">
						<label>Asking Rent/Sale Price</label>
						<input type="number" placeholder="Asking Sale Price" class="form-control" v-model="form.asking_price_sale_rent">
						<span  class="error" v-if="errors.asking_price_sale_rent">{{ errors.asking_price_sale_rent }}</span>
					</div>					
					<div class="form-group">
                        <label for="">{{ $t('current_occupancy') }}</label>
                        <select class="form-control" v-model="form.current_occupancy" >
                            <option value="">{{ $t('current_occupancy') }}</option>
                            <option>Occupied By Owner</option>
                            <option>Rented To Tenant</option>
                            <option>Vacant</option>
                            <option>{{ $t('renovation') }} / {{ $t('make-over_in_progress') }}</option>
                        </select>
                        <span  class="error" v-if="errors.current_occupancy">{{ errors.current_occupancy }}</span>
                    </div>	
                    <div class="form-group">
                        <label>{{ $t('availability_date') }}</label>
						<input type="date" class="form-control" v-model="form.availability_date" placeholder="Availability Date" >
                        <span  class="error" v-if="errors.availability_date">{{ errors.availability_date }}</span>
					</div>
                    <div class="comman-title mt-3" v-if="idType == 'Business'">
						<h4 class="mb-2">{{ $t('hybrid_listing') }} Option</h4>
						<p>You may assign this listing to multiple Estate Agents with our digital-brokerage widget, enhancing the reach and accelerating speed-to-market via the entire or co-agency network with leveraged resources, amplifying your chances of success while maintaining control of the original listing.</p>
						<a href="#">"Choice Of Either 1 Selection, Not Allowed To Have Multiple Choices."</a>
					</div>
                    <div class="form-group" >
                        <div class="form-check" v-if="idType == 'Business'">
                            <input 
                                class="form-check-input" 
                                type="radio" 
                                v-model="form.hybrid_listing_type" 
                                value="1" 
                                id="Assign-To-Recommended-Estate-Agent"
                                :disabled="!hybridAgentDetails?.is_enough_credit  "
                            >

                            <label class="form-check-label w-100" for="Assign-To-Recommended-Estate-Agent">
                                Assign To Recommended Estate Agent
                            </label>

                            <p v-if="!hybridAgentDetails?.is_enough_credit && idType == 'Business'" class="text-danger mt-1">
                                <small>This agent cannot be assigned to this option due to insufficient credits.</small>
                            </p>

                        </div>

                        <div class="property-siderbar-boxs" v-if="Object.keys(hybridAgentDetails).length > 1">
                            <div class="profile-box">
                                <img :src="computedProfileImgSrc">
                                <div>
                                    <h5>{{ hybridAgentDetails.name }}  <img src="frontend/images/varify-user.svg" alt=""></h5>
                                    <span>{{ $t('singapore') }}</span>
                                </div>
                            </div>
                            <div class="list-box">
                                <ul>
                                    <li>{{ $t('position') }}:
                                        <span><b>{{hybridAgentDetails.position_held || '-' }}</b></span></li>
                                    <li>{{ $t('cea_registration_no') }}. <span><b>{{
                                                hybridAgentDetails.agency_registration_no || '-' }}</b></span></li>
                                    <li>{{ $t('agency_name') }}
                                        <span><b>{{hybridAgentDetails.agency_name || '-' }}</b></span></li>
                                    <li>{{ $t('agency_license_no') }}.
                                        <span><b>{{hybridAgentDetails.agency_license || '-' }}</b></span></li>
                                    <li>{{ $t('mobile_no') }}:<span><b>{{ hybridAgentDetails.contact_phone || '-' }}</b></span></li>
                                </ul>
                            </div>
                        </div>
    
                        <div class="form-check">
                            <input 
                                class="form-check-input" 
                                type="checkbox" 
                                v-model="form.attendToInquiry" 
                                id="Attend-To-Inquiry"
                                :checked="shouldAttendToInquiry" 
                                @change="validateInquirySelection"
                            />
                            <label class="form-check-label w-100" for="Attend-To-Inquiry" >
                                Attend To Inquiry By Myself
                            </label>
                        </div>
                        <!-- Validation Message -->
                        <p class="error" v-if="showError" style="color: red; font-size: 12px;">
                            Please select Yes or No.
                        </p>

                        <div class="d-flex align-items-center mt-2 property-siderbar-boxs" style="font-size: 12px;">
                            Prefer to display contact information

                            <!-- No Option -->
                            <span 
                                @click="selectOption(4)" 
                                :class="{'selected': form.hybrid_listing_type === 4, 'unselected': form.hybrid_listing_type !== 4}"
                                style="cursor: pointer; display: flex; align-items: center; gap: 5px; font-size: 12px; margin-left: 12px; padding: 5px; border-radius: 8px; transition: 0.3s; color: red; font-weight: 500;"
                            >
                                No <img src="/frontend/images/unverified_logo.png" style="width: 20px; height: 20px;">
                            </span>

                            <!-- Yes Option -->
                            <span 
                                @click="selectOption(5)" 
                                :class="{'selected': form.hybrid_listing_type === 5, 'unselected': form.hybrid_listing_type !== 5}"
                                style="cursor: pointer; display: flex; align-items: center; gap: 5px; font-size: 12px; padding: 5px; border-radius: 8px; transition: 0.3s; color: #007bff; font-weight: 500;"
                            >
                                Yes <img src="/frontend/images/verified_logo.png" style="width: 20px; height: 20px;">
                            </span>
                        </div>
                        <span  class="error" v-if="errors.hybrid_listing_type">{{ errors.hybrid_listing_type }}</span>
                    </div>
                    <div class="comman-title mt-3">
						<h4>FengShui Map - Enriched Contents</h4>
					</div>
                    <div class="form-group">
						<label>Date Of Property Completed/1st Occupied</label>
						<input type="number" placeholder="Property Identification" class="form-control" v-model="form.FSM_property_completion_occupation_date">
                        <span  class="error" v-if="errors.FSM_property_completion_occupation_date">{{ errors.FSM_property_completion_occupation_date }}</span>
					</div>

                    <div class="comman-title mt-3">
						<h4>Property Orientation</h4>
					</div>
                    
                    <div class="form-group">
                        <label for="">{{ $t('main_block') }}/{{ $t('building_facing') }}</label>
                        <select class="form-control" v-model="form.FSM_property_facing_orientation_building_id">
                            <option value="0" disabled>{{ $t('main_block') }}/{{ $t('building_facing') }}</option>
                            <RecursiveDropdown :options="hierarchicalOptions" />
                        </select>
                        <span  class="error" v-if="errors.FSM_property_facing_orientation_building_id">{{ errors.FSM_property_facing_orientation_building_id }}</span>
                    </div>	

                    <div class="form-group">
                        <label for="">{{ $t('main_door_facing') }} (For Landed Property)</label>
                        <select class="form-control" v-model="form.FSM_property_facing_orientation_main_entrance_id">
                            <option value="0" disabled>{{ $t('main_door_facing') }}</option>
                            <RecursiveDropdown :options="hierarchicalOptions" />
                        </select>
                        <span  class="error" v-if="errors.FSM_property_facing_orientation_main_entrance_id">{{ errors.FSM_property_facing_orientation_main_entrance_id }}</span>
                    </div>	

                    <div class="form-group">
                        <label for="">{{ $t('main_balcony') }}/{{ $t('window_facing') }} (For High-Rise/Non-Landed Unit)</label>
                        <select class="form-control" v-model="form.FSM_property_facing_orientation_main_balcony_window_id">
                            <option value="0" disabled>{{ $t('main_balcony') }}/{{ $t('window_facing') }}</option>
                            <RecursiveDropdown :options="hierarchicalOptions" />
                        </select>
                        <span  class="error" v-if="errors.FSM_property_facing_orientation_main_balcony_window_id">{{ errors.FSM_property_facing_orientation_main_balcony_window_id }}</span>                                            
                    </div>

                    <div class="comman-title mt-3">
						<h4>{{ $t('space_sector') }}</h4>
					</div>
                    <div class="form-group">
                        <label for="">{{ $t('main_door') }}/{{ $t('entrance_sector') }}</label>
                        <select class="form-control" v-model="form.FSM_space_sectors_main_door_entrance_sector_id">
                            <option value="0" disabled>{{ $t('main_door') }}/{{ $t('entrance_sector') }}</option>
                            <RecursiveDropdown :options="hierarchicalOptions" />
                        </select>
                        <span  class="error" v-if="errors.FSM_space_sectors_main_door_entrance_sector_id">{{ errors.FSM_space_sectors_main_door_entrance_sector_id }}</span>
                    </div>
                    <div class="form-group">
                        <label for="">{{ $t('main_living-hall_sector') }}</label>
                        <select class="form-control" v-model="form.FSM_space_sectors_main_living_hall_hall_sector_id">
                            <option value="0" disabled>{{ $t('main_living-hall_sector') }}</option>
                            <RecursiveDropdown :options="hierarchicalOptions" />
                        </select>
                        <span  class="error" v-if="errors.FSM_space_sectors_main_living_hall_hall_sector_id">{{ errors.FSM_space_sectors_main_living_hall_hall_sector_id }}</span>
                    </div>
                    <div class="form-group">
                        <label for="">{{ $t('kitchen_sector') }}</label>
                        <select class="form-control" v-model="form.FSM_space_sectors_kitchen_sector_id">
                            <option value="0" disabled>{{ $t('kitchen_sector') }}</option>
                            <RecursiveDropdown :options="hierarchicalOptions" />
                        </select>
                        <span  class="error" v-if="errors.FSM_space_sectors_kitchen_sector_id">{{ errors.FSM_space_sectors_kitchen_sector_id }}</span>
                    </div>
                    <div class="form-group">
                        <label for="">{{ $t('main') }}/{{ $t('master') }} {{ $t('room_sector') }}</label>
                        <select class="form-control" v-model="form.FSM_space_sectors_main_master_bedroom_sector_id">
                            <option value="0" disabled>{{ $t('main') }}/{{ $t('master') }} {{ $t('room_sector') }}</option>
                            <RecursiveDropdown :options="hierarchicalOptions" />
                        </select>
                        <span  class="error" v-if="errors.FSM_space_sectors_main_master_bedroom_sector_id">{{ errors.FSM_space_sectors_main_master_bedroom_sector_id }}</span>
                    </div>	

					<div class="pull-right row">
                        <div class="col-md-6">
                            <router-link :to="`/widget-property-post-1/${id}/${idType}/${postListingId}`" class="btn comman-btn-6">{{ $t('previous') }}</router-link>
                        </div>
                        <div class="col-md-6">
                            <button type="submit" class="comman-btn-2 next-step">{{ $t('next') }}</button>
                        </div>
					</div>
				</div>
			</form>
		</div>
	</section>

</template>
  
<script> 
  
  import { mapState } from 'vuex';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';
  import { CONSTANT } from '@/constants/constants';
  import RecursiveDropdown from '@/components/RecursiveDropdown.vue';
  import { FUNG_SHUI_CARDINAL } from '@/constants/constantsProperty';
  export default {
    name: 'Widget2Page', 
    data() {
        return {
            hybridAgentDetails: {
                is_enough_credit: false 
            },
			editData: '',
            options: this.transformData(FUNG_SHUI_CARDINAL),
			form: {
                attendToInquiry: false,
                current_occupancy: '',
                hybrid_listing_type: '',
                FSM_property_facing_orientation_building_id: 0,
                FSM_property_facing_orientation_main_entrance_id: 0,
                FSM_property_facing_orientation_main_balcony_window_id: 0,
                FSM_space_sectors_main_door_entrance_sector_id: 0,
                FSM_space_sectors_main_living_hall_hall_sector_id: 0,
                FSM_space_sectors_kitchen_sector_id: 0,
                FSM_space_sectors_main_master_bedroom_sector_id: 0,

                AI_generative_language_Ids: [],
                AI_generative_target_audience_Ids: [],
                AI_generative_pr_look_and_feel_detail_Ids: [],
            },
			errors: {}
        }
    },

	components: {
      RecursiveDropdown
    }, 

	created() {
        this.fetchOptions();
        this.storeAgentId();
        this.fetchHybridAgentDetails(localStorage.getItem('agentId'));
	},

	computed: {
        ...mapState(['isAuthenticated', 'user']),
        hierarchicalOptions() {        
            return this.createHierarchicalOptions(this.options);
        },
        id() {
			return this.$route.params.id;
		},
		idType() {
			return this.$route.params.id_type;
		},   
		postListingId() {
			return this.$route.params.post_listing_id;
		},
        computedProfileImgSrc() {
            return this.hybridAgentDetails.profile_photo_name ? `${CONSTANT.IMAGE_URL}/imagesProfile/${this.hybridAgentDetails.profile_photo_name}` : CONSTANT.DEFAULT_IMAGE;
        },
        shouldAttendToInquiry() {
            // Automatically tick the checkbox when 'Yes' is selected (5) or 'No' (4)
            return this.form.hybrid_listing_type === 5 || this.form.hybrid_listing_type === 4;
        }
    },

	methods: {
        storeAgentId() {
            let agentId = localStorage.getItem('agentId');

            if (!agentId) {
                agentId = this.id;
                localStorage.setItem('agentId', agentId);
            }
        },

        selectOption(value) {
            this.form.hybrid_listing_type = value;
    
            if (value === 5 || value == 4) {
                this.form.attendToInquiry = true;  
            } else {
                this.form.attendToInquiry = false;  
            }
            this.showError = false;
        },

        validateInquirySelection() {
            if (this.form.attendToInquiry) {
                this.showError = ![4, 5].includes(this.form.hybrid_listing_type);
            } else {
                this.showError = false;
                this.form.hybrid_listing_type = null; 
            }
        },

        async fetchHybridAgentDetails(agentId) {
            if (!agentId) {
                return;
            }

            this.isLoading = true;

            try {
                const response = await this.$axios.post('/api/widget-agent-details', {
                    agentId: agentId,
                });

                if (response.data.status == 1 && response.data.output) {
                    this.hybridAgentDetails = response.data.output;
                } else {
                    console.warn('Agent details not found or empty');
                    this.hybridAgentDetails = { is_enough_credit: false }; // Set a safe default
                }
            } catch (error) {
                console.error('Error fetching agent details:', error);
                this.hybridAgentDetails = { is_enough_credit: false }; // Ensure it's defined even on error
            } finally {
                this.isLoading = false;
            }
        },

		async fetchOptions() {
			// Get District list
			try {
				const response = await this.$axios.post('api/location',
				{                    
					type : "City",
					keyword : this.street, 
					parent_id: CONSTANT.COUNTRY_CODE                   
				});
				this.optionsDistrictList = response.data.output.map(item => ({
					value: item.id,
					label: item.name
				}));
			} catch (error) {
				console.error('Error fetching options:', error);
			}

			// Furnishing & Fittings
            try {
                const response = await this.$axios.post('api/pr-master-furnishing-fittings-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_checkbox: 1,
                    show_in_property:1,
                    show_in_included : 0,                     
                    last_record_number : 0                     
                });
                this.checkboxFFlist = response.data.output.map(item => ({
                    id: item.id,
                    name: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }

            if (this.postListingId > 0){
                try {
                    const response = await this.$axios.post('api/property-detail',
                        {
                            user_id :this.user.id,
                            property_listing_id:this.postListingId,
                        });
                    this.editData = response.data.output[0];

                    this.form.asking_price_sale_rent = this.editData.asking_price_sale_rent ?? '';
                    this.form.current_occupancy = this.editData.current_occupancy ?? '';
                    this.form.availability_date = this.editData.availability_date ?? '';
                    this.form.hybrid_listing_type = this.editData.hybrid_listing_type ?? '';

                    this.form.FSM_property_completion_occupation_date = this.editData.FSM_property_completion_occupation_date ?? '';
                    this.form.FSM_property_facing_orientation_building_id = this.editData.FSM_property_facing_orientation_building_id?.id ?? '';
                    this.form.FSM_property_facing_orientation_main_entrance_id = this.editData.FSM_property_facing_orientation_main_entrance_id?.id ?? '';
                    this.form.FSM_property_facing_orientation_main_balcony_window_id = this.editData.FSM_property_facing_orientation_main_balcony_window_id ?? '';
                    this.form.FSM_space_sectors_main_door_entrance_sector_id = this.editData.FSM_space_sectors_main_door_entrance_sector_id?.id ?? '';
                    this.form.FSM_space_sectors_main_living_hall_hall_sector_id = this.editData.FSM_space_sectors_main_living_hall_hall_sector_id ?? '';
                    this.form.FSM_space_sectors_kitchen_sector_id = this.editData.FSM_space_sectors_kitchen_sector_id ?? '';
                    this.form.FSM_space_sectors_main_master_bedroom_sector_id = this.editData.FSM_space_sectors_main_master_bedroom_sector_id ?? '';

                } catch (error) {
                    console.error('Error fetching options:', error);
                }
            }
		},

        validateForm() {
            this.errors = {};
            if (!this.form.asking_price_sale_rent ) {
                this.errors.asking_price_sale_rent = 'Asking Rent/Sale Price is required.';
            }
            
            if (!this.form.current_occupancy ) {
                this.errors.current_occupancy = 'Current Occupancy is required.';
            }
            
            if (!this.form.availability_date ) {
                this.errors.availability_date = 'Current Occupancy is required.';
            }
            
            if (!this.form.FSM_property_completion_occupation_date ) {
                this.errors.FSM_property_completion_occupation_date = 'Date Of Property Completed.';
            }

            // if (this.form.hybrid_listing_type.length == 0) {
            //      this.errors.hybrid_listing_type = 'Choice Of Either 1 Selection, Not Allowed To Have Multiple Choices.';
            // } 
                     
            return Object.keys(this.errors).length === 0;
        },        

        async submitPost() {
            if (this.validateForm()) {
                const token = localStorage.getItem('userToken');
    
                if (this.loading) return;
                this.loading = true;
                try {
                    var loginted_user_id = 1;
                    if(this.user.id > 1){
                        loginted_user_id = this.user.id;
                    }
                    const response = await this.$axios.post('api/update-widget-property', {
                        user_id :loginted_user_id,
                        api_token: token,
                        property_listing_id: this.postListingId,
						
                        asking_price_sale_rent: this.form.asking_price_sale_rent, 
                        current_occupancy: this.form.current_occupancy, 
                        hybrid_listing_type: this.form.hybrid_listing_type, 
                        availability_date: this.form.availability_date, 

                        FSM_property_completion_occupation_date: this.form.FSM_property_completion_occupation_date ? this.form.FSM_property_completion_occupation_date : 0, 
                        FSM_property_facing_orientation_building_id:this.form.FSM_property_facing_orientation_building_id ? this.form.FSM_property_facing_orientation_building_id : 0,
                        FSM_property_facing_orientation_main_entrance_id : this.form.FSM_property_facing_orientation_main_entrance_id ? this.form.FSM_property_facing_orientation_main_entrance_id : 0,
                        FSM_property_facing_orientation_main_balcony_window_id : this.form.FSM_property_facing_orientation_main_balcony_window_id ? this.form.FSM_property_facing_orientation_main_balcony_window_id : 0,
                        FSM_space_sectors_main_door_entrance_sector_id : this.form.FSM_space_sectors_main_door_entrance_sector_id ? this.form.FSM_space_sectors_main_door_entrance_sector_id : 0,
                        FSM_space_sectors_main_living_hall_hall_sector_id : this.form.FSM_space_sectors_main_living_hall_hall_sector_id ? this.form.FSM_space_sectors_main_living_hall_hall_sector_id : 0,
                        FSM_space_sectors_kitchen_sector_id : this.form.FSM_space_sectors_kitchen_sector_id ? this.form.FSM_space_sectors_kitchen_sector_id : 0,
                        FSM_space_sectors_main_master_bedroom_sector_id : this.form.FSM_space_sectors_main_master_bedroom_sector_id ? this.form.FSM_space_sectors_main_master_bedroom_sector_id : 0,

						referral_user_id:this.id,
						referral_user_type:this.idType
                    });

                    localStorage.setItem('property_listing_id', this.postListingId);
                    localStorage.setItem('hybrid_listing_type', this.form.hybrid_listing_type);

                    toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                    this.$router.push('/widget-property-post-3/'+this.id+'/'+this.idType+'/'+this.postListingId);
                } catch (error) {
                    
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },	

        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },

        transformData(data) {
            const map = {};
            const result = [];
    
            data.forEach(item => {
            map[item.id] = { ...item, children: [] };
            });
    
            data.forEach(item => {
            if (item.parent_id === 0) {
                result.push(map[item.id]);
            } else {
                map[item.parent_id].children.push(map[item.id]);
            }
            });
    
            return result;
        },

        createHierarchicalOptions(data) {
            return data.map(option => this.createOptionGroup(option));
        },

        createOptionGroup(option) {
                if (!option.children || option.children.length === 0) {
                    return {
                        type: 'option',
                        id: option.id,
                        label: option.name,
                        depth: option.depth,
                    };
                }
                return {
                    type: 'optgroup',
                    label: option.name,
                    depth: option.depth,
                    children: option.children.map(child => this.createOptionGroup(child))
                };
        },
	}
  }
</script>
  
<style scoped>
/* Hover effect */
span:hover {
    background-color: #f0f0f0;
}

/* Selected option */
.selected {
    background-color: #F6B034; 
    color: white; 
    border: 1px solid #F6B034; 
}
</style>
  