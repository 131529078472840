<template>
    <div><button class="btn comman-btn-2 w-100" data-bs-toggle="modal"
            data-bs-target="#messageModal"><b>Message</b></button></div>

    <div class="modal fade" id="messageModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="messageModalLabel" aria-hidden="true">

        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="messageModalLabel">Send Message</h1>
                </div>
                <div class="modal-body">
                    <label>Message</label>
                    <input style="height:150px" type="text" class="form-control mb-3" v-model="message"
                        placeholder="Enter your message here">
                    <div class="form-check" v-if="isProperty && includePropertyCheckbox">
                        <input class="form-check-input" type="checkbox" v-model="includeProperty" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            Include Property
                        </label>
                    </div>
                    <div class="form-check" v-else-if="isProject">
                        <input class="form-check-input" type="checkbox" v-model="includeProject" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            Include Project
                        </label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('cancel') }}</button>
                    <button type="button" class="btn btn-primary" @click="sendMessage">Send</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import toastr from 'toastr';
    import { mapState } from 'vuex';

    export default {
        name: 'SendMessageComponent',
        props: {
            type: {
                type: String,
                required: true,
                default: 'Property'
            },
            recipient: {
                type: Object,
                required: true,
            },
            property: {
                type: Object,
                required: false,
                default: () => { }
            },
            includePropertyCheckbox: {
                type: Boolean,
                required: false,
                default: true
            },
            project: {
                type: Object,
                required: false,
                default: () => { }
            },
        },
        data() {
            return {
                message: '',
                includeProperty: false,
                includeProject: false
            };
        },
        computed: {
            ...mapState(['isAuthenticated', 'user']),
            isProperty() {
                return this.type === 'property';
            },
            isProject() {
                return this.type === 'project';
            }
        },
        methods: {
            async sendMessage() {
                try {
                    if (!this.isAuthenticated) {
                        toastr.error("You are required to log in before sending message");
                        return;
                    }

                    if (!this.user) {
                        toastr.error("No user found. Please re-login");
                        return;
                    }

                    if (!this.message) {
                        toastr.error("Message cannot be empty");
                        return;
                    }

                    if (this.isProperty && this.includePropertyCheckbox && this.includeProperty && !this.property?.id) {
                        toastr.error("No property found to be included in message");
                        return;
                    }

                    if (this.isProject && this.includeProject && !this.project?.id) {
                        toastr.error("No project found to be included in message");
                        return;
                    }

                    if (!this.user?.id || !this.recipient?.id) {
                        toastr.error("Invalid user");
                        return;
                    }

                    if (this.recipient?.id === this.user?.id) {
                        toastr.error("Invalid recipient user");
                        return;
                    }

                    let params = {
                        api_token: localStorage.getItem('userToken'),
                        content: this.message,
                        include_property: this.includeProperty,
                        include_project: this.includeProject,
                        property_listing_id: this.property?.id,
                        project_listing_id: this.project?.id,
                        sender_id: this.user.id,
                        recipient_id: this.recipient.id,
                    }

                    const response = await this.$axios.post(`api/users/${this.user.id}/messages`, params);

                    if (response.status === 200) {
                        if (this.isProperty && this.property) {
                            // increment property analytic inquiries count
                            await this.$axios.post(`api/properties/${this.property.id}/analytics/inquiries`);
                        } else if (this.isProject && this.project) {
                            await this.$axios.post(`api/projects/${this.project.id}/analytics/inquiries`);
                        }

                        toastr.success('Message sent!')
                        this.message = null;
                        this.includeProperty = false;
                        this.includeProject = false;
                    }
                } catch (error) {
                    console.log(error)
                    toastr.error('Failed to send message:', error);
                }
            }
        },
    };
</script>

<style>

</style>