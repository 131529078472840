<template>
    <div class="filter-bar-se my-4">
        <div class="text-center w-25" v-if="canDownloadReport && passPropertiesData && passPropertiesData.length > 0">
            <downloadexcel
                class="comman-btn-2"
                :fetch="exportPassPropertiesToExcel"
                :fields="getFields('passProperties')"
                :before-generate="startDownload"
                :before-finish="finishDownload"
            >
                {{ $t('download_excel') }}
            </downloadexcel>
        </div>
        <div class="filter-right-bar">
            <div class="dropdown">
                <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                    <img src="frontend/images/Sort-By.svg"> {{ $t('sort_by') }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Oldest' }"
                           @click.prevent="sort('Oldest')">{{ $t('oldest') }}</a></li>
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Newest' }"
                           @click.prevent="sort('Newest')">{{ $t('newest') }}</a></li>
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Highest Price' }"
                           @click.prevent="sort('Highest Price')">{{ $t('highest_price') }}</a></li>
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Lowest Price' }"
                           @click.prevent="sort('Lowest Price')">{{ $t('lowest_price') }}</a></li>
                </ul>
            </div>
        </div>
    </div>
    <template v-if="passPropertiesData && passPropertiesData.length > 0">
        <div class="table-responsive custom-table-design mt-4">

            <table class="text-center table-bordered align-middle" >
                <thead>
                <tr>
                    <th>{{ $t('property') }} ID</th>
                    <th>{{ $t('property_name') }}</th>
                    <th>{{ $t('rent_tenure') }}</th>
                    <th>{{ $t('date') }}</th>
                    <th>{{ $t('price') }}</th>
                    <th>{{ $t('status') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="property in passPropertiesData" :key="property.property?.id">
                    <td>{{ property.property_listing_id }}</td>
                    <td>{{ property.property_listing?.property_name }}</td>
                    <td>{{ property.rent_tenure ?? 'N/A' }}</td>
                    <td>{{ property.rent_start_date ?? property.sold_date }}</td>
                    <td>{{ property.rent_price ?? property.sold_price}}</td>
                    <td>{{ property.transaction_type_text }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </template>
    <template v-else>
        <div class="text-center mt-5 mb-3">{{ $t('no_records_found') }}</div>
    </template>
    <div class="pagination-se mt-3" v-if="passPropertiesData && passPropertiesData.length > 0">
        <nav aria-label="...">
            <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: dataFilters.page === 1 }"
                    @click="dataFilters.page > 1 && changePage(dataFilters.page - 1)" v-if="dataFilters.page > 1">
                    <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                              alt="Previous"></a>
                </li>
                <li v-for="pageNumber in paginatedPages(dataFilters.page, totalPages)" :key="pageNumber"
                    class="page-item" :class="{ active: pageNumber === dataFilters.page }">
                    <a class="page-link"
                       @click.prevent="pageNumber !== dataFilters.page && changePage(pageNumber)">
                        {{ pageNumber }}
                    </a>
                </li>
                <li class="page-item" :class="{ disabled: dataFilters.page === totalPages }"
                    @click="dataFilters.page < totalPages && changePage(dataFilters.page + 1)"
                    v-if="dataFilters.page < totalPages">
                    <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import downloadexcel from "vue-json-excel3";
import {mapState} from "vuex";
import {paginatedPages} from "@/utils/helpers";
import {getJsonFields} from "@/utils/jsonFieldsConfig";
import toastr from "toastr";

export default {
    name: 'PassPropertiesReport',
    components: {
        downloadexcel,
    },
    computed: {
        ...mapState(['isAuthenticated', 'user', 'userProfile']),
        canDownloadReport() {
            return (this.user.is_corporate && this.user?.package?.title == 'Developer');
        },
    },
    created() {
        this.fetchData();
    },
    data() {
        return {
            passPropertiesData: [],
            totalPages: 0,
            totalRecords: 0,
            dataFilters: {
                page: 1,
                sort_by_field: 'Newest',
            },
        }
    },
    methods: {
        //Excel related functions
        paginatedPages,
        getFields(type) {
            return getJsonFields(type);
        },
        startDownload() {
            toastr.info('Preparing the download...');
        },
        finishDownload() {
            toastr.success('Download completed!');
        },
        async exportPassPropertiesToExcel() {
            return this.passPropertiesData;
        },

        //Fetching Data
        async fetchData() {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/get-pass-property-detail',{
                    api_token: token,
                    user_id: this.user.id,
                    sort_by_field: this.dataFilters.sort_by_field,
                    page_number: this.dataFilters.page,
                });

                this.passPropertiesData =response.data.output.sold_rent_properties.data;
                this.totalRecords = response.data.output.total_records;
                this.totalPages = response.data.output.total_pages;

            } catch (error) {
                console.error('Unable to fetch data, error:', error.message);
            }
        },

        //Sort and Paginate functions
        sort(sortBy) {
            this.dataFilters.sort_by_field = sortBy;
            this.dataFilters.page = 1;

            this.fetchData();
        },
        changePage(page) {
            if (page > 0) {
                const totalPages = this.totalPages;
                if (page <= totalPages) {
                    this.dataFilters.page = page;
                    this.fetchData();
                }
            }
        },
    }
}

</script>


