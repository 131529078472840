<template>
    <div class="property-siderbar-boxs">
        <div class="">
            <a style="cursor:default"><b>{{ $t('my_favourable_directions') }}</b></a>
        </div>
        <div class="list-box">
            <ul v-if="Object.keys(personal_gua).length > 0">
                <li><b>Your Life Palace</b> <span><b>{{ $t('sectors') }} / {{ $t('directions') }}</b></span></li>
                <li v-for="(gua, index) in personal_gua" :key="index">
                    <span>{{ gua }}</span><span>{{ index.toUpperCase() }}</span>
                </li>
            </ul>
        </div>
        <div class="my-3">
            <a style="cursor:default"><b>{{ $t('personal_affinity_stars') }}</b></a>
        </div>
        <div v-if="!isAuthenticated" class="login-signup-overlay text-center">
            <a href="/sign-in" class="comman-btn-2"><b>{{ $t('login_to_check_favourable_directions_and_affinity_stars') }}</b></a>
        </div>
        <div class="list-box" v-if="isAuthenticated">
            <table v-if="Object.keys(affinity_stars).length > 0">
                <tr v-if="affinity_stars.nobleman_1 || affinity_stars.nobleman_2 || affinity_stars.intelligence">
                    <td><b>{{ $t('affinity_stars') }}</b></td>
                    <td><b>{{ $t('zodiac') }}/{{ $t('context') }}</b></td>
                </tr>
                <tr v-if="affinity_stars.nobleman_1">
                    <td>{{ $t('nobleman') }}</td>
                    <td>{{ affinity_stars.nobleman_1 }}</td>
                </tr>
                <tr v-if="affinity_stars.nobleman_2">
                    <td>{{ $t('nobleman') }}</td>
                    <td>{{ affinity_stars.nobleman_2 }}</td>
                </tr>
                <tr v-if="affinity_stars.intelligence">
                    <td>{{ $t('intelligence') }}</td>
                    <td>{{ affinity_stars.intelligence }}</td>
                </tr>
                <tr v-if="affinity_stars.general_characteristics">
                    <td>{{ $t('general_characteristics') }}</td>
                    <td>{{ affinity_stars.general_characteristics }}</td>
                </tr>
                <tr v-if="affinity_stars.positive_attributes">
                    <td>{{ $t('positive_attributes') }}</td>
                    <td>{{ affinity_stars.positive_attributes }}</td>
                </tr>
                <tr v-if="affinity_stars.negative_attributes">
                    <td>{{ $t('negative_attributes') }}</td>
                    <td>{{ affinity_stars.negative_attributes }}</td>
                </tr>

            </table>
            <span v-else>
                {{ $t('no_affinity_stars_found') }}.
            </span>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    export default {
        name: 'PersonalAffinityStars',
        data() {
            return {
                affinity_stars: [],
                personal_gua: []
            }
        },
        mounted() {
            this.fetchAffinityStars();
            this.fetchPersonalGua();
        },
        computed: {
            ...mapState(['isAuthenticated', 'user']),
        },
        methods: {
            async fetchAffinityStars() {
                try {
                    if (!this.isAuthenticated) {
                        return;
                    }
                    const response = await this.$axios.get(`api/users/${this.user.id}/personal-affinity-stars`,
                        {
                            params: {
                                api_token: localStorage.getItem('userToken')
                            }
                        });

                    if (response.status === 200) {
                        this.affinity_stars = response.data.output;
                    }
                } catch (error) {
                    console.error('Error retrieving affinity stars:', error);
                }
            },
            async fetchPersonalGua() {
                try {
                    if (!this.isAuthenticated) {
                        return;
                    }
                    const response = await this.$axios.get(`api/users/${this.user.id}/personal-gua`,
                        {
                            params: {
                                api_token: localStorage.getItem('userToken'),
                                good: true
                            }
                        });

                    if (response.status === 200) {
                        const fieldsNeeded = [
                            'n', 'ne', 'e', 'se', 's', 'sw', 'w', 'nw'
                        ];
                        const personalGuaData = fieldsNeeded.reduce((acc, field) => {
                            if (Object.prototype.hasOwnProperty.call(response.data.output, field)) {
                                acc[field] = response.data.output[field];
                            }
                            return acc;
                        }, {});

                        this.personal_gua = personalGuaData;
                    }
                } catch (error) {
                    console.error('Error retrieving personal gua:', error);
                }
            },
        }
    }
</script>