<template>
    <section class="signin-sec">
        <div class="container">
            <div class="signin-bx m-auto">
                <h1>{{ $t('verify_email') }}</h1>
                <p v-if="loading" class="text-center">Verifying...</p>
                <p v-if="verificationStatus === 'success'" class="text-center">Your email has been verified successfully!</p>
                <p v-if="verificationStatus === 'error'" class="text-center">There was an error verifying your email. Please try again later.</p>
            </div>
        </div>
    </section>
</template>
  
<script>
    export default {
        data() {
            return {
                token: this.$route.params.token, // Retrieve the token from the URL
                loading: true,
                verificationStatus: null,
            };
        },
        mounted() {
            this.verifyEmail();
        },
        methods: {
            async verifyEmail() { 
                try {
                    // Send token as part of the URL
                    await this.$axios.post(`/api/verify-email/${this.token}`);

                    this.verificationStatus = 'success';
                } catch (error) {
                    this.verificationStatus = 'error';
                } finally {
                    this.loading = false;
                }
            }

        },
    };
</script>
  