import {formatDate, formatPrice} from "@/utils/helpers";

export function getJsonFields(type) {
    const configs = {
        propertyAnalytic: {
            "Property Name": 'property.property_name',
            "Views": 'views',
            "Inquiries": 'inquiries',
            "Active Status": {
                field: 'property.is_active',
                callback: (value) => (value ? 'Active' : 'Inactive')
            },
            "Status": 'property.current_occupancy',
            "Created At": {
                field: 'property.created_at',
                callback: (value) => formatDate(value),
            },
        },
        projectAnalytic: {
            "Project Name": 'project.project_name',
            "Views": 'views',
            "Inquiries": 'inquiries',
            "Project Status": 'project.project_status',
            "Created At": {
                field: 'project.created_at',
                callback: (value) => value ? formatDate(value) : '-',
            },
        },
        passProperties: {
            "Property ID": "property_listing_id",
            "Property Name": "property_listing.property_name",
            "Rent Tenure": {
                field: "rent_tenure",
                callback: (value) =>(value ?? 'N/A')
            },
            "Date": {
                field: "rent_start_from",
                callback: (value) =>(value ?? "sold_date")
            },
            "Price": {
                field: "rent_price",
                callback: (value) =>(value ?? "sold_price")
            },
            "Status": "transaction_type_text"
        },
        chatDetails: {
            "Sender ID": "latest_chat.sender_id",
            "Sender Name": "latest_chat.sender.name",
            "Property Listing ID": {
                field: "chat_details.property_listing_id",
                callback: (value) => (value ?? 'N/A')
            },
            "Property Name":{
                field: "chat_details.property_listing.property_name",
                callback:  (value) => (value ?? 'N/A')
            },
            "Asking Price Listed": {
                field: "chat_details.property_listing.asking_price_sale_rent",
                callback: (value) => (value ? formatPrice(value) : '-')
            },
            "Vacant Status": {
                field: "chat_details.property_listing.current_occupancy",
                callback: (value) => (value ?? 'N/A')
            },
            "Last Chatted": {
                field: "latest_chat.created_at",
                callback: (value) => value ? formatDate(value) : '-',
            },
            "Reactivity": "latest_chat.is_warm_text",
        },
        chatProjectDetails: {
            "Sender ID": "latest_chat.sender_id",
            "Sender Name": "latest_chat.sender.name",
            "Project Listing ID": {
                field: "chat_details.project_listing_id",
                callback: (value) => (value ?? 'N/A')
            },
            "Project Name": {
                field: "chat_details.project_listing.project_name",
                callback: (value) => (value ?? 'N/A')
            },
            "Land Area From": {
                field: "chat_details.project_listing.land_area_from",
                callback: (value) => (value ? parseInt(value) : '-')
            },
            "Land Area To": {
                field: "chat_details.project_listing.land_area_to",
                callback: (value) => (value ? parseInt(value) : '-')
            },
            "Land Area Unit": "chat_details.project_listing.land_area_unit",
            "Price From": {
                field: "chat_details.project_listing.listing_price_from",
                callback: (value) => (value ? formatPrice(value) : '-')
            },
            "Price To": {
                field: "chat_details.project_listing.listing_price_to",
                callback: (value) => (value ? formatPrice(value) : '-')
            },
            "Developer": {
                field: "chat_details.project_listing.developer_info_name",
                callback: (value) => (value ?? 'N/A')
            },
            "Last Chatted": {
                field: "latest_chat.created_at",
                callback: (value) => value ? formatDate(value) : '-',
            },
            "Reactivity": "latest_chat.is_warm_text",
        },
        serviceSent: {
            "ID": "id",
            "Agent Name":"agent.name",
            "Property Listing ID": "property_listing_id",
            "Property Name":"property_listing.property_name",
            "Engagement Period (Month)": "engagement_period",
            "From": {
                field: "property_listing.serviced_by_agents",
                callback: (agents) => {
                    if (!agents || agents.length === 0) return '-';
                    return agents[0]?.pivot?.valid_from ? formatDate(agents[0].pivot.valid_from) : '-';
                }
            },
            "To": {
                field: "property_listing.serviced_by_agents",
                callback: (agents) => {
                    if (!agents || agents.length === 0) return '-';
                    return agents[0]?.pivot?.valid_to ? formatDate(agents[0].pivot.valid_to) : '-';
                }
            },
            "Property Price (SGD)": {
                field: "property_listing.asking_price_sale_rent",
                callback: (value) => formatPrice(value) ?? 'N/A'
            },
            "Commission (%)": "commission",
            "Appointment Type": "appointment_type_text"

        },
        serviceReceived: {
            "ID": "id",
            "User Name":"user.name",
            "Property Listing ID": "property_listing_id",
            "Property Name":"property_listing.property_name",
            "Engagement Period (Month)": "engagement_period",
            "From": {
                field: "property_listing.serviced_by_agents",
                callback: (agents) => {
                    if (!agents || agents.length === 0) return '-';
                    return agents[0]?.pivot?.valid_from ? formatDate(agents[0].pivot.valid_from) : '-';
                }
            },
            "To": {
                field: "property_listing.serviced_by_agents",
                callback: (agents) => {
                    if (!agents || agents.length === 0) return '-';
                    return agents[0]?.pivot?.valid_to ? formatDate(agents[0].pivot.valid_to) : '-';
                }
            },
            "Property Price (SGD)": {
                field: "property_listing.asking_price_sale_rent",
                callback: (value) => formatPrice(value) ?? 'N/A'
            },
            "Commission (%)": "commission",
            "Appointment Type": "appointment_type_text"

        }
    };

    return configs[type] || {};
}