<template>
  <div class="container">
    <div v-if="!loading" class="card">
      <p>{{ errorMessage }}</p>

      <button @click="goHome" class="comman-btn-2">
        Back to Homepage
      </button>
    </div>
  </div>
</template>

<style scoped>
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    flex-direction: column;
  }

  .card {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    text-align: center;
    margin-bottom: 20px; /* Add margin between elements */
  }

  .error {
    color: red;
  }

  .back-button:hover {
    background-color: #0056b3;
  }
</style>
  
  <script>
  export default {
    data() {
      return {
        loading: true,
        errorMessage: '', // Holds the error message to display
      };
    },
  
    mounted() {
      // Extract the 'error' parameter from the URL
      const urlParams = new URLSearchParams(window.location.search);
      const error = urlParams.get('error'); // Correctly get the error parameter
  
      if (error === '1') {
        this.errorMessage = "Login via Google/Facebook is failed. Please try again later.";
      } else if (error === '2') {
        this.errorMessage = "This email is already registered using normal sign-up method. Please login via email and password.";
      }
  
      this.loading = false; // Stop loading once the check is done
    },

    methods: {
        goHome() {
        this.$router.push('/'); // Redirect to homepage
        }
    }
  };
  </script>
  