<template>
    <section class="pricing-se">
        <div class="container-xl">
            <div class="comman-title text-center">
                <h2>{{ $t('price_and_package_plan') }}</h2>
            </div>
            <div class="row">
                <div class="col-md-12 text-center mb-2"><h4>{{ $t('only_verified_user_can_purchase_package') }}</h4></div>
            </div>
            <div class="row">
                <div v-if="packages.length === 0">
                    {{ $t('no_records_found') }}.
                </div>
                <div v-else class="col-md-4" v-for="packageItem in packages" :key="packageItem.id">
                    <div class="pricing-box">
                        <template v-if="!userActivePackage">
                            <div class="badge-po-re"
                                v-if="packageItem.highlight_text && packageItem.highlight_text.length > 0">{{
                                packageItem.highlight_text }}</div>
                        </template>
                        <template v-else>
                            <div class="badge-po-re" v-if="userActivePackage.PAF_product_id === packageItem.id">{{ $t('currently_subscribed') }}</div>
                        </template>
                        <h3>{{ packageItem.name }} {{ packageItem.second_title }}</h3>
                        <p>{{ packageItem.sub_title }}</p>
                        <div class=" d-block" v-if="packageItem.annual_subscription !== packageItem.current_annual_subscription">
                            <span class="price">
                                {{ formatPrice(parseFloat(packageItem.current_annual_subscription).toFixed(packageItem.current_annual_subscription % 1 !== 0 ? 2 : 0)) }}<br>
                            </span>
                            <del style="color: gray; font-size: 40px;">
                                {{ formatPrice(parseFloat(packageItem.annual_subscription).toFixed(packageItem.annual_subscription % 1 !== 0 ? 2 : 0)) }}
                            </del>
                        </div>
                        <div class="price" v-else>
                            <span>
                                S${{ parseFloat(packageItem.current_annual_subscription).toFixed(packageItem.current_annual_subscription % 1 !== 0 ? 2 : 0) }}
                            </span>
                        </div>
                        
                        <ul v-if="Object.keys(filteredPackageFeatures(packageItem)).length > 0">
                            <li v-for="(feature, index) in filteredPackageFeatures(packageItem)"
                                :key="'feat-'+feature">
                                {{ formatTitleCase(index) }}
                            </li>
                        </ul>
                        <div class="readmore mt-4 mb-3" data-bs-toggle="modal" data-bs-target="#pricing-detail">
                            <a href="javascript:;" style="font-size: 14px;">{{ $t('view_more') }} <i
                                    class="fa fa-arrow-right"></i></a>
                        </div>
                        <a href="#"  v-if="!userActivePackage && isVerified" class="comman-btn-2" @click.prevent="choosePackage(packageItem)">
                            {{ $t('choose_package') }}
                        </a>
                        <a href="#" v-else-if="userActivePackage && isVerified && userActivePackage.PAF_product_id !== packageItem.id" class="comman-btn-2" data-bs-toggle="modal" data-bs-target="#changePackageDetails" @click.prevent="getChangePackageDetails(packageItem)">
                            {{ $t('change_package') }}
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </section>

    <!--Change Package Amount Details-->
    <div class="modal fade" id="changePackageDetails" tabindex="-1" aria-labelledby="changePackageDetailsLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
                        src="frontend/images/close.svg" alt=""></button>
                <div class="modal-body">
                    <label><b>{{ $t('credit_points_to_be_refunded_to_you') }}:</b> {{ refundCreditPoints.toFixed(2) }}</label>
                    <label><b>{{ $t('credit_points_to_pay_for_new_package') }}:</b> {{ paymentCreditPoints }}</label>

                    <small class="text-wrap">{{ $t('your_credit_points_will_be_refunded_and_paid_immediately') }}. 
                        {{ $t('kindly_ask_for_your_consideration_as_your_new_package_subscription_is_awaiting_for_admin_approval') }}.</small>
                </div>
                <div class="modal-footer">
                    <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">{{ $t('close') }}</button>
                    <button type="button" class="comman-btn-2" data-bs-dismiss="modal" @click="submitChangePlan">{{ $t('yes,im_sure') }}</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Pricing Popup Start -->
    <div class="modal fade otp" id="pricing-detail" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
                        src="frontend/images/close.svg" alt=""></button>
                <div class="modal-body">
                    <div class="pricing-table-box">
                        <div class="table-responsive">
                            <table class="table text-center table-borderless align-middle">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th v-if="privatePackages.length > 0 && user.is_private_user" :colspan="privatePackages.length">{{ $t('private') }}</th>
                                        <th v-if="businessPackages.length > 0 && user.is_business" :colspan="businessPackages.length">{{ $t('business') }}</th>
                                        <th v-if="corporatePackages.length > 0 && user.is_corporate" :colspan="corporatePackages.length">{{ $t('corporate') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="packages.length === 0">
                                        <tr>
                                            {{ $t('no_records_found') }}.
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <th class="text-start" style="padding-left: 30px;">Service Offered</th>
                                            <template v-if="privatePackages.length > 0 && user.is_private_user">
                                                <td class="px-2" v-for="(curPackage, packageIndex) in privatePackages" :key="packageIndex">{{ curPackage.title }}</td>
                                            </template>
                                            <template v-if="businessPackages.length > 0 && user.is_business">
                                                <td class="px-2" v-for="(curPackage, packageIndex) in businessPackages" :key="packageIndex">{{ curPackage.title }}</td>
                                            </template>
                                            <template v-if="corporatePackages.length > 0 && user.is_corporate">
                                                <td class="px-2" v-for="(curPackage, packageIndex) in corporatePackages" :key="packageIndex">{{ curPackage.title }}</td>
                                            </template>
                                        </tr>
                                        <template v-for="(feature, featureIndex) in allFeatures" :key="featureIndex">
                                            <tr>
                                                <td class="text-start bg-light" :colspan="totalColspan">
                                                    {{ feature.feature }}
                                                </td>
                                            </tr>
                                            <tr v-for="(columnName, itemIndex) in feature.items" :key="itemIndex">
                                                <td class="text-start">
                                                    <span>{{ formatTitleCase(mapColumnDisplay(columnName)) }}</span>
                                                </td>
                                                <template v-if="privatePackages.length > 0 && user.is_private_user">
                                                    <td v-for="(curPackage, packageIndex) in privatePackages" :key="packageIndex">
                                                        {{ curPackage[columnName] === 0 ? 'N' : curPackage[columnName] === 1 ? 'Y' : curPackage[columnName] === 2 ? 'N/A' : curPackage[columnName] }}
                                                    </td>
                                                </template>
                                            
                                                <template v-if="businessPackages.length > 0 && user.is_business">
                                                    <td v-for="(curPackage, packageIndex) in businessPackages" :key="packageIndex">
                                                        {{ curPackage[columnName] === 0 ? 'N' : curPackage[columnName] === 1 ? 'Y' : curPackage[columnName] === 2 ? 'N/A' : curPackage[columnName] }}
                                                    </td>
                                                </template>
                                                <template v-if="corporatePackages.length > 0 && user.is_corporate">
                                                    <td v-for="(curPackage, packageIndex) in corporatePackages" :key="packageIndex">
                                                        {{ curPackage[columnName] === 0 ? 'N' : curPackage[columnName] === 1 ? 'Y' : curPackage[columnName] === 2 ? 'N/A' : curPackage[columnName] }}
                                                    </td>
                                                </template>
                                            </tr>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if=" user.is_mobile_verified == 0" class="modal fade" id="verifyMobileNumber" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true" aria-labelledby="staticBackdropLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="verifyMobileNumberLabel">{{ $t('verification_needed') }}!</h5>
                </div>
                <div class="modal-body">
                    <p class="mb-3">{{ $t('please_verify_your_mobile_number') }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="comman-btn-2-danger" @click="clearError" data-bs-dismiss="modal">{{ $t('close') }}</button>
                    <button type="button" class="comman-btn-2" @click="navigateToVerify">{{ $t('verify_now') }}</button>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Pricing Popup End -->
</template>
<style type="text/css">
    .pricing-table-box table tr td {
        font-weight: normal !important;
    }
</style>
<script>
    import { mapState } from 'vuex';
    import toastr from 'toastr';
    import { formatPrice, formatTitleCase } from "@/utils/helpers";

    export default {
        data() {
            return {
                isVerified: '',
                packages: [],
                packageFeatures: [],
                allFeatures: [
                    {
                        feature: "General Configuration",
                        isParentFeature: true,
                        items: [
                            "annual_subscription",
                            "promotional_rebate",
                            "current_annual_subscription",
                            "credit_points_upon_1st_sign_up",
                            "listing_active_period",
                            "concurrent_listing_count",
                        ],
                    },
                    {
                        feature: "Business & Corporate Configuration",
                        isParentFeature: true,
                        items: [
                            "feature_agent_company_profile",
                            "feature_agent_company_profile_in_days",
                        ],
                    },
                    {
                        feature: "Property & Project Configuration",
                        isParentFeature: true,
                        items: [
                            "feature_property_project_listing",
                            "feature_property_project_listing_in_days",
                        ],
                    },
                    {
                        feature: "Service Request Configuration",
                        isParentFeature: true,
                        items: [
                            "SR_submit_listing_assignment",
                            "SR_receive_listing_assignment",
                        ],
                    },
                    {
                        feature: "Hybrid Listing Configuration",
                        isParentFeature: true,
                        items: [
                            "referral_url"
                        ],
                    },
                    {
                        feature: "Features",
                        isParentFeature: true,
                        items: [
                            "leads_management_dashboard",
                            "listing_star_CAP_score",
                            "agent_profile_rating",
                        ],
                    },
                    {
                        feature: "Fengshui",
                        isParentFeature: true,
                        items: [
                            "FSM_property_flying_stars_combos",
                            "FSM_auspicious_dates_clashes",
                            "FSM_Personal_favourable_sectors",
                            "FSM_personal_affinity_stars",
                            "FSM_property_favourable_sectors",
                            "FSM_property_auspicious_stars",
                        ],
                    },
                ],
                userActivePackage: null,

                // change plan
                refundCreditPoints: 0,
                paymentCreditPoints: 0,
                packageToChange: null,
            };
        },
        computed: {
            ...mapState(['isAuthenticated', 'user']),
            privatePackages() {
                return this.packages.filter(p => p.show_in_private == 1);
            },
            businessPackages() {
                return this.packages.filter(p => p.show_in_business == 1);
            },
            corporatePackages() {
                return this.packages.filter(p => p.show_in_corporate == 1);
            },
            totalColspan() {
                return 1 + this.privatePackages.length + this.businessPackages.length + this.corporatePackages.length;
            }
        },
        mounted() {
            this.fetchUserProfile();
            if (this.user.is_mobile_verified === 0) {
                this.showModal();
            }
        },
        async created() {
            await this.fetchUserProfile();
            await this.fetchPackages();
        },
        methods: {
            formatPrice,
            formatTitleCase,
            showModal() {
                window.$('#verifyMobileNumber').modal('show');
            },
            navigateToVerify() {
                // Remove any remaining Bootstrap modal backdrop
                document.querySelectorAll('.modal-backdrop').forEach(backdrop => {
                    backdrop.remove();
                });

                this.$router.push('/verify-number'); // Navigate after cleaning up
            },
            async fetchUserProfile() {
                if (!this.isAuthenticated) {
                    return;
                }
                try {
                    const response = await this.$axios.post("/api/my-profile-detail", {
                        api_token: localStorage.getItem('userToken'),
                        user_id: this.user.id
                    });

                    var userData = response.data.output;

                    this.isVerified = userData?.is_admin_verified;
                    this.userActivePackage = response.data.output.package_details || null;
                } catch (error) {
                    console.error('Error fetching profile:', error);
                }
            },
            async fetchPackages() {
                try {
                    const packagesResponse = await this.$axios.post('/api/package-list', {
                        api_token: localStorage.getItem('userToken'),
                        is_private: this.user.is_private_user,
                        is_business: this.user.is_business,
                        is_corporate: this.user.is_corporate,
                    });

                    this.packages = packagesResponse.data.output;
                } catch (error) {
                    console.error('Error fetching packages:', error);
                }
            },
            filteredPackageFeatures(packageItem) {
                let fieldsInclude = ['feature', 'rating', 'SR_submit', 'leads', 'new_listing'];
                return Object.fromEntries(
                    Object.entries(packageItem).filter(
                        ([key, value]) => value === 1 && fieldsInclude.some(field => key.includes(field))
                    )
                );
            },
            mapColumnDisplay(colName) {
                const customDisplays = {
                    listing_active_period: 'Listing Duration (Months)',
                };

                if (colName.startsWith("FSM_")) {
                    colName = colName.replace(/^FSM_/, '');
                }

                return customDisplays[colName] || colName;
            },
            async getChangePackageDetails(packageItem) {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.get(`/api/users/${this.user.id}/packages/${packageItem.id}/change-details`,
                    {
                        params: {
                            api_token: token,
                        }
                    });

                    if (response.data.output) {
                        this.refundCreditPoints = response.data.output.refund_amount;
                        this.paymentCreditPoints = response.data.output.payment_amount;
                        this.packageToChange = packageItem;
                    } else {
                        toastr.error('Failed to fetch package change details. ' + response?.data?.error, 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    toastr.error('Failed to fetch package change details. ' + error?.response?.data?.error, 'Error', { timeOut: 3000 });
                    console.error('Error fetching package change details:', error);
                }
            },
            async submitChangePlan() {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.post(`/api/users/${this.user.id}/packages/${this.packageToChange.id}/change`,
                        {
                            api_token: token,
                        }
                    );

                    if (response.data.output) {
                        toastr.success(response.data.message, { timeOut: 3000 });
                    } else {
                        toastr.error('Failed to change package. ' + response?.data?.error, 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    toastr.error('Failed to change package.' + error?.response?.data?.error, 'Error', { timeOut: 3000 });
                    console.error('Error changing package:', error);
                }
            },
            async choosePackage(packageItem) {
                if (this.userActivePackage) {
                    return;
                }
                this.redirectToPayment(packageItem.id);
            },
            redirectToPayment(packageId) {
                this.$router.push({
                    name: 'Payments',
                    query: { packageId },
                });
            },
            isFloat(x) {
                return !!(x % 1);
            },
        }
    };
</script>