<template>
    <section class="property-slider-se" style="height:100vh; overflow-y:scroll">
        <LoaderCust :isLoading="loading"/>
        <div :class="['container', loading ? 'd-none' : '']">
            <div class="property-slider">
                <div class="property-slider-left">
                    <div class="property-slider-f-slider owl-carousel owl-theme" v-if="propertyImages.length>0">
                        <div class="item" v-for="pimage in propertyImages" :key="pimage.property_listing_id">
                            <div class="property-slider-images">
                                <img :src="`${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${pimage.photo_name}`"
                                     :alt="pimage.photo_name">
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="item">
                            <div class="property-slider-images">
                                <img :src="firstImageImgSrc">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="property-slider-right">
                    <div class="property-slider-thumb">
                        <img :src="firstImageImgSrc">
                    </div>
                    <div class="property-slider-thumb mb-0" v-if="propertyImages.length > 0">
                        <a v-for="(image, index) in propertyImages" :key="index" :class="{'d-none': index != 0}"
                           data-bs-toggle="modal" data-bs-target="#imageModal">
                            <img :src="`${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${image.photo_name}`"
                                 :alt="image.photo_name" class="img-fluid">
                            <span class="overlay">{{morePhotocount}} {{ $t('photos') }} </span>
                        </a>
                    </div>
                </div>
                <div class="modal fade" id="imageModal" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="imageModalLabel">{{ $t('property_images') }}</h5>
                            </div>
                            <div class="modal-body">
                                <div class="image-list d-flex flex-column overflow-auto text-center" style="max-height: 70vh;">
                                    <div v-for="(image, index) in propertyImages" :key="index" class="image-item mb-3">
                                        <img :src="`${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${image.photo_name}`"
                                             :alt="image.photo_name" class="img-fluid rounded">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="property-details-se">
                <div class="row">
                    <div class="col-md-12">
                        <div class="property-title-se d-flex align-items-center">
                            <div class="d-flex flex-grow-1 align-items-center">
                                <h3 class="mb-0 text-wrap d-flex align-items-center">
                                    {{ propertyDetail.property_name }}
                                    <img src="frontend/images/varify-user.svg" alt="">
                                    <img src="frontend/images/file-check.svg" alt="">
                                    <img src="frontend/images/compass-icon.svg" alt="">
                                </h3>
                            </div>
                            <div class="col-auto text-end ms-3">
                                <span class="property-price">S$ {{ formatPrice(propertyDetail.asking_price_sale_rent) }}</span>
                            </div>
                        </div>
                        <div class="property-meta-se">
                            <ul>
                                <li><img src="frontend/images/map-gol-ico.svg" alt=""><span><b>{{ formattedAddress }}</b></span></li>
                                <li><img src="frontend/images/map-gol-ico.svg" alt=""><span><b>{{ $t('listing_reference_id') }}: {{
                                        propertyDetail.id }}</b></span></li>
                                <li><img src="frontend/images/map-gol-ico.svg" alt=""><span><b>{{ $t('published_until') }}: {{ computedPublishedTillDate }}</b></span></li>
                            </ul>
                        </div>
                        <div class="modal fade" id="sharePropertyModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                             aria-hidden="true">
                            <div class="modal-dialog modal-lg modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5">{{ $t('share_property') }}</h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
                                            src="frontend/images/close.svg" alt=""></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row row-cols-1 row-cols-sm-4 share-property-sec">
                                            <div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('copy')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/copy-link.png" class="card-img-top"
                                                             alt="Copy Link Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">{{ $t('copy')}} {{ $t('link')}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('email')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/email-icon.svg" class="card-img-top"
                                                             alt="Email Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">{{ $t('email') }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--<div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('message')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/message.png" class="card-img-top"
                                                            alt="Message Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">Message</span>
                                                    </div>
                                                </div>
                                            </div>-->
                                            <div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('whatsapp')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/whatsapp.png" class="card-img-top"
                                                             alt="Whatsapp Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">{{ $t('whatsapp') }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('messenger')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/messenger.png" class="card-img-top"
                                                             alt="Whatsapp Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">{{ $t('messenger') }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('twitter')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/twitter.png" class="card-img-top"
                                                             alt="Twitter Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">{{ $t('twitter') }} (X)</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('facebook')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/fb-ico.svg" class="card-img-top"
                                                             alt="Facebook Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">{{ $t('facebook') }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('embed')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/embed.png" class="card-img-top"
                                                             alt="Embed Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">{{ $t('embed') }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="property-details-btn-se justify-content-between">
                            <ul>
                                <li><a :class="{ active: selectedTab === 'propertyDetails' }" @click="selectedTab = 'propertyDetails'">{{ $t('property_details') }}</a></li>
                                <li><a :class="{ active: selectedTab === 'fengShui' }" @click="selectedTab = 'fengShui'">{{ $t('feng_shui') }}</a></li>
                            </ul>
                            <ul>
                                <li><a @click="wishlistProperty" style="cursor:pointer" class="btn">
                                    <img v-if="propertyDetail.is_in_wishlist" src="frontend/images/heart-filled-ico.svg" alt="">
                                    <img v-else src="frontend/images/heart-ico.svg" alt="">
                                </a></li>
                            </ul>
                        </div>
                        <div v-if="selectedTab === 'propertyDetails'" class="accordion custom-accordion" id="accordion-one">
                            <div class="accordion-item">
                                <div class="accordion-header" id="heading1">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            :data-bs-target="'#agent-information' + propertyDetail?.id" aria-expanded="true" aria-controls="agent-information">
                                        Agent Information
                                    </button>
                                </div>
                                <div :id="'agent-information' + propertyDetail?.id" class="accordion-collapse collapse" aria-labelledby="heading1">
                                    <div class="row mx-1 mb-1">
                                        <div class="profile-box col-md-4">
                                            <img :src="computedProfileImgSrc">
                                            <div>
                                                <h5>{{propertyUserDetail?.name}} <img src="frontend/images/varify-user.svg" alt="">
                                                </h5>
                                                <span>{{ $t('singapore') }}</span>
                                            </div>
                                        </div>
                                        <div class="list-box col-md-8">
                                            <ul>
                                                <li v-if="user_service_details">{{ $t('position') }}:<span><b>{{user_service_details.position_held}}</b></span></li>
                                                <li v-if="user_service_details">{{ $t('cea_registration_no') }}. <span><b>{{user_service_details.agency_registration_no }}</b></span></li>
                                                <li v-if="user_service_details">{{ $t('agency_name') }}<span><b>{{propertyUserDetail?.agency_name}}</b></span></li>
                                                <li v-if="user_service_details">{{ $t('agency_license_no') }}.<span><b>{{user_service_details.agency_license}}</b></span></li>
                                                <li>{{ $t('mobile_no') }}:<span><b>{{ maskedNumber(propertyUserDetail?.mobile_number) }}</b></span></li>
                                            </ul>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div v-if="selectedTab === 'propertyDetails'" class="accordion custom-accordion" id="accordion-two">
                            <div class="accordion-item">
                                <div class="accordion-header" id="heading2">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            :data-bs-target="'#personal-auspicious' + propertyDetail?.id" aria-expanded="true" aria-controls="personal-auspicious">
                                        {{ $t('personal_affinity_stars') }} & Auspicious Dates
                                    </button>
                                </div>
                                <div :id="'personal-auspicious' + propertyDetail?.id" class="accordion-collapse collapse" aria-labelledby="heading2">
                                    <div class="row mx-1">
                                        <div class="col-md-6">
                                            <PersonalAffinityStars></PersonalAffinityStars>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="property-siderbar-boxs">
                                                <div class="comman-title">
                                                    <a><b>{{ $t('auspicious_dates') }}</b> </a>
                                                </div>
                                                <div v-if="!isAuthenticated" class="login-signup-overlay text-center">
                                                    <a href="/sign-in" class="comman-btn-2"><b>{{ $t('login_to_view_dates') }}</b></a>
                                                </div>
                                                <div class="calendar" :class="!isAuthenticated ? 'd-none' : ''">
                                                    <header>
                                                        <pre class="left"><i class="fa fa-angle-left"></i></pre>
                                                        <div class="header-display">
                                                            <p class="display mb-0">""</p>
                                                        </div>
                                                        <pre class="right"><i class="fa fa-angle-right"></i></pre>
                                                    </header>

                                                    <div class="week">
                                                        <div>S</div>
                                                        <div>M</div>
                                                        <div>T</div>
                                                        <div>W</div>
                                                        <div>T</div>
                                                        <div>F</div>
                                                        <div>S</div>
                                                    </div>
                                                    <div class="days"></div>
                                                    </div>
                                                    <div class="display-selected" :class="!isAuthenticated ? 'd-none' : ''">
                                                        <p class="selected"></p>
                                                    </div>
                                                    <div class="lrm-czh-se" v-if="auspicious[itemId]" :class="!isAuthenticated ? 'd-none' : ''">
                                                        <div class="">
                                                            {{ $t('legend') }}
                                                            <button class="btn renovate-legend pe-none">{{ $t('renovation') }}</button>
                                                            <button class="btn movein-legend pe-none">{{ $t('move_in') }}</button>
                                                        </div>
                                                        <div>
                                                            {{ $t('clashes') }}
                                                            <select v-model="auspicious[itemId].zodiac" class="form-control border border-danger">
                                                                <option value="">{{ $t('zodiac_sign') }}</option>
                                                                <option v-for="(zodOpt, index) in options.zodiac" :key="'zod'+index" :value="zodOpt">
                                                                    {{ zodOpt }}
                                                                </option>
                                                            </select>
                                                            <select v-model="auspicious[itemId].house_direction" class="form-control text-danger">
                                                                <option value="">{{ $t('house_direction') }}</option>
                                                                <option v-for="(houseDirOpt, ind) in options.house_direction" :key="'houseDir'+ind" :value="houseDirOpt">
                                                                    {{ houseDirOpt }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PropertyBasicInformation v-if="selectedTab === 'propertyDetails'" :property-detail="propertyDetail" :property-user-detail="propertyUserDetail"
                                                  :otherDocList="otherDocList" :mode_of_payment="mode_of_payment" :mediaLinksList="mediaLinksList"
                                                  :add_facilitiyList="add_facilitiyList" :facilitiyList="facilitiyList" :featuresList="featuresList"
                                                  :add_featuresList="add_featuresList" @refetch-property="fetchOptions">
                        </PropertyBasicInformation>
                        <PropertyFengShui v-if="selectedTab === 'fengShui'" :property-detail="propertyDetail">
                        </PropertyFengShui>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import LoaderCust from "@/components/LoaderCust.vue";
    import PropertyFengShui from "@/views/listing/PropertyFengShui.vue";
    import PersonalAffinityStars from "@/views/fengshui/PersonalAffinityStars.vue";
    import PropertyBasicInformation from "@/views/listing/PropertyBasicInformation.vue";
    import {CONSTANT} from "@/constants/constants";
    import {mapState} from "vuex";
    import toastr from "toastr";
    import { formatPrice } from '@/utils/helpers';

    export default {
        name: 'ComparedProperty',
        components: {PropertyBasicInformation, PersonalAffinityStars, PropertyFengShui, LoaderCust},
        props: {
            itemId: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                auspicious: {},
                options: {
                    zodiac: [
                        'Boar',
                        'Dog',
                        'Dragon',
                        'Goat',
                        'Horse',
                        'Monkey',
                        'Ox',
                        'Rabbit',
                        'Rat',
                        'Rooster',
                        'Snake',
                        'Tiger'
                    ],
                    house_direction: [
                        'N', 'E', 'S', 'W'
                    ]
                },
                agent_id: null,
                propertyDetail: [''],
                propertyUserDetail: [],
                user_service_details: [],
                propertyImages: [],
                morePhotocount: [],
                firstImage: [],
                secondImage: [],
                featuresList: [],
                add_featuresList: [],
                facilitiyList: [],
                add_facilitiyList: [],
                otherDocList: [],
                mediaLinksList: [],
                mode_of_payment: [],
                zodiacGoodDates: [],
                zodiacBadDates: [],
                directionGoodDates: [],
                directionBadDates: [],
                selectedTab: 'propertyDetails',
            }
        },
        computed: {
            CONSTANT() {
                return CONSTANT
            },
            ...mapState(['isAuthenticated', 'user']),
            computedProfileImgSrc() {
                return this.propertyUserDetail?.profile_photo_name ? `${CONSTANT.IMAGE_URL}/imagesProfile/${this.propertyUserDetail.profile_photo_name}` : CONSTANT.DEFAULT_IMAGE;
            },
            firstImageImgSrc() {
                if (this.firstImage) {
                    return this.firstImage.photo_name ? `${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${this.firstImage.photo_name}` : CONSTANT.DEFAULT_NO_IMAGE;
                } else {
                    return CONSTANT.DEFAULT_NO_IMAGE;
                }
            },
            computedPublishedTillDate() {
                if (this.propertyDetail.published_till_date) {
                    const date = new Date(this.propertyDetail.published_till_date);
                    return date.toISOString().split('T')[0];
                }
                return '-';
            },
            formattedAddress() {
                let addressParts = [
                    this.propertyDetail.unit_no,
                    this.propertyDetail.street,
                    this.propertyDetail.area_hdb_estate,
                    this.propertyDetail.district
                ];
                let address = addressParts.filter(part => part !== null && part !== undefined).join(', ');
                return address || '-';
            },

        },
        created() {
            this.fetchOptions()
            this.getAuspiciousDates();
        },
        mounted() {
            if (!this.auspicious[this.itemId]) {
                this.auspicious[this.itemId] = {
                    zodiac: "",
                    house_direction: ""
                };
            }

            let display = this.$el.querySelector(".display"); // Scoped to this instance
            let days = this.$el.querySelector(".days");
            let previous = this.$el.querySelector(".left");
            let next = this.$el.querySelector(".right");
            //let selected = document.querySelector(".selected");

            let date = new Date();

            let year = date.getFullYear();
            let month = date.getMonth();
            
            this.auspicious[this.itemId].date_month = month + 1;

            function displayCalendar() {
                const firstDay = new Date(year, month, 1);
                const lastDay = new Date(year, month + 1, 0);

                const firstDayIndex = firstDay.getDay();
                const numberOfDays = lastDay.getDate();

                let formattedDate = date.toLocaleString("en-US", {
                    month: "long",
                    year: "numeric"
                });

                display.innerHTML = `${formattedDate}`;

                days.innerHTML = ''; // Clear days before repopulating

                for (let x = 0; x < firstDayIndex; x++) {
                    const div = document.createElement("div");
                    days.appendChild(div);
                }

                for (let i = 1; i <= numberOfDays; i++) {
                    let div = document.createElement("div");
                    let currentDate = new Date(year, month, i);

                    div.dataset.date = currentDate.toDateString();
                    div.innerHTML = i;
                    div.classList.add("day-" + i);

                    /*Hide current date being selected
                        if (
                            currentDate.getFullYear() === new Date().getFullYear() &&
                            currentDate.getMonth() === new Date().getMonth() &&
                            currentDate.getDate() === new Date().getDate()
                        ) {
                            div.classList.add("current-date");
                        }
                    */

                    days.appendChild(div);
                }
            }

            displayCalendar();

            previous.addEventListener("click", () => {
                month--;
                if (month < 0) {
                    month = 11;
                    year--;
                }
                date.setFullYear(year);
                date.setMonth(month);
                this.auspicious[this.itemId].date_month = month + 1;
                displayCalendar();
                this.displaySelected();
            });

            next.addEventListener("click", () => {
                month++;
                if (month > 11) {
                    month = 0;
                    year++;
                }
                date.setFullYear(year);
                date.setMonth(month);
                this.auspicious[this.itemId].date_month = month + 1;
                displayCalendar();
                this.displaySelected();
            });

            // Initialize the calendar and selected date display
            this.displaySelected();
        },
        watch: {
            auspicious: {
                handler() {
                    this.getAuspiciousDates();
                },
                deep: true
            },
        },
        methods: {
            formatPrice,
            handleShare(actionType) {
                const currentLink = window.location.href;

                switch (actionType) {
                    case "copy":
                        navigator.clipboard.writeText(currentLink);
                        toastr.info("Link copied to clipboard!", "info", { timeOut: 3000 });
                        break;

                    case "email":
                        window.location.href = `mailto:?subject=Check this out&body=${encodeURIComponent(
                            currentLink
                        )}`;
                        break;

                    case "whatsapp":
                        window.open(
                            `https://wa.me/?text=${encodeURIComponent(currentLink)}`,
                            "_blank"
                        );
                        break;

                    case "messenger":
                        window.open(
                            `https://www.messenger.com/t?link=${encodeURIComponent(currentLink)}`,
                            "_blank"
                        );
                        break;

                    case "twitter":
                        window.open(
                            `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentLink)}&text=Check this out!`,
                            "_blank"
                        );
                        break;

                    case "facebook":
                        window.open(
                            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentLink)}`,
                            "_blank"
                        );
                        break;

                    case "embed": {
                        let embedCode = `<iframe src="${currentLink}" frameborder="0"></iframe>`;
                        navigator.clipboard.writeText(embedCode);
                        toastr.info("Embed code copied to clipboard!", "info", { timeOut: 3000 });
                        break;
                    }
                    default:
                        console.error("Unknown action type");
                }
            },
            maskedNumber(value) {
                if (value) {
                    const start = value.slice(0, 6); // +6016
                    const end = value.slice(-3); // 108
                    return `${start}****${end}`; // Combine parts with masking
                }
            },
            async fetchOptions() {
                const token = localStorage.getItem('userToken');
                try {
                    this.loading = true;
                    const response = await this.$axios.post('api/property-detail',
                        {
                            user_id: this.user.id,
                            api_token: token,
                            property_listing_id: this.itemId,
                        });
                    this.agent_id = response.data.output[0].user_id;
                    this.userCredit = response.data.output[0].available_credit_points;
                    this.propertyDetail = response.data.output[0];
                    this.propertyUserDetail = response.data.output[0].user_id_details;
                    this.user_service_details = response.data.output[0].user_id_details?.user_service_details;
                    this.propertyImages = response.data.output[0].actual_unit_photo;
                    this.morePhotocount = response.data.output[0].actual_unit_photo.length;
                    this.firstImage = response.data.output[0].actual_unit_photo[1];
                    this.secondImage = response.data.output[0].actual_unit_photo[2];
                    this.featuresList = response.data.output[0].features;
                    this.add_featuresList = response.data.output[0].features_additional;
                    this.facilitiyList = response.data.output[0].facilities;
                    this.add_facilitiyList = response.data.output[0].facility_additional;
                    this.otherDocList = response.data.output[0].doc_other_photos;
                    this.mediaLinksList = response.data.output[0].media_links;

                    setTimeout(function () {

                        window.$('.property-slider-f-slider').owlCarousel({
                            loop: false,
                            margin: 0,
                            nav: true,
                            dots: false,
                            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                            responsive: {
                                0: {
                                    items: 1,
                                },
                                600: {
                                    items: 1
                                },
                                1000: {
                                    items: 1
                                }
                            }
                        })

                    }, 1000);

                    // If not JSON, use it as is
                    try {
                        this.mode_of_payment = JSON.parse(response.data.output[0].mode_of_payment);
                    } catch (e) {
                        this.mode_of_payment = response.data.output[0].mode_of_payment;
                    }
                } catch (error) {
                    console.error('Error fetching options:', error);
                } finally {
                    this.loading = false;
                }
            },
            displaySelected() {
                const dayElements = this.$el.querySelectorAll(".days div");
                const zodiacGoodDates = this.zodiacGoodDates || [];
                const zodiacBadDates = this.zodiacBadDates || [];
                const directionGoodDates = this.directionGoodDates || [];
                const directionBadDates = this.directionBadDates || [];

                dayElements.forEach(dayElement => {
                    dayElement.classList.remove('good-date', 'renovate-good-date', 'movein-good-date', 'zodiac-bad-date', 'direction-bad-date');
                    // Extract the day number from the class (e.g., day-1, day-2)
                    const dayNumber = dayElement.classList.length > 0 ? parseInt(dayElement.classList[0].split('-')[1]) : null;
                    if (dayNumber && this.itemId) {
                        let classText = [];

                        // Check for good dates
                        if (zodiacGoodDates['renovation']?.includes(dayNumber) || directionGoodDates['renovation']?.includes(dayNumber)) {
                            classText.push('renovate-good-date');
                        }

                        if (zodiacGoodDates['move_in']?.includes(dayNumber) || directionGoodDates['move_in']?.includes(dayNumber)) {
                            classText.push('movein-good-date');
                        }

                        // Only show bad dates if zodiac or house direction is selected
                        if (this.auspicious[this.itemId].zodiac) {
                            if (zodiacBadDates['renovation']?.includes(dayNumber) || zodiacBadDates['move_in']?.includes(dayNumber)) {
                                classText.push('zodiac-bad-date');
                            }
                        }

                        if (this.auspicious[this.itemId].house_direction) {
                            if (directionBadDates['renovation']?.includes(dayNumber) || directionBadDates['move_in']?.includes(dayNumber)) {
                                classText.push('direction-bad-date');
                            }
                        }

                        // If both good dates are present, replace them with 'good-date'
                        if (classText.includes('renovate-good-date') && classText.includes('movein-good-date')) {
                            classText = classText.filter(className => className !== 'renovate-good-date' && className !== 'movein-good-date');
                            classText.push('good-date');
                        }

                        dayElement.classList.add(...classText);
                    }
                });

                /* dont need click listener
                const dayElements = document.querySelectorAll(".days div");
                dayElements.forEach((day) => {
                    day.addEventListener("click", (e) => {
                        // Remove previously selected date class
                        const previouslySelected = document.querySelector(".selected-date");
                        if (previouslySelected) {
                            previouslySelected.classList.remove("selected-date");
                        }

                        // Add selected date class
                        const selectedDate = e.target;
                        selectedDate.classList.add("selected-date");

                        selected.innerHTML = `Selected Date : ${selectedDate.dataset.date}`;
                    });
                });
                */
            },
            setAuspiciousType(type) {
                if (!this.auspicious[this.itemId]) {
                    this.auspicious[this.itemId] = {};
                }
                this.auspicious[this.itemId].type = type;
            },
            async getAuspiciousDates() {
                if (!this.isAuthenticated || !this.auspicious[this.itemId] || Object.keys(this.auspicious[this.itemId]).length < 1) {
                    return;
                }
                try {
                    let params = {
                        clash_zodiac: this.auspicious[this.itemId].zodiac,
                        clash_house_direction: this.auspicious[this.itemId].house_direction,
                        month: this.auspicious[this.itemId].date_month,
                        api_token: localStorage.getItem('userToken')
                    }

                    if (!params.month) {
                        return;
                    }

                    const response = await this.$axios.post(`api/auspicious-clash-dates/dates`, params);

                    if (response.status === 200) {
                        const output = response.data.output;

                        this.zodiacGoodDates = output.zodiac.good_dates;
                        this.zodiacBadDates = output.zodiac.bad_dates;

                        this.directionGoodDates = output.direction.good_dates;
                        this.directionBadDates = output.direction.bad_dates;
                        this.displaySelected();
                    }
                } catch (error) {
                    console.error('Error retrieving auspicious clash dates:', error);
                }
            },
            async wishlistProperty() {
                try {
                    if (!this.user?.id) {
                        toastr.error('Please log in to wishlist', 'error', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                        });
                        return;
                    }
                    const wishlistResponse = await this.$axios.post(`api/users/${this.user.id}/wishlist/properties/${this.propertyDetail.id}`, {
                        api_token: localStorage.getItem('userToken'),
                    });

                    if (wishlistResponse.status === 200) {
                        toastr.success(wishlistResponse.data.message, 'Success', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                            onHidden: () => {
                                this.fetchOptions();
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error wishlisting property:', error);
                }
            },
        }
    }
</script>

<style>

</style>