<template>
  <div class="container">
    <h1 class="my-5">Privacy and Policy</h1>
    <h3>1. Introduction</h3>
    <p>This Privacy Policy explains how <b>PROP</b>FACE collects, uses, and discloses your personal information. By using our platform, you consent to the practices described in this Policy.</p>

    <h3 class="mt-4">2. Information Collection</h3>
    <p>We may collect the following types of information:</p>
    <ul>
      <li>
        <p>{{ $t('personal_information') }}: Name, email address, phone number, and other contact details.</p>
      </li>
      <li>
        <p>Property Information: Details about properties listed on our platform.</p>
      </li>
      <li>
        <p>Usage Data: Information about how you use our platform, including IP addresses, browser type, and operating system.</p>
      </li>
    </ul>

    <h3 class="mt-4">3. Information Collection</h3>
    <p>Use of Information</p>
    <ul>
      <li>
        <p>Provide and improve our services.</p>
      </li>
      <li>
        <p>Communicate with you about your account and our services.</p>
      </li>
      <li>
        <p>Personalize your experience on our platform.</p>
      </li>
      <li>
        <p>Comply with legal obligations and enforce our Terms.</p>
      </li>
    </ul>

    <h3 class="mt-4">4. Information Sharing</h3>
    <p>We do not sell or rent your personal information to third parties. We may share your information with:</p>
    <ul>
      <li>
        <p>Service providers who assist us in operating our platform.</p>
      </li>
      <li>
        <p>Legal authorities if required by law.</p>
      </li>
      <li>
        <p>Other users, if you choose to share your information through our platform.</p>
      </li>
    </ul>

    <h3 class="mt-4">5. Data Security</h3>
    <p>We implement reasonable security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is completely secure. We cannot guarantee absolute security.</p>

    <h3 class="mt-4">6. Data Retention</h3>
    <p>We retain your personal information for as long as necessary to fulfil the purposes outlined in this Policy, unless a longer retention period is required or permitted by law.</p>

    <h3 class="mt-4">7. Your Rights</h3>
    <p>You have the right to:</p>
    <ul>
      <li>
        <p>Access and update your personal information.</p>
      </li>
      <li>
        <p>Request the deletion of your personal information.</p>
      </li>
      <li>
        <p>Object to the processing of your personal information.</p>
      </li>
      <li>
        <p>Withdraw your consent at any time.</p>
      </li>
    </ul>

    <h3 class="mt-4">8. Changes to Privacy Policy</h3>
    <p>We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting on our platform. Your continued use of our platform after any modifications constitutes your acceptance of the revised Policy.</p>

  </div>


</template>

<script>
export default {
  name: 'PrivacyPolicy',

};
</script>

<style scoped>
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
</style>