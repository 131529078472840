<template>
    <div class="search-form">
        <div class="tab-title form-group">
            <div class="row align-items-center">
                <div class="col-auto pe-0">
                    <p class="mb-0">Find {{ isProjectSearch ? 'Projects' : 'Properties'}} By Your Destiny Profile <i class="fa fa-search fa-flip-horizontal filterSearchIcon"></i></p>
                </div>
                <div class="col-auto ms-md-auto">
                    <div class="row justify-content-md-end">
                        <div class="col">
                            <select class="form-control" v-model="formData.gender">
                                <option value="" disabled selected hidden>{{ $t("gender") }}</option>
                                <option value="male">{{ $t("male") }}</option>
                                <option value="female">{{ $t("female") }}</option>
                            </select>
                        </div>
                        <div class="col">
                            <input type="text" onfocus="(this.type='date')" onblur="(this.type='text')" placeholder="Year Of Birth" class="form-control" v-model="formData.dateOfBirth">
                        </div>
                        <div class="col-auto">
                            <a href="#" @click.prevent="resetForm" class="comman-btn-5">
                                <img src="frontend/images/refresh-icon.svg">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row align-items-center">
                <div class="col position-relative">
                    <input type="text" class="form-control" name="emailid" @input="getAutocompleteSearch" v-model="formData.keywords" placeholder="Search by Address, School, City, Zip" >

                    <ul v-if="suggestions.length" class="autocomplete-list">
                        <li v-for="(suggestion, index) in suggestions" :key="index" @click="selectSuggestion(suggestion)">
                            {{ suggestion }}
                        </li>
                    </ul>
                </div>
                <div class="col-auto">
                    <button type="submit" class="w-100 btn comman-btn-2" @click="submitForm">
                        <i class="me-2"><img src="frontend/images/search-ico.svg"></i>{{ $t('search') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="row align-items-center justify-content-between">
            <div :class="{
                'col-6': !isProjectSearch,
                'col-4': isProjectSearch
            }">
                <select class="form-control text-center" title="Listing Type" v-model="formData.listingType">
                    <option value="" selected>{{ $t('all_listing_types') }}</option>
                    <option v-for="(option, index) in mappedListingTypes" :key="index" :value="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>
            <div class="col-4" v-if="isProjectSearch">
                <select class="form-control text-center" title="Listing Type" v-model="formData.locationType">
                    <option value="" selected>All Locations</option>
                    <option value="local">Local</option>
                    <option value="x-border">X-Border</option>
                </select>
            </div>
            <div :class="{
                'col-6': !isProjectSearch,
                'col-4': isProjectSearch
            }">
                <div :class="dropdownClass" >
                    <div class="dropdown">
                        <button class="form-control btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false">
                            {{ showSelectedType(formData.selectedType) }}
                        </button>
                        <div class="dropdown-menu  p-4" aria-labelledby="dropdownMenuButton">
                            <!-- Radio buttons for selecting the main category -->
                            <div class="form-check" v-for="(child, parentType) in options.propertyTypes" :key="parentType">
                                <input class="form-check-input" type="radio" :id="parentType" :value="parentType" v-model="formData.selectedType">
                                <label class="form-check-label" :for="parentType">{{ parentType }}</label>
                            </div>

                            <!-- Display categories based on the selected type -->
                            <div v-if="formData.selectedType" class="mt-3">
                                <div v-for="items in options.propertyTypes[formData.selectedType]" :key="items.name">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :id="items.name" :checked="isCategorySelected(items)" @change="selectAll(items, $event.target.checked)">
                                        <label class="form-check-label" :for="items.name">{{ items.name }}</label>
                                    </div>
                                    <div style="padding-left: 20px;">
                                        <div class="form-check" v-for="item in items.children" :key="item">
                                            <input class="form-check-input" type="checkbox" :id="item.name" :name="items.name" :checked="isItemSelected(item)" @change="selectItem(item, $event.target.checked)">
                                            <label class="form-check-label" :for="item">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 my-2">
                <button type="button" class="btn btn-primary comman-btn-2 w-100" @click="isCollapse = !isCollapse">
                    {{ $t('more_filters') }}
                </button>
            </div>
            <div :class="'col-12 '+ (isCollapse ? 'collapse' : 'collapse show')" id="moreFilters">
                <div class="card card-body">
                    <div v-if="!retrievingPriceRange">
                        <label>{{ $t('price') }}</label>
                        <VueSlider v-model="formData.priceRange" :max="priceRangeMax" :lazy="true" tooltip="always" tooltipPlacement="bottom" :tooltip-formatter="val => formatWithCommas(val)"/>
                    </div>
                    <div v-if="!retrievingFloorArea" class="my-5">
                        <label>{{ $t('floor_area') }}</label>
                        <VueSlider v-model="formData.floorAreaRange" :max="floorAreaRangeMax" :lazy="true" tooltip="always" tooltipPlacement="bottom" :tooltip-formatter="val => formatWithCommas(val)"/>
                    </div>
                    <div v-if="!retrievingRoomsCount">
                        <label>{{ $t('no_of_rooms') }}</label>
                        <VueSlider v-model="formData.roomCountRange" :max="roomCountRangeMax" :lazy="true" tooltip="always" tooltipPlacement="bottom"/>
                    </div>
                    <div class="mt-5">
                        <label>{{ $t('furnishing_levels') }}</label>
                        <div class="form-check" v-for="(level, levelIndex) in options.furnishingLevels" :key="'furnishing-level-' + levelIndex">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                :id="'level-' + level.id"
                                :value="level.id"
                                v-model="formData.selectedFurnishingLevels"
                            >
                            <label class="form-check-label" :for="'level-' + level.id">{{ level.name }}</label>
                        </div>
                    </div>

                    <div class="mt-3">
                        <label>{{ $t('land_tenure') }}</label>
                        <div class="form-check" v-for="(tenure, tenureIndex) in options.landTenures" :key="'land-tenure-' + tenureIndex">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                :id="'land-tenure-' + tenure.id"
                                :value="tenure.id"
                                v-model="formData.selectedLandTenures"
                            >
                            <label class="form-check-label" :for="'land-tenure-' + tenure.id">{{ tenure.name }}</label>
                        </div>
                    </div>

                    <!--only for property search-->
                    <div class="mt-3" v-if="!isProjectSearch">
                        <label>{{ $t('facing_directions') }}</label>
                        <div class="form-check" v-for="(facingDirection, fdIndex) in options.facingDirections" :key="'facing-direction-' + fdIndex">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                :id="'facing-direction-' + facingDirection.id"
                                :value="facingDirection.id"
                                v-model="formData.selectedFacingDirections"
                            >
                            <label class="form-check-label" :for="'facing-direction-' + facingDirection.id">{{ facingDirection.cardinal }}</label>
                        </div>
                    </div>

                    <div class="mt-3">
                        <label>{{ $t('others') }}</label>
                        <div class="form-check" v-if="!isProjectSearch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="formData.is_direct_owner"
                            >
                            <label class="form-check-label">{{ $t('direct_owner') }}</label>
                        </div>
                        <div class="form-check" v-if="!isProjectSearch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="formData.is_FSM_linked"
                            >
                            <label class="form-check-label">{{ $t('fengshui_map') }}</label>
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="formData.is_approved_by_admin"
                            >
                            <label class="form-check-label">{{ $t('verified_property') }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlider from 'vue-3-slider-component'
import { getConstListingTypes, getConstPropertyTypes, getFurnishingLevelOptions, getLandTenureOptions, getFacingDirectionOptions } from "@/utils/apiHelpers";
export default {
    name: 'ExploreMoreSearch',
    components: { VueSlider },
    props: {
        type: {
            type: String,
            Required: true
        },
        params: {
            type: Object
        },
        queryData: {
            type: Object,
            required: false
        },
        isProjectSearch: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    data() {
        return {
            formData: this.queryData || {
                priceRange: [],
                floorAreaRange: [],
                roomCountRange: [],
            },
            isDropdownOpen: false,
            selectedType: null,
            showCategories: [],
            options: {
                propertyTypes: [],
                listingTypes: [],
                furnishingLevels: [],
                landTenures: [],
                facingDirections: []
            },
            suggestions: [],
            retrievingPriceRange: false,
            priceRangeMax: 100,
            retrievingFloorArea: false,
            floorAreaRangeMax: 100,
            retrievingRoomsCount: false,
            roomCountRangeMax: 100,
            isCollapse: true
        }
    },

    mounted() {
        document.addEventListener('click', this.handleOutsideClick);
        this.getOptions();
        this.getSliderRanges();
    },
       
    computed: {
        mappedListingTypes() {
            let options = [];
            // Map property types based on the parent prop
            if (this.type === 'Buy') {
                options = this.options.listingTypes.filter(event =>
                    event.isForSale === 1
                )
            } else {
                options = this.options.listingTypes.filter(event =>
                    event.isForRent === 1
                )
            }
            return options;
        },

        dropdownClass() {
            // Add logic to adjust the class based on selected options
            if (this.selectedType) {
                return 'extended-dropdown'; // Class to adjust dropdown size
            }
            return '';
        }
    },

    methods: {
        formatWithCommas(val) {
            return Number(val).toLocaleString('en-US');
        },
        getRoundingMultiple(value) {
            if (value < 1000) return 100;          
            if (value < 10000) return 1000;
            if (value < 100000) return 10000;
            if (value < 1000000) return 100000;
            return 1000000;
        },
        async getSliderRanges() {
            await this.getRanges('retrievingPriceRange', 'api/properties/price-range', 'priceRange', 'priceRangeMax');
            await this.getRanges('retrievingFloorArea', 'api/properties/floor-area-range', 'floorAreaRange', 'floorAreaRangeMax');
            await this.getRanges('retrievingRoomsCount', 'api/properties/room-count-range', 'roomCountRange', 'roomCountRangeMax');
        },
        async getRanges(retrieveVariable, url, rangeVariable, maxVariable) {
            try {
                this[retrieveVariable] = true;
                const response = await this.$axios.get(url, {
                    params: {
                        is_for_sale: this.type == "Buy" ? 1 : 0,
                        is_for_rent: this.type == "Rent" ? 1 : 0,
                    }
                })
                let max = response.data.output?.max ?? 100;
                if (isNaN(max) || max === null) {
                    max = 100;
                }

                const roundingMultiple = this.getRoundingMultiple(max);
                let roundedMax = Math.ceil(max / roundingMultiple) * roundingMultiple;

                if (isNaN(roundedMax) || roundedMax === null) {
                    roundedMax = 100;
                }

                this[maxVariable] = roundedMax;
                this.formData[rangeVariable] = [0, roundedMax];
            } catch (error) {
                console.error('Error while retrieving range', error);
                this.formData[rangeVariable] = [];
            } finally {
                this[retrieveVariable] = false;
            }
        },
        async getOptions() {
            const listingTypeOptions = await getConstListingTypes();
            this.options.listingTypes = listingTypeOptions || [];

            const propertyTypeOptions = await getConstPropertyTypes();
            this.options.propertyTypes = propertyTypeOptions || {};

            const furnishingLevelOptions = await getFurnishingLevelOptions(this.isProjectSearch, !this.isProjectSearch);
            this.options.furnishingLevels = furnishingLevelOptions || {};

            const landTenureOptions = await getLandTenureOptions(this.isProjectSearch, !this.isProjectSearch);
            this.options.landTenures = landTenureOptions || {};

            const facingDirectionsOptions = await getFacingDirectionOptions();
            this.options.facingDirections = facingDirectionsOptions || {};

            this.$emit('update-options', this.options);
        },

        resetSelectedCategories() {
            this.formData.selectedCategories = {};
        },

        showSelectedType(selectedType) {
            if (selectedType) {
                return selectedType.charAt(0).toUpperCase() + selectedType.slice(1);
            }

            return 'Select Type';
        },

        resetForm() {
            // should have all ExploreMore getFilters formData params
            Object.assign(this.formData, {
                dateOfBirth: '',
                gender: '',
                is_FSM_linked: false,
                is_approved_by_admin: true,
                is_curated_property: null,
                is_direct_owner: false,
                is_featured: null,
                is_widget_property: null,
                keywords: '',
                listingType: '',
                locationType: '',
                selectedCategories: {},
                selectedCountry: null,
                selectedFacingDirections: [],
                selectedFurnishingLevels: [],
                selectedLandTenures: [],
                selectedType: null,
            });
            this.submitForm();
        },

        isCategorySelected(parentItem) {
            let categoryAllSelected = true;
            const allItems = parentItem.children || [];
            if (!this.formData.selectedCategories[this.formData.selectedType]) {
                this.formData.selectedCategories[this.formData.selectedType] = [];
            }
            allItems.forEach(element => {
                if (!this.formData.selectedCategories[this.formData.selectedType].includes(element.id)) {
                    categoryAllSelected = false;
                }
            });
            return categoryAllSelected;
        },

        isItemSelected(item) {
            if (!this.formData.selectedCategories[this.formData.selectedType]) {
                return false;
            }
            return this.formData.selectedCategories[this.formData.selectedType].includes(item.id);
        },

        selectAll(parentItem, isChecked) {
            const allItems = parentItem.children || [];
            if (!this.formData.selectedCategories[this.formData.selectedType]) {
                this.formData.selectedCategories[this.formData.selectedType] = [];
            }
            if (isChecked) {
                // push all
                allItems.forEach(item => {
                    this.formData.selectedCategories[this.formData.selectedType].push(item.id);
                });
            } else {
                // remove all
                allItems.forEach(item => {
                    if (this.formData.selectedCategories[this.formData.selectedType].includes(item.id)) {
                        const index = this.formData.selectedCategories[this.formData.selectedType].indexOf(item.id);
                        if (index > -1) {
                            this.formData.selectedCategories[this.formData.selectedType].splice(index, 1);
                        }
                    }
                });
            }
            this.formData.selectedCategories[this.formData.selectedType] = [...new Set(this.formData.selectedCategories[this.formData.selectedType])];
        },

        selectItem(item, isChecked) {
            if (!this.formData.selectedCategories[this.formData.selectedType]) {
                this.formData.selectedCategories[this.formData.selectedType] = [];
            }

            if (isChecked && !this.formData.selectedCategories[this.formData.selectedType].includes(item.id)) {
                this.formData.selectedCategories[this.formData.selectedType].push(item.id);
            }

            if (!isChecked && this.formData.selectedCategories[this.formData.selectedType].includes(item.id)) {
                const index = this.formData.selectedCategories[this.formData.selectedType].indexOf(item.id);
                if (index > -1) {
                    this.formData.selectedCategories[this.formData.selectedType].splice(index, 1);
                }
            }
        },

        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },

        handleOutsideClick(e) {
            if (!this.$el.contains(e.target) && this.isDropdownOpen) {
                this.isDropdownOpen = false;
            }
        },

        async getAutocompleteSearch() {
            if (this.formData.keywords.length <= 3) {
                this.suggestions = [];
                return;
            }

            const token = localStorage.getItem('userToken');
            try {
                let names = [];
                let url = this.isProjectSearch ? '/api/projects/names' : '/api/properties/names'

                const response = await this.$axios.get(`${url}`,
                {
                    params: {
                        api_token: token,
                    }
                });

                if (response.data.output) {
                    names = response.data.output;
                    this.suggestions = names.filter((item) =>
                        item.toLowerCase().includes(this.formData.keywords.toLowerCase())
                    );
                }
            } catch (error) {
                console.error('Error fetching autocomplete:', error);
            }
        },

        selectSuggestion(suggestion) {
            this.formData.keywords = suggestion;
            this.suggestions = [];
        },

        submitForm() {
            this.$emit('submit', this.formData);
        }
    },
    watch: {
        type() {
            this.getSliderRanges();
            this.submitForm();
        },
        queryData() {
            this.formData = this.queryData;
            this.submitForm();
        }
    }
}
</script>

<style scoped>
.dropdown-menu {
  position: absolute;
  z-index: 999;
  background: white;
  border: 1px solid #ccc;
  max-height: 300px;
  overflow-y: auto;
}

.btn-dropdown {
    background-color: #f2f2f2; /* Light grey background */
    color: #333; /* Dark grey text color */
    border: none; /* No border */
    padding: 8px 16px; /* Top and bottom padding 8px, left and right 16px */
    font-size: 16px; /* Text size */
    border-radius: 5px; /* Rounded corners */
    display: inline-block; /* Ensures the dropdown behaves as an inline block */
    width: 100%; /* Full width */
    text-align: left; /* Aligns text to the left */
}

.btn-dropdown:hover {
    background-color: #e6e6e6; /* Slightly darker grey on hover */
    cursor: pointer; /* Changes cursor to pointer on hover */
}

.btn-dropdown:focus {
    background-color: #d9d9d9; /* Even darker grey on focus */
    outline: none; /* Removes outline on focus */
    box-shadow: 0 0 3px #666; /* Adds focus shadow */
}

/* Placeholder style for the property type button */
button#dropdownMenuButton {
    color: #999; /* Placeholder color */
    background-color: #f2f2f2; /* Light background */
    border: none; /* Remove border */
    outline: none; /* Remove the default outline */
    box-shadow: none; /* Remove any focus box-shadow */
}

/* When a property type is selected, change the color */
button#dropdownMenuButton.selected {
    color: #333; /* Regular text color when selected */
}

/* Ensure the placeholder color and border stay consistent on focus */
button#dropdownMenuButton:focus {
    color: #999; /* Keep placeholder color on focus */
    border: none; /* Prevent border on focus */
    outline: none; /* Prevent outline on focus */
    box-shadow: none; /* Remove the focus box-shadow */
}
/* Autocomplete dropdown styles */
.autocomplete-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  margin: 0 0.75rem 0 0.75rem;
  list-style: none;
}

.autocomplete-list li {
  padding: 8px 12px;
  cursor: pointer;
}

.autocomplete-list li:hover {
  background-color: #f0f0f0;
}
</style>