import axios from '../axios'; // axios.js

export const sendCode = async (axios, mobileNumber) => {
    try {
        const response = await axios.post("api/code/send", {
            mobile_number: mobileNumber,
        });
        console.log("Code sent:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error sending code:", error);
        throw error;
    }
};

export async function getConstListingTypes() {
    try {
        const response = await axios.get("api/const-listing-types");
        return response.data;
    } catch (error) {
        console.error("Error fetching const listing types:", error);
        return [];
    }
}

export async function getConstPropertyTypes() {
    try {
        const response = await axios.get("api/const-property-types");
        return response.data;
    } catch (error) {
        console.error("Error fetching const property types:", error);
        return [];
    }
}

export async function getFurnishingLevelOptions(showInProject = false, showInProperty = false) {
    try {
        const response = await axios.post("api/pr-master-furnishing-fittings-list", {
            parent_id : 0,
            show_in_project: showInProject,
            show_in_property: showInProperty,
            take: 6
        });
        return response.data.output;
    } catch (error) {
        console.error("Error fetching furnishing level options:", error);
        return [];
    }
}

export async function getLandTenureOptions(showInProject = false, showInProperty = false) {
    try {
        const response = await axios.post("api/pr-land-tenures-list", {
            parent_id : 0,
            show_in_project: showInProject,
            show_in_property: showInProperty,
            take: 6
        });
        return response.data.output;
    } catch (error) {
        console.error("Error fetching land tenure options:", error);
        return [];
    }
}

export async function getFacingDirectionOptions() {
    try {
        const response = await axios.post("api/facing-direction-list", {
            parent_id : 0,
        });
        return response.data.output;
    } catch (error) {
        console.error("Error fetching land tenure options:", error);
        return [];
    }
}
