<template>
    <section class="verify-service-detail-page">
        <div class="container">
            <div class="top-tabs-design">
                <ul>
                    <li><a href="/profile">{{ $t('my_profile') }} <span v-if="userProfile.is_user_verified">
                                <img src="/frontend/images/verified_logo.png" alt="Verified"
                                    style="width: 16px; height: 16px; margin-left: 5px; margin-top: 5px;">
                            </span></a></li>
                    <li><a href="javascript:;" class="active">{{ $t('verify_service_details') }}</a></li>
                    <li><a href="/property-data-analytic">{{ $t('analytics_and_report') }}</a></li>
                    <li><a href="/wishlist">{{ $t('my_wishlist') }}</a></li>
                    <li><a href="/proxy-listing-page"> Proxy Listing </a></li>
                    <li><a href="/requested-service">{{ $t('requested_service') }}</a></li>
                    <li><a href="/messages">{{ $t('messages') }}</a></li>
                </ul>
            </div>
            <div class="row">
                <div class="col-md-9">
                    <div class="comman-title">
                        <h3>{{ $t('verify_service_details_custom_title') }}</h3><br>
                    </div>
                    <div>
                        {{ $t('verification_status') }}:
                        <span v-if="isVerified">
                            <img src="/frontend/images/verified_logo.png" alt="Verified"
                                style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                            <span style="font-size: 10px; vertical-align: middle;"> PropFace {{ $t('verified') }}</span>
                        </span>
                        <span v-else>
                            <img src="/frontend/images/unverified_logo.png" alt="Unverified"
                                style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                            <span style="font-size: 10px; vertical-align: middle; color: red;"> PropFace {{ $t('unverified') }}</span>
                        </span>

                        <span v-if="isCeaVerified">
                            <img src="/frontend/images/verified_logo.png" alt="Verified"
                                style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                            <span style="font-size: 10px; vertical-align: middle;"> CEA {{ $t('verified') }}</span>
                        </span>
                        <span v-else>
                            <img src="/frontend/images/unverified_logo.png" alt="Unverified"
                                style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                            <span style="font-size: 10px; vertical-align: middle; color: red;"> CEA {{ $t('unverified') }}</span>
                        </span>
                    </div>
                    <div class="profile-card-edit">
                        <div class="comman-title">
                            <h3>{{ $t('personal_information') }}</h3>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label>{{ $t('registration_no') }}</label>
                                <input type="text" placeholder="Registration No" class="form-control"
                                    v-model="form.registration_no" required :disabled="isCeaVerified || userServiceData?.registration_no">
                                <span class="error" v-if="errors.registration_no">{{ errors.registration_no
                                    }}</span>
                            </div>
                            <div class="form-group col-md-3">
                                <label>{{ $t('registration_start_date') }}</label>
                                <input type="date" class="form-control" :disabled="isCeaVerified || userServiceData?.registration_no"
                                    v-model="form.registration_start_date">
                                <span class="error" v-if="errors.registration_start_date">{{
                                    errors.registration_start_date }}</span>
                            </div>
                            <div class="form-group col-md-3">
                                <label>{{ $t('registration_end_date') }}</label>
                                <input type="date" class="form-control" :disabled="isCeaVerified || userServiceData?.registration_no"
                                    v-model="form.registration_end_date">
                                <span class="error" v-if="errors.registration_end_date">{{
                                    errors.registration_end_date
                                    }}</span>
                            </div>
                        </div>

                        <button v-if="!userServiceData?.is_cea_verified" @click.prevent="checkCeaRegNo" type="button"
                            class="comman-btn-2">{{ $t('verify') }} CEA</button>
                    </div>

                    <div class="profile-card-edit">
                        <form @submit.prevent="updateServicedData">
                            <div class="row">
                                <div class="comman-title">
                                    <h3>{{ $t('agency') }}/{{ $t('company') }} </h3>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-3">
                                        <label>{{ $t('agency_name') }}</label>
                                        <input type="text" placeholder="Agency Name" class="form-control"
                                            v-model="form.agency_name" required>
                                        <span class="error" v-if="errors.agency_name">{{ errors.agency_name
                                            }}</span>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>{{ $t('agency_license') }}</label>
                                        <input type="text" placeholder="Agency License" class="form-control"
                                            v-model="form.agency_license" required>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>{{ $t('agency') }} {{ $t('registration_no') }}</label>
                                        <input type="text" placeholder="Agency Registration No" class="form-control"
                                            v-model="form.agency_registration_no" required>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>{{ $t('position_held') }}</label>
                                        <input type="text" placeholder="Position Held" class="form-control"
                                            v-model="form.position_held">
                                        <span class="error" v-if="errors.position_held">{{ errors.position_held
                                            }}</span>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="row">
                                <div class="comman-title">
                                    <h3>{{ $t('my_services') }}</h3>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label>{{ $t('my_services') }}</label>
                                        <select id="service_type_id" v-model="form.service_type_id"
                                            class="form-control">
                                            <!-- <option value="" disabled selected>Please Facilities</option> -->
                                            <option value="0" disabled>{{ $t('select_service') }}</option>
                                            <option v-for="option in service_typeList" :key="option.value"
                                                :value="option.value">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                        <span class="error" v-if="errors.service_type_id">{{ errors.service_type_id
                                            }}</span>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>{{ $t('company_name') }}</label>
                                        <input type="text" placeholder="Company Name" class="form-control"
                                            v-model="form.company_name">
                                    </div>

                                    <div class="form-group col-md-4">
                                        <label>{{ $t('working_hours') }}</label>
                                        <input type="text" placeholder="Working Hours" class="form-control"
                                            v-model="form.working_hours">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="comman-title">
                                    <h3>{{ $t('my_specialty_location') }}</h3>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label>{{ $t('location_1') }}</label>
                                        <div class="dropdown">
                                            <input id="district_id" type="text" v-model="form.location_1"
                                                class="form-control" @input="filterDistricts('location_1')"
                                                @focus="dropdownVisible = true" placeholder="Select or type a District"
                                                @blur="closeDropdown" />
                                            <ul v-if="dropdownVisible && filteredDistrictList.location_1.length > 0"
                                                class="dropdown-menu show"
                                                style="width: 100%; max-height: 200px; overflow-y: auto;">
                                                <li v-for="option in filteredDistrictList.location_1"
                                                    :key="option.value"
                                                    @mousedown.prevent="selectDistrict('location_1', option.label)"
                                                    class="dropdown-item">
                                                    {{ option.label }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>{{ $t('location_2') }}</label>
                                        <div class="dropdown">
                                            <input id="district_id" type="text" v-model="form.location_2"
                                                class="form-control" @input="filterDistricts('location_2')"
                                                @focus="dropdownVisible = true" placeholder="Select or type a District"
                                                @blur="closeDropdown" />
                                            <ul v-if="dropdownVisible && filteredDistrictList.location_2.length > 0"
                                                class="dropdown-menu show"
                                                style="width: 100%; max-height: 200px; overflow-y: auto;">
                                                <li v-for="option in filteredDistrictList.location_2"
                                                    :key="option.value"
                                                    @mousedown.prevent="selectDistrict('location_2', option.label)"
                                                    class="dropdown-item">
                                                    {{ option.label }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>{{ $t('location_3') }}</label>
                                        <div class="dropdown">
                                            <input id="district_id" type="text" v-model="form.location_3"
                                                class="form-control" @input="filterDistricts('location_3')"
                                                @focus="dropdownVisible = true" placeholder="Select or type a District"
                                                @blur="closeDropdown" />
                                            <ul v-if="dropdownVisible && filteredDistrictList.location_3.length > 0"
                                                class="dropdown-menu show"
                                                style="width: 100%; max-height: 200px; overflow-y: auto;">
                                                <li v-for="option in filteredDistrictList.location_3"
                                                    :key="option.value"
                                                    @mousedown.prevent="selectDistrict('location_3', option.label)"
                                                    class="dropdown-item">
                                                    {{ option.label }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group">
                                        <div class="mb-3">
                                            <label>{{ $t('availability_days') }}</label>
                                            <ul class="d-flex flex-row gap-3 list-unstyled">
                                                <li v-if="form.is_available_mon">
                                                    <span class="badge comman-btn-2 text-dark px-3 py-2">{{ $t('mon') }}</span>
                                                </li>
                                                <li v-if="form.is_available_tue">
                                                    <span class="badge comman-btn-2 text-dark px-3 py-2">{{ $t('tue') }}</span>
                                                </li>
                                                <li v-if="form.is_available_wed">
                                                    <span class="badge comman-btn-2 text-dark px-3 py-2">{{ $t('wed') }}</span>
                                                </li>
                                                <li v-if="form.is_available_thu">
                                                    <span class="badge comman-btn-2 text-dark px-3 py-2">{{ $t('thu') }}</span>
                                                </li>
                                                <li v-if="form.is_available_fri">
                                                    <span class="badge comman-btn-2 text-dark px-3 py-2">{{ $t('fri') }}</span>
                                                </li>
                                                <li v-if="form.is_available_sat">
                                                    <span class="badge comman-btn-2 text-dark px-3 py-2">{{ $t('sat') }}</span>
                                                </li>
                                                <li v-if="form.is_available_sun">
                                                    <span class="badge comman-btn-2 text-dark px-3 py-2">{{ $t('sun') }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="form-group">
                                            <div class="form-check form-check-inline">
                                                <label class="form-check-label w-100"
                                                    for="is_available_mon">{{ $t('monday') }}</label>
                                                <input class="form-check-input" type="checkbox"
                                                    v-model="form.is_available_mon" id="is_available_mon">
                                            </div>
                                            <div class="form-check form-check-inline">

                                                <label class="form-check-label w-100"
                                                    for="is_available_tue">{{ $t('tuesday') }}</label>
                                                <input class="form-check-input" type="checkbox"
                                                    v-model="form.is_available_tue" id="is_available_tue">
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <label class="form-check-label w-100"
                                                    for="is_available_wed">{{ $t('wednesday') }}</label>
                                                <input class="form-check-input" type="checkbox"
                                                    v-model="form.is_available_wed" id="is_available_wed">
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <label class="form-check-label w-100"
                                                    for="is_available_thu">{{ $t('thursday') }}</label>
                                                <input class="form-check-input" type="checkbox"
                                                    v-model="form.is_available_thu" id="is_available_thu">
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <label class="form-check-label w-100"
                                                    for="is_available_fri">{{ $t('friday') }}</label>
                                                <input class="form-check-input" type="checkbox"
                                                    v-model="form.is_available_fri" id="is_available_fri">
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <label class="form-check-label w-100"
                                                    for="is_available_sat">{{ $t('saturday') }}</label>
                                                <input class="form-check-input" type="checkbox"
                                                    v-model="form.is_available_sat" id="is_available_sat">
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <label class="form-check-label w-100"
                                                    for="is_available_sun">{{ $t('sunday') }}</label>
                                                <input class="form-check-input" type="checkbox"
                                                    v-model="form.is_available_sun" id="is_available_sun">
                                            </div>
                                        </div>
                                        <p v-if="copySuccess" class="copy-success">URL {{ $t('copied_to_clipboard') }}!</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="comman-title">
                                    <h3>{{ $t('my_experience') }}</h3>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6 disabled">
                                        <label>{{ $t('service') }}</label>
                                        <select id="personal_service_type_id" v-model="form.personal_service_type_id"
                                            class="form-control">
                                            <option value="0">{{ $t('select_service') }}</option>
                                            <option v-for="option in service_typeList" :key="option.value"
                                                :value="option.value">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>{{ $t('working_since') }}</label>
                                        <select v-model="this.form.personal_working_since" id="year-select"
                                            class="form-control">
                                            <option value="">{{ $t('working_since') }}</option>
                                            <option value="0">{{ $t('select') }} {{ $t('working_since') }}</option>
                                            <option v-for="year in years" :key="year" :value="year">{{ year }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex">
                                <button class="btn comman-btn-2" type="submit">{{ $t('save') }}</button>
                                <button class="btn comman-btn-4 ms-2" type="button" @click="fetchOptions">{{ $t('cancel') }}</button>
                            </div>

                        </form>
                    </div>
                    <div class="profile-card-edit"
                            v-if="userProfile.is_business && profilServicesData?.agency_name">
                            <div class="comman-title">
                                <h3>{{ $t('my_widget_content') }}</h3>
                            </div>

                            <form>
                                <div class="form-group">
                                    <label for="title">{{ $t('title') }}</label>
                                    <input type="text" id="title" v-model="widget.title" class="form-control"
                                        placeholder="Enter the title for your widget" />
                                </div>

                                <div class="form-group">
                                    <label for="description">{{ $t('description') }}</label>
                                    <textarea id="description" v-model="widget.description" class="form-control"
                                        placeholder="Enter a brief description" rows="3"></textarea>
                                </div>

                                <div class="form-group">
                                    <label for="image">{{ $t('image') }} URL ((Min 300x200))</label>
                                    <input type="file" id="image" @change="onImageUpload" class="form-control"
                                        accept="image/*" />
                                    <div v-if="widget.image" class="form-group">
                                        <label>{{ $t('image') }} {{ $t('preview')}}:</label>
                                        <img :src="widget.image" alt="Uploaded Image" class="img-fluid"
                                            style="max-width: 100%; height: auto;" />
                                    </div>
                                </div>

                                <div class="mt-4">
                                    <button @click="submitWidget" type="button" class="comman-btn-2">{{ $t('save')}}
                                        {{ $t('widget')}}</button>
                                </div>
                            </form>

                            <div class="d-flex">
                                <div class="url-container">
                                    <small>{{ $t('share_link_custom_description')}}</small>
                                    <div class="url-box">
                                        <div>{{ referal_current_Url }}</div>
                                        <button @click="copyToClipboard" type="button"
                                            class="btn btn-warning btn-sm">{{ $t('copy')}} URL</button>
                                    </div>
                                    <p v-if="copySuccess" class="copy-success">URL {{ $t('copied_to_clipboard') }}!</p>
                                </div>
                            </div>

                            <div class="form-group mt-4">
                                <label>{{ $t('share_your_widget') }}:</label>
                                <div class="d-flex gap-3">
                                    <a :href="'https://wa.me/?text=' + encodeURIComponent(referal_current_Url)"
                                        target="_blank" class="social-icon">
                                        <img src="/frontend/images/whatsapp.png" alt="WhatsApp"
                                            style="width: 40px; height: 40px;" />
                                    </a>

                                    <a :href="'https://t.me/share/url?url=' + encodeURIComponent(referal_current_Url)"
                                        target="_blank" class="social-icon">
                                        <img src="/frontend/images/telegramicon.png" alt="Telegram"
                                            style="width: 40px; height: 40px;" />
                                    </a>

                                    <a href="https://www.facebook.com" target="_blank" class="social-icon">
                                        <img src="/frontend/images/messenger.png" alt="Facebook"
                                            style="width: 40px; height: 40px;" />
                                    </a>
                                </div>
                            </div>
                        </div>
                </div>
                <ProfilePageSideBar></ProfilePageSideBar>
            </div>
        </div>
    </section>
</template>

<script>
    import toastr from "toastr";
    import { mapState } from "vuex";
    import ProfilePageSideBar from "@/views/ProfilePageSideBar.vue";

    export default {
        name: 'VerifyServiceDetailPage',
        components: { ProfilePageSideBar },
        data() {
            return {
                isVerified: '',
                isCeaVerified: false,
                errors: {},
                isLoading: false,
                copySuccess: false,
                referal_current_Url: '',
                form: {
                    name: '',
                    first_name: '',
                    last_name: '',
                    gender: '',
                    date_of_birth: '',
                    mobile_phone: '',
                    email: '',
                    is_mobile_verified: '',
                    is_email_verified: '',
                    contact_phone: '',
                    contact_email: '',
                    facebook_url: '',
                    linkedin_url: '',
                    twitter_url: '',
                    instagram_url: '',
                    tiktok_url: '',
                    youtube_url: '',
                    about_you: '',
                    agency_name: '',
                    agency_license: '',
                    agency_registration_no: '',
                    position_held: '',
                    personal_service_type_id: 0,
                    personal_working_since: '',
                    service_type_id: 0,
                    show_as_agent: 0,
                    is_available_mon: 0,
                    is_available_tue: 0,
                    is_available_wed: 0,
                    is_available_thu: 0,
                    is_available_fri: 0,
                    is_available_sat: 0,
                    is_available_sun: 0,
                    location_1: '',
                    location_2: '',
                    location_3: '',
                },
                package_details: [],
                myprofileData: [],
                service_typeList: [],
                profilServicesData: [],
                widget: {
                    title: '',
                    description: '',
                    image: '',
                    url: '',
                },
                years: [],
                isAgent: null,
                isFeatured: null,
                featuredTo: null,
                featuredFrom: null,
                dropdownVisible: false,
                districtList: [],
                filteredDistrictList: {
                    location_1: [],
                    location_2: [],
                    location_3: [],
                },
                userServiceData: null
            }
        },
        computed: {
            ...mapState(['user', 'isAuthenticated', 'userProfile'])
        },
        mounted() {
            this.fetchDistricts();
        },
        async created() {
            await this.fetchOptions();
            this.generateYears();
        },
        methods: {
            async fetchDistricts() {
                try {
                    const token = localStorage.getItem('userToken');
                    const response = await this.$axios.get("/api/get-districts", {
                        params: {
                            user_id: this.user.id,
                            api_token: token,
                        }
                    });

                    if (response.data.success) {
                        const districts = response.data.data.sg_districts;
                        if (districts) {
                            this.districtList = districts.map((d) => ({
                                label: d.district,
                                value: d.district
                            }));
                        }
                    } else {
                        this.districtList = [];
                        console.warn("No districts found.");
                    }
                } catch (error) {
                    console.error("Error fetching districts:", error);
                }
            },
            filterDistricts(field) {
                if (!this.form[field]) {
                    this.filteredDistrictList[field] = this.districtList;
                    return;
                }
                const searchText = this.form[field].toLowerCase();
                this.filteredDistrictList[field] = this.districtList.filter(option =>
                    option.label && option.label.toLowerCase().includes(searchText)
                );
            },
            selectDistrict(field, district) {
                this.form[field] = district;
                this.dropdownVisible = false;
            },
            closeDropdown() {
                setTimeout(() => {
                    this.dropdownVisible = false;
                }, 200);
            },
            generateYears() {
                const currentYear = new Date().getFullYear();
                for (let year = 1970; year <= currentYear; year++) {
                    this.years.push(year);
                }
            },
            validateForm() {
                this.errors = {};
                if (!this.form.agency_name) {
                    this.errors.agency_name = 'Agency Name is required.';
                }
                //if (!this.form.position_held) {
                //    this.errors.position_held = 'Position Held is required.';
                //}
                if (!this.form.service_type_id) {
                    this.errors.service_type_id = 'My Services is required.';
                }
                //   if (!this.form.availability_days ) {
                //       this.errors.availability_days = 'Availability Days is required.';
                //   }

                return Object.keys(this.errors).length === 0;
            },
            async updateServicedData() {
                if (this.validateForm()) {
                    this.isLoading = true;
                    const token = localStorage.getItem('userToken');
                    try {
                        const response = await this.$axios.post('api/add-update-user-services', {
                            user_id: this.user.id,
                            api_token: token,
                            user_service_detail_id: 0,
                            agency_name: this.form.agency_name ? this.form.agency_name : '',
                            agency_license: this.form.agency_license ? this.form.agency_license : '',
                            agency_registration_no: this.form.agency_registration_no ? this.form.agency_registration_no : '',
                            position_held: this.form.position_held ? this.form.position_held : '',
                            service_type_id: this.form.service_type_id ? this.form.service_type_id : '',
                            company_name: this.form.company_name ? this.form.company_name : '',
                            availability_days: this.form.availability_days ? this.form.availability_days : 0,
                            location_1: this.form.location_1 ?? null,
                            location_2: this.form.location_2 ?? null,
                            location_3: this.form.location_3 ?? null,
                            is_available_mon: this.form.is_available_mon,
                            is_available_tue: this.form.is_available_tue,
                            is_available_wed: this.form.is_available_wed,
                            is_available_thu: this.form.is_available_thu,
                            is_available_fri: this.form.is_available_fri,
                            is_available_sat: this.form.is_available_sat,
                            is_available_sun: this.form.is_available_sun,
                            working_hours: this.form.working_hours ? this.form.working_hours : 0,
                            personal_service_type_id: this.form.personal_service_type_id,
                            personal_working_since: this.form.personal_working_since ? this.form.personal_working_since : 0,
                            registration_no: this.form.registration_no,
                            registration_start_date: this.form.registration_start_date,
                            registration_end_date: this.form.registration_end_date
                        });

                        this.profilServicesData = response.data.output;
                        toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                    } catch (error) {

                        if (error.response && error.response.data) {
                            this.errors = this.extractErrors(error.response.data.errors || {});
                            toastr.error('Something went wrong', 'error', { timeOut: 3000 });
                        } else {
                            // console.error('An unexpected error occurred:', error);
                            toastr.error('Something went wrong', 'error', { timeOut: 3000 });
                        }
                    } finally {
                        this.isLoading = false;
                    }
                }
            },
            async fetchOptions() {
                const token = localStorage.getItem('userToken');
                // Get Features list
                try {
                    const response = await this.$axios.post('api/services-type-list',
                        {
                            user_id: this.user.id,
                            api_token: token
                        });
                    this.service_typeList = response.data.output.map(item => ({
                        value: item.id,
                        label: item.name
                    }));

                    if (this.service_typeList.length > 0) {
                        this.form.personal_service_type_id = this.service_typeList[0].value;
                    }

                } catch (error) {
                    console.error('Error fetching options:', error);
                }

                if (this.isAuthenticated) {
                    try {
                        const response = await this.$axios.post('/api/my-profile-detail', {
                            api_token: token,
                            user_id: this.user.id
                        });

                        var userData = response.data.output;
                        await this.$store.dispatch('profileUpdate', response.data.output);
                        var userServiceData = userData.service_details;
                        this.userServiceData = userServiceData;
                        this.isVerified = userServiceData?.is_verified ?? false;
                        this.isCeaVerified = userServiceData?.is_cea_verified ?? false;
                        this.package_details = userData.package_details;
                        this.isFeatured = userServiceData?.is_featured;
                        this.featuredTo = userServiceData?.featured_to;
                        this.featuredFrom = userServiceData?.featured_from;
                        this.profilServicesData = userServiceData;
                        this.referal_current_Url = `${window.BACKEND_URL}/` + 'widget-property/create/' + userData.id + '/' + userData.referral_user_type;
                        this.form = userData,
                        this.form.registration_no = userServiceData?.registration_no;
                        this.form.registration_start_date = userServiceData?.registration_start_date;
                        this.form.registration_end_date = userServiceData?.registration_end_date;
                        this.form.registration_no = userServiceData?.registration_no;
                        this.form.agency_name = userServiceData?.agency_name,
                        this.form.agency_license = userServiceData?.agency_license,
                        this.form.agency_registration_no = userServiceData?.agency_registration_no,
                        this.form.position_held = userServiceData?.position_held,
                        this.form.service_type_id = userServiceData?.service_type_id ?? 0,
                        this.form.company_name = userServiceData?.company_name,
                        this.form.availability_days = userServiceData?.availability_days,
                        this.form.is_available_mon = userServiceData?.is_available_mon == 1 ? true : false,
                        this.form.is_available_tue = userServiceData?.is_available_tue == 1 ? true : false,
                        this.form.is_available_wed = userServiceData?.is_available_wed == 1 ? true : false,
                        this.form.is_available_thu = userServiceData?.is_available_thu == 1 ? true : false,
                        this.form.is_available_fri = userServiceData?.is_available_fri == 1 ? true : false,
                        this.form.is_available_sat = userServiceData?.is_available_sat == 1 ? true : false,
                        this.form.is_available_sun = userServiceData?.is_available_sun == 1 ? true : false,
                        this.form.working_hours = userServiceData?.working_hours,
                        this.form.show_as_agent = userServiceData?.show_as_agent == 1 ? true : false,
                        this.form.personal_service_type_id = userServiceData?.personal_service_type_id ?? this.service_typeList[0].value,
                        this.form.personal_working_since = userServiceData?.personal_working_since ?? '',

                        // widget
                        this.widget = {
                            title: userData?.widget_details?.title ?? '',
                            description: userData?.widget_details?.description ?? '',
                            image: userData?.widget_details?.image ?? '',
                            url: userData?.widget_details?.url ?? '',
                        }
                        // This is not executed, as some error has thrown before
                        await this.$store.dispatch('profileUpdate', response.data.output);
                    } catch (error) {
                        console.error('Failed to fetch profile:', error);
                    }

                } else {
                    console.log('User is not authenticated');
                }
            },

            async checkCeaRegNo() {
                try {
                    if (this.userServiceData?.is_cea_verified) {
                        toastr.error('You are already CEA verified.', 'error');
                        return;
                    }
                    const response = await this.$axios.post(`api/users/${this.user.id}/verify-cea-license`, {
                        api_token: localStorage.getItem('userToken'),
                        registration_no: this.form.registration_no
                    })

                    if (response?.status === 200) {
                        toastr.success('You are now CEA verified.', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1000
                        });

                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                } catch (error) {
                    toastr.error('CEA Verification Failed. ' + error?.response?.data?.error, 'error')
                }
            },

            //widget functions
            async submitWidget() {
                try {
                    // Create a new FormData object to send the image as a file
                    const formData = new FormData();

                    // Append all data to the FormData object, including the image as a file

                    formData.append('api_token', localStorage.getItem('userToken'));
                    formData.append('user_id', this.user.id);
                    formData.append('title', this.widget.title);
                    formData.append('description', this.widget.description);
                    formData.append('url', this.referal_current_Url);

                    // Check if there is an image and append it to the formData
                    if (this.widget.image) {
                        formData.append('image', this.widget.image);
                    }

                    // Send the form data with axios
                    await this.$axios.post('api/widgets', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    });

                    toastr.success('Widget updated successfully');
                } catch (error) {
                    toastr.error('Error submitting widget. ' + error?.response?.data?.message, 'error');
                } finally {
                    this.fetchOptions();
                }
            },
            onImageUpload(event) {
                const file = event.target.files[0];
                if (file) {
                    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
                    if (validImageTypes.includes(file.type)) {
                        this.widget.image = file;  // Send the file directly
                    } else {
                        alert("Please upload a valid image.");
                    }
                }
            },
            copyToClipboard() {
                navigator.clipboard.writeText(this.referal_current_Url)
                    .then(() => {
                        this.copySuccess = true;
                        setTimeout(() => {
                            this.copySuccess = false; // Reset success message after 2 seconds
                        }, 2000);
                    })
                    .catch(err => {
                        console.error('Failed to copy: ', err);
                    });
            },
        }

    }
</script>

<style scoped>
    .container {
        margin-top: 20px;
    }

    .url-container {
        margin: 10px 0px;
        width: 100%;
    }

    .url-box {
        border: 1px solid #ddd;
        padding: 7px 7px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ddd;
        text-transform: lowercase;
    }

    .copy-success {
        color: green;
        margin-top: 10px;
    }
</style>