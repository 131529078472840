<template>
    <div class="wishlist-page">
        <section class="mrp-search-se">
            <div class="container">
                <div class="top-tabs-design">
                    <ul>
                        <li><a href="/profile">{{ $t('my_profile') }}</a></li>
                        <li v-if="!user.is_private_user"><a href="/verify-service-detail-page">{{ $t('verify_service_details') }}</a></li>
                        <li v-if="!user.is_private_user"><a href="/property-data-analytic">{{ $t('analytics_and_report') }}</a></li>
                        <li><a href="javascript:;" class="active">{{ $t('my_wishlist') }}</a></li>
                        <li><a href="/proxy-listing-page"> Proxy Listing </a></li>
                        <li><a href="/requested-service">{{ $t('requested_service') }}</a></li>
                        <li><a href="/messages">{{ $t('messages') }}</a></li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-9">
                        <div class="saved-properties">
                            <div class="filter-bar-se mb-0">
                                <div class="comman-title mb-0">
                                    <h3 class="mb-2">{{ $t('saved_properties') }}</h3>
                                    <span>{{ $t('showing') }} {{ totalProperties }} {{ $t('properties') }}</span>
                                </div>
                                <div class="filter-right-bar">
                                    <div class="dropdown">
                                        <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown"
                                           aria-expanded="true">
                                            <img src="frontend/images/Sort-By.svg"> {{ $t('sort_by') }}
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                                            <li><a class="dropdown-item" href="#"
                                                   :class="{ 'selected': propertyFilters.sort_by_field === 'Newest' }"
                                                   @click.prevent="sortProperties('Newest')">{{ $t('newest') }}</a></li>
                                            <li><a class="dropdown-item" href="#"
                                                   :class="{ 'selected': propertyFilters.sort_by_field === 'Lowest Price' }"
                                                   @click.prevent="sortProperties('Lowest Price')">{{ $t('lowest_price') }}</a></li>
                                            <li><a class="dropdown-item" href="#"
                                                   :class="{ 'selected': propertyFilters.sort_by_field === 'Highest Price' }"
                                                   @click.prevent="sortProperties('Highest Price')">{{ $t('highest_price') }}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="property-listing">
                                <template v-if="!loadingProperties && wishlistProperties.length > 0">
                                    <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                                        <div class="col mb-3" v-for="property in wishlistProperties" :key="property.id">
                                            <div class="project-bx">
                                                <picture style="position: relative;">
                                                    <a class="wishlist-icon-in-pic" @click="removeProperty(property)" title="Remove Property">
                                                        <img src="frontend/images/heart-filled-ico.svg" alt="Remove"/>
                                                    </a>
                                                    <img :src="getPropertyImage(property.actual_unit_photos?.[0]?.photo_name || null)" @error="handleImageError"
                                                         alt="Property Image" />
                                                </picture>
                                                <div class="project-info">
                                                    <a :href="'/property-detail/' + property.id" class="text-decoration-none">
                                                        <div class="mb-2 align-items-center">
                                                            <h3 style="color: black;">{{ property.property_name }}</h3>
                                                            <div class="price-pro">
                                                                <span><small class="ml-0">S$</small> {{ formatPrice(property.asking_price_sale_rent) }}</span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{ formattedAddress(property) }}</p>

                                                    <div class="d-flex flex-column">
                                                        <div class="mt-auto">
                                                            <div class="pro-specify">
                                                                <span><i><img src="frontend/images/spec-ico1.svg"></i>{{property.no_of_rooms}}</span>
                                                                <span><i><img src="frontend/images/spec-ico2.svg"></i>{{ property.no_of_baths }}</span>
                                                                <span><i><img src="frontend/images/spec-ico3.svg"></i>{{property.no_car_park_lots}}</span>
                                                                <span><i><img src="frontend/images/spec-ico4.svg"></i>{{ formatZeros(property.floor_area) }} {{property.floor_area_unit}}</span>
                                                            </div>
                                                            <div class="pro-tag">
                                                                <a style="cursor:default">{{ property.property_type?.code }}</a>
                                                                <a style="cursor:default">{{ property.land_title_type }}</a>
                                                            </div>
                                                            <ul>
                                                                <li v-if="property.is_direct_owner">
                                                                    <figure>
                                                                        <img src="frontend/images/specify-ico1.png">
                                                                    </figure>
                                                                    <small>{{ $t('direct') }}<br>{{ $t('owner') }}</small>
                                                                </li>
                                                                <li  v-if="property.is_sing_pass || property.is_corp_pass ">
                                                                    <figure>
                                                                        <img src="frontend/images/specify-ico2.png">
                                                                    </figure>
                                                                    <small>{{ $t('singpass') }}<br>{{ $t('verified') }} </small>
                                                                </li>
                                                                <li v-if="property.is_document_approved">
                                                                    <figure>
                                                                        <img src="frontend/images/specify-ico3.png">
                                                                    </figure>
                                                                    <small>{{ $t('documents') }}<br>{{ $t('verified') }}</small>
                                                                </li>
                                                                <li v-if="property.is_below_mean_value">
                                                                    <figure>
                                                                        <img src="frontend/images/Down-arrow.svg">
                                                                    </figure>
                                                                    <small>BMV</small>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <LoaderCust :isLoading="loadingProperties"/>
                                    <div v-if="!loadingProperties" class="text-center">{{ $t('no_records_found') }}</div>
                                </template>
                                <div class="pagination-se" v-if="!loadingProperties && wishlistProperties.length > 0">
                                    <nav aria-label="...">
                                        <ul class="pagination justify-content-center">
                                            <li class="page-item" :class="{ disabled: propertyFilters.page === 1 }"
                                                @click="propertyFilters.page > 1 && changePropertyPage(propertyFilters.page - 1)" v-if="propertyFilters.page > 1">
                                                <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                                                          alt="Previous"></a>
                                            </li>
                                            <li v-for="pageNumber in paginatedPages(propertyFilters.page, propertyTotalPages)" :key="pageNumber"
                                                class="page-item" :class="{ active: pageNumber === propertyFilters.page }">
                                                <a class="page-link"
                                                   @click.prevent="pageNumber !== propertyFilters.page && changePropertyPage(pageNumber)">
                                                    {{ pageNumber }}
                                                </a>
                                            </li>
                                            <li class="page-item" :class="{ disabled: propertyFilters.page === propertyTotalPages }"
                                                @click="propertyFilters.page < propertyTotalPages && changePropertyPage(propertyFilters.page + 1)"
                                                v-if="propertyFilters.page < propertyTotalPages">
                                                <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div class="saved-agents pt-5" style="background-color: #FAF6EE;">
                            <div class="container">
                                <div class="saved-agents">
                                    <div class="filter-bar-se mb-0">
                                        <div class="comman-title mb-0">
                                            <h3 class="mb-2">{{ $t('saved_service_providers') }}</h3>
                                            <span>{{ $t('showing') }} {{ totalAgents }} {{ $t('service_providers') }}</span>
                                        </div>
                                    </div>
                                    <div class="wishlist-agent">
                                        <template v-if="!loadingAgents && wishlistAgents.length > 0">
                                            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                                                <div class="col mb-3" v-for="agent in wishlistAgents" :key="agent.id">
                                                    <div class="project-bx">
                                                        <picture style="position: relative;">
                                                            <a class="wishlist-icon-in-pic" @click="removeAgent(agent)" title="Remove Agent">
                                                                <img src="frontend/images/heart-filled-ico.svg" alt="Remove"/>
                                                            </a>
                                                            <img :src="getAgentImage(agent.profile_photo_name)"/>
                                                        </picture>
                                                        <div class="project-info">
                                                            <a :href="'/agents/' + agent.id" class="text-decoration-none">
                                                                <div class="mb-2 align-items-center row justify-content-between">
                                                                    <div class="col-12 pe-0 col-md-7">
                                                                        <h3 class="mb-0" style="color: black;">
                                                                            {{ agent.name }}
                                                                            <img v-if="agent.is_admin_verified" src="frontend/images/verified.svg" alt="">
                                                                        </h3>
                                                                    </div>
                                                                    <div class="col-12 text-end col-md-5" v-if="agent.service_detail?.personal_working_since">
                                                                        <small style="color: black;">{{ $t('joined') }} {{ workingSince(agent.service_detail?.personal_working_since) }} {{ $t('years_ago') }}</small>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <div class="pro-specify">
                                                                <span>{{ $t('position') }}: {{ agent.service_detail?.position_held }}</span>
                                                            </div>
                                                            <div class="pro-specify">
                                                                <span>{{ formatPhoneNumber(agent.contact_phone) }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <LoaderCust :isLoading="loadingAgents"/>
                                            <div v-if="!loadingAgents" class="text-center">{{ $t('no_records_found') }}</div>
                                        </template>
                                        <div class="pagination-se" v-if="!loadingAgents && wishlistAgents.length > 0">
                                            <nav aria-label="...">
                                                <ul class="pagination justify-content-center">
                                                    <li class="page-item" :class="{ disabled: agentFilters.page === 1 }"
                                                        @click="agentFilters.page > 1 && changeAgentPage(agentFilters.page - 1)" v-if="agentFilters.page > 1">
                                                        <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                                                                  alt="Previous"></a>
                                                    </li>
                                                    <li v-for="pageNumber in paginatedPages(agentFilters.page, agentTotalPages)" :key="pageNumber"
                                                        class="page-item" :class="{ active: pageNumber === agentFilters.page }">
                                                        <a class="page-link"
                                                           @click.prevent="pageNumber !== agentFilters.page && changeAgentPage(pageNumber)">
                                                            {{ pageNumber }}
                                                        </a>
                                                    </li>
                                                    <li class="page-item" :class="{ disabled: agentFilters.page === agentTotalPages }"
                                                        @click="agentFilters.page < agentTotalPages && changeAgentPage(agentFilters.page + 1)"
                                                        v-if="agentFilters.page < agentTotalPages">
                                                        <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ProfilePageSideBar></ProfilePageSideBar>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import LoaderCust from '@/components/LoaderCust.vue';
    import toastr from 'toastr';
    import { CONSTANT } from '@/constants/constants';
    import { formatPrice, formatPhoneNumber, formatZeros, getUserProfileImage, paginatedPages, getPropertyImage } from '@/utils/helpers';
    import { mapState } from 'vuex';
    import ProfilePageSideBar from "@/views/ProfilePageSideBar.vue";

    export default {
        name: 'WishlistPage',

        components: {
            ProfilePageSideBar,
            LoaderCust,
        },

        computed: {
            ...mapState(['user', 'userProfile']),
        },

        data() {
            return {
                wishlistProperties: [],
                propertyTotalPages: 0,
                totalProperties: 0,
                loadingProperties: false,
                propertyFilters: {
                    page: 1,
                    sort_by_field: 'Newest',
                },
                wishlistAgents: [],
                agentTotalPages: 0,
                totalAgents: 0,
                loadingAgents: false,
                agentFilters: {
                    page: 1,
                    sort_by_field: 'Newest',
                },
            }
        },

        created() {
            if (this.$route.query.formData) {
                // Replace the route with the same path but without query parameters (during F5 remove params)
                this.$router.replace({ path: this.$route.path });
            }
        },

        mounted() {
            this.fetchProperties();
            this.fetchAgents();
        },

        methods: {
            formatPrice,
            formatZeros,
            getUserProfileImage,
            getPropertyImage,
            paginatedPages,
            formatPhoneNumber,

            formattedAddress(property) {
                let addressParts = [
                    property.street,
                    property.area_hdb_estate,
                    property.district
                ];
                let address = addressParts.filter(part => part !== null && part !== undefined).join(', ');
                return address || '-';
            },
            workingSince(year) {
                return new Date().getFullYear() - year;
            },
            async removeProperty(property) {
                try {
                    if (!this.user?.id) {
                        toastr.error('Please log in to wishlist', 'error', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                        });
                        return;
                    }
                    const wishlistResponse = await this.$axios.post(`api/users/${this.user.id}/wishlist/properties/${property.id}`, {
                        api_token: localStorage.getItem('userToken'),
                    });

                    if (wishlistResponse.status === 200) {
                        toastr.success(wishlistResponse.data.message, 'Success', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                            onHidden: () => {
                                this.fetchProperties();
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error wishlisting property:', error);
                }
            },
            async fetchProperties() {
                this.loadingProperties = true;
                try {
                    if (!this.user?.id) {
                        toastr.error('Please log in to view wishlist', 'error', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                        });
                        return;
                    }
                    const wishlistResponse = await this.$axios.get(`api/users/${this.user.id}/wishlist/properties`, {
                        params: {
                            api_token: localStorage.getItem('userToken'),
                            sort_by_field: this.propertyFilters.sort_by_field,
                            page_number: this.propertyFilters.page,
                        },
                    });

                    this.propertyTotalPages = wishlistResponse.data.output.no_of_total_pages ?? 0;
                    this.totalProperties = wishlistResponse.data.output.no_of_total_records ?? 0;
                    this.wishlistProperties = wishlistResponse.data.output.data.data ?? [];
                } catch (error) {
                    console.error('Error fetching wishlists:', error);
                } finally {
                    this.loadingProperties = false;
                }
            },
            sortProperties(sortBy) {
                this.propertyFilters.sort_by_field = sortBy;
                this.propertyFilters.page = 1;
                this.fetchProperties();
            },
            changePropertyPage(page) {
                if (page > 0 && page <= this.propertyTotalPages) {
                    this.propertyFilters.page = page;
                    this.fetchProperties();
                }
            },

            getAgentImage(path) {
                return path ? `${CONSTANT.IMAGE_URL}/imagesProfile/${path}` : CONSTANT.DEFAULT_NO_IMAGE;
            },
            async fetchAgents() {
                this.loadingAgents = true;
                try {
                    const wishlistResponse = await this.$axios.get(`api/users/${this.user.id}/wishlist/agents`, {
                        params: {
                            api_token: localStorage.getItem('userToken'),
                            sort_by_field: this.agentFilters.sort_by_field,
                            page_number: this.agentFilters.page
                        }
                    });

                    this.agentTotalPages = wishlistResponse.data.output.no_of_total_pages ?? 0;
                    this.totalAgents = wishlistResponse.data.output.no_of_total_records ?? 0;
                    this.wishlistAgents = wishlistResponse.data.output.data.data ?? [];
                } catch (error) {
                    console.error('Error fetching wishlists:', error);
                } finally {
                    this.loadingAgents = false;
                }
            },
            async removeAgent(agent) {
                try {
                    const wishlistResponse = await this.$axios.post(`api/wishlist/agents/${agent.id}`, {
                        api_token: localStorage.getItem('userToken'),
                        user_id: this.user?.id,
                    });

                    if (wishlistResponse.status === 200) {
                        toastr.success(wishlistResponse.data.message, 'Success', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                            onHidden: () => {
                                this.fetchAgents();
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error wishlisting agent:', error);
                }
            },
            changeAgentPage(page) {
                if (page > 0 && page <= this.agentTotalPages) {
                    this.agentFilters.page = page;
                    this.fetchAgents();
                }
            },
        }
    }
</script>

<style scoped>
    .dropdown-item:hover,
    .dropdown-item.selected {
        background-color: #ffe6b9;
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        margin-top: 20px;
    }
</style>