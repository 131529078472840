<template>
    <div class="property-listing p-5">
        <div class="text-end">
            <h4 class="mb-2">{{ $t('available_credits') }}</h4>
            <p class="mb-0"><b>{{ user.available_credit_points ?? '0' }} {{ $t('credits') }}</b></p>
            <p class="mb-0" v-if="user.credit_points_expiry_date">{{ $t('expire_by') }} {{ user.credit_points_expiry_date }}</p>
        </div>

        <hr>
        <div class="filter-bar-se my-4">
            <h3>{{ $t('credit_transactions') }}</h3>
            <div class="filter-right-bar">
                <div class="dropdown">
                    <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                        <img src="frontend/images/Sort-By.svg"> {{ $t('sort_by') }}
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                        <li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Oldest' }"
                                @click.prevent="sort('Oldest')">{{ $t('oldest') }}</a></li>
                        <li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Newest' }"
                                @click.prevent="sort('Newest')">{{ $t('newest') }}</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <template v-if="historyTransactions.length > 0">
            <div class="table-responsive custom-table-design">
                <table class="table text-center table-bordered align-middle">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>{{ $t('type') }}</th>
                            <th>{{ $t('amount') }} ($/{{ $t('points') }})</th>
                            <th>{{ $t('cumulative_points') }}</th>
                            <th>{{ $t('datetime') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="transaction in historyTransactions" :key="transaction.id">
                            <td>{{ transaction.id }}</td>
                            <td>{{ formatType(transaction.trans_type) }} <span v-if="transaction?.remarks">- {{ transaction.remarks }}</span></td>
                            <td>{{ transaction.credit_points }}</td>
                            <td>{{ transaction.cumulative_points }}</td>
                            <td>{{ formatDate(transaction.created_at, true) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <template v-else>
            <div class="text-center">{{ $t('no_records_found') }}</div>
        </template>
        <div class="pagination-se" v-if="historyTransactions.length > 0">
            <nav aria-label="...">
                <ul class="pagination justify-content-center">
                    <li class="page-item" :class="{ disabled: filters.page === 1 }"
                        @click="filters.page > 1 && changePage(filters.page - 1)" v-if="filters.page > 1">
                        <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                alt="Previous"></a>
                    </li>
                    <li v-for="pageNumber in paginatedPages(filters.page, totalPages)" :key="pageNumber"
                        class="page-item" :class="{ active: pageNumber === filters.page }">
                        <a class="page-link"
                            @click.prevent="pageNumber !== filters.page && changePage(pageNumber)">
                            {{ pageNumber }}
                        </a>
                    </li>
                    <li class="page-item" :class="{ disabled: filters.page === totalPages }"
                        @click="filters.page < totalPages && changePage(filters.page + 1)"
                        v-if="filters.page < totalPages">
                        <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import toastr from 'toastr';
    import { formatDate, paginatedPages } from '@/utils/helpers';

    export default {
        data() {
            return {
                historyTransactions: [],
                totalPages: 0,
                totalRecords: 0,
                filters: {
                    page: 1,
                    sort_by_field: 'Newest',
                }
            };
        },
        computed: {
            ...mapState(['isAuthenticated', 'user', 'userProfile']),
        },
        mounted() {
            this.getHistory();
        },
        methods: {
            formatDate,
            paginatedPages,
            formatType(type) {
                let typeText = type;
                if (type == 'Points') {
                    typeText = 'Deduct';
                } else if (type == 'Package') {
                    typeText = 'Points';
                } else if (type == 'Redeem') {
                    typeText = 'Addition';
                }

                return typeText;
            },
            sort(sortBy) {
                this.filters.sort_by_field = sortBy;
                this.filters.page = 1;

                this.getHistory();
            },
            changePage(page) {
                if (page > 0) {
                    const totalPages = this.totalPages;
                    if (page <= totalPages) {
                        this.filters.page = page;
                        this.getHistory();
                    }
                }
            },
            async getHistory() {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.get(`/api/users/${this.user.id}/credits-history`,
                    {
                        params: {
                            api_token: token,
                            sort_by_field: this.filters.sort_by_field,
                            page_number: this.filters.page,
                        }
                    });

                    if (response.data.output) {
                        this.historyTransactions = response.data.output.history.data ?? [];
                        this.totalPages = response.data.output.history.last_page ?? 0;
                        this.totalRecords = response.data.output.history.total ?? 0;
                    } else {
                        toastr.error('Failed to fetch history', 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    toastr.error('Failed to fetch history', 'Error', { timeOut: 3000 });
                    console.error('Error fetching history:', error);
                }
            }
            
        }
    };
</script>

<style scoped>

</style>