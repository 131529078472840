<template>
    <section class="payment-se">
        <div class="container">
            <div class="payment-box">
                <div class="comman-title d-flex">
                    <h3>Current Balance</h3>
                    <span class="ms-auto payment-price">{{ user.available_credit_points }}</span>
                </div>
                <div class="form-group">
                    <label>Enter Preferred Amounts</label>
                    <input type="number" placeholder="Enter Preferred Amounts" class="form-control mb-2"
                        v-model.number="purchaseAmount" @input="handleInputChange" step="1">
                    <small class="pull-right">Min Reload Amount is $50</small>
                </div>
                <div class="btn-group-a">
                    <a href="#" v-for="amount in amounts" :key="amount" :class="{ active: purchaseAmount === amount }"
                        @click.prevent="setAmount(amount)">
                        ${{ amount }}
                    </a>
                </div>
            </div>
            <div class="comman-title">
                <h3>Payment Method</h3>
            </div>
            <div class="payment-box">
                <div class="text-end mb-2">
                    <img src="frontend/images/visa.jpg" alt=""><img src="frontend/images/master-card.png" alt="">
                </div>
                <div id="card-element" class="my-3"></div>
            </div>
            <div class="d-md-flex protect-payment">
                <div class="d-flex align-items-center">
                    <img src="frontend/images/lock.svg" class="me-3">
                    <p class="mb-0">We protect your payment information using<br> encryption to provide bank-level
                        security.</p>
                </div>
                <div class="ms-auto">
                    <button class="comman-btn-2" data-bs-toggle="modal" data-bs-target="#otpCodeModal">
                        Confirm Payment
                    </button>
                    <div class="modal fade" id="otpCodeModal" data-bs-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="otpCodeModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">OTP Verification</h5>
                                </div>
                                <div class="modal-body">
                                    <div><a href="#" @click.prevent="sendOtp">Click here to send OTP</a></div>
                                    <small>OTP will be sent to your registered mobile number <b>{{ user.mobile_number }}</b>.</small>
                                    <p v-if="otpCodeSent">OTP sent</p>
                                    <input type="text" v-model="otpCode" placeholder="Enter OTP" class="form-control mt-3">
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('cancel') }}</button>
                                    <button type="button" class="btn btn-primary" @click="verifyOtp">{{ $t('submit') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import toastr from "toastr";
    import { loadStripe } from '@stripe/stripe-js';

    export default {
        data() {
            return {
                currentBalance: 50,
                purchaseAmount: 50,
                amounts: [50, 100, 250, 350, 500],
                topUp: {},
                topUpPaymentUpdate: {},

                // stripe fields
                stripe: null,
                elements: null,
                card: null,

                otpCodeSent: false,
                otpCode: "",
                lockoutTime: 0,
            };
        },
        computed: {
            ...mapState(['isAuthenticated', 'user']),
        },
        async created() {
            await this.fetchProfileDetails();
        },
        async mounted() {
            if (!window.STRIPE_KEY) {
                toastr.error('Stripe key not found!', 'Error', { timeOut: 3000 });
            }
            this.stripe = await loadStripe(window.STRIPE_KEY);
            this.elements = this.stripe.elements();

            const options = {
                hidePostalCode: true,
                iconStyle: "solid"
            }
            this.card = this.elements.create('card', options);
            this.card.mount('#card-element');
        },
        methods: {
            async fetchProfileDetails() {
                try {
                    const profileResponse = await this.$axios.post('api/my-profile-detail', {
                        user_id: JSON.parse(localStorage.getItem('user')).id,
                        api_token: localStorage.getItem('userToken'),
                    });

                    this.$store.commit('SET_USER', profileResponse.data.output);
                } catch (error) {
                    console.error('Failed to fetch profile details:', error);
                }
            },
            setAmount(amount) {
                this.purchaseAmount = amount;
            },
            handleInputChange() {
                clearTimeout(this.inputTimeout);

                this.inputTimeout = setTimeout(() => {
                    this.validateAmount();
                }, 750);
            },
            validateAmount() {
                if (this.purchaseAmount < 50) {
                    this.purchaseAmount = 50;
                }

                 // Round down to nearest integer if its decimal
                if (!Number.isInteger(this.purchaseAmount)) {
                    this.purchaseAmount = Math.floor(this.purchaseAmount);
                }
            },

            async sendOtp() {
                try {
                    if (this.lockoutTime > 0) {
                        toastr.error('You are timed out. Please try again later.')
                        return;
                    }
                    const response = await this.$axios.post(`/api/code/send/${this.user.id}`, {
                        api_token: localStorage.getItem('userToken'),
                    });

                    if (response.status === 200) {
                        toastr.success('OTP sent to your mobile.', 'Success', { timeOut: 3000 });
                        this.otpCodeSent = true;
                    } else {
                        toastr.error('Failed to send OTP.', 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    toastr.error('Error sending OTP: ' + error.message, 'Error', { timeOut: 3000 });
                }
            },

            async verifyOtp() {
                try {
                    const response = await this.$axios.post(`/api/code/verify/${this.user.id}`, {
                        api_token: localStorage.getItem('userToken'),
                        otp: this.otpCode,
                    });

                    if (response.status === 200) {
                        this.submitTopUp();
                    } else {
                        toastr.error('Invalid OTP. Please try again.', 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    if (error.response.status === 429) {
                        this.lockoutTime = error.response.data.retry_after;
                        toastr.error(`Too many attempts. Try again in ${this.lockoutTime} seconds.`, 'Locked Out');

                        let interval = setInterval(() => {
                            if (this.lockoutTime > 0) {
                                this.lockoutTime--;
                            } else {
                                clearInterval(interval);
                            }
                        }, 1000);
                    } else if (error.response.status === 422) {
                        toastr.warning(`Incorrect OTP. ${error.response.data.remaining_attempts} attempts left.`, 'Warning');
                    } else {
                        toastr.error('Error verifying OTP: ' + error.message, 'Error', { timeOut: 3000 });
                    }
                }
            },
    
            // with stripe
            async submitTopUp() {
                try {
                    const { paymentMethod, error } = await this.stripe.createPaymentMethod({
                        type: 'card',
                        card: this.card,
                    });

                    if (error) {
                        toastr.error(error.message, 'Error', { timeOut: 3000 });
                        return;
                    }
                    toastr.info('Processing top up', 'Info');
                    const response = await this.$axios.post(`api/users/${this.user.id}/credits/top-up`, {
                        api_token: localStorage.getItem('userToken'),
                        amount: this.purchaseAmount,
                        payment_method: paymentMethod.id,
                    });

                    if (response.status === 200) {
                        toastr.success('Credit points top up successfully!', 'Success', { timeOut: 3000 });
                    } else {
                        toastr.error(response.data.error, 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    console.error('Error submitting top up:', error);
                } finally {
                    await this.fetchProfileDetails();
                    this.card.clear();
                    this.purchaseAmount = 50;
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                }
            },
        }
    };
</script>