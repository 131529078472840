<template>
    <section class="proxy-listing-page">
        <div class="container">
            <div class="top-tabs-design">
                <ul>
                    <li><a href="/profile">{{ $t('my_profile') }}</a></li>
                    <li v-if="!user.is_private_user"><a href="/verify-service-detail-page">{{ $t('verify_service_details') }}</a></li>
                    <li v-if="!user.is_private_user"><a href="/property-data-analytic">{{ $t('analytics_and_report') }}</a></li>
                    <li><a href="/wishlist">{{ $t('my_wishlist') }}</a></li>
                    <li><a href="javascript:;" class="active"> Proxy Listing </a></li>
                    <li><a href="/requested-service">{{ $t('requested_service') }}</a></li>
                    <li><a href="/messages">{{ $t('messages') }}</a></li>
                </ul>
            </div>
            <div class="row">
                <div class="col-md-9" >
                    <template v-if="proxyListing.length > 0">
                        <div class="table-responsive custom-table-design">
                            <h2 class="mb-2">Proxy Listings</h2>
                            <table class="text-center table-bordered align-middle" >
                                <thead>
                                <tr>
                                    <th>{{ $t('property_name') }}</th>
                                    <th>Street</th>
                                    <th>Area HDB Estate</th>
                                    <th>District</th>
                                    <th>Floor Area</th>
                                    <th>Price</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="property in proxyListing" :key="property.id">
                                    <td @click="redirectToPropertyDetails(property.property_listing_id)" style="cursor: pointer;">{{ property?.property_name }}</td>
                                    <td>{{ property.street }}</td>
                                    <td>{{ property.area_hdb_estate }}</td>
                                    <td>{{ property.district }}</td>
                                    <td>{{ property.floor_area }}</td>
                                    <td>{{ property.asking_price_sale_rent }}</td>
                                    <td class="d-flex align-items-center justify-content-between gap-2">
                                        <button class="comman-btn-2 w-50" @click="acceptProxyListing(property.id)">Accept</button>
                                        <button class="comman-btn-2-danger w-50" @click="declineProxyListing(property.id)">Decline</button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                    <template v-else>
                        <div class="text-center mt-5 mb-3">{{ $t('no_records_found') }}</div>
                    </template>
                    <div class="pagination-se mt-3" v-if="proxyListing.length > 0">
                        <nav aria-label="...">
                            <ul class="pagination justify-content-center">
                                <li class="page-item" :class="{ disabled: dataFilters.page === 1 }"
                                    @click="dataFilters.page > 1 && changePage(dataFilters.page - 1)" v-if="dataFilters.page > 1">
                                    <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                                              alt="Previous"></a>
                                </li>
                                <li v-for="pageNumber in paginatedPages(dataFilters.page, totalPages)" :key="pageNumber"
                                    class="page-item" :class="{ active: pageNumber === dataFilters.page }">
                                    <a class="page-link"
                                       @click.prevent="pageNumber !== dataFilters.page && changePage(pageNumber)">
                                        {{ pageNumber }}
                                    </a>
                                </li>
                                <li class="page-item" :class="{ disabled: dataFilters.page === totalPages }"
                                    @click="dataFilters.page < totalPages && changePage(dataFilters.page + 1)"
                                    v-if="dataFilters.page < totalPages">
                                    <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>
                <ProfilePageSideBar></ProfilePageSideBar>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapState} from "vuex";
    import ProfilePageSideBar from "@/views/ProfilePageSideBar.vue";
    import toastr from 'toastr';
    import {paginatedPages} from "@/utils/helpers";


    export default {
        name: 'ProxyListingPage',
        components: {ProfilePageSideBar},
        data() {
            return {
                proxyListing: '',
                totalRecords: 0,
                totalPages: 0,
                dataFilters: {
                    page: 1,
                },
            }
        },
        created() {
            this.getProxyListings();
        },
        computed: {
            ...mapState(['isAuthenticated', 'user', 'userProfile']),
        },
        methods: {
            paginatedPages,
            redirectToPropertyDetails(propertyId) {
                this.$router.push({ name: 'propertyDetail', params: { id: propertyId } });
            },
            async getProxyListings() {
                const token = localStorage.getItem('userToken');
                try {
                    if (this.user) {
                        const response = await this.$axios.get(`/api/properties/proxy/${this.user.id}`,
                            {
                                params: {
                                    api_token: token,
                                }
                            });
                        this.proxyListing = response.data.output.data.data;
                        this.totalRecords = response.data.output.total_records;
                        this.totalPages = response.data.output.total_pages;
                    }
                } catch (error) {
                    console.error('Unable to fetch proxy listing');
                }
            },
            async acceptProxyListing(propertyId) {
                const token = localStorage.getItem('userToken');
                try {
                    if (this.user) {
                        const response = await this.$axios.post(`/api/properties/${propertyId}/proxy/accept`, {
                            api_token: token,
                            property_listing_id: propertyId,
                        });
                        if (response.data.status == 1) {
                            toastr.success('Proxy Listing Accepted');
                            await this.getProxyListings();
                        } else {
                            toastr.error('Failed To Accept Proxy Listing ');
                        }
                    }
                } catch (error) {
                    console.error('Unable to accept proxy listing');
                }
            },
            async declineProxyListing(propertyId) {
                const token = localStorage.getItem('userToken');
                try {
                    if (this.user) {
                        const response = await this.$axios.post(`/api/properties/${propertyId}/proxy/reject`,
                            {
                                api_token: token,
                            });
                        if (response.data.status === 1) {
                            toastr.success('Proxy Listing Declined');
                            await this.getProxyListings();
                        } else {
                            toastr.error('Failed To Decline Proxy Listing ');
                        }
                    }
                } catch (error) {
                    console.error('Unable to accept proxy listing');
                }
            },
            changePage(page) {
                if (page > 0) {
                    const totalPages = this.totalPages;
                    if (page <= totalPages) {
                        this.dataFilters.page = page;
                        this.getProxyListings();
                    }
                }
            },
        }

    }

</script>

<style scoped>

    .proxy-listing-page {
        padding: 70px 0px;
    }
</style>


