<template>
    <section class="signin-sec">
        <div class="container">
            <div class="signin-bx m-auto">
                <h1>Login</h1>
                <form @submit.prevent="login">
                    <div class="form-group">
                        <input type="email" placeholder="Email address" class="form-control" v-model="email"
                            @blur="validateEmail" :class="{ 'is-invalid': emailError }" />
                        <div v-if="emailError" class="error">{{ emailError }}</div>
                    </div>
                    <div class="form-group">
                        <input type="password" placeholder="Password" class="form-control" v-model="password"
                            @blur="validatePassword" :class="{ 'is-invalid': passwordError }" />
                        <div v-if="passwordError" class="error">{{ passwordError }}</div>
                    </div>
                    <div class="d-flex justify-content-between form-group">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">
                                Remember me
                            </label>
                        </div>
                        <span class="forgot-link ml-auto"><a href="/forget-password">Forgot Password?</a></span>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn comman-btn-2 w-100" :disabled="loading">Login</button>
                    </div>
                    <div class="or-text"><span>Or continue with</span></div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-6 col-6">
                                <div class="login-link">
                                    <a href="" @click.prevent="loginWithGoogle"><i><img
                                    src="frontend/images/google-login.png"></i>Google</a>
                                </div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="login-link">
                                    <a href="" @click.prevent="loginWithFacebook"><i><img
                                    src="frontend/images/fb-login.png"></i>{{ $t('facebook') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <small class="no-login">
                        By continuing, I agree to PROPFACE's <br><a href="/terms-and-conditions">Terms of Use</a> & <a
                            href="/privacy-policy">Privacy Policy.</a>
                    </small>
                    <small class="no-login mb-0">
                        Not a member? <a href="/sign-up"><b>Create account</b></a>
                    </small>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapActions } from 'vuex';
    import 'toastr/build/toastr.min.css';
    import toastr from 'toastr';
    export default {
        name: 'SignIn',
        data() {
            return {
                email: '',
                password: '',
                emailError: '',
                passwordError: '',
                errorMessage: '',
                loading: false,
                form: {
                    resetEmail: null,
                },
                failedAttempts: 0,
                lockoutTime: null, 
            };
        },
        computed: {
            ...mapActions(['setAuthenticated', 'setUser']),
            isFormValid() {
                return !this.emailError && !this.passwordError && this.email && this.password;
            },
        },
        methods: {
            async loginWithGoogle() {
                try {
                    // Request the Google redirect URL from the API
                    const response = await this.$axios.get('api/google');
                    window.location.href = response.data.redirect_url; // Redirect to Google login
                } catch (error) {
                    console.error('Error during Google login:', error);
                }
            },

            async loginWithFacebook() {
                try {
                    // Request the Facebook redirect URL from the API
                    const response = await this.$axios.get('api/facebook/redirect');
                    window.location.href = response.data.redirect_url; // Redirect to Facebook login
                } catch (error) {
                    console.error('Error during Facebook login:', error);
                }
            },

            validateEmail() {
                this.emailError = this.email
                    ? /\S+@\S+\.\S+/.test(this.email)
                        ? ''
                        : 'Invalid email format'
                    : 'Email is required';
            },
            validatePassword() {
                this.passwordError = this.password
                    ? this.password.length >= 6
                        ? ''
                        : 'Password must be at least 6 characters long'
                    : 'Password is required';
            },

            startLockoutCountdown(remainingTime) {
                this.lockoutEndTime = Date.now() + remainingTime * 1000;

                this.lockoutInterval = setInterval(() => {
                    const timeLeft = Math.max(0, this.lockoutEndTime - Date.now());
                    if (timeLeft <= 0) {
                        clearInterval(this.lockoutInterval);
                        this.lockoutInterval = null;
                        this.errorMessage = '';
                    } else {
                        this.errorMessage = `Account is locked. Try again in ${Math.ceil(timeLeft / 1000)} seconds.`;
                    }
                }, 1000);
            },

            async login() {
                this.validateEmail();
                this.validatePassword();

                // Prevent login if there are validation errors
                if (this.emailError || this.passwordError) {
                    return;
                }

                if (this.loading) return; // Prevent multiple submissions

                // Check lockout status before proceeding with login
                const lockoutFailed = await this.checkLockout();
                if (lockoutFailed) {
                    return;  // If lockout check fails (true), stop the login process
                }

                try {
                    // Make the login API call
                    const response = await this.$axios.post('api/login', {
                        email: this.email,
                        password: this.password,
                    });

                    localStorage.setItem('userId', response.data.user.id);

                    // Dispatch login action to store user data
                    await this.$store.dispatch('login', response.data);

                    // Handle post-login actions
                    await this.handlePostLoginActions(response.data);

                } catch (error) {

                    this.failedAttempts += 1;

                    if (this.failedAttempts >= 4) {
                        // Set lockout time for 10 minutes
                        this.lockoutTime = new Date().getTime() + 10 * 60 * 1000;
                        this.errorMessage = 'Too many failed attempts. Try again in 10 minutes.';
                        toastr.error(this.errorMessage, 'Failed', { timeOut: 3000 });

                        // Send API request to lock the account
                        this.$axios.post('/api/lock-account', { email: this.email })
                            .then(() => {
                                toastr.error('Account locked!', 'Failed', { timeOut: 3000 });
                            })
                            .catch(error => {
                                console.error('Error locking account:', error);
                        });
                    } else {
                        this.errorMessage = `Login failed. Please check your credentials. (${4 - this.failedAttempts} attempts remaining)`;
                        toastr.error(this.errorMessage, 'Failed', { timeOut: 3000 });
                    }

                    console.error('Login error:', error);
                    
                } finally {
                    this.loading = false; // Reset loading state
                }
            },

            async checkLockout() {
                try {
                    await this.$axios.post('api/check-lockout', {
                        email: this.email
                    });

                    return false; // No lockout
                } catch (error) {
                    if (error.response && error.response.status === 429) {
                        const remainingTime = error.response.data.remaining_time || 0;
                        this.startLockoutCountdown(remainingTime); // Start countdown
                        const minutes = Math.floor(remainingTime / 60);
                        const seconds = remainingTime % 60;

                        toastr.error(`Lock for ${minutes}m ${seconds}s`, 'Account Locked', { timeOut: 3000 });
                        return true; // Lockout is active
                    } else {
                        this.errorMessage = 'An error occurred. Please try again.';
                        toastr.error(this.errorMessage, 'Failed', { timeOut: 3000 });
                        return false;
                    }
                }
            },

            async handlePostLoginActions(userData) {
                const redirectAfterLogin = localStorage.getItem('hybrid_listing_type');
                const propertyListingId = localStorage.getItem('property_listing_id');
                const userId = localStorage.getItem('userId');

                // Define the function to update the user for a property
                const updateUserForProperty = async (propertyListingId, userId) => {
                    try {
                        await this.$axios.post('/api/update-user-property', {
                            property_listing_id: propertyListingId,
                            user_id: userId,
                        });
                    } catch (error) {
                        console.error(
                            'Error:',
                            error.response?.data?.message || 'An error occurred while updating the property user.'
                        );
                    }
                };

                try {
                    // Handle hybrid listing type redirection
                    if (redirectAfterLogin) {
                        if (propertyListingId && userId) {
                            await updateUserForProperty(propertyListingId, userId);
                            this.$router.push('/my-property/');
                            return;
                        } 
                    }

                    if (userData.user.is_email_verified == 0) {
                        this.$router.push('/verify-email');
                    } else {
                        // Default redirection if no specific condition matches
                        this.$router.push('/profile');
                    }
                } catch (error) {
                    console.error('Error during login actions:', error.message || error);
                }
            },

            async googleLogin() {
                // TODO: Google Login
                const today = new Date();
                const googleIdToken = 'GT-' + today.getHours();
                localStorage.removeItem('facebookAccessToken');
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    const response = await this.$axios.post('api/login-google', {
                        google_id_token: googleIdToken,
                    });

                    if (response.data && response.data.token) {
                        await this.$store.dispatch('login', response.data);
                    }

                    localStorage.setItem('googleIdToken', googleIdToken);
                    this.$router.push('/sign-up-social');
                } catch (error) {
                    // Handle login errors
                    this.errorMessage = 'Login failed. Please check your credentials.';
                    console.error('Login error:', error);
                } finally {
                    this.loading = false;
                }
            },
            async facebookLogin() {
                // TODO: FB Login
                const today = new Date();
                const facebookAccessToken = 'FB-' + today.getHours();
                localStorage.removeItem('googleIdToken');
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    const response = await this.$axios.post('api/login-facebook', {
                        facebook_access_token: facebookAccessToken,
                    });

                    if (response.data && response.data.token) {
                        await this.$store.dispatch('login', response.data);
                    }

                    localStorage.setItem('facebookAccessToken', facebookAccessToken);
                    this.$router.push('/sign-up-social');
                } catch (error) {
                    // Handle login errors
                    this.errorMessage = 'Login failed. Please check your credentials.';
                    console.error('Login error:', error);
                } finally {
                    this.loading = false;
                }
            },
            async widgetPropetyIdSet(property_listing_id, userId) {
                const token = localStorage.getItem('userToken');
                this.loading = true;
                try {
                    const response = await this.$axios.post('api/widget-property-set-user-id-after-login', {
                        user_id: userId,
                        api_token: token,
                        property_listing_id: property_listing_id,
                    });
                    if (response.data) {
                        localStorage.removeItem('widget_propertyId');
                        this.$router.push('/my-property/');
                    }
                } catch (error) {
                    this.errorMessage = 'Login failed. Please check your credentials.';
                    console.error('Login error:', error);
                } finally {
                    this.loading = false;
                }
            },
            async forgetPassword() {
                if (!this.validateResetEmail(this.form.resetEmail)) {
                    alert("Please enter a valid email address.");
                    return;
                }

                this.loading = true;

                try {
                    const response = await this.$axios.get('/auth/forgot-password', {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ email: this.form.resetEmail }),
                    });

                    if (!response) {
                        throw new Error("Something went wrong. Please try again.");
                    }

                    const result = await response.json();

                    alert(result.message || "Password reset email sent successfully.");
                    this.resetForm();
                } catch (error) {
                    console.error("Error:", error);
                    alert(error.message || "Unable to send password reset email. Please try again.");
                } finally {
                    this.loading = false;
                }
            },
            validateResetEmail(email) {
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return regex.test(email);
            },
            resetForm() {
                this.form.resetEmail = "";
            },
        }
    }
</script>