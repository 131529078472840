<template>
    <section class="signin-sec">
        <div class="container">
            <div class="signin-bx m-auto">
                <h1>{{ $t('verify') }} {{ $t('email_address') }}</h1>
                <p class="text-center">
                    Hi <b>{{ user.name }}</b>!  
                    <br>You're almost set to start enjoying our features.  
                    <br>Simply click the button below for email verification  
                    <br>The link expires in <b>48 hours</b>.
                </p>
                <div class="d-flex justify-content-center">
                    <button class="comman-btn-2 mt-3" @click.prevent="sendEmailCode">{{ $t('verify') }} {{ $t('email_address') }}</button>
                </div>
            </div>
        </div>
    </section>
</template>

<style>
    .disabled {
        pointer-events: none;
        color: grey;
    }
</style>

<script>
import { mapState } from "vuex";
import toastr from "toastr";

export default {
    data() {
        return {
            errors: {},
        };
    },
    computed: {
        ...mapState(['isAuthenticated', 'user']),
    },
    methods: { 
        async sendEmailCode() {
            try {
                await this.$axios.post(`api/send-verification-email/${this.user.id}`, {
                    email: this.user.email,
                });
                toastr.success('Verification link sent successfully!', 'Success');
                // Handle success (e.g., show a success message or start a countdown timer)
            } catch (error) {
                toastr.error('Verification link failed', 'Failed');
                console.error('Error sending verification link:', error);
                // Handle error (e.g., show an error message)
            }
        },
    }
};
</script>
