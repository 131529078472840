<template>
    <div class="p-5">
        <div class="mb-3">
            <h4>{{ $t('reward_points_management') }}</h4>
        </div>
        <div class="flex items-center justify-content-between row reward-points">
            <div class="col">
                <div class="card bpt-box">
                    <div class="card-body align-items-center text-center">
                        <h4 class="mb-0">{{ user?.available_reward_points ?? '0' }}</h4>
                        <div class="card-title mb-0">{{ $t('available') }} {{ $t('reward_points') }}</div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card bpt-box h-100">
                    <div class="card-body align-items-center text-center">
                        <h4 class="mb-0">{{ user?.earned_reward_points ?? '0' }}</h4>
                        <div class="card-title mb-0">{{ $t('earned') }} {{ $t('reward_points') }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex items-center justify-content-between row reward-points my-3" v-if="currentTier">
            <div class="col my-auto justify-content-center d-flex">
                <div class="circle text-center d-flex align-items-center justify-content-center">
                    <h3>{{ currentTier?.badge_name }} {{ $t('status') }}</h3>
                </div>
            </div>
            <div class="col my-auto" v-if="nextTier">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" :style="{ width: tierPercentage + '%', 'background-color': '#F6B034' }"
                        :aria-valuenow="tierPercentage" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div>{{ nextTier?.points_from - user.earned_reward_points }} {{ $t('points_to') }} {{ nextTier?.badge_name }} {{ $t('status') }}</div>
            </div>
            <div class="col my-auto justify-content-center d-flex" v-if="nextTier">
                <div class="circle text-center d-flex align-items-center justify-content-center">
                    <h3>{{ nextTier?.badge_name }} {{ $t('status') }}</h3>
                </div>
            </div>
        </div>

        <hr>
        <div class="filter-bar-se my-4">
            <h3>{{ $t('points_transactions') }}</h3>
            <div class="filter-right-bar">
                <div class="dropdown">
                    <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                        <img src="frontend/images/Sort-By.svg"> {{ $t('sort_by') }}
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                        <li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Oldest' }"
                                @click.prevent="sort('Oldest')">{{ $t('oldest') }}</a></li>
                        <li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Newest' }"
                                @click.prevent="sort('Newest')">{{ $t('newest') }}</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <template v-if="historyTransactions.length > 0">
            <div class="table-responsive custom-table-design">
                <table class="table text-center table-bordered align-middle">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>{{ $t('type') }}</th>
                            <th>{{ $t('amount') }}</th>
                            <th>{{ $t('cumulative_points') }}</th>
                            <th>{{ $t('datetime') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="transaction in historyTransactions" :key="transaction.id">
                            <td>{{ transaction.id }}</td>
                            <td>{{ formatTitleCase(transaction.remarks) }}</td>
                            <td>{{ transaction.new_available_value - transaction.old_available_value }}</td>
                            <td>{{ transaction.new_available_value }}</td>
                            <td>{{ formatDate(transaction.created_at, true) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <template v-else>
            <div class="text-center">{{ $t('no_records_found') }}</div>
        </template>
        <div class="pagination-se" v-if="historyTransactions.length > 0">
            <nav aria-label="...">
                <ul class="pagination justify-content-center">
                    <li class="page-item" :class="{ disabled: filters.page === 1 }"
                        @click="filters.page > 1 && changePage(filters.page - 1)" v-if="filters.page > 1">
                        <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                alt="Previous"></a>
                    </li>
                    <li v-for="pageNumber in paginatedPages(filters.page, totalPages)" :key="pageNumber"
                        class="page-item" :class="{ active: pageNumber === filters.page }">
                        <a class="page-link"
                            @click.prevent="pageNumber !== filters.page && changePage(pageNumber)">
                            {{ pageNumber }}
                        </a>
                    </li>
                    <li class="page-item" :class="{ disabled: filters.page === totalPages }"
                        @click="filters.page < totalPages && changePage(filters.page + 1)"
                        v-if="filters.page < totalPages">
                        <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import toastr from 'toastr';
    import { formatDate, formatTitleCase, paginatedPages } from '@/utils/helpers';

    export default {
        data() {
            return {
                historyTransactions: [],
                totalPages: 0,
                totalRecords: 0,
                filters: {
                    page: 1,
                    sort_by_field: 'Newest',
                },
                currentTier: null,
                nextTier: null,
                tierPercentage: 0
            };
        },
        computed: {
            ...mapState(['isAuthenticated', 'user', 'userProfile']),
        },
        mounted() {
            this.getHistory();
            this.getTierStatus();
        },
        methods: {
            formatDate,
            paginatedPages,
            formatTitleCase,
            sort(sortBy) {
                this.filters.sort_by_field = sortBy;
                this.filters.page = 1;

                this.getHistory();
            },
            changePage(page) {
                if (page > 0) {
                    const totalPages = this.totalPages;
                    if (page <= totalPages) {
                        this.filters.page = page;
                        this.getHistory();
                    }
                }
            },
            async getHistory() {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.get(`/api/users/${this.user.id}/reward-points-history`,
                    {
                        params: {
                            api_token: token,
                            sort_by_field: this.filters.sort_by_field,
                            page_number: this.filters.page,
                        }
                    });

                    if (response.data.output) {
                        this.historyTransactions = response.data.output.history.data ?? [];
                        this.totalPages = response.data.output.history.last_page ?? 0;
                        this.totalRecords = response.data.output.history.total ?? 0;
                    } else {
                        toastr.error('Failed to fetch history', 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    toastr.error('Failed to fetch history', 'Error', { timeOut: 3000 });
                    console.error('Error fetching history:', error);
                }
            },
            async getTierStatus() {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.get(`/api/users/${this.user.id}/reward-tier`,
                    {
                        params: {
                            api_token: token
                        }
                    });

                    if (response.data.output) {
                        this.currentTier = response.data.output.current_tier ?? null;
                        this.nextTier = response.data.output.next_tier ?? null;

                        this.tierPercentage = Math.floor((this.user.earned_reward_points - this.currentTier.points_from) / (this.currentTier.points_to - this.currentTier.points_from) * 100)
                    } else {
                        toastr.error('Failed to fetch tier status', 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    toastr.error('Failed to fetch tier status', 'Error', { timeOut: 3000 });
                    console.error('Error fetching tier status:', error);
                }
            }
        }
    };
</script>

<style scoped>

</style>