<template>    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3 v-if="this.itemId>0"><a href="#"><i class="fa fa-angle-left"></i></a> {{ $t('edit') }} {{ $t('property_listing') }}</h3>
                    <h3 v-if="this.itemId==0"><a href="#"><i class="fa fa-angle-left"></i></a> {{ $t('create') }} {{ $t('property_listing') }}</h3>
                </div>
                <div class="progress-bar-box">
                    <div>1/5</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>
                            <li><a class="active" href="/post-property-listing">{{ $t('step') }} 1</a></li>
                            <li :class="isDisabledStep2"><router-link :to="`/post-property-listing-2/${itemId}`">{{ $t('step') }} 2</router-link></li>
                            <li :class="isDisabledStep3"><router-link :to="`/post-property-listing-3/${itemId}`">{{ $t('step') }} 3</router-link></li>
                            <li :class="isDisabledStep4"><router-link :to="`/post-property-listing-4/${itemId}`">{{ $t('step') }} 4</router-link></li>
                            <li :class="isDisabledStep5"><router-link :to="`/post-property-listing-5/${itemId}`">{{ $t('step') }} 5</router-link></li>								
                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <form @submit.prevent="submitPost">
                        <div class="property-box">
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>{{ $t('key_information') }}</h3>
                                </div>

                                <div v-if="user.is_corporate || user?.package?.title === 'Gold' || user?.package?.title === 'Platinum'" class="form-group">
                                    <div>
                                        <label>{{ $t('project') }}: <strong>{{ projectName ?? '-' }}</strong></label>
                                        <Multiselect
                                            v-model="form.project" :options="projects" :searchable="true"
                                                      label="project_name" track-by="id" :close-on-select="true" :show-labels="false"
                                                     placeholder="Pick a project"></Multiselect>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>{{ $t('listing_type') }}*</label>
                                    <select 
                                        id="group1Select" 
                                        class="form-control"
                                        v-model="form.listingType" 
                                    >
                                        <option value="" disabled>{{ $t('listing_type') }}</option>
                                        <option 
                                        v-for="option in optionsGroup1" 
                                        :key="option.value" 
                                        :value="option.value"
                                        >
                                        {{ option.label }}
                                        </option>
                                    </select>
                                    <span  class="error" v-if="errors.listingType">{{ errors.listingType }}</span>
                                </div>
                                <div class="form-check mb-2">
                                    <input class="form-check-input" type="checkbox" v-model="form.qualify_for_foreigner" id="qualify_for_foreigner">
                                    <label class="form-check-label w-100" for="qualify_for_foreigner">
                                        {{ $t('qualify_for_foreigner') }}
                                    </label>
                                </div>	
                            </div>
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>{{ $t('property_address') }} / {{ $t('location') }}</h3>
                                </div>
                                <div class="form-group">
                                    <label>{{ $t('property_name') }}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="form.property_name"
                                        placeholder="Property Name"
                                        @input="fetchPropertyDetails(form.property_name)"
                                    />
                                    <span class="error" v-if="errors.property_name">{{ errors.property_name }}</span>
                                </div>

                                <div class="col">
                                    <div class="form-group">
                                        <label>{{ $t('country') }}</label>
                                        <!-- Country Selection -->
                                        <div>
                                            <select v-model="form.country" @change="handleCountryChange" class="form-control">
                                                <option value="" disabled selected>{{ $t('select') }} {{ $t('country') }}</option>
                                                <option value="singapore">{{ $t('singapore') }}</option>
                                                <option  v-if="this.user.is_corporate && purchasedPackage === 1"  value="malaysia">{{ $t('malaysia') }}</option>
                                            </select>
                                            <span class="error" v-if="errors.country">{{ errors.country }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row row-cols-1 row-cols-md-3">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('block_no') }}</label>
                                            <input type="string" class="form-control"  v-model="form.block_no" placeholder="Block No">
                                            <span  class="error" v-if="errors.block_no">{{ errors.block_no }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('level_no') }}.</label>
                                            <input type="string" class="form-control" v-model="form.level_no" placeholder="Level No.">
                                            <span  class="error" v-if="errors.level_no">{{ errors.level_no }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('unit_no') }}.</label>
                                            <input type="string" class="form-control"  v-model="form.unit_no" placeholder="Unit No.">
                                            <span  class="error" v-if="errors.unit_no">{{ errors.unit_no }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('street') }}*</label>
                                                <input
                                                class="form-control"
                                                type="text"
                                                v-model="form.street"
                                                @input="fetchSuggestions"
                                                placeholder="Type to Street..."
                                                />
                                                <span  class="error" v-if="errors.street">{{ errors.street }}</span>
                                                <ul v-if="suggestions.length" class="sugesstion_ul">
                                                    <li v-for="(suggestion, index) in suggestions" :key="index" @click="selectSuggestion(suggestion)">
                                                        {{ suggestion }}
                                                    </li>
                                                </ul>              
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>{{ $t('area') }} / {{ $t('hdb_estate') }}*</label>
                                        <div class="dropdown">
                                            <input
                                                id="area"
                                                type="text"
                                                v-model="form.area_hdb_estate"
                                                class="form-control"
                                                @input="fetchSuggestionsArea"
                                                @focus="dropdownVisible = true"
                                                placeholder="Select or type an Area"
                                                @blur="closeDropdown"
                                            />
                                            <ul 
                                                v-if="dropdownVisible && suggestionsArea.length > 0" 
                                                class="dropdown-menu show" 
                                                style="width: 100%; max-height: 200px; overflow-y: auto;"
                                            >
                                                <li 
                                                    v-for="(suggestion, index) in suggestionsArea" 
                                                    :key="index" 
                                                    @mousedown.prevent="selectSuggestionArea(suggestion)" 
                                                    class="dropdown-item"
                                                >
                                                    {{ suggestion }}
                                                </li>
                                            </ul>
                                            <span class="error" v-if="errors.area_hdb_estate">{{ errors.area_hdb_estate }}</span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div>
                                            <label>{{ $t('postcode') }}</label>
                                            <input 
                                                v-model="form.postcode" 
                                                type="text" 
                                                class="form-control" 
                                                placeholder="Enter Postcode" 
                                                />
                                            <span class="error" v-if="errors.postcode">{{ errors.postcode }}</span>
                                        </div>
                                    </div>
                             
                                    <div class="form-group">
                                        <label>{{ $t('district') }}</label>

                                        <div class="dropdown">
                                            <input
                                                id="district_id"
                                                type="text"
                                                v-model="form.district"
                                                class="form-control"
                                                @input="filterDistricts"
                                                @focus="dropdownVisible = true"
                                                placeholder="Select or type a District"
                                                @blur="closeDropdown"
                                            />
                                            <ul 
                                                v-if="dropdownVisible && filteredDistrictList.length > 0" 
                                                class="dropdown-menu show" 
                                                style="width: 100%; max-height: 200px; overflow-y: auto;"
                                            >
                                                <li 
                                                    v-for="option in filteredDistrictList" 
                                                    :key="option.value" 
                                                    @mousedown.prevent="selectDistrict(option.label)" 
                                                    class="dropdown-item"
                                                >
                                                    {{ option.label }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div v-if="form.country === 'malaysia'" class="col">
                                        <div class="form-group">
                                        <label>{{ $t('state') }}</label>
                                        <div>
                                            <select class="form-control" v-model="form.state">
                                            <option value="" disabled>{{ $t('select') }} {{ $t('state') }}</option>
                                            <!-- Loop through STATE_MY constant and display states -->
                                            <option v-for="state in stateMyOptions" :key="state.value" :value="state.value">
                                                {{ state.label }}
                                            </option>
                                            </select>
                                            <span class="error" v-if="errors.state">{{ errors.state }}</span>
                                        </div>
                                        </div>
                                    </div>

                                </div>								
                            </div>
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>{{ $t('basic_information') }}</h3>
                                </div>
                                <div class="row row-cols-1 row-cols-md-2">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('property_type') }}</label>
                                            <select class="form-control" v-model="form.selectedPropertyType">
                                                <option value="" disabled>{{ $t('select') }} {{ $t('property_type') }}</option>
                                                <RecursiveDropdown :options="propertyOptions" />
                                            </select>
                                            <span class="error" v-if="errors.selectedPropertyType">{{ errors.selectedPropertyType }}</span>
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('land_tenure') }}</label>
                                            <select id="land_tenure" v-model="form.land_tenure" class="form-control">
                                                <option value="" disabled selected>{{ $t('land_tenure') }}</option>
                                                <option v-for="option in optionsLandTenuresList" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                            <span class="error" v-if="errors.selectedLandTenures">{{ errors.selectedLandTenures }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <div class="row row-cols-1 row-cols-md-2">
                                                <div class="col">
                                                    <label>{{ $t('floor_area') }} *</label>
                                                    <input type="number" min="0"  class="form-control col" v-model="form.floor_area" placeholder="Floor Area">
                                                    <span  class="error" v-if="errors.floor_area">{{ errors.floor_area }}</span>
                                                </div>
                                                <div class="col">
                                                    <label>{{ $t('floor_area') }} (SQF/SQM)*</label>
                                                    <select class="form-control col" v-model="form.floor_area_unit">
                                                        <option value="SQF">SQF</option>
                                                        <option value="SQM">SQM</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <div class="row row-cols-1 row-cols-md-2">
                                                <div class="col">
                                                    <label>{{ $t('land_area') }} ({{ $t('for_landed_only') }})</label>
                                                    <input type="number" min="0" class="form-control col" v-model="form.land_area" placeholder="Land Area">
                                                </div>
                                                <div class="col">
                                                    <label>{{ $t('land_area') }} (SQF/SQM)</label>
                                                    <select class="form-control col" v-model="form.land_area_unit">
                                                        <option value="SQF">SQF</option>
                                                        <option value="SQM">SQM</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('land_title_type') }}*</label>
                                            <select class="form-control col" v-model="form.land_title_type">
                                                <option value="" selected> {{ $t('land_title_type') }}</option>
                                                <option v-if="form.country !== 'malaysia'" value="HDB">HDB</option>
                                                <option value="Strata">Strata</option>
                                                <option value="Landed">Landed</option>
                                            </select>
                                            <span  class="error" v-if="errors.land_title_type">{{ errors.land_title_type }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('developer') }}</label>
                                            <input type="text" class="form-control" v-model="form.developer_name" placeholder="Developer">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('total') }} {{ $t('blocks') }}</label>
                                            <input type="number" min="0" class="form-control" v-model="form.total_blocks" placeholder="Total Blocks">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('total') }} {{ $t('units') }}</label>
                                            <input type="number" min="0" class="form-control" v-model="form.total_units" placeholder="Total Units">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('total') }} {{ $t('carpark_lots') }}</label>
                                            <input type="number" min="0" class="form-control" v-model="form.total_car_parks" placeholder="Total carpark Lots">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('year_of_completion') }}</label>
                                            <input type="number" min="0" class="form-control"   v-model="form.year_of_competion_TOP" placeholder="Year of Completion">
                                        </div>
                                    </div>
                                </div>								
                            </div>
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>{{ $t('configuration') }}</h3>
                                </div>
                                <div class="row row-cols-1 row-cols-md-3">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('rooms') }}*</label>
                                            <div v-if="customRoomInput === ''">
                                            <select v-model="form.no_of_rooms" class="form-control" @change="handleSelectChange">
                                                <option value="" disabled selected>{{ $t('select') }} {{ $t('options') }}</option>
                                                <option v-for="n in 9" :key="n" :value="n">{{ n }}</option>
                                                <option value="custom">{{ $t('others') }}</option>
                                            </select>
                                            </div>
                                            <input 
                                                v-if="form.no_of_rooms === 'custom' || form.no_of_rooms > 9" 
                                                type="number" 
                                                min="0" 
                                                class="form-control mt-2"
                                                v-model="customRoomInput"
                                                placeholder="Enter Rooms Number"
                                            >
                                            <span class="error" v-if="errors.no_of_rooms">{{ errors.no_of_rooms }}</span>
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('bath') }}*</label>
                                            <div v-if="customBathInput === ''">
                                            <select v-model="form.no_of_baths" class="form-control" @change="handleBathSelectChange">
                                                <option value="" disabled selected>{{ $t('select') }} {{ $t('options') }}</option>
                                                <option v-for="n in 9" :key="n" :value="n">{{ n }}</option>
                                                <option value="custom">{{ $t('others') }}</option>
                                            </select>
                                            </div>
                                            <input 
                                                v-if="form.no_of_baths === 'custom' || form.no_of_baths > 9" 
                                                type="number" 
                                                min="0" 
                                                class="form-control mt-2"
                                                v-model="customBathInput"
                                                placeholder="Enter Bath Number"
                                            >
                                            <span class="error" v-if="errors.no_of_baths">{{ errors.no_of_baths }}</span>
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('carpark_lots') }}*</label>
                                            <div v-if="customCarParkLotsInput === ''">
                                            <select v-model="form.no_car_park_lots" class="form-control" @change="handleCarParkLotsSelectChange">
                                                <option value="" disabled selected>{{ $t('select') }} {{ $t('options') }}</option>
                                                <option v-for="n in 9" :key="n" :value="n">{{ n }}</option>
                                                <option value="custom">{{ $t('others') }}</option>
                                            </select>
                                            </div>
                                            <input 
                                                v-if="form.no_car_park_lots === 'custom' || form.no_car_park_lots > 9" 
                                                type="number" 
                                                min="0" 
                                                class="form-control mt-2"
                                                v-model="customCarParkLotsInput"
                                                placeholder="Enter Car Park Lots Number"
                                            >
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('layout_type') }}*</label>
                                            <select id="layout_type_id" v-model="form.layout_type_id" class="form-control">
                                                <option value="" disabled selected>{{ $t('layout_type') }}</option>
                                                <option v-for="option in optionsLayoutTypeList" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </div> 
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('floor_level') }}*</label>
                                            <select id="floor_level_id" v-model="form.floor_level_id" class="form-control">
                                                <option value="" disabled selected>{{ $t('floor_level') }}</option>
                                                <option v-for="option in optionsFloorLevelList" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                            <span  class="error" v-if="errors.floor_level_id">{{ errors.floor_level_id }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('main_view') }}*</label>
                                            <select id="main_view_id" v-model="form.main_view_id" class="form-control">
                                                <option value="" disabled selected>{{ $t('main_view') }}</option>
                                                <option v-for="option in optionsMainList" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>								
                            </div>
                            <div class="d-flex justify-content-md-end">
                                <button class="btn comman-btn-6">{{ $t('cancel') }}</button>
                                <button class="btn comman-btn-2 ms-3" type="submit">Save & Next</button>
                            </div>
                            <div class="progress-bar-box">
                                <div>{{ $t('form_completion') }}: {{ completionPercentage }}%</div>
                            <div class="progress">
                                <div 
                                    class="progress-bar" 
                                    role="progressbar" 
                                    :style="{ width: completionPercentage + '%' }" 
                                    :aria-valuenow="completionPercentage" 
                                    aria-valuemin="0" 
                                    aria-valuemax="100">
                                </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
  
  <script>
  import { mapState } from 'vuex';
  import RecursiveDropdown from '@/components/RecursiveDropdown.vue';
  import { OPTIONS_GROUP_1,PROPERTY_TYPE,PROPERTY_TYPE_MY, STATE_MY} from '@/constants/constantsProperty';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';
  import Multiselect from 'vue-multiselect';

  export default {
    name: 'PostPropertyListing', 
    components: {
      RecursiveDropdown, Multiselect
    },  
    data() {
        return {
            currentPage: 'step1',
            isDisabledStep2 :'disabled',
            isDisabledStep3 :'disabled',
            isDisabledStep4 :'disabled',
            isDisabledStep5 :'disabled',
            optionsGroup1: OPTIONS_GROUP_1,
            optionsLandTenuresList: [],
            optionsDistrictList: [],
            optionsLayoutTypeList: [],
            optionsFloorLevelList: [],
            optionsMainList: [],
            suggestions: [],
            suggestionsArea: [],
            suggestionsDistrict: [],
            editData: '',
            projects: [],
            package_details: [],
            dropdownVisible: false,
            districtList: [],
            filteredDistrictList: [], 
            areaList: [],
            filteredAreaList: [],
            projectName: '',
            customRoomInput: '',
            customBathInput: '',
            customCarParkLotsInput: '',
            form: {
                property_name: '',
                street: '',
                qualify_for_foreigner: '0',
                project: '',
                listingType: '',
                district_id: '',
                postcode: '',
                state: '',
                country: 'singapore',
                land_tenure: '',
                no_of_rooms: '',
                no_of_baths: '',
                no_car_park_lots: '',
                layout_type_id: '',
                floor_level_id: '',
                land_title_type: '',
                main_view_id: '',
                selectedPropertyType: '',
                floor_area_unit: 'SQF',
                land_area_unit: 'SQF',
            },

            options: this.transformData(PROPERTY_TYPE),
            optionsMy: this.transformData(PROPERTY_TYPE_MY),
            optionsStateMy: this.transformData(STATE_MY),
            errors: {}
        }
    },

    computed: {
      ...mapState(['isAuthenticated', 'user']),

      hierarchicalOptions() {        
        return this.createHierarchicalOptions(this.options);
      },

      hierarchicalOptionsMy() {        
        return this.createHierarchicalOptionsMy(this.optionsMy);
      },

      propertyOptions() {
        return this.form.country === 'malaysia' ? this.hierarchicalOptionsMy : this.hierarchicalOptions;
      },

      stateMyOptions() {
        return STATE_MY;
      },
        itemId() {
        return this.$route.params.id;
        },
        completionPercentage() {
        const fields = [
            this.form.listingType,
            this.form.property_name,
            this.form.street,
            this.form.area_hdb_estate,
            this.form.district,
            this.form.block_no,
            this.form.level_no,
            this.form.unit_no,
            this.form.land_tenure,
            this.form.floor_area,
            this.form.land_area,
            this.form.land_title_type,
            this.form.developer_name,
            this.form.total_blocks,
            this.form.total_units,
            this.form.total_car_parks,
            this.form.year_of_competion_TOP,
            this.form.no_of_rooms,
            this.form.no_of_baths,
            this.form.no_car_park_lots,
            this.form.layout_type_id,
            this.form.floor_level_id,
            this.form.main_view_id,
        ];
        const filledFields = fields.filter(field => field != null && field !== "").length;
        return Math.round((filledFields / fields.length) * 100);
        },
    },

    mounted() {
        this.fetchOptions();
        this.fetchDistricts(); 
        this.fetchAreas();
    },

    created() {
        this.fetchOptions();
        if(this.itemId > 0){
            this.isDisabledStep2 = '';
            this.isDisabledStep3 = '';
            this.isDisabledStep4 = '';
            this.isDisabledStep5 = '';
        }
        this.checkCurrentPackage();
    },

    watch: {
        'form.property_name': function (newValue) {
            if (newValue.length >= 3) {
                this.fetchPropertyDetails(newValue);
            }
        },
        'form.country'(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.resetFields();
            }
        },
        'form.project': {
            handler(newValue) {
                this.projectName = newValue ? newValue.project_name : 'Not Found';
            },
            deep: true,
            immediate: true
        },

        completionPercentage() {
            this.saveCompletion();
        },
    },

    beforeMount() {
        this.fetchProjects();
    },

    methods: {

        handleSelectChange() {
            if (this.form.no_of_rooms !== 'custom') {
                this.customRoomInput = '';
            }
        },

        handleBathSelectChange() {
            if (this.form.no_of_baths !== 'custom') {
                this.customBathInput = '';
            }
        },

        handleCarParkLotsSelectChange() {
            if (this.form.no_car_park_lots !== 'custom') {
                this.customCarParkLotsInput = '';
            }
        },

        handleCountryChange() {
            this.fetchDistricts(); 
            this.fetchAreas(); 
            this.resetFields(); 
        },

        resetFields() {
            this.form.property_name = '',
            this.form.street = '';
            this.form.area_hdb_estate = '',
            this.form.postcode = '',
            this.form.district = '',
            this.form.state = '',
            this.form.block_no = '',
            this.form.level_no = '',
            this.form.unit_no = ''
        },

        createHierarchicalOptionsStateMy(options) {
            return options.map(option => ({
                value: option.value,
                label: option.label
            }));
        },

        async fetchDistricts() {
            try {
                const token = localStorage.getItem('userToken');
                const parent_id = this.form.country === 'singapore' ? 93 : 7; 

                // Fetch districts using the updated API structure
                const response = await this.$axios.post("/api/location", {
                    type: "City",
                    parent_id: parent_id,
                    api_token: token
                });

                if (response.data.status === 1 && response.data.output.length) {
                    // Map district data to label and value
                    this.districtList = response.data.output.map((d) => ({
                        label: d.name,
                        value: d.name
                    }));
                } else {
                    this.districtList = [];
                    console.warn("No districts found.");
                }
            } catch (error) {
                console.error("Error fetching districts:", error);
            }
        },

        filterDistricts() {
            if (!this.form.district) {
                this.filteredDistrictList = this.districtList;
                return;
            }
            const searchText = this.form.district.toLowerCase();
            this.filteredDistrictList = this.districtList.filter(option =>
                option.label && option.label.toLowerCase().includes(searchText)
            );
        },

        selectDistrict(district) {
            this.form.district = district;
            this.dropdownVisible = false;
        },

        async fetchAreas() {
            try {
                const token = localStorage.getItem("userToken");
                const parent_id = this.form.country === 'singapore' ? 93 : 7; 

                // Fetch areas using the updated API structure
                const response = await this.$axios.post("/api/location", {
                    type: "Locality",
                    parent_id: parent_id,
                    api_token: token
                });

                if (response.data.status === 1 && response.data.output.length) {
                    // Map area data to label and value
                    this.areaList = response.data.output.map((d) => ({
                        label: d.name, // Assuming 'name' holds the area value
                        value: d.name
                    }));
                } else {
                    this.areaList = [];
                    console.warn("No areas found for this country.");
                }
            } catch (error) {
                console.error("Error fetching areas:", error);
            }
        },

        filterAreas() {
            if (!this.form.area_hdb_estate) {
                this.filteredAreaList = this.areaList;
                return;
            }
            const searchText = this.form.area_hdb_estate.toLowerCase();
            this.filteredAreaList = this.areaList.filter(option =>
                option.label && option.label.toLowerCase().includes(searchText)
            );
        },

        selectArea(area) {
            this.form.area_hdb_estate = area;
            this.dropdownVisible = false;
        },
        
        closeDropdown() {
            setTimeout(() => {
                this.dropdownVisible = false;
            }, 200);
        },

        async checkCurrentPackage() {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('/api/my-profile-detail', {
                    api_token: token,
                    user_id: this.user.id
                });

                let userData = response.data.output;
                
                // Check if package_details is not null or empty and set purchasedPackage
                this.purchasedPackage = userData?.package_details ? 1 : 0;
            } catch (error) {
                console.error('Failed to fetch profile:', error);
            }
        },
        
        savePageCompletion(page, percentage) {
            const completionData = JSON.parse(localStorage.getItem('formCompletionData')) || {};
            completionData[page] = percentage;
            localStorage.setItem('formCompletionData', JSON.stringify(completionData));
        },

        saveCompletion() {
            const percentage = this.completionPercentage;
            this.savePageCompletion('page1', percentage);
        },

        validateForm() {
            this.errors = {};

            if (!this.form.listingType) {
                this.errors.listingType = 'Listing Type is required.';
            }
            if (!this.form.property_name) {
                this.errors.property_name = 'Property Name is required.';
            }
            if (!this.form.street) {
                this.errors.street = 'Street is required.';
            }
            if (!this.form.area_hdb_estate) {
                this.errors.area_hdb_estate = 'Area / HDB Estate.';
            }
            if (!this.form.floor_area) {
                this.errors.floor_area = 'Floor Area is required.';
            }
            if (!this.form.level_no) {
                this.errors.level_no = 'Level No is required.';
            }
            if (!this.form.block_no) {
                this.errors.block_no = 'Block No is required.';
            }
            if (!this.form.land_title_type) {
                this.errors.land_title_type = 'Land Title Type is required.';
            }
            if (!this.form.no_of_rooms) {
                this.errors.no_of_rooms = 'Rooms is required.';
            }
            if (this.form.no_of_rooms === 'custom' && !this.customRoomInput) {
                this.errors.no_of_rooms = 'Rooms is required.';
            }
            if (!this.form.no_of_baths) {
                this.errors.no_of_baths = 'Baths is required.';
            }
            if (this.form.no_of_baths === 'custom' && !this.customBathInput) {
                this.errors.no_of_baths = 'Baths is required.';
            }
            if (!this.form.floor_level_id) {
                this.errors.floor_level_id = 'Floor Level is required.';
            }
            if (!this.form.unit_no) {
                this.errors.unit_no = 'Unit No is required.';
            }
            if (!this.form.selectedPropertyType) {
                this.errors.selectedPropertyType = 'Property type is required.';
            }
            if (!this.form.land_tenure) {
                this.errors.selectedLandTenures = 'Land Tenure is required.';
            }
            return Object.keys(this.errors).length === 0;
        },    
            
        async submitPost() {
            
            if (this.validateForm()) {

                // Set country code based on the selected country
                if (this.form.country === 'singapore') {
                    this.form.countryCode = '93'; 
                } else if (this.form.country === 'malaysia') {
                    this.form.countryCode = '7';
                }

                if (this.customRoomInput) {
                    this.form.no_of_rooms = this.customRoomInput;
                }

                if (this.customBathInput) {
                    this.form.no_of_baths = this.customBathInput;
                }

                if (this.customCarParkLotsInput) {
                    this.form.no_car_park_lots = this.customCarParkLotsInput;
                }

                var qualify_for_foreigner = this.form.qualify_for_foreigner == true ? 1 : 0
                const token = localStorage.getItem('userToken');
                if (this.loading) return;
                this.loading = true;
                try {
                    var property_listing_id_edit = 0;
                    if(this.itemId > 0){
                        property_listing_id_edit = this.itemId;
                    }
                    const response = await this.$axios.post('api/add-update-property', {
                        user_id :this.user.id,
                        api_token: token,
                        property_listing_id:property_listing_id_edit,
                        country_id: this.form.countryCode ?? 0,
                        project_listing_id: this.form.project?.id ?? '',
                        listing_type: this.form.listingType,
                        qualify_for_foreigner:qualify_for_foreigner,
                        property_name:this.form.property_name,
                        street:this.form.street,
                        area_hdb_estate:this.form.area_hdb_estate,
                        district:this.form.district ?? '',
                        district_id:this.form.district_id ?? '',
                        postcode:this.form.postcode ?? '',
                        state:this.form.state ?? '',
                        block_no:this.form.block_no,
                        level_no:this.form.level_no,
                        unit_no:this.form.unit_no,
                        property_type:this.form.selectedPropertyType,
                        land_tenure:this.form.land_tenure,
                        floor_area:this.form.floor_area,
                        floor_area_unit:this.form.floor_area_unit,
                        land_area:this.form.land_area ? this.form.land_area : 0,
                        land_area_unit:this.form.land_area_unit,
                        land_title_type:this.form.land_title_type,
                        developer_name:this.form.developer_name ? this.form.developer_name : '',
                        total_blocks:this.form.total_blocks ? this.form.total_blocks : 0,
                        total_units:this.form.total_units ? this.form.total_units : 0,
                        total_car_parks:this.form.total_car_parks ? this.form.total_car_parks : 0,
                        year_of_competion_TOP:this.form.year_of_competion_TOP ? this.form.year_of_competion_TOP : 0,
                        no_of_rooms:this.form.no_of_rooms,
                        no_of_baths:this.form.no_of_baths,
                        no_car_park_lots:this.form.no_car_park_lots,
                        layout_type_id:this.form.layout_type_id,
                        floor_level_id:this.form.floor_level_id,
                        main_view_id:this.form.main_view_id
                    });
                    
                    toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                    const property_listing_id = response.data.output.id
                    this.$router.push('/post-property-listing-2/' + property_listing_id);

                } catch (error) {
                    
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },

        async fetchPropertyDetails(propertyName) {
            try {
                const token = localStorage.getItem('userToken');
                
                // Make an API request using axios
                const response = await this.$axios.post('/api/property-details', {
                    user_id :this.user.id,
                    api_token: token,
                    property_name: propertyName,
                }, {
                });

                // Handle the response
                if (response.data.success) {
                    this.form.street = response.data.data.street || '';
                    this.form.area_hdb_estate = response.data.data.area_hdb_estate || '';
                    this.form.district_id = response.data.data.district_id || '';
                    this.form.district = response.data.data.district || '';
                }
            } catch (error) {
                console.error(error.response ? error.response.data.message : error.message);
            }
        },

        // async fetchPostcodeDetails(postcode) {
        //     try {
        //         const token = localStorage.getItem('userToken');
                
        //         // Make an API request using axios
        //         const response = await this.$axios.post('/api/property-postcode-details', {
        //             user_id :this.user.id,
        //             api_token: token,
        //             postcode: postcode,
        //         }, {
        //         });

        //         // Handle the response
        //         if (response.data.success) {
        //             this.form.district_id = response.data.data.district_id || '';
        //             this.form.district = response.data.data.district || '';
        //             this.form.state = response.data.data.state || '';
        //         }
        //     } catch (error) {
        //         // Handle errors
        //         console.error(error.response ? error.response.data.message : error.message);
        //     }
        // },

        // async fetchSuggestions() {
        //     if (this.form.street.length < 2) {
        //         this.suggestions = []; // Clear suggestions if query is too short
        //         return;
        //     }
        //     try {
        //         const parent_id = this.form.country === 'singapore' ? 93 : 7; 
        //         const response = await this.$axios.post('api/location',
        //         {                    
        //             type : "Locality",
        //             keyword : this.street, 
        //             parent_id: parent_id
        //         });
        //         this.suggestions = response.data.output.map(item => item.name).filter(name => name.toLowerCase().includes(this.form.street.toLowerCase()));

        //     } catch (error) {
        //         console.error('Error fetching suggestions:', error);
        //     }
        // },
        
        async fetchSuggestionsArea() {
            if (this.form.area_hdb_estate.length < 2) {
                this.suggestionsArea = []; // Clear suggestions if query is too short
                return;
            }

            try {
                const parent_id = this.form.country === 'singapore' ? 93 : 7; 
                const response = await this.$axios.post('api/location', {
                    type: "Locality",
                    keyword: this.form.area_hdb_estate,
                    parent_id: parent_id
                });

                this.suggestionsArea = response.data.output
                    .map(item => item.name)
                    .filter(name => name.toLowerCase().includes(this.form.area_hdb_estate.toLowerCase()));

            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        },

        async fetchSuggestionsDistrict() {
            if (this.form.district.length < 2) {
                this.suggestionsDistrict = []; // Clear suggestions if query is too short
                return;
            }
            try {
                const parent_id = this.form.country === 'singapore' ? 93 : 7; 
                const response = await this.$axios.post('api/location',
                {                    
                    type : "City",
                    keyword : this.district, 
                    parent_id: parent_id
                });
                // this.suggestionsDistrict = response.data.output.map(item => item.name).filter(name => name.toLowerCase().includes(this.form.district.toLowerCase()));
                this.suggestionsDistrict = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));

            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        },

        selectSuggestion(suggestion) {
            this.form.street = suggestion;
            this.suggestions = [];
        },

        selectSuggestionArea(suggestion) {
            this.form.area_hdb_estate = suggestion;
            this.suggestionsArea = [];
        },

        selectSuggestionDistrict(suggestion) {
            
            this.form.district = suggestion.label;
            this.form.district_id = suggestion.value;
            this.suggestionsDistrict = [];
        },

        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },  

        transformData(data) {
            const map = {};
            const result = [];
    
            data.forEach(item => {
            map[item.id] = { ...item, children: [] };
            });
    
            data.forEach(item => {
            if (item.parent_id === 0) {
                result.push(map[item.id]);
            } else {
                map[item.parent_id].children.push(map[item.id]);
            }
            });
    
            return result;
        },

        createHierarchicalOptions(data) {
            return data.map(option => this.createOptionGroup(option));
        },

        createHierarchicalOptionsMy(data) {
            return data.map(optionMy => this.createOptionGroup(optionMy));
        },

        createOptionGroup(option) {

            if (!option.children || option.children.length === 0) {
                return {
                    type: 'option',
                    id: option.id,
                    label: option.name,
                    depth: option.depth,
                };
            }
            return {
                type: 'optgroup',
                label: option.name,
                depth: option.depth,
                children: option.children.map(child => this.createOptionGroup(child))
            };

        },

        changeDistrict() {
            const selectedValue = this.form.district_id; // Get the value from v-model
            const selectedOption = this.optionsDistrictList.find(option => option.value === selectedValue);
            
            if (selectedOption) {
                const selectedLabel = selectedOption.label;
                this.form.district = selectedLabel; // Set the district name to the form field
            }
        },

        async fetchProjects() {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.get('api/projects', {
                    params: {
                        api_token: token,
                    }
                });
                this.projects = response.data.output;
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        },

        async fetchOptions() {

            const token = localStorage.getItem('userToken');

             // Get District list
             try {
                const parent_id = this.form.country === 'singapore' ? 93 : 7; 
                const response = await this.$axios.post('api/location',
                {                    
                    type : "City",
                    keyword : this.street, 
                    parent_id: parent_id                
                });
                this.optionsDistrictList = response.data.output.map(item => ({
                    value: item.id,
                    label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }

            // Get Land Tenure list
            try {
                const response = await this.$axios.post('api/pr-land-tenures-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_property:1,
                    last_record_number : 0                     
                });
                this.optionsLandTenuresList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Get Layout type list
            try {
                const response = await this.$axios.post('api/pr-layout-features-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_property:1,
                    last_record_number : 0                     
                });
                this.optionsLayoutTypeList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Get Floor level
            try {
                const response = await this.$axios.post('api/pr-floor-levels-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsFloorLevelList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Get {{ $t('main_view') }}
            try {
                const response = await this.$axios.post('api/pr-main-view-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_property:1,
                    last_record_number : 0                     
                });
                this.optionsMainList = response.data.output.map(item => ({
                    value: item.id,
                    label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }

            if(this.itemId > 0){                
                try {
                    const response = await this.$axios.post('api/property-detail',
                    {                    
                        user_id :this.user.id,
                        api_token: token,
                        property_listing_id:this.itemId,                    
                    });

                    this.editData = response.data.output[0];
                    this.form.project = this.editData.project_listing_id;
                    this.projectName = this.editData.project_name;
                    this.form.listingType = this.editData.listing_type;
                    this.form.qualify_for_foreigner = this.editData.qualify_for_foreigner == 1 ? true : false;
                    this.form.property_name = this.editData.property_name;
                    this.form.street = this.editData.street;
                    this.form.selectedPropertyType = this.editData.property_type;
                    this.form.area_hdb_estate = this.editData.area_hdb_estate;
                    this.form.country = this.editData.country_id == 93 ? "singapore" : "malaysia";
                    this.form.postcode = this.editData.postcode;
                    this.form.district = this.editData.district;
                    this.form.district_id = this.editData.district_id ?? '';
                    this.form.state = this.editData.state;
                    this.form.block_no = this.editData.block_no;
                    this.form.level_no = this.editData.level_no;
                    this.form.unit_no = this.editData.unit_no;
                    this.form.land_tenure = this.editData.land_tenure == null ? '' : this.editData.land_tenure ;
                    this.form.floor_area = this.editData.floor_area;
                    this.form.land_area = this.editData.land_area;
                    this.form.land_area_unit = this.editData.land_area_unit;
                    this.form.land_title_type = this.editData.land_title_type;
                    this.form.developer_name = this.editData.developer_name;
                    this.form.total_blocks = this.editData.total_blocks;
                    this.form.total_units = this.editData.total_units;
                    this.form.total_car_parks = this.editData.total_car_parks;
                    this.form.year_of_competion_TOP = this.editData.year_of_competion_TOP;
                    this.form.no_of_rooms = this.editData.no_of_rooms;

                    if (this.form.no_of_rooms > 9 ) {
                        this.customRoomInput = this.form.no_of_rooms;
                    }

                    this.form.no_of_baths = this.editData.no_of_baths;

                    if (this.form.no_of_baths > 9 ) {
                        this.customBathInput = this.form.no_of_baths;
                    }

                    this.form.no_car_park_lots = this.editData.no_car_park_lots;

                    if (this.form.no_car_park_lots > 9 ) {
                        this.customCarParkLotsInput = this.form.no_car_park_lots;
                    }

                    this.form.layout_type_id = this.editData.layout_type_id;
                    this.form.floor_level_id = this.editData.floor_level_id;
                    this.form.main_view_id = this.editData.main_view_id;
                } catch (error) {
                    console.error('Error fetching options:', error);
                }
            }
        }
    }
  }
  </script>
  
  <style scoped>
    .sugesstion_ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        border: 1px solid #ddd;
        position: absolute;
        background: #fff;
        z-index: 10;
    }
    .sugesstion_ul li {
        cursor: pointer;
        padding: 8px;
    }
    .sugesstion_ul li:hover {
        background-color: #f0f0f0;
    }
  </style>
  