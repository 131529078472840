<template>
    <section class="agent-pages-se">
        <div class="container">
            <div class="comman-title text-center">
                <h2>Find to Assist Developer</h2>
            </div>
            <div class="agent-search-box">
                <div class="row align-items-center">
                    <div class="col position-relative">
                        <input v-model="filters.keyword" type="text" class="form-control" @input="getAutocompleteSearch"
                                placeholder="Enter Specific Name, Description, Agency, Company, Location" />
                        <ul v-if="suggestions.length" class="autocomplete-list">
                            <li v-for="(suggestion, index) in suggestions" :key="index" @click="selectSuggestion(suggestion)">
                                {{ suggestion }}
                            </li>
                        </ul>
                    </div>
                    <div class="col-auto">
                        <button class="comman-btn-2" @click.prevent="fetchAllDevelopers">
                            <img src="frontend/images/search-ico.svg" alt=""><b> Search</b>
                        </button>
                    </div>
                </div>
            </div>

            <div class="filter-bar-se mt-5">
                <div class="comman-title">
                    <h3>List Of Service Providers</h3>
                </div>
                <div class="filter-right-bar">
                    <div class="dropdown">
                        <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                            <img src="frontend/images/Sort-By.svg"> {{ $t('sort_by') }}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                            <li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Name' }"
                                    @click.prevent="sortDevelopers('Name')">Name</a></li>
                            <li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Newest' }"
                                    @click.prevent="sortDevelopers('Newest')">{{ $t('newest') }}</a></li>
                            <li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Most Experience' }"
                                    @click.prevent="sortDevelopers('Most Experience')">Most
                                    Experienced</a></li>
                            <li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Least Experience' }"
                                   @click.prevent="sortDevelopers('Least Experience')">Least
                                Experienced</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <template v-if="!loading && developers.length > 0">
                <div class="row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-2">
                        <div class="col" v-for="developer in developers" :key="developer.id">
                        <div class="agent-bx">
                            <div class="agent-pic">
                                <a class="wishlist-icon-in-pic" @click="wishlistDeveloper(developer)" title="Wishlist Developer">
                                    <img v-if="developer?.is_in_wishlist" src="frontend/images/heart-filled-ico.svg" alt="Remove"/>
                                    <img v-else src="frontend/images/heart-ico.svg" alt="Remove"/>
                                </a>
                                <div v-if="developer?.user_service_details?.is_featured == 1" class="featured-banner">
                                    <span>FEATURED</span>
                                </div>
                                <div class="mark-logo">
                                    <img src="frontend/images/logo.png">
                                </div>
                                <picture>
                                    <router-link :to="{ name: 'DeveloperDetail', params: { id: developer.id } }">
                                        <img :src="getUserProfileImage(developer?.profile_photo_name)" />
                                    </router-link>
                                </picture>
                            </div>
                            <div class="agent-info">
                                <div class="row row-cols-1">
                                    <div class="col col-7">
                                        <h4 style="display: flex; gap: 10px;">{{ developer.name }}
                                            <img v-if="developer?.user_service_details" src="/frontend/images/verified_logo.png" alt="Verified" style="width: 16px; height: 16px;">
                                            <img v-else src="/frontend/images/unverified_logo.png" alt="Unverified" style="width: 16px; height: 16px;">
                                        </h4>
                                        <p>{{ $t('position') }}: {{ developer?.user_service_details?.position_held }}</p>
                                        <p>{{ formatPhoneNumber(developer.mobile_number) }}</p>
                                    </div>
                                    <div class="col col-5">
                                        <p>Join {{ yearsAgo(developer?.user_service_details?.personal_working_since) }}</p>
                                    </div>
                                </div>
                                <router-link :to="{ name: 'DeveloperDetail', params: { id: developer.id } }"
                                             class="comman-btn-2 w-100">
                                    <b> View Profile</b>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <LoaderCust :isLoading="loading"/>
                <div v-if="!loading" class="text-center">{{ $t('no_records_found') }}</div>
            </template>
            <div class="pagination-se" v-if="!loading && developers.length > 0">
                <nav aria-label="...">
                    <ul class="pagination justify-content-end">
                        <li class="page-item" :class="{ disabled: filters.page === 1 }"
                            @click="filters.page > 1 && changePage(filters.page - 1, false)" v-if="filters.page > 1">
                            <a class="page-link"><img src="frontend/images/left-arrow-icon.svg" alt="Previous"></a>
                        </li>
                        <li v-for="pageNumber in paginatedPages(filters.page, totalPages)" :key="pageNumber"
                            class="page-item" :class="{ active: pageNumber === filters.page }">
                            <a class="page-link" @click.prevent="pageNumber !== filters.page && changePage(pageNumber, false)">
                                {{ pageNumber }}
                            </a>
                        </li>
                        <li class="page-item" :class="{ disabled: filters.page === totalPages }"
                            @click="filters.page < totalPages && changePage(filters.page + 1, false)"
                            v-if="filters.page < totalPages">
                            <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import { CONSTANT } from "@/constants/constants";
import { formatPhoneNumber, getUserProfileImage, paginatedPages, yearsAgo } from "@/utils/helpers";
import toastr from 'toastr';
import LoaderCust from "@/components/LoaderCust.vue";

export default {
    components: {LoaderCust},
    data() {
        return {
            totalPages: 1,
            totalRecords: 1,
            services: [],
            days: [
                { key: 'availability_monday', label: 'Monday' },
                { key: 'availability_tuesday', label: 'Tuesday' },
                { key: 'availability_wednesday', label: 'Wednesday' },
                { key: 'availability_thursday', label: 'Thursday' },
                { key: 'availability_friday', label: 'Friday' },
                { key: 'availability_saturday', label: 'Saturday' },
                { key: 'availability_sunday', label: 'Sunday' }
            ],
            filters: {
                page: 1,
                sort_by_field: 'Newest',
                keyword: '',
            },
            developers: [],
            suggestions: [],
            loading: false,
        };
    },
    computed: {
        CONSTANT() {
            return CONSTANT
        },
        ...mapState(['user']),
    },
    async created() {
        await this.fetchDevelopers();
        //await this.fetchLocations();
        await this.fetchServices();
    },
    methods: {
        paginatedPages,
        getUserProfileImage,
        yearsAgo,
        formatPhoneNumber,
        handleCheckboxChange(key, event) {
            this.filters[key] = event.target.checked ? 1 : 0;
        },
        //async fetchLocations() {
        //  try {
        //    const response = await this.$axios.post('/api/location', {
        //      parent_id: CONSTANT.COUNTRY_CODE,
        //      type: 'City',
        //      keyword: '',
        //    });
        //    this.locations = response.data.output;
        //  } catch (error) {
        //    console.error('Error fetching locations:', error);
        //  }
        //},
        async fetchServices() {
            try {
                const response = await this.$axios.post('/api/services-type-list', {
                    user_id: this.user.id,
                    api_token: localStorage.getItem('userToken'),
                });
                this.services = response.data.output;
            } catch (error) {
                console.error('Error fetching locations:', error);
            }
        },
        async wishlistDeveloper(developer) {
            try {
                const wishlistResponse = await this.$axios.post(`api/wishlist/developers/${developer.id}`, {
                    api_token: localStorage.getItem('userToken'),
                    user_id: this.user?.id,
                });

                if (wishlistResponse.status === 200) {
                    toastr.success(wishlistResponse.data.message, 'Success', {
                        progressBar: true,
                        closeButton: true,
                        timeOut: 1500,
                        onHidden: () => {
                            this.fetchDevelopers();
                        }
                    });
                }
            } catch (error) {
                console.error('Error wishlisting developer:', error);
            }
        },
        async fetchDevelopers() {
            this.loading = true;
            try {
                const developersResponse = await this.$axios.post('/api/developer-search-browse', {
                    api_token: localStorage.getItem('userToken'),
                    user_id: this.user.id,
                    keyword: this.filters.keyword,
                    page_number: this.filters.page,
                });

                this.totalPages = developersResponse.data.output.no_of_total_pages ?? 0;
                this.totalRecords = developersResponse.data.output.no_of_total_records ?? 0;
                this.developers = developersResponse.data.output.developer_info ?? [];
                this.isFeatured = developersResponse.data.output.is_featured;

            } catch (error) {
                console.error('Error fetching developers:', error);
            } finally {
                this.loading = false;
            }
        },
        sortDevelopers(sortBy) {
            this.filters.sort_by_field = sortBy;
            this.filters.page = 1;

            this.fetchDevelopers();
        },
        changePage(page) {
            if (page > 0) {
                const totalPages = this.totalPages;
                if (page <= totalPages) {
                    this.filters.page = page;

                    this.fetchDevelopers();
                }
            }
        },
        async getAutocompleteSearch() {
            if (this.filters.keyword.length <= 3) {
                this.suggestions = [];
                return;
            }

            const token = localStorage.getItem('userToken');
            try {
                let names = [];

                const response = await this.$axios.get(`/api/agents/names`,
                {
                    params: {
                        api_token: token,
                    }
                });

                if (response.data.output) {
                    names = response.data.output;
                    this.suggestions = names.filter((item) =>
                        item.toLowerCase().includes(this.filters.keyword.toLowerCase())
                    );
                }
            } catch (error) {
                console.error('Error fetching autocomplete:', error);
            }
        },
        selectSuggestion(suggestion) {
            this.filters.keyword = suggestion;
            this.suggestions = [];
        },
    }
};
</script>

<style scoped>
    .dropdown-item:hover,
    .dropdown-item.selected {
        background-color: #ffe6b9;
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        margin-top: 20px;
    }

    /* Autocomplete dropdown styles */
    .autocomplete-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    margin: 0 0.75rem 0 0.75rem;
    list-style: none;
    }

    .autocomplete-list li {
    padding: 8px 12px;
    cursor: pointer;
    }

    .autocomplete-list li:hover {
    background-color: #f0f0f0;
    }
</style>