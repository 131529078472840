<template>
    <div class="page-wrap">
        <section class="mrp-search-box-se">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation"><a class="nav-link active" href="javascript:;">{{ $t('property') }}</a></li>
                            <li class="nav-item" role="presentation"><a class="nav-link" href="/explore-more-project">{{ $t('project') }}</a></li>
                        </ul>
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" :class="{ active: activeTab === 'Buy' }" id="Buy-tab"
                                    data-bs-toggle="tab" data-bs-target="#Buy" type="button" role="tab"
                                    aria-controls="Buy" aria-selected="true" @click="setActiveTab('Buy')">Buy</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" :class="{ active: activeTab === 'Rent' }" id="Rent-tab"
                                    data-bs-toggle="tab" data-bs-target="#Rent" type="button" role="tab"
                                    aria-controls="Rent" aria-selected="false"
                                    @click="setActiveTab('Rent')">Rent</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="Buy" role="tabpanel" aria-labelledby="Buy-tab">
                                <ExploreMoreSearch @submit="submitSearch" type="Buy" :queryData="formData" @update-options="updateOptions">
                                </ExploreMoreSearch>
                            </div>
                            <div class="tab-pane fade" id="Rent" role="tabpanel" aria-labelledby="Rent-tab">
                                <ExploreMoreSearch @submit="submitSearch" type="Rent" :queryData="formData" @update-options="updateOptions">
                                </ExploreMoreSearch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="mrp-search-se">
            <div class="container">
                <div class="filter-bar-se mb-0">
                    <div class="comman-title mb-0">
                        <h3 class="mb-2">Most Recent Posting</h3>
                        <span>{{ $t('showing') }} {{ totalRecords }} {{ $t('properties') }}</span>
                    </div>
                    <div class="filter-right-bar">
                        <div class="dropdown">
                            <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown"
                                aria-expanded="true">
                                <img src="frontend/images/Sort-By.svg"> {{ $t('sort_by') }}
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                                <li><a class="dropdown-item" href="#"
                                        :class="{ 'selected': filters.sort_by_field === 'Newest' }"
                                        @click.prevent="sortProperties('Newest')">{{ $t('newest') }}</a></li>
                                <li><a class="dropdown-item" href="#"
                                        :class="{ 'selected': filters.sort_by_field === 'Lowest Price' }"
                                        @click.prevent="sortProperties('Lowest Price')">{{ $t('lowest_price') }}</a></li>
                                <li><a class="dropdown-item" href="#"
                                        :class="{ 'selected': filters.sort_by_field === 'Highest Price' }"
                                        @click.prevent="sortProperties('Highest Price')">{{ $t('highest_price') }}</a></li>
                                <li><a class="dropdown-item" href="#"
                                        :class="{ 'selected': filters.sort_by_field === 'Lowest Floor Area' }"
                                        @click.prevent="sortProperties('Lowest Floor Area')">Lowest Floor Area</a></li>
                                <li><a class="dropdown-item" href="#"
                                        :class="{ 'selected': filters.sort_by_field === 'Highest Floor Area' }"
                                        @click.prevent="sortProperties('Highest Floor Area')">Highest Floor Area</a>
                                </li>
                                <li><a class="dropdown-item" href="#"
                                       :class="{ 'selected': filters.sort_by_field === 'Rooms (Low - High)' }"
                                       @click.prevent="sortProperties('Rooms (Low - High)')">Rooms (Low - High)</a>
                                </li>
                                <li><a class="dropdown-item" href="#"
                                       :class="{ 'selected': filters.sort_by_field === 'Rooms (High - Low)' }"
                                       @click.prevent="sortProperties('Rooms (High - Low)')">Rooms (High - Low)</a>
                                </li>
                                <li><a class="dropdown-item" href="#"
                                       :class="{ 'selected': filters.sort_by_field === 'Price/SQF (Low - High)' }"
                                       @click.prevent="sortProperties('Price/SQF (Low - High)')">Price/SQF (Low - High)</a>
                                </li>
                                <li><a class="dropdown-item" href="#"
                                       :class="{ 'selected': filters.sort_by_field === 'Price/SQF (High - Low)' }"
                                       @click.prevent="sortProperties('Price/SQF (High - Low)')">Price/SQF (High - Low)</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="property-listing">
                    <template v-if="!loading && properties.length > 0">
                        <div class="property-listing-box" v-for="property in properties" :key="property.id">
                            <div class="property-listing-img">
                                <div class="property-carousel explore-owl owl-carousel owl-theme">
                                    <picture style="position: relative;">
                                        <a class="wishlist-icon-in-pic" @click="wishlistProperty(property)" title="Wishlist Property">
                                            <img v-if="property.is_in_wishlist" src="frontend/images/heart-filled-ico.svg" alt="Remove"/>
                                            <img v-else src="frontend/images/heart-ico.svg" alt="Remove"/>
                                        </a>
                                        <img :src="property.actual_unit_photos.length 
                                                ? getPropertyImage(property.actual_unit_photos[0].photo_name) 
                                                :getPropertyImage(null) " 
                                        :alt="property.property_name" />
                                    </picture>
                                </div>
                                <div v-if="property.is_featured === 1" class="featured-banner">
                                    <span>FEATURED</span>
                                </div>
                                <div class="mark-logo">
                                    <img src="frontend/images/logo.png" alt="logo">
                                </div>
                            </div>
                            <div class="property-listing-contant">
                                <div class="project-info">
                                    <h3><a @click="redirectToPropertyDetails(property)">{{ property.property_name }}</a></h3>
                                    <p class="loct-info">
                                        <img src="frontend/images/map-gol-ico.svg">
                                        {{ property.street }}, {{ property.district }}
                                    </p>
                                    <div class="price-pro ms-md-auto">
                                        <span> {{ formatPrice(property.asking_price_sale_rent) }} <small>($
                                                {{computedSQF(property)}}/{{ property.land_area_unit }})</small></span>
                                    </div>
                                    <div class="pro-specify">
                                        <span><i><img src="frontend/images/spec-ico1.svg"></i>{{ property.no_of_rooms
                                            }}</span>
                                        <span><i><img src="frontend/images/spec-ico2.svg"></i>{{ property.no_of_baths
                                            }}</span>
                                        <span><i><img src="frontend/images/spec-ico3.svg"></i>{{
                                            property.no_car_park_lots }}</span>
                                        <span><i><img src="frontend/images/spec-ico4.svg"></i>{{ formatZeros(property.floor_area) }}
                                            {{ property.land_area_unit }}</span>
                                    </div>
                                    <div class="pro-tag">
                                        <a v-if="getListingTypeTag(property.listing_type)">{{ getListingTypeTag(property.listing_type)}}</a>
                                        <a v-if="property.listing_type?.text">{{ property.listing_type.text }}</a>
                                        <a v-if="property.property_type_details?.parent_id_top_level_1_code">{{ property.property_type_details.parent_id_top_level_1_code }}</a>
                                        <a v-if="property.property_type_details?.code">{{ property.property_type_details.code }}</a>
                                        <a v-if="property.land_tenure?.name">{{ property.land_tenure.name }}</a>
                                    </div>
                                    <ul>
                                        <li v-if="property.is_in_wishlist">
                                            <figure>
                                                <img src="frontend/images/heart-ico.svg">
                                            </figure>
                                            <small>favorite</small>
                                        </li>
                                        <li v-if="property.is_direct_owner">
                                            <figure>
                                                <img src="frontend/images/specify-ico1.png">
                                            </figure>
                                            <small>{{ $t('direct') }}<br>{{ $t('owner') }}</small>
                                        </li>
                                        <li v-if="property.is_sing_pass || property.is_corp_pass ">
                                            <figure>
                                                <img src="frontend/images/specify-ico2.png">
                                            </figure>
                                            <small>{{ $t('singpass') }}<br>{{ $t('verified') }} </small>
                                        </li>
                                        <li v-if="property.is_document_approved">
                                            <figure>
                                                <img src="frontend/images/specify-ico3.png">
                                            </figure>
                                            <small>{{ $t('documents') }}<br>{{ $t('verified') }}</small>
                                        </li>
                                        <!--<li v-if="property.is_FSM_linked">
                                            <figure>
                                                <img src="frontend/images/specify-ico4.png">
                                            </figure>
                                            <small>Fengshui<br>Map</small>
                                        </li>
                                        <li v-if="property.is_assigned_to_the_agent">
                                            <figure>
                                                <img src="frontend/images/specify-ico5.png">
                                            </figure>
                                            <small>{{ $t('hybrid_listing') }}<br>Owners </small>
                                        </li>-->
                                        <li v-if="property.is_below_mean_value">
                                            <figure>
                                                <img src="frontend/images/Down-arrow.svg">
                                            </figure>
                                            <small>BMV</small>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="property-listing-profile">
                                <div class="property-l-profile-box">
                                    <div class="profile-box">
                                        <img :src="getUserProfileImage(property.user_id_details?.profile_photo_name)" />
                                        <div>
                                            <span>Posted By</span>
                                            <h5>{{ property.user_id_details?.name }} <img
                                                    src="frontend/images/varify-user.svg" alt=""></h5>
                                        </div>
                                    </div>
                                    <hr v-if="property.user_id_details?.user_service_details">
                                    <div class="agency-reg-no">
                                        <p v-if="property.user_id_details?.user_service_details" >{{ $t('agency_name') }}: <br>
                                            {{property.user_id_details?.user_service_details?.agency_name || 'N/A' }}</p>
                                        <p v-if="property.user_id_details?.user_service_details" >Agency Reg No: {{property.user_id_details?.user_service_details?.agency_registration_no || 'N/A' }}</p>
                                    </div>
                                    <hr>
                                    <p>Contact Number: {{ formatPhoneNumber(property.user_id_details?.mobile_number) }}</p>
                                    <p>{{ $t('email') }}: {{ property.user_id_details?.contact_email ?? 'N/A' }}</p>
                                </div>
                                <template v-if="property.user_id_details && (user.id != property.user_id) && (user.id != property.user_id_details.id)">

                                </template>
                                <div class="property-l-profile-box">
                                    <div v-if="!property?.user?.is_private_user">
                                        <div class="text-center">Partner with me to market your property</div>
                                        <router-link
                                            :to="'/widget-property/create/' + property.user.id + '/Business'"
                                            class="my-2 comman-btn-2 w-100 d-block text-decoration-none"
                                        >
                                            <div style="font-size: 24px;"><b>Create Hybrid Listing</b></div>
                                            <span>Own Your Story, Amplify Its Reach!</span>
                                        </router-link>
                                    </div>
                                    <SendMessageExploreMore type="property" :recipient="property.user_id_details" :property="property"/>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <LoaderCust :isLoading="loading"/>
                        <div v-if="!loading" class="text-center">{{ $t('no_records_found') }}</div>
                    </template>
                    <div class="pagination-se" v-if="!loading && properties.length > 0">
                        <nav aria-label="...">
                            <ul class="pagination justify-content-center">
                                <li class="page-item" :class="{ disabled: filters.page === 1 }"
                                    @click="filters.page > 1 && changePage(filters.page - 1)" v-if="filters.page > 1">
                                    <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                            alt="Previous"></a>
                                </li>
                                <li v-for="pageNumber in paginatedPages(filters.page, totalPages)" :key="pageNumber"
                                    class="page-item" :class="{ active: pageNumber === filters.page }">
                                    <a class="page-link"
                                        @click.prevent="pageNumber !== filters.page && changePage(pageNumber)">
                                        {{ pageNumber }}
                                    </a>
                                </li>
                                <li class="page-item" :class="{ disabled: filters.page === totalPages }"
                                    @click="filters.page < totalPages && changePage(filters.page + 1)"
                                    v-if="filters.page < totalPages">
                                    <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        <section class="offer-pro-sec" v-if="!loading && recentlyProperties.length > 0">
            <div class="container">
                <div class="comman-title d-md-flex align-items-center justify-content-between">
                    <div class="offer-heading">
                        <span>{{ $t("browse_more_offers") }}</span>
                        <h3>recently browsed properties</h3>
                    </div>
                </div>
                <LoaderCust :isLoading="loading"/>
                <div class="property-carousel owl-carousel owl-theme">
                    <div v-for="property in recentlyProperties" :key="property.id" class="item">
                        <div class="project-bx">
                            <a :href="''">
                                <picture>
                                    <img src="frontend/images/project-img1.jpg">
                                </picture>
                            </a>
                            <div class="project-info">
                                <div class="d-md-flex mb-2 align-items-center">
                                    <h3><a @click="redirectToPropertyDetails(property)">{{ property.property_name }}</a></h3>
                                    <div class="price-pro ms-md-auto">
                                        <span> {{ formatPrice(property.asking_price_sale_rent) }}</span>
                                    </div>
                                </div>
                                <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{
                                    property.street }}, {{ property.district }}</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>{{ property.no_of_rooms
                                        }}</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>{{ property.no_of_baths
                                        }}</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>{{ property.no_car_park_lots
                                        }}</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>{{ formatZeros(property.floor_area) }} {{
                                        property.land_area_unit }}</span>
                                </div>
                                <div class="pro-tag">
                                    <a v-if="getListingTypeTag(property.listing_type)">{{ getListingTypeTag(property.listing_type)}}</a>
                                    <a v-if="property?.property_type_details?.parent_id_top_level_1_code">{{
                                        property.property_type_details.parent_id_top_level_1_code }}</a>
                                    <a v-if="property?.property_type_details?.code">{{ property.property_type_details.code
                                        }}</a>
                                    <a v-if="property?.land_title_type">{{ property.land_title_type }}</a>
                                </div>
                                <ul>
                                    <li v-if="property.is_in_wishlist">
                                        <figure>
                                            <img src="frontend/images/heart-ico.svg">
                                        </figure>
                                        <small>favorite</small>
                                    </li>
                                    <li v-if="property.is_direct_owner">
                                        <figure>
                                            <img src="frontend/images/specify-ico1.png">
                                        </figure>
                                        <small>{{ $t('direct') }}<br>{{ $t('owner') }}</small>
                                    </li>
                                    <li v-if="property.is_sing_pass || property.is_corp_pass ">
                                        <figure>
                                            <img src="frontend/images/specify-ico2.png">
                                        </figure>
                                        <small>{{ $t('singpass') }}<br>{{ $t('verified') }} </small>
                                    </li>
                                    <li v-if="property.is_document_approved">
                                        <figure>
                                            <img src="frontend/images/specify-ico3.png">
                                        </figure>
                                        <small>{{ $t('documents') }}<br>{{ $t('verified') }}</small>
                                    </li>
                                    <!--<li v-if="property.is_FSM_linked">
                                        <figure>
                                            <img src="frontend/images/specify-ico4.png">
                                        </figure>
                                        <small>Fengshui<br>Map</small>
                                    </li>
                                    <li v-if="property.is_assigned_to_the_agent">
                                        <figure>
                                            <img src="frontend/images/specify-ico5.png">
                                        </figure>
                                        <small>{{ $t('hybrid_listing') }}<br>Owners </small>
                                    </li>-->
                                    <li v-if="property.is_below_mean_value">
                                        <figure>
                                            <img src="frontend/images/Down-arrow.svg">
                                        </figure>
                                        <small>BMV</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import ExploreMoreSearch from './ExploreMoreSearch.vue';
    import LoaderCust from '@/components/LoaderCust.vue';
    import toastr from 'toastr';
    import { getListingTypeTag, formatPhoneNumber, formatPrice, formatZeros, getUserProfileImage, paginatedPages, getPropertyImage } from '@/utils/helpers';
    import { mapState } from 'vuex';
    import SendMessageExploreMore from "@/views/SendMessageExploreMore.vue";

    export default {
        name: 'ExploreMore',

        components: {SendMessageExploreMore, ExploreMoreSearch, LoaderCust },

        data() {
            return {
                activeTab: 'Buy',
                properties:[],
                totalPages: 0,
                totalRecords: 0,
                loading: false,
                filters: {
                    page: 1,
                    sort_by_field: 'Newest',
                },
                formData: {},
                options: {},
                isForceUpdatingQuery: false
            }
        },

        created() {
            if (this.$route.query.formData) {
                // Replace the route with the same path but without query parameters (during F5 remove params)
                this.$router.replace({ path: this.$route.path });
            }
        },

        mounted() {
            window.$(document).ready(function () {
                window.$('.property-image-carousel').owlCarousel({
                    loop: false,
                    margin: 20,
                    nav: true,
                    dots: false,
                    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                    responsive: {
                        0: {
                            items: 1,
                            margin: 10
                        },
                        600: {
                            items: 2
                        },
                        1000: {
                            items: 3
                        }
                    }
                })
                window.$('.testimonail-carousel').owlCarousel({
                    loop: false,
                    margin: 25,
                    nav: false,
                    dots: true,
                    responsive: {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 2
                        },
                        1000: {
                            items: 3
                        }
                    }
                })
            });

            this.getFilters();
        },

        computed: {
            ...mapState(['user']),
            recentlyProperties() {
                return this.properties.slice(0, 3);
            }
        },

        methods: {
            getListingTypeTag,
            formatPhoneNumber,
            formatPrice,
            formatZeros,
            getUserProfileImage,
            getPropertyImage,
            paginatedPages,

            updateOptions(newOptions) {
                this.options = newOptions;
            },

            searchByPropertyType(parentCategoryName, childCategoryName) {
                let categoryIds = [];
                let params = {
                    selectedType: "",
                    selectedCategories: {},
                    property_type: null,
                    listingType: "",
                    gender: ""
                };
                params.selectedType = parentCategoryName;
                const categoryArray = this.options.propertyTypes[parentCategoryName];
                for (const category of categoryArray) {
                    // if no child, push parent category
                    if (!childCategoryName) {
                        categoryIds.push(category.id);
                    }
                    // Check within children
                    if (category.children && category.children.length > 0) {
                        if (childCategoryName) {
                            // push parent category and child category
                            const childMatch = category.children.find((child) => child.name === childCategoryName);
                            if (childMatch) {
                                categoryIds.push(category.id);
                                categoryIds.push(childMatch.id);
                            }
                        } else {
                            category.children.forEach(childCategory => {
                                categoryIds.push(childCategory.id);
                            });
                        }
                    }
                }
                if (!params.selectedCategories) {
                    params.selectedCategories = [];
                }
                params.selectedCategories[parentCategoryName] = categoryIds;
                params.property_type = [...new Set(categoryIds)].join(',');

                this.formData = params;
                this.fetchProperties(params);
            },

            searchByLandTitleType(landTitleName) {
                let params = {
                    land_title_type: landTitleName,
                };

                this.fetchProperties(params);
            },

            async wishlistProperty(property) {
                try {
                    if (!this.user?.id) {
                        toastr.error('Please log in to wishlist', 'error', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                        });
                        return;
                    }
                    const wishlistResponse = await this.$axios.post(`api/users/${this.user.id}/wishlist/properties/${property.id}`, {
                        api_token: localStorage.getItem('userToken'),
                    });

                    if (wishlistResponse.status === 200) {
                        toastr.success(wishlistResponse.data.message, 'Success', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                            onHidden: () => {
                                this.fetchProperties();
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error wishlisting property:', error);
                }
            },

            getFilters() {
                if (this.loading) {
                    return;
                }
                const formDataString = this.$route.query.formData;
                const parsedFormData = formDataString ? JSON.parse(formDataString) : {};
                this.formData = {
                    dateOfBirth: parsedFormData.dateOfBirth || '',
                    gender: parsedFormData.gender || '',
                    is_FSM_linked: parsedFormData?.is_FSM_linked || null,
                    is_approved_by_admin: parsedFormData?.is_approved_by_admin || true,
                    is_curated_property: parsedFormData?.is_curated_property || null,
                    is_direct_owner: parsedFormData?.is_direct_owner || null,
                    is_widget_property: parsedFormData?.is_widget_property || null,
                    keywords: parsedFormData.keywords || '',
                    listingType: parsedFormData.listingType || '',
                    selectedCategories: parsedFormData.selectedCategories || {},
                    selectedCountry: parsedFormData?.selectedCountry || null,
                    selectedFacingDirections: parsedFormData?.selectedFacingDirections || [],
                    selectedFurnishingLevels: parsedFormData?.selectedFurnishingLevels || [],
                    selectedLandTenures: parsedFormData?.selectedLandTenures || [],
                    selectedType: parsedFormData.selectedType || null,
                    priceRange: parsedFormData?.priceRange ?? [],
                    floorAreaRange: parsedFormData?.floorAreaRange ?? [],
                    roomCountRange: parsedFormData?.roomCountRange ?? [],
                };

                if (Object.prototype.hasOwnProperty.call(parsedFormData, 'sort_by_field')) {
                    this.filters.sort_by_field = parsedFormData.sort_by_field;
                }

                this.activeTab = parsedFormData?.activeTab || 'Buy';

                if (Object.keys(parsedFormData).length === 1 && 'activeTab' in parsedFormData) {
                    const newQuery = { ...this.$route.query };
                    delete newQuery.formData;

                    this.isForceUpdatingQuery = true;
                    this.$router.replace({ name: 'ExploreMore', query: newQuery });
                }

                this.fetchProperties();
            },
            setActiveTab(tab) {
                if (tab !== this.activeTab) {
                    this.formData.listingType = '';
                }
                this.activeTab = tab;
                this.fetchProperties();
            },

            computedSQF(property) {
                return property.asking_price_sale_rent > 0 ? Math.ceil(property.asking_price_sale_rent / property.floor_area) : 0;
            },

            async fetchProperties(params = null) {
                this.loading = true;
                try {
                    let parameters = {
                        is_for_sale: this.activeTab == "Buy" ? 1 : 0,
                        is_for_rent: this.activeTab == "Rent" ? 1 : 0,
                        sort_by_field: this.filters.sort_by_field,
                        page_number: this.filters.page,
                        user_id: this.user?.id,
                        listing_type: this.formData?.listingType,
                        selected_country: this.formData?.selectedCountry,
                        gender: this.formData?.gender,
                        date_of_birth: this.formData?.dateOfBirth,
                    };
                    if (params) {
                        parameters = { ...parameters, ...params };
                    } else {
                        let otherParams = {
                            property_type: this.formData?.selectedType
                                ? this.formData?.selectedCategories[this.formData.selectedType]?.join(',')
                                : '',
                            keywords: this.formData?.keywords,
                            is_FSM_linked: this.formData?.is_FSM_linked,
                            is_direct_owner: this.formData?.is_direct_owner,
                            is_approved_by_admin: this.formData?.is_approved_by_admin || true,
                            is_widget_property: this.formData?.is_widget_property,
                            is_curated_property: this.formData?.is_curated_property,
                            land_title_type: this.formData?.land_title_type,
                            selected_country: this.formData?.selectedCountry,
                            selected_furnishing_levels: this.formData?.selectedFurnishingLevels.join(','),
                            selected_land_tenures: this.formData?.selectedLandTenures.join(','),
                            selected_facing_directions: this.formData?.selectedFacingDirections.join(','),
                            price_range: this.formData?.priceRange ?? [],
                            floor_area_range: this.formData?.floorAreaRange ?? [],
                            room_count_range: this.formData?.roomCountRange ?? [],
                        };
                        parameters = { ...parameters, ...otherParams };
                    }

                    const propertiesResponse = await this.$axios.post('api/property-search-browse', parameters)

                    this.properties = propertiesResponse.data.output.property_list ?? [];
                    this.totalPages = propertiesResponse.data.output.no_of_total_pages ?? 0;
                    this.totalRecords = propertiesResponse.data.output.no_of_total_records ?? 0;

                    this.$nextTick(() => {
                        this.initializeOwlCarousel();
                    });
                } catch (error) {
                    console.error('Error fetching properties:', error);
                } finally {
                    this.loading = false;
                }
            },
            sortProperties(sortBy) {
                this.filters.sort_by_field = sortBy;
                this.filters.page = 1;

                this.fetchProperties();
            },

            changePage(page) {
                if (page > 0) {
                    const totalPages = this.totalPages;
                    if (page <= totalPages) {
                        this.filters.page = page;

                        this.fetchProperties();
                    }
                }
            },

            submitSearch(data) {
                this.formData = data;
                this.filters.page = 1;
                this.fetchProperties();
            },

            initializeOwlCarousel() {
                window.$('.property-carousel').owlCarousel({
                    loop: true,
                    margin: 0,
                    nav: true,
                    dots: true,
                    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                    responsive: {
                        0: {
                            items: 1,
                            margin: 0
                        },
                        600: {
                            items: 1
                        },
                        1000: {
                            items: 1
                        }
                    }
                });
            },
            redirectToPropertyDetails(property) {
                // Construct the new URL with the user_id as a query parameter
                const url = `${window.FRONTEND_URL}/property-detail/${property.id}?user_id=${property.user_id_details.id}`;

                // Redirect to the new URL
                window.location.href = url;
            }
        },
        watch: {
            '$route.query'() {
                if (this.isForceUpdatingQuery) {
                    this.isForceUpdatingQuery = false;
                    return;
                }
                this.getFilters();
            }
        }
    }
</script>

<style scoped>
    .dropdown-item:hover,
    .dropdown-item.selected {
        background-color: #ffe6b9;
    }


</style>