<template>
    <div class="page-wrap">
        <section class="mrp-search-box-se">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation"><a class="nav-link" href="/explore-more">{{ $t('property') }}</a></li>
                            <li class="nav-item" role="presentation"><a  class="nav-link active" href="javascript:;">{{ $t('project') }}</a></li>
                        </ul>
                        <div class="tab-content " id="Buy" role="tabpanel" aria-labelledby="search-tab">
                            <ExploreMoreSearch @submit="submitSearch" type="Buy" :queryData="formData" @update-options="updateOptions" :isProjectSearch="true">
                            </ExploreMoreSearch>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="mrp-search-se">
            <div class="container">
                <div class="filter-bar-se mb-0">
                    <div class="comman-title mb-0">
                        <h3 class="mb-2">Most Recent Project Posting</h3>
                        <span>{{ $t('showing') }} {{ totalRecords }} Projects</span>
                    </div>
                    <div class="filter-right-bar">
                        <div class="dropdown">
                            <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown"
                               aria-expanded="true">
                                <img src="frontend/images/Sort-By.svg"> {{ $t('sort_by') }}
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                                <li><a class="dropdown-item" href="#"
                                       :class="{ 'selected': filters.sort_by_field === 'Newest' }"
                                       @click.prevent="sortProjects('Newest')">{{ $t('newest') }}</a></li>
                                <li><a class="dropdown-item" href="#"
                                       :class="{ 'selected': filters.sort_by_field === 'Lowest Price' }"
                                       @click.prevent="sortProjects('Lowest Price')">{{ $t('lowest_price') }}</a></li>
                                <li><a class="dropdown-item" href="#"
                                       :class="{ 'selected': filters.sort_by_field === 'Highest Price' }"
                                       @click.prevent="sortProjects('Highest Price')">{{ $t('highest_price') }}</a></li>
                                <li><a class="dropdown-item" href="#"
                                       :class="{ 'selected': filters.sort_by_field === 'Lowest Floor Area' }"
                                       @click.prevent="sortProjects('Lowest Floor Area')">Lowest Floor Area</a></li>
                                <li><a class="dropdown-item" href="#"
                                       :class="{ 'selected': filters.sort_by_field === 'Highest Floor Area' }"
                                       @click.prevent="sortProjects('Highest Floor Area')">Highest Floor Area</a>
                                </li>
                                <li><a class="dropdown-item" href="#"
                                    :class="{ 'selected': filters.sort_by_field === 'Featured Date' }"
                                    @click.prevent="sortProjects('Featured Date')">Featured Date</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="property-listing">
                    <template v-if="!loading && projects.length > 0">
                        <div class="property-listing-box" v-for="project in projects" :key="project.id">
                            <div class="property-listing-img">
                                <div class="property-carousel explore-owl owl-carousel owl-theme">
                                    <picture style="position: relative;">
<!--                                        <a class="wishlist-icon-in-pic" @click="wishlistProperty(property)" title="Wishlist Property">-->
<!--                                            <img v-if="property.is_in_wishlist" src="frontend/images/heart-filled-ico.svg" alt="Remove"/>-->
<!--                                            <img v-else src="frontend/images/heart-ico.svg" alt="Remove"/>-->
<!--                                        </a>-->
                                        <img v-for="(image, index) in project.actual_unit_photos" :key="index"
                                             :src="getProjectImage(image.photo_name)" :alt="project.project_name" />
                                    </picture>
                                </div>
                                <div v-if="project.is_featured === 1" class="featured-banner">
                                    <span>FEATURED</span>
                                </div>
                                <div class="mark-logo">
                                    <img src="frontend/images/logo.png" alt="logo">
                                </div>
                            </div>
                            <div class="property-listing-contant">
                                <div class="project-info">
                                    <h3><a @click="redirectToProjectDetails(project)">{{ project.project_name }}</a></h3>
                                    <p class="loct-info">
                                        <img src="frontend/images/map-gol-ico.svg">
                                        {{ project.street }}, {{ project.district }}
                                    </p>
                                    <div class="price-pro row mb-0"><span>Starting From:</span></div>
                                    <div class="price-pro row mb-0"><span> S${{ project.listing_price_from }} to S${{project.listing_price_to}}</span></div>
                                    <div class="price-pro row"><small>(From ${{computedSQF(project).start}} to ${{computedSQF(project).to}} /SQF)</small></div>
                                    <div class="pro-specify">
                                        <span><i><img src="frontend/images/spec-ico1.svg"></i>{{ $t('from') }} {{project.no_of_bedrooms_from}} {{ $t('to') }} {{project.no_of_bedrooms_to}}</span>
                                        <span><i><img src="frontend/images/spec-ico2.svg"></i>{{ $t('from') }} {{project.no_of_baths_from}} {{ $t('to') }} {{project.no_of_baths_to}}</span>
                                        <span><i><img src="frontend/images/spec-ico3.svg"></i>{{ $t('from') }} {{project.no_car_park_from}} {{ $t('to') }} {{project.no_car_park_to}}</span>
                                        <span><i><img src="frontend/images/spec-ico4.svg"></i>{{ $t('from') }} {{ project.floor_area_from}} {{ $t('to') }} {{project.floor_area_to}} {{project.land_area_unit}}</span>
                                    </div>
                                    <div class="pro-tag">
                                        <a v-if="getListingTypeTag(project.listing_type)">{{ getListingTypeTag(project.listing_type) }}</a>
                                        <a v-if="project.property_type_details.parent_id_top_level_1_name" @click="searchByProjectType(project.property_type_details.parent_id_top_level_1_name)">{{
                                                project.property_type_details.parent_id_top_level_1_code }}</a>
                                        <a v-if="project.property_type_details.name && project.property_type_details.parent_id_top_level_1_name" @click="searchByProjectType(project.property_type_details.parent_id_top_level_1_name, project.property_type_details.name)">{{
                                                project.property_type_details.code }}</a>
                                        <a v-if="project.land_title_type" @click=searchByLandTitleType(project.land_title_type)>{{ project.land_title_type }}</a>
                                    </div>
                                    <ul>
                                        <!--<li v-if="project.is_in_wishlist">
                                            <figure>
                                                <img src="frontend/images/heart-ico.svg">
                                            </figure>
                                            <small>favorite</small>
                                        </li>-->
                                        <li v-if="project.is_direct_owner">
                                            <figure>
                                                <img src="frontend/images/specify-ico1.png">
                                            </figure>
                                            <small>{{ $t('direct') }}<br>{{ $t('owner') }}</small>
                                        </li>
                                        <li v-if="project.is_sing_pass || project.is_corp_pass ">
                                            <figure>
                                                <img src="frontend/images/specify-ico2.png">
                                            </figure>
                                            <small>{{ $t('singpass') }}<br>{{ $t('verified') }} </small>
                                        </li>
                                        <li v-if="project.is_document_approved">
                                            <figure>
                                                <img src="frontend/images/specify-ico3.png">
                                            </figure>
                                            <small>{{ $t('documents') }}<br>{{ $t('verified') }}</small>
                                        </li>
                                        <!--<li v-if="project.is_FSM_linked">
                                            <figure>
                                                <img src="frontend/images/specify-ico4.png">
                                            </figure>
                                            <small>Fengshui<br>Map</small>
                                        </li>
                                        <li v-if="project.is_assigned_to_the_agent">
                                            <figure>
                                                <img src="frontend/images/specify-ico5.png">
                                            </figure>
                                            <small>{{ $t('hybrid_listing') }}<br>Owners </small>
                                        </li>-->
                                    </ul>
                                </div>
                            </div>
                            <div class="property-listing-profile">
                                <div class="property-l-profile-box">
                                    <div class="profile-box">
                                        <img :src="getUserProfileImage(project.user_id_details?.profile_photo_name)" />
                                        <div>
                                            <span>Posted By</span>
                                            <h5>{{ project.user_id_details?.name }} <img
                                                src="frontend/images/varify-user.svg" alt=""></h5>
                                        </div>
                                    </div>
                                    <hr v-if="project.user_id_details?.user_service_details">
                                    <div class="agency-reg-no">
                                        <p v-if="project.user_id_details?.user_service_details" >{{ $t('agency_name') }}: <br>
                                            {{project.user_id_details?.user_service_details?.agency_name || 'N/A' }}</p>
                                        <p v-if="project.user_id_details?.user_service_details" >Agency Reg No: {{project.user_id_details?.user_service_details?.agency_registration_no || 'N/A' }}</p>
                                    </div>
                                    <hr>
                                    <p>Contact Number: {{ formatPhoneNumber(project.user_id_details?.mobile_number) }}</p>
                                    <p>{{ $t('email') }}: {{ project.user_id_details?.contact_email ?? 'N/A' }}</p>
                                </div>
                                <div class="property-l-profile-box" v-if="project.user_id_details && (user.id != project.user_id)">
                                    <SendMessageComponent type="project" :recipient="project.user_id_details" :project="project"/>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <LoaderCust :isLoading="loading"/>
                        <div v-if="!loading" class="text-center">{{ $t('no_records_found') }}</div>
                    </template>
                    <div class="pagination-se" v-if="!loading && projects.length > 0">
                        <nav aria-label="...">
                            <ul class="pagination justify-content-center">
                                <li class="page-item" :class="{ disabled: filters.page === 1 }"
                                    @click="filters.page > 1 && changePage(filters.page - 1)" v-if="filters.page > 1">
                                    <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                                              alt="Previous"></a>
                                </li>
                                <li v-for="pageNumber in paginatedPages(filters.page, totalPages)" :key="pageNumber"
                                    class="page-item" :class="{ active: pageNumber === filters.page }">
                                    <a class="page-link"
                                       @click.prevent="pageNumber !== filters.page && changePage(pageNumber)">
                                        {{ pageNumber }}
                                    </a>
                                </li>
                                <li class="page-item" :class="{ disabled: filters.page === totalPages }"
                                    @click="filters.page < totalPages && changePage(filters.page + 1)"
                                    v-if="filters.page < totalPages">
                                    <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        <section class="offer-pro-sec" v-if="!loading && recentlyProjects.length > 0">
            <div class="container">
                <div class="comman-title d-md-flex align-items-center justify-content-between">
                    <div class="offer-heading">
                        <span>{{ $t("browse_more_offers") }}</span>
                        <h3>recently browsed projects</h3>
                    </div>
                </div>
                <LoaderCust :isLoading="loading"/>
                <div class="property-carousel owl-carousel owl-theme">
                    <div v-for="project in recentlyProjects" :key="project.id" class="item">
                        <div class="project-bx">
                            <!--							<div class="heart-pro">-->
                            <!--								<a href=""><i class="fa fa-heart-o"></i></a>-->
                            <!--							</div>-->
                            <a :href="''">
                                <picture>
                                    <img :src="getProjectImage(project.actual_unit_photos?.[0]?.photo_name || null)" @error="handleImageError"
                                        :alt="project.project_name" />
                                </picture>
                            </a>
                            <div class="project-info">
                                <div class="d-md-flex mb-2 align-items-center">
                                    <h3><a @click="redirectToProjectDetails(project)">{{ project.property_name }}</a></h3>
                                    <h3><a :href="'project-detail/' + project.id">{{ project.property_name }}</a>
                                    </h3>
<!--                                    <div class="price-pro ms-md-auto">-->
<!--                                        <span><small>s$</small> {{ project.asking_price_sale_rent }}</span>-->
<!--                                    </div>-->
                                </div>
                                <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{
                                        project.street }}, {{ project.district }}</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>{{project.no_of_bedrooms_from}} - {{ project.no_of_bedrooms_to }}</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>{{ project.no_of_baths_from }} - {{ project.no_of_baths_to }}</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>{{project.no_car_park_from}} - {{ project.no_car_park_to }}</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>{{project.floor_area_from}} {{project.floor_area_unit}} - {{ project.land_area_from }} {{ project.land_area_unit }}</span>
                                </div>
                                <div class="pro-tag">
                                    <a v-if="getListingTypeTag(project.listing_type)">{{ getListingTypeTag(project.listing_type) }}</a>
                                    <a style="cursor:default" v-if="project.property_type_details?.parent_id_top_level_1_code">{{ project.property_type_details?.parent_id_top_level_1_code }}</a>
                                    <a style="cursor:default" v-if="project.property_type_details?.code">{{ project.property_type_details?.code }}</a>
                                    <a style="cursor:default" v-if="project.land_title_type">{{ project.land_title_type }}</a>
                                </div>
                                <ul>
                                    <li v-if="project.is_direct_owner">
                                        <figure>
                                            <img src="frontend/images/specify-ico1.png">
                                        </figure>
                                        <small>{{ $t('direct') }}<br>{{ $t('owner') }}</small>
                                    </li>
                                    <li  v-if="project.is_sing_pass || project.is_corp_pass ">
                                        <figure>
                                            <img src="frontend/images/specify-ico2.png">
                                        </figure>
                                        <small>{{ $t('singpass') }}<br>{{ $t('verified') }} </small>
                                    </li>
                                    <li v-if="project.is_document_approved">
                                        <figure>
                                            <img src="frontend/images/specify-ico3.png">
                                        </figure>
                                        <small>{{ $t('documents') }}<br>{{ $t('verified') }}</small>
                                    </li>
                                    <li v-if="project.is_FSM_linked">
                                        <figure>
                                            <img src="frontend/images/specify-ico4.png">
                                        </figure>
                                        <small>Fengshui<br>Map</small>
                                    </li>
                                    <li v-if="project.is_assigned_to_the_agent">
                                        <figure>
                                            <img src="frontend/images/specify-ico5.png">
                                        </figure>
                                        <small>{{ $t('hybrid_listing') }}<br>Owners </small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script >
    import ExploreMoreSearch from "@/views/property/ExploreMoreSearch.vue";
    import LoaderCust from "@/components/LoaderCust.vue";
    import { mapState } from "vuex";
    import { formatPhoneNumber, getListingTypeTag, getProjectImage, getUserProfileImage, paginatedPages} from "@/utils/helpers";
    import SendMessageComponent from "@/views/SendMessageComponent.vue";

    export default {
        name: 'ExploreMoreProject',
        components: {SendMessageComponent, LoaderCust, ExploreMoreSearch },
        data() {
            return {
                projects:[],
                totalPages: 0,
                totalRecords: 0,
                loading: false,
                filters: {
                    page: 1,
                    sort_by_field: 'Newest',
                },
                formData: {},
                options: {},
            }
        },
        created() {
            if (this.$route.query.formData) {
                // Replace the route with the same path but without query parameters (during F5 remove params)
                this.$router.replace({ path: this.$route.path });
            }
        },
        mounted() {
            window.$(document).ready(function () {
                window.$('.property-image-carousel').owlCarousel({
                    loop: false,
                    margin: 20,
                    nav: true,
                    dots: false,
                    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                    responsive: {
                        0: {
                            items: 1,
                            margin: 10
                        },
                        600: {
                            items: 2
                        },
                        1000: {
                            items: 3
                        }
                    }
                })
                window.$('.testimonail-carousel').owlCarousel({
                    loop: false,
                    margin: 25,
                    nav: false,
                    dots: true,
                    responsive: {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 2
                        },
                        1000: {
                            items: 3
                        }
                    }
                })
            });

            this.getFilters();
        },
        computed: {
            ...mapState(['user']),
            recentlyProjects() {
                return this.projects.slice(0, 3);
            }
        },
        methods: {
            formatPhoneNumber,
            getListingTypeTag,
            getUserProfileImage,
            getProjectImage,
            paginatedPages,

            updateOptions(newOptions) {
                this.options = newOptions;
            },
            searchByProjectType(parentCategoryName, childCategoryName) {
                let categoryIds = [];
                let params = {
                    selectedType: "",
                    selectedCategories: {},
                    property_type: null,
                    listingType: "",
                    gender: ""
                };
                params.selectedType = parentCategoryName;
                const categoryArray = this.options.propertyTypes[parentCategoryName];
                for (const category of categoryArray) {
                    // if no child, push parent category
                    if (!childCategoryName) {
                        categoryIds.push(category.id);
                    }
                    // Check within children
                    if (category.children && category.children.length > 0) {
                        if (childCategoryName) {
                            // push parent category and child category
                            const childMatch = category.children.find((child) => child.name === childCategoryName);
                            if (childMatch) {
                                categoryIds.push(category.id);
                                categoryIds.push(childMatch.id);
                            }
                        } else {
                            category.children.forEach(childCategory => {
                                categoryIds.push(childCategory.id);
                            });
                        }
                    }
                }
                if (!params.selectedCategories) {
                    params.selectedCategories = [];
                }
                params.selectedCategories[parentCategoryName] = categoryIds;
                params.property_type = [...new Set(categoryIds)].join(',');

                this.formData = params;
                this.fetchProjects(params);
            },
            searchByLandTitleType(landTitleName) {
                let params = {
                    land_title_type: landTitleName,
                };

                this.fetchProjects(params);
            },

            // async wishlistProjects(project) {
            //     try {
            //         if (!this.user?.id) {
            //             toastr.error('Please log in to wishlist', 'error', {
            //                 progressBar: true,
            //                 closeButton: true,
            //                 timeOut: 1500,
            //             });
            //             return;
            //         }
            //         const wishlistResponse = await this.$axios.post(`api/users/${this.user.id}/wishlist/project/${project.id}`, {
            //             api_token: localStorage.getItem('userToken'),
            //         });
            //
            //         if (wishlistResponse.status === 200) {
            //             toastr.success(wishlistResponse.data.message, 'Success', {
            //                 progressBar: true,
            //                 closeButton: true,
            //                 timeOut: 1500,
            //                 onHidden: () => {
            //                     this.fetchProjects();
            //                 }
            //             });
            //         }
            //     } catch (error) {
            //         console.error('Error wishlisting project:', error);
            //     }
            // },

            getFilters() {
                const formDataString = this.$route.query.formData;
                const parsedFormData = formDataString ? JSON.parse(formDataString) : {};
                this.formData = {
                    gender: parsedFormData.gender || '',
                    dateOfBirth: parsedFormData.dateOfBirth || '',
                    keywords: parsedFormData.keywords || '',
                    listingType: parsedFormData.listingType || '',
                    locationType: parsedFormData?.locationType || '',
                    selectedCategories: parsedFormData.selectedCategories || {},
                    selectedType: parsedFormData.selectedType || null,
                    selectedFurnishingLevels: parsedFormData?.selectedFurnishingLevels || [],
                    selectedLandTenures: parsedFormData?.selectedLandTenures || [],
                    is_approved_by_admin: parsedFormData?.is_approved_by_admin || true,
                };

                if (Object.prototype.hasOwnProperty.call(parsedFormData, 'sort_by_field')) {
                    this.filters.sort_by_field = parsedFormData.sort_by_field;
                }
                this.activeTab = this.$route.query.currentTab || 'Buy';
                this.fetchProjects();
            },

            computedSQF(project) {
                let start = project.listing_price_from > 0 ? Math.ceil(project.listing_price_from / project.floor_area_from) : 0;
                let to = project.listing_price_to > 0 ? Math.ceil(project.listing_price_to / project.floor_area_to) : 0;

                if (start > to) {
                    [start, to] = [to, start];
                }

                return { start , to };
            },

            async fetchProjects(params = null) {
                this.loading = true;
                try {
                    let parameters = {
                        sort_by_field: this.filters.featured_sort_by_field,
                        page_number: this.filters.featured_page ,
                        user_id: this.user?.id,
                        listing_type: this.formData?.listingType,
                        location_type: this.formData?.locationType,
                    };
                    if (params) {
                        parameters = { ...parameters, ...params };
                    } else {
                        let otherParams = {
                            property_type: this.formData?.selectedType
                                ? this.formData?.selectedCategories[this.formData.selectedType]?.join(',')
                                : '',
                            keywords: this.formData?.keywords,
                            land_title_type: this.formData?.land_title_type,
                            selected_furnishing_levels: this.formData?.selectedFurnishingLevels.join(','),
                            selected_land_tenures: this.formData?.selectedLandTenures.join(','),
                            is_approved_by_admin: this.formData?.is_approved_by_admin || true,
                        };
                        parameters = { ...parameters, ...otherParams };
                    }

                    const propertiesResponse = await this.$axios.post('api/project-search-browse', parameters);

                    this.projects = propertiesResponse.data.output.project_list ?? [];
                    this.totalPages = propertiesResponse.data.output.no_of_total_pages ?? 0;
                    this.totalRecords = propertiesResponse.data.output.no_of_total_records ?? 0;

                    this.$nextTick(() => {
                        this.initializeOwlCarousel();
                    });
                } catch (error) {
                    console.error('Error fetching projects:', error);
                } finally {
                    this.loading = false;
                }
            },
            sortProjects(sortBy) {

                this.filters.sort_by_field = sortBy;
                this.filters.page = 1;

                this.fetchProjects();
            },

            changePage(page) {
                if (page > 0) {
                    const totalPages = this.totalPages;
                    if (page <= totalPages) {
                        this.filters.page = page;
                        this.fetchProjects();
                    }
                }
            },

            submitSearch(data) {
                this.formData = data;
                this.filters.page = 1;
                this.fetchProjects();
            },

            initializeOwlCarousel() {
                window.$('.property-carousel').owlCarousel({
                    loop: true,
                    margin: 0,
                    nav: true,
                    dots: true,
                    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                    responsive: {
                        0: {
                            items: 1,
                            margin: 0
                        },
                        600: {
                            items: 1
                        },
                        1000: {
                            items: 1
                        }
                    }
                });
            },
            redirectToProjectDetails(project) {
                window.location.href = window.FRONTEND_URL + `/project-detail/${project.id}`;
            }
        }

    }

</script>

<style scoped>
    .dropdown-item:hover,
    .dropdown-item.selected {
        background-color: #ffe6b9;
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        margin-top: 20px;
    }
</style>