<template>
    <section class="signin-sec">
        <div class="container">
            <div class="signin-bx m-auto">
                <h1>{{ $t('verify_number') }}</h1>
                <p class="text-center">
                    We sent a verification code to <b>{{ user.mobile_number }}</b>. Please input the code below.
                </p>
                <form @submit.prevent="verifyCode">
                    <div class="form-group">
                        <input type="number" v-model="verification_code" placeholder="Verification code"
                            class="form-control" required>
                        <span class="error" v-if="errors.verification_code">{{ errors.verification_code }}</span>
                    </div>
                    <div class="form-group">
                        <p><b>Resend code:
                            <a href="javascript:;" v-if="isButtonDisabled">
                                <span>{{ formattedTime }}</span>
                            </a>
                            <a href="javascript:;" v-else @click="reSendCode" :class="{disabled: isButtonDisabled}">
                                <span>Resend</span>
                            </a>
                        </b></p>
                    </div>
                    <div class="form-group">
                        <div class="g-recaptcha" :data-sitekey="recaptcha.siteKey"></div>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn comman-btn-2 w-100">{{ $t('verify') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>


<style>
    .disabled {
        pointer-events: none;
        color: grey;
    }
</style>

<script>
import toastr from "toastr";
import { CONSTANT } from "@/constants/constants";
import { mapState } from "vuex";

export default {
    data() {
        return {
            verification_code: '',
            errors: {},
            isButtonDisabled: false,
            timer: 0,
            intervalId: null,
            recaptcha: {
                siteKey: CONSTANT.GOOGLE.SITE_KEY,
                token: '',
                isScriptLoaded: false
            },
            lockDuration: 240, // Lock duration (4 minutes)
            regularDuration: 240, // Regular resend duration (4 minute)
        };
    },
    computed: {
        ...mapState(['isAuthenticated', 'user']),
        formattedTime() {
            const minutes = Math.floor(this.timer / 60);
            const seconds = this.timer % 60;
            return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        },
    },
    async created() {
        await this.startTimer(this.regularDuration);
    },
    mounted() {
        this.loadRecaptcha();
    },
    methods: {
        async reSendCode() {
            this.startTimer(this.regularDuration);
            try {
                await this.$axios.post('api/code/send', {
                    mobile_number: this.user.mobile_number,
                });
                // You can show a success message here if needed
                toastr.success('Verification code sent successfully!', 'Success');
            } catch (error) {
                console.error('Error sending code:', error);
                // Handle error (e.g., show an error message)
            }
        },
        startTimer(duration) {
            this.isButtonDisabled = true;
            this.timer = duration; // Set the timer to the provided duration

            if (this.intervalId) {
                clearInterval(this.intervalId);
            }

            this.intervalId = setInterval(() => {
                if (this.timer > 0) {
                    this.timer -= 1;
                } else {
                    this.isButtonDisabled = false;
                    clearInterval(this.intervalId);
                }
            }, 1000);
        },
        async verifyCode() {
            try {
            // Ensure reCAPTCHA is loaded
            if (typeof window.grecaptcha === 'undefined') {
                toastr.error('reCAPTCHA script is not loaded correctly.', 'Error', { timeOut: 3000 });
                return;
            }

            const recaptchaResponse = window.grecaptcha.getResponse();
            if (!recaptchaResponse.length) {
                toastr.error('Please complete reCAPTCHA verification.', 'Error', { timeOut: 3000 });
                return;
            }

            this.recaptcha.token = recaptchaResponse;
            
                const response = await this.$axios.post('api/code/verify', {
                    mobile_number: this.user.mobile_number,
                    code: this.verification_code,
                });

                toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                this.$router.push('/profile');
            } catch (error) {
                if (error.response && error.response.status === 429) {
                    const retryAfter = error.response.data.retry_after || this.lockDuration;
                    this.startTimer(retryAfter); // Start timer based on lock duration
                    toastr.error(
                        `Too many invalid attempts. Please try again after ${Math.ceil(retryAfter / 60)} minutes.`,
                        'Error',
                        { timeOut: 5000 }
                    );
                } else if (error.response && error.response.status === 422) {
                    toastr.error(
                        `${error.response.data.message} Remaining attempts: ${error.response.data.remaining_attempts}`,
                        'Error',
                        { timeOut: 5000 }
                    );
                } else {
                    toastr.error('An unexpected error occurred. Please try again.', 'Error', { timeOut: 3000 });
                }
            }
        },
        async loadRecaptcha() {
            try {
                await this.loadRecaptchaScript();
                if (window.grecaptcha) {
                    window.grecaptcha.render(document.querySelector('.g-recaptcha'), {
                        sitekey: this.recaptcha.siteKey,
                    });
                }
                this.isScriptLoaded = true;
            } catch (error) {
                console.error('Error loading reCAPTCHA script:', error);
            }
        },
        loadRecaptchaScript() {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = 'https://www.google.com/recaptcha/api.js';
                script.async = true;
                script.defer = true;

                script.onload = () => {
                    resolve();
                };

                script.onerror = () => {
                    reject(new Error('Failed to load reCAPTCHA script'));
                };

                document.head.appendChild(script);
            });
        },
    },
};
</script>
