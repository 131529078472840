<template>
    <section class="profile-se">
        <div class="container">
            <div class="top-tabs-design">
                <ul>
                    <li><a href="javascript:;" class="active">{{ $t('my_profile') }}</a></li>
                    <li v-if="!user.is_private_user">
                        <a href="/verify-service-detail-page">{{ $t('verify_service_details') }}</a>
                    </li>
                    <li v-if="!user.is_private_user"><a href="/property-data-analytic">{{ $t('analytics_and_report') }}</a></li>
                    <li><a href="/wishlist">{{ $t('my_wishlist') }}</a></li>
                    <li><a href="/proxy-listing-page"> Proxy Listing </a></li>
                    <li><a href="/requested-service">{{ $t('requested_service') }}</a></li>
                    <li><a href="/messages">{{ $t('messages') }}</a></li>
                </ul>
            </div>
            <div class="row">
                <div class="col-md-9">
                    <LoaderCust :isLoading="isLoading" />
                    <form @submit.prevent="updateProfileBasic">
                        <div class="profile-card-edit">
                            <div class="row">
                                <div class="comman-title col-md-6">
                                    <h3>{{ $t('profile_picture') }}</h3>
                                </div>
                                <label class="col-md-6 d-flex align-content-end justify-content-end">
                                    {{ $t('verification_status') }}:
                                    <span v-if="isVerified">
                                        <img src="/frontend/images/verified_logo.png" alt="Verified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                                        <span style="font-size: 10px; vertical-align: middle;"> {{ $t('verified') }}</span>
                                    </span>
                                        <span v-else>
                                        <img src="/frontend/images/unverified_logo.png" alt="Unverified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                                        <span style="font-size: 10px; vertical-align: middle; color: red;">{{ $t('unverified') }}</span>
                                    </span>
                                </label>
                            </div>
                            <div class="profile-card-edit-box">
                                <figure v-if="imageUrl">
                                    <img :src="computedImageSrc" @error="handleImageError">
                                </figure>

                                <div class="profile-user-content">
                                    <div class="d-flex">
                                        <input type="file" ref="fileInput" @change="onFileChange"
                                            style="display: none;" />
                                        <a href="javascript:;" @click.prevent="triggerFileInput"
                                            class="comman-btn-2"><b>{{ $t('upload') }} {{ $t('profile_picture') }}</b></a>
                                        <a href="javascript:;" @click.prevent="removeProfilePhoto"
                                            class="comman-btn-4 ms-3"><b>{{ $t('remove') }} {{ $t('profile_picture') }}</b></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="profile-card-edit">
                            <div class="comman-title">
                                <h3>{{ $t('my_referral_code') }}</h3>
                            </div>
                            <div class="row">
                                <div class="form-group col">
                                    <label>{{ $t('referral_code_custom_description') }}</label>
                                    <!-- Display referral code -->
                                    <input type="text" placeholder="Referral Code" class="form-control"
                                        :value="referralCode" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="profile-card-edit">
                            <div class="comman-title">
                                <h3>{{ $t('my_details') }}</h3>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>{{ $t('display_name') }} </label>
                                    <input type="text" placeholder="Display Name" class="form-control"
                                        v-model="form.name">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{ $t('first_name') }} </label>
                                    <input type="text" placeholder="First Name" class="form-control"
                                        v-model="form.first_name">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{ $t('last_name') }}</label>
                                    <input type="text" placeholder="Last Name" class="form-control"
                                        v-model="form.last_name">
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>{{ $t('birth_date') }}</label>
                                    <input type="date" placeholder="Last Name" class="form-control"
                                        v-model="form.date_of_birth">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{ $t('gender') }}</label>
                                    <select class="form-control" v-model="form.gender">
                                        <option value="" disabled>{{ $t('gender') }}</option>
                                        <option value="Male">{{ $t('male') }}</option>
                                        <option value="Female">{{ $t('female') }}</option>
                                        <option value="Other">{{ $t('other') }}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>
                                        {{ $t('mobile_contact_no') }}
                                        <span v-if="userProfile.is_mobile_verified">
                                            <img src="/frontend/images/verified_logo.png" alt="Verified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                                            <span style="font-size: 10px; vertical-align: middle;"> {{ $t('verified') }}</span>
                                        </span>
                                        <span v-else>
                                            <img src="/frontend/images/unverified_logo.png" alt="Unverified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                                            <a href="/verify-number" ><span style="font-size: 10px; vertical-align: middle; color: red;">{{ $t('verify_now') }}</span></a>
                                        </span>
                                    </label>

                                    <input
                                        type="text"
                                        placeholder="Mobile Number"
                                        class="form-control" @blur="validateMobileNumber" :class="{ 'is-invalid': verifyError }"
                                        v-model="form.mobile_number"
                                    >
                                    <span v-if="mobileNumberError" class="error">{{ mobileNumberError }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label for="currentPassword">{{ $t('current_password') }}</label>
                                    <input class="form-control"
                                        type="password"
                                        id="currentPassword" placeholder="Current Password"
                                        v-model="form.currentPassword"
                                    />
                                    <span v-if="passwordError" class="error">{{ passwordError }}</span>
                                </div>
                                <div v-if="form.currentPassword" class="form-group col-md-4">
                                    <label for="newPassword">{{ $t('new_password') }}</label>
                                    <input class="form-control"
                                        type="password"
                                        id="newPassword"
                                        v-model="form.newPassword"
                                    />
                                </div>
                                <div v-if="form.currentPassword" class="form-group col-md-4">
                                    <label for="confirmPassword">{{ $t('confirm_new_password') }}</label>
                                    <input class="form-control"
                                        type="password"
                                        id="confirmPassword"
                                        v-model="form.confirmPassword"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>
                                        {{ $t('email_address') }}
                                        <span v-if="userProfile.is_email_verified">
                                            <img src="/frontend/images/verified_logo.png" alt="Verified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;"> 
                                            <span style="font-size: 10px; vertical-align: middle;"> {{ $t('verified') }}</span>
                                        </span>
                                        <span v-else>
                                            <img src="/frontend/images/unverified_logo.png" alt="Unverified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                                            <span style="font-size: 10px; vertical-align: middle; color: red;"> {{ $t('unverified') }}</span>
                                        </span>

                                    </label>
                                    <input type="text" placeholder="Email" class="form-control" v-model="userProfile.email" disabled>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{ $t('contact_email') }}</label>
                                    <input type="email" placeholder="Email" class="form-control"
                                           v-model="form.contact_email">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{ $t('tiktok') }}</label>
                                    <input type="text" placeholder="Tiktok" class="form-control"
                                           v-model="form.tiktok_url">
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>{{ $t('facebook') }}</label>
                                    <input type="text" placeholder="Facebook" class="form-control"
                                           v-model="form.facebook_url">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{ $t('twitter') }}</label>
                                    <input type="text" placeholder="Twitter" class="form-control"
                                           v-model="form.twitter_url">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{ $t('instagram') }}</label>
                                    <input type="text" placeholder="Instagram" class="form-control"
                                           v-model="form.instagram_url">
                                </div>
                            </div>
                        </div>
                        <div class="profile-card-edit">
                            <div class="comman-title">
                                <h3>{{ $t('about_myself') }}</h3>
                            </div>
                            <div class="form-group mb-0">
                                <label>{{ $t('about_myself') }}</label>
                                <textarea class="form-control" placeholder="About Myself"
                                    v-model="form.about_you"></textarea>
                            </div>
                        </div>
                        <div class="profile-card-edit d-flex">
                            <button class="btn comman-btn-2" type="submit">{{ $t('save') }}</button>
                            <button class="btn comman-btn-4 ms-2" type="button" @click="fetchOptions">{{ $t('cancel') }}</button>
                        </div>
                    </form>
                </div>
                <ProfilePageSideBar></ProfilePageSideBar>
            </div>
        </div>
    </section>
</template>

<script>
    import LoaderCust from '@/components/LoaderCust.vue';
    import { CONSTANT } from '@/constants/constants';
    import { mapState } from 'vuex';
    import toastr from 'toastr';
    import 'toastr/build/toastr.min.css';
    import ProfilePageSideBar from "@/views/ProfilePageSideBar.vue";
    export default {
        components: {
            ProfilePageSideBar,
            LoaderCust,
        },
        data() {
            return {
                referralCode: '',
                referal_current_Url: '',
                copySuccess: false,
                isLoading: false,
                selectedYear: new Date().getFullYear(), // Default to current year
                years: [],
                form: {
                    name: '',
                    first_name: '',
                    last_name: '',
                    gender: '',
                    date_of_birth: '',
                    mobile_phone: '',
                    email: '',
                    is_mobile_verified: '',
                    is_email_verified: '',
                    contact_phone: '',
                    contact_email: '',
                    facebook_url: '',
                    linkedin_url: '',
                    twitter_url: '',
                    instagram_url: '',
                    tiktok_url: '',
                    youtube_url: '',
                    about_you: '',
                    agency_name: '',
                    agency_license: '',
                    agency_registration_no: '',
                    position_held: '',
                    personal_service_type_id: 0,
                    personal_working_since: '',
                    service_type_id: 0,
                    show_as_agent: 0,
                    is_available_mon: 0,
                    is_available_tue: 0,
                    is_available_wed: 0,
                    is_available_thu: 0,
                    is_available_fri: 0,
                    is_available_sat: 0,
                    is_available_sun: 0,
                    newPassword: '',
                    currentPassword: '',
                    confirmPassword: '',

                },
                amount: 0,
                service_typeList: [],
                myprofileData: [],
                selectedFile: null,
                imageUrl: '/frontend/images/profile.svg',
                errors: {},
                phoneError: '',
                mobileNumberError: '',
                verifyError: '',
                passwordError: '',
                profilServicesData: [],
                package_details: [],
                isVerified: '',
                isFeatured: null,
                featuredTo: null,
                featuredFrom: null,
                isActive: null,
                errorMessage: '',
                currencies: [],
                selectedCurrencyRate: localStorage.getItem("preferredCurrencyRate") || 1,
            };
        },
        computed: {
            ...mapState(['isAuthenticated', 'user', 'userProfile']),
            computedImageSrc() {
                return this.userProfile?.profile_photo_name ? `${CONSTANT.IMAGE_URL}/imagesProfile/${this.userProfile.profile_photo_name}` : CONSTANT.DEFAULT_IMAGE;
            },
        },
        mounted() {
            if (this.userProfile) {
                this.initializeForm();
            }
            this.fetchReferralCode()
            const storedRate = localStorage.getItem("preferredCurrencyRate");
            if (storedRate) {
                this.selectedCurrencyRate = storedRate;
            }
            if (this.userProfile.is_mobile_verified === 0) {
                this.showModal();
            }
        },
        watch: {
            userProfile: {
                handler(newValue) {
                    if (newValue) {
                        this.initializeForm();
                    }
                },
                immediate: true,
            },
        },
        async created() {
            this.fetchOptions();
            this.generateYears();
            this.fetchCurrencies();
        },
        methods: {
            showModal() {
                window.$('#verifyMobileNumber').modal('show');
            },
            async fetchCurrencies() {
                try {
                    const token = localStorage.getItem('userToken');
                    const response = await this.$axios.get("/api/currencies", {
                        params: {
                            api_token: token,
                        }
                    });
                    this.currencies = response.data;

                } catch (error) {
                    console.error("Error fetching currencies:", error);
                }
            },
            initializeForm() {
                this.form.name = this.userProfile.name || '';
                this.form.first_name = this.userProfile.first_name || '';
                this.form.last_name = this.userProfile.last_name || '';
                this.form.gender = this.userProfile.gender || '';
                this.form.date_of_birth = this.userProfile.date_of_birth || '';
                this.form.mobile_number= this.userProfile.mobile_number || '';
                this.form.email = this.userProfile.email || '';
                this.form.is_mobile_verified = this.userProfile.is_mobile_verified || '';
                this.form.is_email_verified = this.userProfile.is_email_verified || '';
            },
            async onFileChange(event) {
                this.selectedFile = event.target.files[0];
                if (this.selectedFile) {
                    // Generate a preview URL
                    this.imageUrl = URL.createObjectURL(this.selectedFile);
                    this.computedImageSrc = URL.createObjectURL(this.selectedFile);

                    const token = localStorage.getItem('userToken');
                    // Create a FormData object
                    const formData = new FormData();
                    formData.append('profile_photo_name', this.selectedFile);
                    formData.append('dataJson', JSON.stringify({
                        user_id: this.user.id,
                        api_token: token,
                    }));
                    try {
                        const response = await this.$axios.post('api/update-profile-photo', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        });
                        toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                        const response_profile = await this.$axios.post('/api/profile', {
                            api_token: token,
                            user_id: this.user.id,
                        });
                        // this.form.verify_phone = response_profile.data.output.contact_phone
                        await this.$store.dispatch('profileUpdate', response_profile.data.output);

                        //await this.$store.dispatch('profileUpdate', response.data);
                    } catch (error) {
                        if (error.response && error.response.data) {
                            this.errors = this.extractErrors(error.response.data.errors || {});
                        } else {
                            console.error('An unexpected error occurred:', error);
                        }
                    } finally {
                        this.isLoading = false;
                    }
                }
            },
            triggerFileInput() {
                // Trigger the file input click event
                this.$refs.fileInput.click();
            },
            async removeProfilePhoto() {

                const token = localStorage.getItem('userToken');
                const response_remove_photo = await this.$axios.post('/api/remove-profile-photo', {
                    user_id: this.user.id,
                    api_token: token
                });
                if (response_remove_photo.data)  {
                    toastr.success(response_remove_photo.data.message, 'Success', { timeOut: 3000 });
                    await this.$store.dispatch('profileUpdate', response_remove_photo.data.output);
                }
            },
            async fetchReferralCode() {
                const token = localStorage.getItem('userToken');
                this.isLoading = true;
                try {
                    // Fetch the referral code from the API
                    const response = await this.$axios.get(`/api/users/${this.user.id}/referral-code`,
                    {
                        params: {
                            api_token: token,
                        }
                    });
                    if (response.data.referral_code) {
                    this.referralCode = response.data.referral_code; // Store the referral code
                    } else {
                    // toastr.warning('Referral code not found for the user', 'Warning', { timeOut: 3000 });
                    console.error('Referral code not found for the user');
                    }
                } catch (error) {
                    // toastr.error('Failed to fetch referral code', 'Error', { timeOut: 3000 });
                    console.error('Error fetching referral code:', error);
                } finally {
                    this.isLoading = false;
                }
            },
            validateMobileNumber() {
                const mobileNumberPattern = /^\+(60|65)[0-9]{8,13}$/;

                if (!mobileNumberPattern.test(this.form.mobile_number)) {
                    this.mobileNumberError = 'Invalid mobile number. Must start with +60 or +65, followed by 8-13 digits.';
                    return false;
                }

                this.mobileNumberError = '';
                return true;
            },
            validatePhone() {
                const phonePattern = /^\+(60|65)[0-9]{8,13}$/;
                if (!phonePattern.test(this.form.contact_phone)) {
                    this.phoneError = 'Invalid phone number. Must start with +60 or +65, followed by 8-13 digits.';
                    return false;
                }
                this.phoneError = '';
                return true;
            },
            validatePassword() {
                if (this.form.newPassword !== this.form.confirmPassword) {
                    this.passwordError = 'New passwords do not match!';
                    return false;
                } else {
                    if (!this.form.newPassword) {
                        this.passwordError = 'New Password is required.';
                        return false;
                    } else if (this.form.newPassword.length < 8) {
                        this.passwordError = 'New Password must be at least 8 characters long.';
                        return false;
                    } else if (!/[A-Z]/.test(this.form.newPassword)) {
                        this.passwordError = 'New Password must include one uppercase letter';
                        return false;
                    } else if (!/[a-z]/.test(this.form.newPassword)) {
                        this.passwordError = 'New Password must include one lowercase letter';
                        return false;
                    } else if (!/\d/.test(this.form.newPassword)) {
                        this.passwordError = 'New Password must include at least one number';
                        return false;
                    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(this.form.newPassword)) {
                        this.passwordError = 'New Password must include at least one special character.';
                        return false;
                    }
                }
                return true;
            },
            async updateProfileBasic() {
                // Validate password first if not empty
                if (this.form.confirmPassword) {
                    const isPasswordValid = this.validatePassword();
                    if (!isPasswordValid) {
                        return;
                    }
                }

                const isMobilePhoneValid = this.validateMobileNumber();
                if (!isMobilePhoneValid) {
                    toastr.error('Invalid phone number. It must start with +60 or +65, followed by 8-13 digits.');
                    return; // Stop execution if phone number is invalid
                }

                const isPhoneValid = this.validatePhone();
                if (!isPhoneValid) {
                    toastr.error('Invalid phone number');
                    return; // Stop execution if phone number is invalid
                }

                if (this.isLoading) return;
                this.isLoading = true;
                const token = localStorage.getItem('userToken');

                try {
                    const response = await this.$axios.post('api/update-basic-profile', {
                        user_id: this.user.id,
                        api_token: token,
                        name: this.form.name,
                        first_name: this.form.first_name,
                        last_name: this.form.last_name,
                        gender: this.form.gender,
                        date_of_birth: this.form.date_of_birth,
                        mobile_number: this.form.mobile_number,
                        email: this.form.email,
                        is_mobile_verified: this.form.is_mobile_verified,
                        is_email_verified: this.form.is_email_verified,
                        contact_phone: this.form.contact_phone,
                        contact_email: this.form.contact_email,
                        facebook_url: this.form.facebook_url,
                        linkedin_url: this.form.linkedin_url,
                        twitter_url: this.form.twitter_url,
                        instagram_url: this.form.instagram_url,
                        tiktok_url: this.form.tiktok_url,
                        youtube_url: this.form.youtube_url,
                        about_you: this.form.about_you,
                        current_password: this.form.currentPassword,
                        new_password: this.form.newPassword,

                    });
                    //this.updateServicedData();
                    toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                } catch (error) {
                    if (error.response && error.response.data) {
                        const errorData = error.response.data;
                        
                        if (errorData.errors) {
                            this.errors = this.extractErrors(errorData.errors);

                            // Show the first validation error in a toast notification
                            const firstErrorKey = Object.keys(errorData.errors)[0]; // Get the first error key (e.g., "mobile_number")
                            const firstErrorMessage = errorData.errors[firstErrorKey][0]; // Get the first error message

                            toastr.error(firstErrorMessage, 'Validation Error', { timeOut: 3000 });
                        } else if (errorData.message) {
                            this.errors = [errorData.message]; // Convert single error message to an array
                            toastr.error(errorData.message, 'Failed', { timeOut: 3000 });
                        } else {
                            this.errors = ['An unknown error occurred'];
                            toastr.error('An unknown error occurred', 'Error', { timeOut: 3000 });
                        }
                    } else {
                        console.error('An unexpected error occurred:', error);
                        this.errors = ['Something went wrong, please try again.']; 
                        toastr.error('Something went wrong, please try again.', 'Error', { timeOut: 3000 });
                    }
                } finally {
                    this.isLoading = false;
                }
            },
            async handleInputChangeUsername() {

                try {
                    const token = localStorage.getItem('userToken');
                    await this.$axios.post('api/update-user-name-url', {
                        user_id: this.user.id,
                        api_token: token,
                        user_name_url: this.userProfile.user_name_url
                    });

                } catch (error) {

                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.isLoading = false;
                }
            },
            extractErrors(errors) {
                // Extract the first error message from each field's errors array
                const formattedErrors = {};
                for (const [field, messages] of Object.entries(errors)) {
                    if (Array.isArray(messages) && messages.length > 0) {
                        formattedErrors[field] = messages[0]; // Get the first error message
                    }
                }
                return formattedErrors;
            },
            generateYears() {
                const currentYear = new Date().getFullYear();
                for (let year = 1970; year <= currentYear; year++) {
                    this.years.push(year);
                }
            },
            async fetchOptions() {
                const token = localStorage.getItem('userToken');
                // Get Features list
                try {
                    const response = await this.$axios.post('api/services-type-list',
                        {
                            user_id: this.user.id,
                            api_token: token
                        });
                    this.service_typeList = response.data.output.map(item => ({
                        value: item.id,
                        label: item.name
                    }));
                } catch (error) {
                    console.error('Error fetching options:', error);
                    await this.logout();
                }
                // Get Srviced data
                // try {
                //   const response = await this.$axios.post('api/my-profile-detail',
                //     {
                //       user_id : this.user.id,
                //       api_token : token
                //     });
                //   this.myprofileData = response.data.output
                //   this.profilServicesData = this.myprofileData.service_details
                // } catch (error) {
                //   console.error('Error fetching options:', error);
                // }
                // If Profile data
                if (this.isAuthenticated) {
                    try {
                        const response = await this.$axios.post('/api/my-profile-detail', {
                            api_token: token,
                            user_id: this.user.id
                        });

                        var userData = response.data.output;
                        await this.$store.dispatch('profileUpdate', response.data.output);
                        var userServiceData = userData.service_details[0];
                        this.package_details = userData.package_details;
                        this.isVerified = userData?.is_admin_verified;
                        this.isFeatured = userServiceData?.is_featured;
                        this.featuredTo = userServiceData?.featured_to;
                        this.featuredFrom = userServiceData?.featured_from;
                        this.profilServicesData = userServiceData;
                        this.referal_current_Url = `${window.BACKEND_URL}/` + 'widget-property/create/' + userData.id + '/' + userData.referral_user_type;
                        this.form = userData,
                        this.form.agency_name = userServiceData?.agency_name,
                        this.form.agency_license = userServiceData?.agency_license,
                        this.form.agency_registration_no = userServiceData?.agency_registration_no,
                        this.form.position_held = userServiceData?.position_held,
                        this.form.service_type_id = userServiceData?.service_type_id ?? 0,
                        this.form.company_name = userServiceData?.company_name,
                        this.form.availability_days = userServiceData?.availability_days,
                        this.form.is_available_mon = userServiceData?.is_available_mon == 1 ? true : false,
                        this.form.is_available_tue = userServiceData?.is_available_tue == 1 ? true : false,
                        this.form.is_available_wed = userServiceData?.is_available_wed == 1 ? true : false,
                        this.form.is_available_thu = userServiceData?.is_available_thu == 1 ? true : false,
                        this.form.is_available_fri = userServiceData?.is_available_fri == 1 ? true : false,
                        this.form.is_available_sat = userServiceData?.is_available_sat == 1 ? true : false,
                        this.form.is_available_sun = userServiceData?.is_available_sun == 1 ? true : false,
                        this.form.working_hours = userServiceData?.working_hours,
                        this.form.show_as_agent = userServiceData?.show_as_agent == 1 ? true : false,
                        this.form.personal_service_type_id = userServiceData?.personal_service_type_id ?? 0,
                        this.form.personal_working_since = userServiceData?.personal_working_since ?? '',
                        this.isActive = userData.is_active,
                        // This is not executed, as some error has thrown before
                        await this.$store.dispatch('profileUpdate', response.data.output);
                    } catch (error) {
                        console.error('Failed to fetch profile:', error);
                        toastr.error('Your session has expired. Please log in again');
                        await this.logout(); 
                    }
                } else {
                    toastr.error('Your session has expired. Please log in again');
                    await this.logout();          
                }
            },
            async logout() {
                try {
                    await this.$store.dispatch('logout'); // Call Vuex logout action
                    localStorage.clear(); // Clear all localStorage data
                    this.$router.push('/login'); // Redirect user to login page
                } catch (error) {
                    console.error('Logout failed:', error);
                }
            },
        }
    };
</script>

<style scoped>

</style>