<template>
    <section class="agent-pages-se">
        <div class="container">
            <div class="comman-title text-center">
                <h2>Search for a Service Provider</h2>
            </div>
            <div class="agent-search-box">
                <div class="row row-cols-1 row-cols-sm-3 row-cols-md-5">
                    <div class="col-md-3 col-sm-12">
                        <div class="form-group">
                            <select v-model="filters.type_of_service" class="form-control">
                                <option value="0" disabled>Type of Service</option>
                                <option v-for="service in services" :key="service.id" :value="service.id">
                                    {{ service.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <div class="form-group">
                            <select v-model="filters.exp_in_yrs" class="form-control">
                                <option value="0-99" disabled>Years Experience</option>
                                <option value="0-2">Less than 2 Years</option>
                                <option value="2-4">2 - 4 Years</option>
                                <option value="5-9">5 - 9 Years</option>
                                <option value="10-19">10 - 19 Years</option>
                                <option value="20-99">Above 20 Years</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <div class="form-group">
                            <div class="dropdown">
                                <button class="form-control dropdown-toggle" type="button" id="availabilityDropdown"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    Select Availability
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="availabilityDropdown">
                                    <li v-for="(day, index) in days" :key="index" class="dropdown-item">
                                        <input type="checkbox" :id="day.key" :value="1"
                                               :checked="filters[day.key] === 1"
                                               @change="handleCheckboxChange(day.key, $event)" />
                                        <label :for="day.key">&nbsp;{{ day.label }}</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <div class="form-group">
                            <select v-model="filters.rating" class="form-control">
                                <option value="0-5" disabled>{{ $t('rating') }}</option>
                                <option value="0-3">Less than 3 Stars</option>
                                <option value="3-3.99">3 - 3.99 Stars</option>
                                <option value="4-5">4 - 5 Stars</option>
                            </select>
                        </div>
                    </div>
                </div>
                    <div class="row align-items-center">
                        <div class="col position-relative">
                            <input v-model="filters.keyword" type="text" class="form-control" @input="getAutocompleteSearch"
                                   placeholder="Enter Specific Name, Description, Agency, Company, Location" />
                            <ul v-if="suggestions.length" class="autocomplete-list">
                                <li v-for="(suggestion, index) in suggestions" :key="index" @click="selectSuggestion(suggestion)">
                                    {{ suggestion }}
                                </li>
                            </ul>
                        </div>
                        <div class="col-auto">
                            <button class="comman-btn-2" @click.prevent="fetchAgents">
                                <img src="frontend/images/search-ico.svg" alt=""><b> Search</b>
                            </button>
                        </div>
                    </div>
            </div>
            <div class="filter-bar-se mt-5">
                <div class="comman-title">
                    <h3>List Of Service Providers</h3>
                </div>
                <div class="filter-right-bar">
                    <div class="dropdown">
                        <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                            <img src="frontend/images/Sort-By.svg"> {{ $t('sort_by') }}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                            <li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Name' }"
                                    @click.prevent="sortAgents('Name')">Name</a></li>
                            <li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Newest' }"
                                    @click.prevent="sortAgents('Newest')">{{ $t('newest') }}</a></li>
                            <li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Most Experience' }"
                                    @click.prevent="sortAgents('Most Experience')">Most
                                    Experienced</a></li>
                            <li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Least Experience' }"
                                   @click.prevent="sortAgents('Least Experience')">Least
                                Experienced</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <template v-if="!loading && agents.length > 0">
                <div class="row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-2">
                    <div class="col" v-for="agent in agents" :key="agent.id">
                    <div class="agent-bx">
                        <div class="agent-pic">
                            <a class="wishlist-icon-in-pic" @click="wishlistAgent(agent)" title="Wishlist Agent">
                                <img v-if="agent?.is_in_wishlist" src="frontend/images/heart-filled-ico.svg" alt="Remove"/>
                                <img v-else src="frontend/images/heart-ico.svg" alt="Remove"/>
                            </a>
                            <div v-if="agent?.user_service_details?.is_featured == 1" class="featured-banner">
                                <span>FEATURED</span>
                            </div>
                            <div class="mark-logo">
                                <img src="frontend/images/logo.png">
                            </div>
                            <picture>
                                <router-link :to="{ name: 'AgentDetail', params: { id: agent.id } }">
                                    <img :src="getUserProfileImage(agent?.profile_photo_name)" />
                                </router-link>
                            </picture>
                        </div>
                        <div class="agent-info">
                            <div class="row row-cols-1">
                                <div class="col col-7">
                                    <h4 style="display: flex; gap: 10px;">{{ agent.name }}
                                        <img v-if="agent?.user_service_details" src="/frontend/images/verified_logo.png" alt="Verified" style="width: 16px; height: 16px;">
                                        <img v-else src="/frontend/images/unverified_logo.png" alt="Unverified" style="width: 16px; height: 16px;">
                                    </h4>
                                    <p><span>@{{ agent.user_name_url }}</span></p>
                                    <p>{{ $t('position') }}: {{ agent?.user_service_details?.position_held }}</p>
                                    <p>{{ formatPhoneNumber(agent.contact_phone) }}</p>
                                </div>
                                <div class="col col-5">
                                    <p>Join {{ yearsAgo(agent?.user_service_details?.personal_working_since) }}</p>
                                    <p><img src="frontend/images/star.svg" alt=""> {{
                                            agent?.user_service_details?.total_rating_points }}
                                        Ratings</p>
                                    <p><img src="frontend/images/thumbs-up-star.svg" alt=""> {{
                                            agent?.user_service_details?.total_reviews
                                        }} reviews</p>
                                    <p><img src="frontend/images/handshakes.svg" alt=""> {{
                                            agent?.user_service_details?.total_digital_handshakes }} Handshakes</p>
                                </div>
                            </div>
                            <router-link :to="{ name: 'AgentDetail', params: { id: agent.id } }"
                                         class="comman-btn-2 w-100">
                                <b> View Profile</b>
                            </router-link>
                        </div>
                    </div>
                </div>
                </div>
            </template>
            <template v-else>
                <LoaderCust :isLoading="loading"/>
                <div v-if="!loading" class="text-center">{{ $t('no_records_found') }}</div>
            </template>
            <div class="pagination-se" v-if="!loading && agents.length > 0">
                <nav aria-label="...">
                    <ul class="pagination justify-content-end">
                        <li class="page-item" :class="{ disabled: filters.page === 1 }"
                            @click="filters.page > 1 && changePage(filters.page - 1, false)" v-if="filters.page > 1">
                            <a class="page-link"><img src="frontend/images/left-arrow-icon.svg" alt="Previous"></a>
                        </li>
                        <li v-for="pageNumber in paginatedPages(filters.page, totalPages)" :key="pageNumber"
                            class="page-item" :class="{ active: pageNumber === filters.page }">
                            <a class="page-link" @click.prevent="pageNumber !== filters.page && changePage(pageNumber, false)">
                                {{ pageNumber }}
                            </a>
                        </li>
                        <li class="page-item" :class="{ disabled: filters.page === totalPages }"
                            @click="filters.page < totalPages && changePage(filters.page + 1, false)"
                            v-if="filters.page < totalPages">
                            <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import { CONSTANT } from "@/constants/constants";
import { formatPhoneNumber, getUserProfileImage, paginatedPages, yearsAgo } from "@/utils/helpers";
import toastr from 'toastr';
import LoaderCust from "@/components/LoaderCust.vue";

export default {
    components: {LoaderCust},
    data() {
        return {
            totalPages: 1,
            totalRecords: 1,
            services: [],
            days: [
                { key: 'availability_monday', label: 'Monday' },
                { key: 'availability_tuesday', label: 'Tuesday' },
                { key: 'availability_wednesday', label: 'Wednesday' },
                { key: 'availability_thursday', label: 'Thursday' },
                { key: 'availability_friday', label: 'Friday' },
                { key: 'availability_saturday', label: 'Saturday' },
                { key: 'availability_sunday', label: 'Sunday' }
            ],
            filters: {
                page: 1,
                sort_by_field: 'Newest',
                keyword: '',
                exp_in_yrs: '0-99',
                rating: '0-5',
                type_of_service: 0,
                availability_monday: 1,
                availability_tuesday: 1,
                availability_wednesday: 1,
                availability_thursday: 1,
                availability_friday: 1,
                availability_saturday: 1,
                availability_sunday: 1,
            },
            agents: [],
            suggestions: [],
            loading: false,

        };
    },
    computed: {
        CONSTANT() {
            return CONSTANT
        },
        ...mapState(['user']),
    },
    async created() {
        await this.fetchAgents();
        //await this.fetchLocations();
        await this.fetchServices();
    },
    methods: {
        paginatedPages,
        getUserProfileImage,
        yearsAgo,
        formatPhoneNumber,
        handleCheckboxChange(key, event) {
            this.filters[key] = event.target.checked ? 1 : 0;
        },
        //async fetchLocations() {
        //  try {
        //    const response = await this.$axios.post('/api/location', {
        //      parent_id: CONSTANT.COUNTRY_CODE,
        //      type: 'City',
        //      keyword: '',
        //    });
        //    this.locations = response.data.output;
        //  } catch (error) {
        //    console.error('Error fetching locations:', error);
        //  }
        //},
        async fetchServices() {
            try {
                const response = await this.$axios.post('/api/services-type-list', {
                    user_id: this.user.id,
                    api_token: localStorage.getItem('userToken'),
                });
                this.services = response.data.output;
            } catch (error) {
                console.error('Error fetching locations:', error);
            }
        },
        async wishlistAgent(agent) {
            try {
                const wishlistResponse = await this.$axios.post(`api/wishlist/agents/${agent.id}`, {
                    api_token: localStorage.getItem('userToken'),
                    user_id: this.user?.id,
                });

                if (wishlistResponse.status === 200) {
                    toastr.success(wishlistResponse.data.message, 'Success', {
                        progressBar: true,
                        closeButton: true,
                        timeOut: 1500,
                        onHidden: () => {
                            this.fetchAgents();
                        }
                    });
                }
            } catch (error) {
                console.error('Error wishlisting agent:', error);
            }
        },
        async fetchAgents() {
            this.loading = true;
            try {
                const agentsResponse = await this.$axios.post('/api/agent-search-browse', {
                    api_token: localStorage.getItem('userToken'),
                    user_id: this.user.id,
                    type_of_service: this.filters.type_of_service,
                    exp_in_yrs: this.filters.exp_in_yrs,
                    is_available_mon: this.filters.availability_monday,
                    is_available_tue: this.filters.availability_tuesday,
                    is_available_wed: this.filters.availability_wednesday,
                    is_available_thu: this.filters.availability_thursday,
                    is_available_fri: this.filters.availability_friday,
                    is_available_sat: this.filters.availability_saturday,
                    is_available_sun: this.filters.availability_sunday,
                    avg_ratings: this.filters.rating,
                    keyword: this.filters.keyword,
                    sort_by_field: this.filters.sort_by_field,
                    page_number: this.filters.page,
                });

                this.totalPages = agentsResponse.data.output.no_of_total_pages ?? 0;
                this.totalRecords = agentsResponse.data.output.no_of_total_records ?? 0;
                this.agents = agentsResponse.data.output.agent_info ?? [];

            } catch (error) {
                console.error('Error fetching agents:', error);
            } finally {
                this.loading = false;
            }
        },
        sortAgents(sortBy) {
            this.filters.sort_by_field = sortBy;
            this.filters.page = 1;

            this.fetchAgents();
        },
        changePage(page) {
            if (page > 0) {
                const totalPages = this.totalPages;
                if (page <= totalPages) {
                    this.filters.page = page;

                    this.fetchAgents();
                }
            }
        },
        async getAutocompleteSearch() {
            if (this.filters.keyword.length <= 3) {
                this.suggestions = [];
                return;
            }

            const token = localStorage.getItem('userToken');
            try {
                let names = [];

                const response = await this.$axios.get(`/api/agents/names`,
                {
                    params: {
                        api_token: token,
                    }
                });

                if (response.data.output) {
                    names = response.data.output;
                    this.suggestions = names.filter((item) =>
                        item.toLowerCase().includes(this.filters.keyword.toLowerCase())
                    );
                }
            } catch (error) {
                console.error('Error fetching autocomplete:', error);
            }
        },
        selectSuggestion(suggestion) {
            this.filters.keyword = suggestion;
            this.suggestions = [];
        },
    }
};
</script>

<style scoped>
    .dropdown-item:hover,
    .dropdown-item.selected {
        background-color: #ffe6b9;
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        margin-top: 20px;
    }

    /* Autocomplete dropdown styles */
    .autocomplete-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    margin: 0 0.75rem 0 0.75rem;
    list-style: none;
    }

    .autocomplete-list li {
    padding: 8px 12px;
    cursor: pointer;
    }

    .autocomplete-list li:hover {
    background-color: #f0f0f0;
    }
</style>