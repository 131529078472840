<template>
    <h4 class="mt-4">{{ $t('service_request_sent') }}</h4>
    <div class="filter-bar-se my-4">
        <div class="text-center w-25" v-if="canDownloadReport && serviceSent && serviceSent.length > 0">
            <downloadexcel
                class="comman-btn-2"
                :fetch="exportServiceSentToExcel"
                :fields="getFields('serviceSent')"
                :before-generate="startDownload"
                :before-finish="finishDownload"
            >
                {{ $t('download_excel') }}
            </downloadexcel>
        </div>
        <div class="filter-right-bar">
            <div class="dropdown">
                <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                    <img src="frontend/images/Sort-By.svg"> {{ $t('sort_by') }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Oldest' }"
                           @click.prevent="sort(true,'Oldest')">{{ $t('oldest') }}</a></li>
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Newest' }"
                           @click.prevent="sort(true,'Newest')">{{ $t('newest') }}</a></li>
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Exclusive' }"
                           @click.prevent="sort(true,'Exclusive')">{{ $t('exclusive') }}</a></li>
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Non Exclusive' }"
                           @click.prevent="sort(true,'Non Exclusive')">{{ $t('non_exclusive') }}</a></li>
                </ul>
            </div>
        </div>
    </div>
    <template v-if="serviceSent && serviceSent.length > 0">
        <div class="table-responsive custom-table-design">
            <table class="text-center table-bordered align-middle" >
                <thead>
                <tr>
                    <th>ID</th>
                    <th>{{ $t('agent_name') }}</th>
                    <th>{{ $t('property_listing') }} ID</th>
                    <th>{{ $t('property_name') }}</th>
                    <th>{{ $t('engagement_period') }} ({{ $t('month') }})</th>
                    <th>{{ $t('from') }}</th>
                    <th>{{ $t('to') }}</th>
                    <th>{{ $t('property_price') }} ({{ $t('SGD') }})</th>
                    <th>{{ $t('commission') }} (%)</th>
                    <th>{{ $t('appointment_type') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="service in serviceSent" :key="service.id">
                    <td>{{ service.id }}</td>
                    <td>{{ service?.agent?.name }}</td>
                    <td>{{ service.property_listing_id }}</td>
                    <td>{{ service?.property_listing?.property_name ?? 'N/A'}}</td>
                    <td>{{ service.engagement_period }}</td>
                    <td>{{ formatDate(service?.property_listing?.serviced_by_agents[0]?.pivot?.valid_from) ?? 'N/A' }}</td>
                    <td>{{ formatDate(service?.property_listing?.serviced_by_agents[0]?.pivot?.valid_to) ?? 'N/A' }}</td>
                    <td>{{ formatPrice(service?.property_listing?.asking_price_sale_rent) ?? 'N/A' }}</td>
                    <td>{{ service.commission }}</td>
                    <td>{{ service.appointment_type_text }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </template>
    <template v-else>
        <div class="text-center mt-4 mb-3">{{ $t('no_records_found') }}</div>
    </template>
    <div class="pagination-se mt-3" v-if="serviceSent && serviceSent.length > 0">
        <nav aria-label="...">
            <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: dataFilters.page === 1 }"
                    @click="dataFilters.page > 1 && changePage(dataFilters.page - 1)" v-if="dataFilters.page > 1">
                    <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                              alt="Previous"></a>
                </li>
                <li v-for="pageNumber in paginatedPages(dataFilters.page, totalSentPages)" :key="pageNumber"
                    class="page-item" :class="{ active: pageNumber === dataFilters.page }">
                    <a class="page-link"
                       @click.prevent="pageNumber !== dataFilters.page && changePage(pageNumber)">
                        {{ pageNumber }}
                    </a>
                </li>
                <li class="page-item" :class="{ disabled: dataFilters.page === totalSentPages }"
                    @click="dataFilters.page < totalSentPages && changePage(dataFilters.page + 1)"
                    v-if="dataFilters.page < totalSentPages">
                    <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                </li>
            </ul>
        </nav>
    </div>
    <hr>
    <h4 class="mt-4">{{ $t('service_request_received') }}</h4>
    <div class="filter-bar-se my-4">
        <div class="text-center w-25" v-if="canDownloadReport && serviceReceived && serviceReceived.length > 0">
            <downloadexcel
                class="comman-btn-2"
                :fetch="exportServiceReceivedToExcel"
                :fields="getFields('serviceReceived')"
                :before-generate="startDownload"
                :before-finish="finishDownload"
            >
                {{ $t('download_excel') }}
            </downloadexcel>
        </div>
        <div class="filter-right-bar">
            <div class="dropdown">
                <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                    <img src="frontend/images/Sort-By.svg"> {{ $t('sort_by') }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.received_sort_by_field === 'Oldest' }"
                           @click.prevent="sort(false,'Oldest')">{{ $t('oldest') }}</a></li>
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.received_sort_by_field === 'Newest' }"
                           @click.prevent="sort(false,'Newest')">{{ $t('newest') }}</a></li>
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.received_sort_by_field === 'Exclusive' }"
                           @click.prevent="sort(false,'Exclusive')">{{ $t('exclusive') }}</a></li>
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.received_sort_by_field === 'Non Exclusive' }"
                           @click.prevent="sort(false,'Non Exclusive')">{{ $t('non_exclusive') }}</a></li>
                </ul>
            </div>
        </div>
    </div>
    <template v-if="serviceReceived && serviceReceived.length > 0">
        <div class="table-responsive custom-table-design">
            <table class="text-center table-bordered align-middle" >
                <thead>
                <tr>
                    <th>ID</th>
                    <th>{{ $t('user_name') }}</th>
                    <th>{{ $t('property_listing') }} ID</th>
                    <th>{{ $t('property_name') }}</th>
                    <th>{{ $t('engagement_period') }} ({{ $t('month') }})</th>
                    <th>{{ $t('from') }}</th>
                    <th>{{ $t('to') }}</th>
                    <th>{{ $t('property_price') }} ({{ $t('SGD') }})</th>
                    <th>{{ $t('commission') }} (%)</th>
                    <th>{{ $t('appointment_type') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="service in serviceReceived" :key="service.id">
                    <td>{{ service.id }}</td>
                    <td>{{ service?.user?.name }}</td>
                    <td>{{ service.property_listing_id }}</td>
                    <td>{{ service?.property_listing?.property_name ?? 'N/A'}}</td>
                    <td>{{ service.engagement_period }}</td>
                    <td>{{ formatDate(service?.property_listing?.serviced_by_agents[0]?.pivot?.valid_from) ?? 'N/A' }}</td>
                    <td>{{ formatDate(service?.property_listing?.serviced_by_agents[0]?.pivot?.valid_to) ?? 'N/A' }}</td>
                    <td>{{ formatPrice(service?.property_listing?.asking_price_sale_rent) ?? 'N/A' }}</td>
                    <td>{{ service.commission }}</td>
                    <td>{{ service.appointment_type_text }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </template>
    <template v-else>
        <div class="text-center mt-4 mb-3">{{ $t('no_records_found') }}</div>
    </template>
    <div class="pagination-se mt-3" v-if="serviceReceived && serviceReceived.length > 0">
        <nav aria-label="...">
            <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: dataFilters.receivedPage === 1 }"
                    @click="dataFilters.receivedPage > 1 && changePage(dataFilters.receivedPage - 1)" v-if="dataFilters.receivedPage > 1">
                    <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                              alt="Previous"></a>
                </li>
                <li v-for="pageNumber in paginatedPages(dataFilters.receivedPage, totalReceivedPages)" :key="pageNumber"
                    class="page-item" :class="{ active: pageNumber === dataFilters.receivedPage }">
                    <a class="page-link"
                       @click.prevent="pageNumber !== dataFilters.receivedPage && changePage(pageNumber)">
                        {{ pageNumber }}
                    </a>
                </li>
                <li class="page-item" :class="{ disabled: dataFilters.receivedPage === totalReceivedPages }"
                    @click="dataFilters.receivedPage < totalReceivedPages && changePage(dataFilters.receivedPage + 1)"
                    v-if="dataFilters.receivedPage < totalReceivedPages">
                    <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import downloadexcel from "vue-json-excel3";
import {mapState} from "vuex";
import {paginatedPages,formatPrice,formatDate} from "@/utils/helpers";
import {getJsonFields} from "@/utils/jsonFieldsConfig";
import toastr from "toastr";

export default {
    name: 'ServiceRequestReport',
    components: {
        downloadexcel,
    },
    computed: {
        ...mapState(['isAuthenticated', 'user', 'userProfile']),
        canDownloadReport() {
            return (this.user.is_corporate && this.user?.package?.title == 'Developer');
        },
    },
    created() {
        this.fetchServiceDetail(true);
        this.fetchServiceDetail(false);
    },
    data() {
        return {
            serviceReceived: [],
            serviceSent: [],
            totalSentPages: 0,
            totalSentRecords: 0,
            totalReceivedPages: 0,
            totalReceivedRecords: 0,
            dataFilters: {
                page: 1,
                sort_by_field: 'Newest',
                receivedPage: 1,
                received_sort_by_field: 'Newest',
            },
        }
    },
    methods: {
        formatPrice,
        formatDate,
        //Excel related functions
        getFields(type) {
            return getJsonFields(type);
        },
        startDownload() {
            toastr.info('Preparing the download...');
        },
        finishDownload() {
            toastr.success('Download completed!');
        },
        async exportServiceSentToExcel() {
            return this.serviceSent;
        },
        async exportServiceReceivedToExcel() {
            return this.serviceReceived;
        },

        //Fetching Data
        async fetchServiceDetail(isSent) {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/get-service-request-details',{
                    api_token: token,
                    user_id: this.user.id,
                    is_sent: isSent,
                    sort_by_field: isSent ? this.dataFilters.sort_by_field : this.dataFilters.received_sort_by_field,
                    page_number: isSent ? this.dataFilters.page : this.dataFilters.receivedPage,
                });

                if (isSent) {
                    this.serviceSent = response.data.output.service_requests.data;
                    this.totalSentPages = response.data.output.total_pages;
                    this.totalSentRecords = response.data.output.total_records;
                } else {
                    this.serviceReceived = response.data.output.service_requests.data;
                    this.totalReceivedPages = response.data.output.total_pages;
                    this.totalReceivedRecords = response.data.output.total_records;
                }
            } catch (error) {
                toastr.error('Unable to fetch service request data, error:', error.message);
            }
        },

        //Sort and Paginate functions
        paginatedPages,
        sort(isSent, sortBy) {
            if (isSent) {
                this.dataFilters.sort_by_field = sortBy;
                this.dataFilters.page = 1;
            } else {
                this.dataFilters.received_sort_by_field = sortBy;
                this.dataFilters.receivedPage = 1;
            }

            this.fetchServiceDetail(isSent);
        },
        changePage(page, isSent) {
            if (page > 0) {
                const totalPages = isSent ? this.totalSentPages : this.totalReceivedPages;
                if (page <= totalPages) {
                    if (isSent) {
                        this.dataFilters.page = page;
                    } else {
                        this.dataFilters.receivedPage = page;
                    }
                    this.fetchServiceDetail(isSent);
                }
            }
        },
    }
}

</script>


