<template>
    
    <header class="main-header">			
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container-fluid">
                <a class="navbar-brand" href="/"><img alt="Propverse" src="frontend/images/logo.png"></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {{ $t('buy') }}
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <!-- <li><a class="dropdown-item" href="/developers">{{ $t('find_a_developer') }}</a></li> -->
                                <li><a class="dropdown-item" href="/agents">{{ $t('get_an_agent') }}</a></li>
                                <li><a class="dropdown-item" href="/explore-more">{{ $t('diy') }}</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {{ $t('rent') }}
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <!-- <li><a class="dropdown-item" href="/developers">{{ $t('find_a_developer') }}</a></li> -->
                                <li><a class="dropdown-item" href="/agents">{{ $t('get_an_agent') }}</a></li>
                                <li><a class="dropdown-item" @click="browseRentProperty()">{{ $t('diy') }} - {{ $t('renter_tenant') }}</a></li>
                                <li><a class="dropdown-item" href="/post-property-listing">{{ $t('diy') }} - {{ $t('owner_landlord') }}</a></li>								
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {{ $t('sell') }}
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <!-- <li><a class="dropdown-item" href="/developers">{{ $t('find_a_developer') }}</a></li> -->
                                <li><a class="dropdown-item" href="/agents">{{ $t('get_an_agent') }}</a></li>
                                <li><a class="dropdown-item" href="/post-property-listing">{{ $t('diy') }}</a></li>								
                            </ul>
                        </li>
                    </ul>

                    <div class="list-property-btn ms-md-auto"  v-if="isAuthenticated">
                       <a href="/wishlist" class="fav-header-ico me-lg-3">
                           <img src="frontend/images/heart-ico.svg">
                       </a>
                        <div class="dropdown me-lg-3">
                            <a class="fav-header-ico d-flex align-content-center justify-content-center" href="javascript:;" id="notiDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-bell-o text-dark" style="font-size: 18px;"></i>
                                <span v-if="num_of_notification > 0" class="badge bg-danger position-absolute top-0 start-100 translate-middle">
                                    {{ num_of_notification }}
                                </span>
                            </a>
                            <ul class="dropdown-menu overflow-scroll" aria-labelledby="notiDropdown" style="width: 500px; max-height: 500px;">
                                <div class="text-end my-1"><a class="py-1" @click="markAllAsRead">{{ $t('mark_all_as_read') }}</a></div>
                                <li v-if="notifications && notifications.length === 0" class="dropdown-item">{{ $t('no_new_notifications') }}</li>
                                <li v-for="notification in notifications"
                                    :key="notification.id"
                                    @click="markAsRead(notification)"
                                    class="dropdown-item d-flex align-items-center border-bottom justify-content-between"
                                    :class="{ 'bg-light': notification.read_status == 0 }">

                                    <!-- Unread Indicator (Only visible if read_status == 0) -->
                                    <div class="d-flex align-items-center me-2">
                                        <!-- Mail Icon -->
                                        <div><i class="fa fa-envelope me-2 text-primary"></i></div>
                                        <!-- Notification Content -->
                                        <div>
                                            <span class="text-break" style="cursor: pointer;">{{ notification.content }}</span><br>
                                            <a class="ml-3" :href="getLink(notification.content)" v-if="getLink(notification.content)" style="cursor: pointer;">View</a>
                                        </div>
                                    </div>
                                    <span v-if="notification.read_status == 0" class="me-2">
                                        <i class="fa fa-circle text-danger"></i>
                                    </span>
                                </li>
                            </ul>
                        </div>
                       <LanguageLocaleDropdown/>
                       <div class="dropdown me-lg-3">
                            <a class="comman-btn-1" href="javascript:;" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img :src="getAvatarImageUrl(userProfile?.profile_photo_name)">  {{ user.name }} 
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown" >
                                <li><a class="dropdown-item" href="/profile">{{ $t('my_profile') }}</a></li>
                                <li v-if="!user.is_mobile_verified"><router-link class="dropdown-item" :to="{ name: 'verifyNumber' }" @click.prevent="sendOtpCode">{{ $t('verify_number') }}</router-link></li>
                                <li><router-link class="dropdown-item" :to="{ name: 'Packages' }">{{ $t('purchase_package') }}</router-link></li>
                                <li><a class="dropdown-item" href="/my-property">{{ $t('my_property') }}</a></li>
                                <li><a class="dropdown-item" href="/my-project" v-if="canCreateProject">{{ $t('my_project') }}</a></li>
                                <li><a class="dropdown-item text-danger" href="/" @click="logout">{{ $t('logout') }}</a></li>
                            </ul>
                        </div>
                     
                        <div class="dropdown me-lg-3" v-if="user.is_mobile_verified">
                            <a href="javascript:;" class="comman-btn-2" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{ $t('add_listing') }}</a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" href="/post-property-listing">{{ $t('post_property') }}</a></li>
                                <li><a class="dropdown-item" href="/post-project-listing" v-if="canCreateProject">{{ $t('post_project') }}</a></li>
                            </ul>
                        </div>
                        <div v-else>
                            <router-link class="dropdown-item comman-btn-2" :to="{ name: 'verifyNumber' }" @click.prevent="sendOtpCode">{{ $t('add_listing') }}</router-link>
                        </div>
                        
                    </div>
                    <div class="list-property-btn ms-md-auto" v-else>
                        <a href="#" class="fav-header-ico me-md-3">
                            <img src="frontend/images/heart-ico.svg">
                        </a>
                        <LanguageLocaleDropdown/>
                        <a href="/sign-in" class="comman-btn-1 me-md-3">{{ $t('login') }}</a>
                      <div class="dropdown">
                          <a href="/sign-up" class="comman-btn-2 btn btn-primary dropdown-toggle" id="signUpButton" role="button" aria-expanded="false">{{ $t('sign_up') }}!</a>
                        <ul class="dropdown-menu" aria-labelledby="signUpButton">
                            <li>
                              <a class="dropdown-item" href="/sign-up">{{ $t('as_private_user') }}</a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/sign-up-business">{{ $t('as_estate_agent') }}</a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/sign-up-corporate">{{ $t('as_agency_developer') }}</a>
                            </li>
                        </ul>
                      </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>
  <script>
  import toastr from "toastr";
  import { CONSTANT } from '@/constants/constants';
  import { mapState } from 'vuex';
  import LanguageLocaleDropdown from './LanguageLocaleDropdown.vue';
  export default {
    name: 'HeaderPart',
    components: { LanguageLocaleDropdown },
    computed: {
        ...mapState(['isAuthenticated', 'user', 'userProfile']),
        canCreateProject() {
            return this.user.is_corporate;
        }
    },
    data() {
       return {
           activeTab: 'Rent',
           notifications: [],
           num_of_notification: '',
       }
    },
      mounted() {
        this.fetchNumOfNotications();
        this.fetchNotifications();
      },
    methods: {
        ...mapState(['logout']),
        browseRentProperty() {
            let data = { activeTab: 'Rent' };

            this.$router.replace({ name: 'ExploreMore', query: { formData: JSON.stringify(data) }})
        },
        extractId(content) {
            const matches = content.match(/\((\d+)\)/g); // Find all occurrences of (number)
            if (matches) {
                const lastMatch = matches[matches.length - 1]; // Get the last match
                return lastMatch.replace(/\(|\)/g, ''); // Remove parentheses and return the ID
            }
            return null; // Return null if no match is found
        },
        getLink(content) {
            let link = null;
            let lastId = this.extractId(content);

            if (content.includes('deleted')) {
                return link;
            } else if (content.includes('credit points')) {
                link = '/credits-history';
            } else if (content.includes('package')) {
                link = '/packages';
            } else if (content.includes('Service Request')) {
                link = '/requested-service'
            }

            if (!link && !lastId) {
                return link;
            }

            if (content.includes('Property')) {
                link = '/property-detail/' + lastId;
            } else if (content.includes('Project')) {
                link = '/project-detail/' + lastId;
            }

            return link;
        },
        async fetchNumOfNotications() {
            if (!this.isAuthenticated) {
                return;
            }
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/notifications/count', {
                    api_token : token,
                });
                this.num_of_notification = response.data.count;
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        },
        async markAllAsRead() {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post(`/api/users/${this.user.id}/notifications/markAllAsRead`, {
                    api_token: token
                });

                if (response.data.status === 1) {
                    await this.fetchNotifications();
                    await this.fetchNumOfNotications();
                }
            } catch (error) {
                console.error("Error marking all notifications as read:", error);
            }
        },
        async markAsRead(notification) {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post(`/api/notifications/${notification.id}/markAsRead`, {
                    api_token: token
                });

                if (response.data.status === 1) {
                    await this.fetchNotifications();
                    await this.fetchNumOfNotications();
                }
            } catch (error) {
                console.error("Error marking notification as read:", error);
            }
        },
        async fetchNotifications() {
            if (!this.isAuthenticated) {
                return;
            }
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/notifications', {
                   api_token : token,
                });
                this.notifications = response.data;
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        },
        getAvatarImageUrl(path) {
            return path ? `${CONSTANT.IMAGE_URL}/imagesProfile/${path}` : CONSTANT.DEFAULT_IMAGE;
        },
        async logout() {
            try {
                await this.$store.dispatch('logout'); // Call Vuex logout action
                localStorage.clear();    // Clear all localStorage data
            } catch (error) {
                console.error('Logout failed:', error);
            }
        },
        handleImageError(event) {
            event.target.src = this.defaultImageSrc; // Set default image on error
        },
        async sendOtpCode() {
            try {
                await this.$axios.post('api/code/send', {
                    mobile_number: this.user.mobile_number,
                });
                // You can show a success message here if needed
                toastr.success('Verification code sent successfully!', 'Success');
            } catch (error) {
                console.error('Error sending code:', error);
                // Handle error (e.g., show an error message)
            }
        },
        async sendEmailCode() {
            try {
                await this.$axios.post(`api/send-verification-email/${this.user.id}`, {
                    email: this.user.email,
                });
                toastr.success('Verification link sent successfully!', 'Success');
                // Handle success (e.g., show a success message or start a countdown timer)
            } catch (error) {
                toastr.error('Verification link failed', 'Failed');
                console.error('Error sending verification link:', error);
                // Handle error (e.g., show an error message)
            }
        },
    }
  }
  </script>

<style>
    .dropdown:hover .dropdown-menu {
      display: block;
    }

    .dropdown .dropdown-menu {
      right: 0;
      left: auto;
    }

    .text-break {
        word-wrap: break-word;
        white-space: normal;
    }
</style>

  