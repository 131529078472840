<template>
  <div class="m-5">
    <section class="custom-listing">
      <div class="container">
        <div class="filter-bar-se">
          <div class="comman-title mb-0">
            <h3>Most Viewed</h3>
          </div>
          <div class="filter-right-bar">
            <div class="dropdown">
              <a href="/filter" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                <img src="frontend/images/filter-ico.svg"> {{ $t('sort_by') }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                <li><a class="dropdown-item" href="/newest" @click.prevent="sortProperties('Newest')">{{ $t('newest') }}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>


export default {
  name: 'MostViewed',
  // components: {PropertyBox},

};
</script>

<style>

</style>