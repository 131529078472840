<template>
    <section class="listing-se">
        <div class="container">
            <div class="filter-bar-se mb-4">
                <div class="comman-title mb-0">
                    <h3 class="mb-2">{{ $t('my_property_history') }}</h3>
                </div>
            </div>
            <div class="top-tabs-design">
                <ul>
                    <li><a href="/my-property">{{ $t('my_active_property') }}</a></li>
                    <li><a href="javascript:;" class="active">{{ $t('property_history') }}</a></li>
                </ul>
            </div>
            <LoaderCust :isLoading="isLoading" />
            <h5 class="mt-5">Your Sold Listings</h5>
            <div v-if="!isLoading && !soldListings.length" class="alert alert-warning">No Sold Listing Found.</div>
            <div class="row row-cols-lg-3 row-cols-sm-2">
                <div class="col" v-for="item in soldListings" :key="item.id">
                    <router-link :to="`/property-detail/${item.id}`">
                        <div class="project-bx">
                            <div class="edit-pro d-flex justify-content-between">
                                <a type="button" data-bs-toggle="modal" data-bs-target="#deleteProperty" @click="setSelectedProperty(item)">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </div>
                            <picture v-if="item.actual_unit_photo && item.actual_unit_photo.length > 0">
                                <img :src="getImageUrl(item.actual_unit_photo[0].photo_name)" @error="handleImageError" alt="Property Image" />
                            </picture>
                            <picture v-else>
                                <img :src="CONSTANT.DEFAULT_NO_IMAGE"  @error="handleImageError" alt="Property Image" />
                            </picture>
                            <div class="project-info">
                                <div class="d-md-flex mb-2 align-items-center">
                                    <h3><a href="">{{ item.property_name }}</a></h3>
                                    <div class="price-pro ms-md-auto">
                                        <span><small>s$</small> {{item.asking_price_sale_rent}}</span>
                                    </div>
                                </div>
                                <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{ item.street }},{{ item.area_hdb_estate }},{{ item.district }}</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>{{item.no_of_rooms}}</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>{{item.no_car_park_lots}}</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>{{item.land_area}} {{item.land_area_unit}}</span>
                                </div>
                                <div class="pro-tag">
                                    <a v-if="item.property_type_details.parent_id_top_level_1_name">{{
                                            item.property_type_details.parent_id_top_level_1_name }}</a>
                                    <a v-if="item.property_type_details.code">{{
                                            item.property_type_details.code }}</a>
                                    <a v-if="item.land_title_type">{{ item.land_title_type }}</a>
                                </div>
                                <ul>
                                    <li v-if="item.is_direct_owner">
                                        <figure>
                                            <img src="frontend/images/specify-ico1.png">
                                        </figure>
                                        <small>{{ $t('direct') }}<br>{{ $t('owner') }}</small>
                                    </li>
                                    <li  v-if="item.is_sing_pass || item.is_corp_pass ">
                                        <figure>
                                            <img src="frontend/images/specify-ico2.png">
                                        </figure>
                                        <small>{{ $t('singpass') }}<br>{{ $t('verified') }} </small>
                                    </li>
                                    <li v-if="item.is_document_approved">
                                        <figure>
                                            <img src="frontend/images/specify-ico3.png">
                                        </figure>
                                        <small>{{ $t('documents') }}<br>{{ $t('verified') }}</small>
                                    </li>
                                    <!--<li v-if="item.is_FSM_linked">
                                        <figure>
                                            <img src="frontend/images/specify-ico4.png">
                                        </figure>
                                        <small>Fengshui<br>Map</small>
                                    </li>
                                    <li v-if="item.is_assigned_to_the_agent">
                                        <figure>
                                            <img src="frontend/images/specify-ico5.png">
                                        </figure>
                                        <small>{{ $t('hybrid_listing') }}<br>Owners </small>
                                    </li>-->
                                    <li v-if="item.is_below_mean_value">
                                        <figure>
                                            <img src="frontend/images/Down-arrow.svg">
                                        </figure>
                                        <small>BMV</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <h5 class="mt-5">Your Rented Property</h5>
            <div v-if="!isLoading && !rentedListings.length" class="alert alert-warning">No Rented Listing Found.</div>
            <div class="row row-cols-lg-3 row-cols-sm-2">
                <div class="col" v-for="item in rentedListings" :key="item.id">
                    <router-link :to="`/property-detail/${item.id}`">
                        <div class="project-bx">
                            <div class="edit-pro d-flex justify-content-between">
                                <a type="button" data-bs-toggle="modal" data-bs-target="#deleteProperty" @click="setSelectedProperty(item)">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </div>
                            <picture v-if="item.actual_unit_photo && item.actual_unit_photo.length > 0">
                                <img :src="getImageUrl(item.actual_unit_photo[0].photo_name)" @error="handleImageError" alt="Property Image" />
                            </picture>
                            <picture v-else>
                                <img :src="CONSTANT.DEFAULT_NO_IMAGE"  @error="handleImageError" alt="Property Image" />
                            </picture>
                            <div class="project-info">
                                <div class="d-md-flex mb-2 align-items-center">
                                    <h3><a href="">{{ item.property_name }}</a></h3>
                                    <div class="price-pro ms-md-auto">
                                        <span><small>s$</small> {{item.asking_price_sale_rent}}</span>
                                    </div>
                                </div>
                                <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{ item.street }},{{ item.area_hdb_estate }},{{ item.district }}</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>{{item.no_of_rooms}}</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>{{item.no_car_park_lots}}</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>{{item.land_area}} {{item.land_area_unit}}</span>
                                </div>
                                <div class="pro-tag">
                                    <a v-if="item.property_type_details.parent_id_top_level_1_name">{{
                                            item.property_type_details.parent_id_top_level_1_name }}</a>
                                    <a v-if="item.property_type_details.code">{{
                                            item.property_type_details.code }}</a>
                                    <a v-if="item.land_title_type">{{ item.land_title_type }}</a>
                                </div>
                                <ul>
                                    <li v-if="item.is_direct_owner">
                                        <figure>
                                            <img src="frontend/images/specify-ico1.png">
                                        </figure>
                                        <small>{{ $t('direct') }}<br>{{ $t('owner') }}</small>
                                    </li>
                                    <li  v-if="item.is_sing_pass || item.is_corp_pass ">
                                        <figure>
                                            <img src="frontend/images/specify-ico2.png">
                                        </figure>
                                        <small>{{ $t('singpass') }}<br>{{ $t('verified') }} </small>
                                    </li>
                                    <li v-if="item.is_document_approved">
                                        <figure>
                                            <img src="frontend/images/specify-ico3.png">
                                        </figure>
                                        <small>{{ $t('documents') }}<br>{{ $t('verified') }}</small>
                                    </li>
                                    <!--<li v-if="item.is_FSM_linked">
                                        <figure>
                                            <img src="frontend/images/specify-ico4.png">
                                        </figure>
                                        <small>Fengshui<br>Map</small>
                                    </li>
                                    <li v-if="item.is_assigned_to_the_agent">
                                        <figure>
                                            <img src="frontend/images/specify-ico5.png">
                                        </figure>
                                        <small>{{ $t('hybrid_listing') }}<br>Owners </small>
                                    </li>-->
                                    <li v-if="item.is_below_mean_value">
                                        <figure>
                                            <img src="frontend/images/Down-arrow.svg">
                                        </figure>
                                        <small>BMV</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </router-link>
                    <div class="d-flex justify-content-between mt-2">
                        <div class="d-flex flex-grow-1">
                            <button class="comman-btn-2 flex-fill mx-1" data-bs-toggle="modal" data-bs-target="#renewProperty" @click="setSelectedProperty(item)">
                                {{ $t('republish_property') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="renew-property-modal">
            <div class="modal fade" id="renewProperty" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div v-if="userCredit < 10">
                            <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">{{ $t('insufficient_credits') }}</h5>
                            </div>
                            <div class="modal-body">
                                <b>{{ $t('republishing_property_requires_10_credits') }}</b><br>
                                {{ $t('your_current') }} {{ $t('credit') }}: <b>{{ userCredit }}</b>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">{{ $t('close') }}</button>
                                <a class="comman-btn-2" href="/top-up" onclick="$('#renewProperty').modal('hide')">
                                    <img src="/frontend/images/Choose-plan.svg" alt="topUp">
                                    {{ $t('top_up') }}
                                </a>
                            </div>
                        </div>
                        <div v-else>
                            <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">{{ $t('republish_property') }}</h5>
                            </div>
                            <div class="modal-body">
                                {{ $t('clicking_confirm_will_request_to_republish_this_current_listing') }}. <br>
                                {{ $t('this_action_will_cost_you_10_credits') }}. <br>
                                {{ $t('your_current') }} {{ $t('credit') }}: <b>{{ userCredit }}</b>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">{{ $t('close') }}</button>
                                <button type="button" class="comman-btn-2"  @click="renewProperty()" data-bs-dismiss="modal">{{ $t('confirm') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    import {mapState} from "vuex";
    import LoaderCust from "@/components/LoaderCust.vue";
    import {CONSTANT} from "@/constants/constants";
    import toastr from "toastr";

    export default {
        name: 'PropertyHistory',
        components: {
            LoaderCust
        },
        computed: {
            ...mapState(['isAuthenticated', 'user']),
        },
        data() {
            return {
                CONSTANT,
                userCredit: null,
                isLoading: true,
                rentedListings: [],
                soldListings: [],
                selectedProperty: null,
                selectedPropertyId: null,
            }
        },
        created() {
            this.fetchPastProperty();
        },
        methods: {
            setSelectedProperty(property) {
                if (!property) {
                    toastr.error('No request provided');
                    return;
                }
                this.selectedProperty = property;
                this.selectedPropertyId = property.id
            },
            getImageUrl(photoName) {
                return photoName ? `${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${photoName}` : CONSTANT.DEFAULT_IMAGE;
            },
            async fetchPastProperty() {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.post('api/fetch-past-property',{
                        api_token: token,
                        user_id: this.user.id
                    })
                    this.rentedListings = response.data.output.rentProperties;
                    this.soldListings = response.data.output.soldProperties;
                    this.userCredit = response.data.output.userCredit;

                    this.isLoading = false;
                } catch(error) {
                    console.error('Error Message:', error);
                }
            },
            async renewProperty() {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.post('api/renew-property-listing', {
                        api_token : token,
                        property_listing_id : this.selectedPropertyId,
                    });

                    if (response.data.status === 1) {
                        toastr.success('Republish Successful!');

                        this.isLoading = true;
                        await this.fetchPastProperty();
                    } else {
                        toastr.error('Republish Fail!')
                    }
                } catch (error) {
                    console.error('Error Renew Property:', error);
                }
            }
        },
    }
</script>



