<template>
    <div class="d-flex justify-content-center align-items-center vh-100">
        <div class="w-25">
        <form>
            <input type="hidden" v-model="token"/>
            <label class="my-3 d-flex justify-content-center">
                <h2 class="text-warning">Enter Your Informations</h2>
            </label>
            <div>
                <label for="email"><h6>{{ $t('email') }}</h6></label>
                <input id="email" v-model="email" type="email" class="form-control" required autofocus autocomplete="username"/>
            </div>

            <div class="mt-4">
                <label for="password"><h6>Password</h6></label>
                <input id="password" v-model="password" type="password" class="form-control" required autocomplete="new-password"/>
            </div>

            <div class="mt-4">
                <label for="password_confirmation"><h6>Confirm Password</h6></label>
                <input id="password_confirmation" v-model="passwordConfirmation" type="password" class="form-control" required autocomplete="new-password"/>
            </div>

            <div class="mt-4 d-flex justify-content-center">
                <button type="button" class="comman-btn-2" @click="submitForm">
                    Reset Password
                </button>
            </div>
        </form>
    </div>
    </div>
</template>

<script>
import toastr from 'toastr';
export default {
    name: 'ResetPassword',
    data() {
        return {
            email: '',
            password: '',
            passwordConfirmation: '',
            token: this.$route.params.token,
        };
    },
    methods: {
        async submitForm() {
            try {
                const response = await this.$axios.post('api/reset-password', {
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.passwordConfirmation,
                    token: this.token,
                });

                if (response.status === 200) {
                    toastr.success('Password reset successfully.');

                    this.$router.push('/');
                }
            } catch (error) {
                toastr.error('An error occurred:', error);
            }
        }

    },
};
</script>

<style scoped>

</style>
