<template>
    <section class="hero-banner-sec">
        <picture>
            <img src="frontend/images/banner.jpg">
        </picture>
        <div class="container">
            <div class="banner-outer">
                <div class="row">``
                    <div class="col-md-8">
                        <div class="caption-heading">
                            <div style="display: inline-block; position: relative;">
                                <h1>
                                  PROPFACE<br>{{ $t("your_place") }}, {{ $t("your_space") }} !
                                </h1>
                                <h1 style="text-align: right; font-size: 25px;">{{ $t("beyond_property_listings") }}</h1>
                            </div>
                              
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" :class="{ active: activeTab === 'Buy' }" id="Buy-tab"
                                        data-bs-toggle="tab" data-bs-target="#Buy" type="button" role="tab"
                                        aria-controls="Buy" aria-selected="true"
                                        @click="setActiveTab('Buy')">{{ $t("buy") }}</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" :class="{ active: activeTab === 'Rent' }" id="Rent-tab"
                                        data-bs-toggle="tab" data-bs-target="#Rent" type="button" role="tab"
                                        aria-controls="Rent" aria-selected="false"
                                        @click="setActiveTab('Rent')">{{ $t("rent") }}</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" role="tabpanel"
                                    aria-labelledby="Buy-tab">
                                    <PropertySearch :type="activeTab"></PropertySearch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="browse-property-sec">
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-lg-9">
                    <div class="property-slider-outer">
                        <div class="comman-title">
                            <h3>{{ $t("browse_by_property_type") }}</h3>
                        </div>
                        <LoaderCust :isLoading="loading"/>
                        <div v-if="!loading" class="pro-type-carousel owl-carousel owl-theme h-100">
                            <div class="item" v-for="(property, index) in response.property_type_details" :key="index">
                                <div class="card bpt-box" @click="browseProperty(property)" style="cursor: pointer;">
                                    <div class="card-body">
                                        <picture>
                                            <img src="frontend/images/property-ico1.png" class="card-img-top"
                                                 alt="Property Icon">
                                        </picture>
                                        <h3 class="card-title mb-0">{{ property.name }}</h3>
                                    </div>
                                    <div class="card-footer border border-0">
                                        {{ property.total }} {{ $t("properties") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3">
                    <div class="own-property-bx">
                        <h3>{{ $t("create_own_listing") }}!</h3>
                        <a :href="'/widget-property/create/' + user.id " class="comman-btn-2"> {{ $t("start_free") }} <i
                                class="ms-2 align-middle d-inline-block"><img
                                    src="frontend/images/start-ico.svg"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="desc-info-box" v-html="$t('homepage_custom_description_1')"></div>
        </div>
    </section>

    <section class="property-features-sec d-sm-none d-md-block">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 col-lg-7">
                    <div class="feature-tabslist">
                        <ul class="nav nav-tabs" id="myTab-2" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="Hybrid-tab" data-bs-toggle="tab" data-bs-target="#Hybrid"
                                    type="button" role="tab" aria-controls="Hybrid" aria-selected="true">
                                    <small>{{ $t("1st_in_the_market") }}!</small>
                                    <span>{{ $t("hybrid_listings") }}</span>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="Crowd-tab" data-bs-toggle="tab"
                                    data-bs-target="#Crowd" type="button" role="tab" aria-controls="Crowd"
                                    aria-selected="false">
                                    <small>{{ $t("community_scoring") }}!</small>
                                    <span>{{ $t("crowd_source") }}</span>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="ai-tab" data-bs-toggle="tab" data-bs-target="#ai"
                                    type="button" role="tab" aria-controls="ai" aria-selected="false">
                                    <small>{{ $t("accuracy_comparison") }}!</small>
                                    <span>{{ $t("ai_database") }}</span>
                                </button>
                            </li>
                            <li class="nav-item w-100-lg" role="presentation">
                                <button class="nav-link" id="Feng-tab" data-bs-toggle="tab" data-bs-target="#Feng"
                                    type="button" role="tab" aria-controls="Feng" aria-selected="false">
                                    <small>{{ $t("bonus_feature") }}!</small>
                                    <span>{{ $t("fengshui_mapping") }}</span>
                                </button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTab-2Content">
                            <div class="tab-pane " id="Hybrid" role="tabpanel" aria-labelledby="Hybrid-tab">
                                <div class="comman-title">
                                    <h4>{{ $t("hybrid_listings") }}</h4>
                                </div>
                                <div class="feature-list-bx" v-html="$t('homepage_hybrid_listings_description_1')"></div>
                            </div>

                            <div class="tab-pane fade show active" id="Crowd" role="tabpanel"
                                aria-labelledby="Crowd-tab">
                                <div class="comman-title">
                                    <h4>{{ $t("crowd_source") }}</h4>
                                </div>
                                <div class="feature-list-bx" v-html="$t('homepage_crowd_source_description_1')"></div>
                            </div>

                            <div class="tab-pane" id="ai" role="tabpanel" aria-labelledby="ai-tab">
                                <div class="comman-title">
                                    <h4>{{ $t("ai_database") }}</h4>
                                </div>
                                <div class="feature-list-bx" v-html="$t('homepage_ai_database_description_1')"></div>
                            </div>

                            <div class="tab-pane" id="Feng" role="tabpanel" aria-labelledby="Feng-tab">
                                <div class="comman-title">
                                    <h4>{{ $t("fengshui_mapping") }}</h4>
                                </div>
                                <div class="feature-list-bx" v-html="$t('homepage_fengshui_mapping_description_1')"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-5 d-md-flex align-items-center">
                    <div class="fea-pic">
                        <figure>
                            <img src="frontend/images/feature-img.jpg">
                        </figure>
                        <div class="position-absolute bottom-0 start-0 p-3 bg-none bg-opacity-75 w-100 text-center">
                            <div v-html="$t('homepage_fea_pic_description')"></div>
                        </div>
                        <div class="mark-logo">
                            <img src="frontend/images/logo.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--enable after project pages are done-->
    <section class="offer-pro-sec d-sm-none d-md-block" v-if="response.developer_projects && response.developer_projects.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">
                    <h3>{{ $t(getSectionName('developer_projects')) }}</h3>
                </div>
                <div class="readmore ms-md-auto">
                    <a @click.prevent="redirectToExploreMore('developer_projects')">
                        {{ $t("explore_more") }}
                        <i><img src="frontend/images/rgt-arrow.svg" alt="Right arrow" /></i>
                    </a>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                <div class="col" v-for="item in response.developer_projects.slice(0,4)" :key="item.id">
                    <ProjectBox :item="item"></ProjectBox>
                </div>
            </div>
        </div>
    </section>

    <section class="recnet-list-sec d-sm-none d-md-block" v-if="response.latest_properties && response.latest_properties.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">
                    <h3>{{ $t(getSectionName('latest_properties')) }}</h3>
                </div>
                <div class="readmore ms-md-auto">
                    <a @click.prevent="redirectToExploreMore('latest_properties')">
                        {{ $t("explore_more") }}
                        <i><img src="frontend/images/rgt-arrow.svg" alt="Right arrow" /></i>
                    </a>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                <div class="col" v-for="item in response.latest_properties.slice(0,4)" :key="item.id">
                    <PropertyBox :item="item"></PropertyBox>
                </div>
            </div>
        </div>
    </section>

    <section class="offer-pro-sec d-sm-none d-md-block" v-if="response.featured_properties && response.featured_properties.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">
                    <h3>{{ $t(getSectionName('featured_properties')) }}</h3>
                </div>
                <div class="readmore ms-md-auto">
                    <a @click.prevent="redirectToExploreMore('featured_properties')">
                        {{ $t("explore_more") }}
                        <i><img src="frontend/images/rgt-arrow.svg" alt="Right arrow" /></i>
                    </a>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                <div class="col" v-for="item in response.featured_properties.slice(0,4)" :key="item.id">
                    <PropertyBox :item="item"></PropertyBox>
                </div>
            </div>
        </div>
    </section>

    <section class="recnet-list-sec d-sm-none d-md-block" v-if="response.fsm_properties && response.fsm_properties.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">
                    <h3>{{ $t(getSectionName('fsm_properties')) }}</h3>
                </div>
                <div class="readmore ms-md-auto">
                    <a @click.prevent="redirectToExploreMore('fsm_properties')">
                        {{ $t("explore_more") }}
                        <i><img src="frontend/images/rgt-arrow.svg" alt="Right arrow" /></i>
                    </a>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                <div class="col" v-for="item in response.fsm_properties.slice(0,4)" :key="item.id">
                    <PropertyBox :item="item"></PropertyBox>
                </div>
            </div>
        </div>
    </section>

    <section class="offer-pro-sec d-sm-none d-md-block" v-if="response.classified_properties && response.classified_properties.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">
                    <h3>{{ $t(getSectionName('classified_properties')) }}</h3>
                </div>
                <div class="readmore ms-md-auto">
                    <a @click.prevent="redirectToExploreMore('classified_properties')">
                        {{ $t("explore_more") }}
                        <i><img src="frontend/images/rgt-arrow.svg" alt="Right arrow" /></i>
                    </a>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                <div class="col" v-for="item in response.classified_properties.slice(0,4)" :key="item.id">
                    <PropertyBox :item="item"></PropertyBox>
                </div>
            </div>
        </div>
    </section>

    <section class="recnet-list-sec d-sm-none d-md-block" v-if="response.curated_properties && response.curated_properties.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">
                    <h3>{{ $t(getSectionName('curated_properties')) }}</h3>
                </div>
                <div class="readmore ms-md-auto">
                    <a @click.prevent="redirectToExploreMore('curated_properties')">
                        {{ $t("explore_more") }}
                        <i><img src="frontend/images/rgt-arrow.svg" alt="Right arrow" /></i>
                    </a>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                <div class="col" v-for="item in response.curated_properties.slice(0,4)" :key="item.id">
                    <PropertyBox :item="item"></PropertyBox>
                </div>
            </div>
        </div>
    </section>

<!--    <section class="explore-loc-sec" v-if="response.locations_details && response.locations_details.length > 0">-->
<!--        <LoaderCust :isLoading="loading"/>-->
<!--        <div class="container" v-if="!loading">-->
<!--            <div class="row d-md-flex align-items-center">-->
<!--                <div class="col-md-12 col-lg-5">-->
<!--                    <div class="comman-title mb-0">-->
<!--                        <h3>Explore Property Listings by Location</h3>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="row row-cols-lg-3 row-cols-md-2  row-cols-d-md-flex align-items-stretch">-->
<!--                <div v-for="(location, index) in response.locations_details" :key="index">-->
<!--                    <div class="location-exp-bx" @click="browsePropertyByLocation(location?.country_id)" style="cursor: pointer;">-->
<!--                        <div class="mark-logo top-lft">-->
<!--                            <img src="frontend/images/logo.png">-->
<!--                        </div>-->
<!--                        <picture>-->
<!--                            <img :src="getImageUrl(location?.country?.image)" />-->
<!--                        </picture>-->
<!--                        <h5>{{ location?.country?.name }}</h5>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->

    <section class="why-sec">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-7">
                    <div class="why-ser-bx">
                        <div class="comman-title">
                            <h3>{{ $t('why') }} PROPverse?</h3>
                        </div>
                        <ul>
                            <li>
                                <figure>
                                    <img src="frontend/images/why-ico1.svg">
                                </figure>
                                <div v-html="$t('homepage_why_us_description_1')"></div>
                            </li>
                            <li>
                                <figure>
                                    <img src="frontend/images/why-ico2.svg">
                                </figure>
                                <div v-html="$t('homepage_why_us_description_2')"></div>
                            </li>
                            <li>
                                <figure>
                                    <img src="frontend/images/why-ico3.svg">
                                </figure>
                                <div v-html="$t('homepage_why_us_description_3')"></div>
                            </li>
                        </ul>
                        <div class="d-flex">
                            <a href="/about-us" class="comman-btn-2">{{ $t('learn_more') }}</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-5">
                    <div class="why-pic">
                        <figure>
                            <img src="frontend/images/why-img.jpg">
                        </figure>
                        <div class="mark-logo">
                            <img src="frontend/images/logo.png">
                        </div>
                        <div v-html="$t('homepage_why_us_description_4')" class="position-absolute bottom-0 start-0 p-3 bg-none bg-opacity-75 w-100 text-center" style="color:var(--primary-F6B034);" ></div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="home-counter-sec">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading && response.stats && response.stats.length > 0">
            <ul class="justify-content-center d-flex">
                <li v-for="(item, index) in response.stats" :key="index">
                    <h3>{{ item.count_val }}</h3>
                    <span>{{ item.name }}</span>
                </li>
            </ul>
        </div>
    </section>

    <section class="meet-agent-sec d-sm-none d-md-block" v-if="response.agent_details && response.agent_details.length > 0">
        <div class="container">
            <div class="comman-title text-center">
                <span>{{ $t('our_registered_agents') }}</span>
            </div>
        
            <LoaderCust :isLoading="loading"/>
            <div v-if="!loading" class="row">
                <div class="comman-title text-center">
                    <h3>{{ $t('meet_your_estate_agents') }}</h3>
                </div>
                <div v-for="(agent, index) in response.agent_details" :key="index" class="col-md-4">
                    <div>
                        <div class="meet-agent-bx">
                            <div class="meet-pic">
                                <a :href="'agents/' + response.agent_details[index]?.id">
                                    <picture>
                                        <img :src="getAgentImage(agent.profile_photo_name)">
                                    </picture>
                                </a>
                                <div class="mark-logo">
                                    <img src="frontend/images/logo.png">
                                </div>
                            </div>
                            <div class="agent-info">
                                <h3>{{ agent.name }}</h3>
                                <span>{{ agent.user_service_details.service_type_id.name }}</span>
                                <p v-if="agent.about_you">{{ agent.about_you }}</p>
                            </div>
                            <div class="agent-profile">
                                <a v-bind:href="'/agents/' +  agent.id " class="comman-btn-2">{{ $t('view_profile') }}</a>
                                <div class="profile-call ms-md-auto">
                                    <a href="tel:0121231234">
                                        <img src="frontend/images/phone-ico.svg">
                                    </a>
                                    <a href="mailto:agent@example.com">
                                        <img src="frontend/images/email-ico.svg">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

    // import $ from 'jquery'; // Import jQuery
    import LoaderCust from '@/components/LoaderCust.vue';
    import ProjectBox from './home/ProjectBox.vue';
    import PropertyBox from './home/PropertyBox.vue';
    import PropertySearch from './home/PropertySearch.vue';
    import { CONSTANT } from '@/constants/constants';
    import { mapState } from 'vuex';

    export default {
        name: 'HomePage',

        components: { PropertySearch, PropertyBox, ProjectBox, LoaderCust },

        data() {
            return {
                activeTab: 'Buy',
                response: {},
                loading: false,
                SectionTitleMap: {
                    property_type_details: 'Browse Property Type',
                    developer_projects: 'Developer Projects',
                    latest_properties: 'Most Recent Property Listings',
                    featured_properties: 'Featured Properties (For Sale / For Rent)',
                    fsm_properties: 'Enriched Property Listings (FengShui Map)',
                    classified_properties: 'Classified Property Listings',
                    curated_properties: 'Curated Property Collections (Malaysia & Others)',
                    locations_details: 'Explore Property Listings by Location',
                    stats: 'Status',
                    agent_details: 'Meet our agents'
                }
            }
        },

        mounted() {
            window.$(document).ready(function () {
                window.$('.property-carousel').owlCarousel({
                    loop: false,
                    margin: 0,
                    nav: true,
                    dots: true,
                    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                    responsive: {
                        0: {
                            items: 1,
                            margin: 0
                        },
                        600: {
                            items: 1
                        },
                        1000: {
                            items: 1
                        }
                    }
                });

                // Initialize testimonial carousel
                window.$('.testimonail-carousel').owlCarousel({
                    loop: false,
                    margin: 25,
                    nav: false,
                    dots: true,
                    responsive: {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 2
                        },
                        1000: {
                            items: 3
                        }
                    }
                });
            });

            this.loading = true;
            this.$axios.post('api/home-page-details', {
                "user_id": this.user?.id,
                "country_id": 93,
                "location_type_id": 0,
                "property_type_id": 0
            }).then((response) => {
                this.response = response.data.output;
                setTimeout(() => {

                    var $carousel = window.$('.pro-type-carousel');
                    $carousel.owlCarousel({
                        loop: $carousel.find('.item').length > 4, // Only loop if more than one item
                        margin: 20,
                        nav: true,
                        dots: false,
                        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                        responsive: {
                            0: {
                                items: 1,
                                margin: 10
                            },
                            600: {
                                items: 2
                            },
                            1000: {
                                items: 4
                            }
                        }
                    });

                }, 1000)
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.loading = false;
            });
        },

        computed: {
            ...mapState(['user', 'isAuthenticated'])
        },

        methods: {
            browseProperty(propertyType) {
                let data = { selectedType: '', selectedCategories: { "Commercial & Industrial": [], "Residential": [] } };
                let type = '';

                const commercials = ['Office', 'Retail'];
                if (commercials.includes(propertyType.name)) {
                    type = "Commercial & Industrial";
                } else {
                    type = "Residential";
                }
                data.selectedType = type;
                data.selectedCategories[type].push(propertyType.property_type);
                this.$router.push({ name: 'ExploreMore', query: { formData: JSON.stringify(data) } }).then(() => {
                    window.scrollTo(0, 0);
                });
            },

            browsePropertyByLocation(countryId) {
                if (!countryId || countryId == null || countryId == undefined) {
                    return;
                }

                let data = { selectedCountry: countryId };

                this.$router.push({ name: 'ExploreMore', query: { formData: JSON.stringify(data) } }).then(() => {
                    window.scrollTo(0, 0);
                });
            },

            setActiveTab(tab) {
                this.activeTab = tab;
            },

            getSectionName(apiArrayName) {
                return this.SectionTitleMap[apiArrayName] || null;
            },

            redirectToExploreMore(sectionName) {
                let pageToRedirect = 'ExploreMore';
                let params = {};
                if (sectionName == 'featured_properties' || sectionName == 'fsm_properties' || sectionName == 'classified_properties' || sectionName == 'developer_projects') {
                    if (sectionName == 'fsm_properties') {
                        params.is_FSM_linked = true;
                    } else if (sectionName == 'classified_properties') {
                        params.is_widget_property = true;
                    } else if (sectionName == 'developer_projects') {
                        pageToRedirect = 'ExploreMoreProject';
                    }
                } else if (sectionName == 'curated_properties') {
                    params.is_curated_property = true;
                } else if (sectionName == 'latest_properties') {
                    params.sort_by_field = 'Newest';
                }

                this.$router.push({ name: pageToRedirect, query: { formData: JSON.stringify(params) } }).then(() => {
                    window.scrollTo(0, 0);
                });
            },

            getImageUrl(path) {
                return path ? `${CONSTANT.IMAGE_URL}/imagesLocation/${path}` : CONSTANT.DEFAULT_NO_IMAGE;
            },

            getAgentImage(path) {
                return path ? `${CONSTANT.IMAGE_URL}/imagesProfile/${path}` : CONSTANT.DEFAULT_NO_IMAGE;
            },
        }
    }
</script>

<style scoped>
    /* Add styles specific to the Home page here */
</style>