<template>
    <div class="col-md-3">
        <div class="profile-package-card">
            <h3 v-if="package_details">{{ $t('package') }}</h3>
            <h4 v-if="package_details">{{ package_details.title }}</h4>
            <p v-if="package_details">{{ $t('valid_until') }} {{ package_details.paf_todate }}</p>
            <a href="/packages" class="comman-btn-2"><img src="frontend/images/Choose-plan.svg" alt="">
                {{ !package_details ? $t('choose_package') : $t('view_package')}}</a>
            <br>
            <h4 v-if="canFeatureAgent">{{ $t('feature') }}</h4>
            <button v-if="canFeatureAgent" class="comman-btn-2 w-100" data-bs-toggle="modal" data-bs-target="#feature_profile">
                {{ $t('feature_my_profile') }}
            </button>
            <h4>{{ $t('credits') }}</h4>
            <p class="mb-0"><b>{{ userProfile.available_credit_points ?? '0' }} {{ $t('credits') }}</b></p>
            <p class="mb-0" v-if="userProfile.credit_points_expiry_date">{{ $t('expire_by') }} {{ userProfile.credit_points_expiry_date }}</p>
            <router-link class="comman-btn-2 my-3" :to="{ name: 'TopUp' }"><img
                src="/frontend/images/top-up.png" alt="">
                {{ $t('top_up') }}</router-link>
            <router-link class="comman-btn-4" :to="{ name: 'CreditsHistory' }"><img
                src="/frontend/images/credit-history.png" alt="">
                {{ $t('credits_history') }}</router-link>

            <h4 class="mt-4">{{ $t('reward_points') }}</h4>
            <p class="mb-1"><b>{{ userProfile.available_reward_points ?? '0' }} {{ $t('points') }}</b></p>
            <router-link class="comman-btn-4 my-3" :to="{ name: 'RewardPoints' }"><img
                src="/frontend/images/credit-history.png" alt="">
                {{ $t('reward_points') }}</router-link>
            <button type="button" class="comman-btn-2 w-100" data-bs-toggle="modal" data-bs-target="#convertRewardPoint">{{ $t('redeem') }} {{ $t('reward_points') }}</button>
        </div>
        <div class="profile-package-card">
            <div v-if="isVerified">
                <h3>{{ $t('profile_setting') }}</h3>
                <div class="d-flex mb-3">
                    <label class="form-check-label" for="flexSwitchCheckChecked"><b>{{ isActive ? 'Active' : 'Deactivated' }} :</b></label>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                               :checked="isActive" @click="toggleActive">
                    </div>
                </div>
                <div class="d-flex mb-3">
                    <div class="form-group w-100">
                        <label for="currency"><b>{{ $t('preferred_currency') }}:</b></label>
                        <select class="form-control" v-model="selectedCurrencyRate" @change="saveCurrencyRate">
                            <option v-for="currency in currencies" :key="currency.code" :value="currency">
                                {{ currency.name }} ({{ currency.code }})
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <h4 class="mb-2">{{ $t('availability_days') }}</h4>
            <ul v-if="profilServicesData" class="d-flex flex-wrap gap-1 list-unstyled m-0">
                <li class="mb-0 pb-0" v-if="profilServicesData?.is_available_mon">
                    <span class="badge comman-btn-2 text-dark px-1 py-1 m-0">{{ $t('mon') }}</span>
                </li>
                <li class="mb-0 pb-0" v-if="profilServicesData?.is_available_tue">
                    <span class="badge comman-btn-2 text-dark px-1 py-1 m-0">{{ $t('tue') }}</span>
                </li>
                <li class="mb-0 pb-0" v-if="profilServicesData?.is_available_wed">
                    <span class="badge comman-btn-2 text-dark px-1 py-1 m-0">{{ $t('wed') }}</span>
                </li>
                <li class="mb-0 pb-0" v-if="profilServicesData?.is_available_thu">
                    <span class="badge comman-btn-2 text-dark px-1 py-1 m-0">{{ $t('thu') }}</span>
                </li>
                <li class="mb-0 pb-0" v-if="profilServicesData?.is_available_fri">
                    <span class="badge comman-btn-2 text-dark px-1 py-1 m-0">{{ $t('fri') }}</span>
                </li>
                <li class="mb-0 pb-0" v-if="profilServicesData?.is_available_sat">
                    <span class="badge comman-btn-2 text-dark px-1 py-1 m-0">{{ $t('sat') }}</span>
                </li>
                <li class="mb-0 pb-0" v-if="profilServicesData?.is_available_sun">
                    <span class="badge comman-btn-2 text-dark px-1 py-1 m-0">{{ $t('sun') }}</span>
                </li>
            </ul>
            <p v-else>{{ 'N/A' }}</p>
            <h4 class="mt-2 mb-0">{{ $t('working_hours') }}</h4>
            <p v-if="profilServicesData">{{ profilServicesData.working_hours ?? 'N/A'}}</p>
            <p v-else>{{ 'N/A' }}</p>
            <h4 class="mb-0">{{ $t('contacts') }}</h4>
            <p class="mb-0">{{ $t('email') }}: {{ userProfile.email ?? '-'}}</p>
            <p class="mb-2">{{ $t('phone') }}: {{ userProfile.contact_phone ?? '-'}}</p>
            <h4>{{ $t('my_social_medias') }}</h4>
            <div class="social">
                <a :href="userProfile.facebook_url" v-if="userProfile.facebook_url"
                   target="_blank">
                    <img src="/frontend/images/fb-ico.svg">
                </a>

                <a :href="userProfile.twitter_url" v-if="userProfile.twitter_url" target="_blank">
                    <img src="/frontend/images/tweet-ico.svg">
                </a>

                <a :href="userProfile.instagram_url" v-if="userProfile.instagram_url"
                   target="_blank">
                    <img src="/frontend/images/instagram-icon.svg">
                </a>

                <a :href="userProfile.tiktok_url" v-if="userProfile.tiktok_url" target="_blank">
                    <img src="/frontend/images/tiktok-icon.svg">
                </a>
            </div>
        </div>
        <div class="modal fade" id="feature_profile" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="feature-profileLabel">{{ $t('feature_my_profile_now') }}</h5>
                    </div>
                    <div v-if="isFeatured" class="modal-body">
                        <h4><b>{{ $t('congrats,you_are_featured') }}</b></h4>
                        <br>
                        <label><b>{{ $t('feature_from') }}:</b> {{ featuredFrom }}</label>
                        <label><b>{{ $t('feature_to') }}:</b> {{ featuredTo }}</label>
                        <div class="d-flex justify-content-end">
                            <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">{{ $t('close') }}</button>
                        </div>
                    </div>
                    <div v-else>
                        <div class="d-flex justify-content-start mt-3 ps-3" >
                            <h4 class="mb-3">{{ $t('propface_now_offer_featuring_service') }}</h4>
                        </div>
                        <div class="px-3">
                            <p>{{ $t('featuring_service_custom_description') }} {{ userProfile?.package_details?.feature_agent_company_profile_in_days }} {{ $t('days') }}</p>
                            <label>{{ $t('current_credit_balance') }}</label>
                            <p><b>{{ userProfile.available_credit_points ?? '0' }} {{ $t('credits') }}</b></p>
                            <div class="modal-footer">
                                <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">{{ $t('close') }}</button>
                                <a v-if="userProfile.available_credit_points < 10" class="comman-btn-2" href="/top-up" onclick="$('#feature_profile').modal('hide')">{{ $t('top_up') }}</a>
                                <button type="button" class="comman-btn-2" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#confirmationFeature">{{ $t('feature_now') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="confirmationFeature" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="confirmationFeatureLabel">{{ $t('confirmation') }}</h5>
                    </div>
                    <div class="modal-body">
                        <p class="mb-3">{{ $t('10_credits_will_be_deducted_from_your_account') }}</p>
                        <label>{{ $t('credit_balance_after_deduction') }}</label>
                        <p><b>{{ userProfile.available_credit_points - 10 ?? '0' }} {{ $t('credits') }}</b></p>
                        <span class="error">{{ $t('warning_message_for_action_cant_reversed') }}</span>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">{{ $t('close') }}</button>
                        <button type="button" class="comman-btn-2" data-bs-dismiss="modal" @click="featureAgent">{{ $t('yes,im_sure') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="convertRewardPoint" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true" aria-labelledby="staticBackdropLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="convertRewardPointLabel">{{ $t('convert_reward_points_to_credit_points') }}</h5>
                    </div>
                    <div class="modal-body">
                        <p class="mb-3">{{ $t('your_current') }} {{ $t('reward_points') }}: {{ userProfile.available_reward_points ?? '0'  }}</p>
                        <p class="mb-3">{{ $t('your_current') }} {{ $t('credits') }}: {{ userProfile.available_credit_points ?? '0'  }}</p>
                        <label>{{ $t('enter_the_amount_you_want_to_convert') }}</label>
                        <input class="form-control" placeholder="Enter Amount" v-model="amount" type="number" step="1">
                        <span v-if="errorMessage" class="error">{{ errorMessage }}</span>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="comman-btn-2-danger" @click="clearError" data-bs-dismiss="modal">{{ $t('close') }}</button>
                        <button type="button" class="comman-btn-2" v-if="amount" @click="convertRewardPoint" data-bs-dismiss="modal">{{ $t('confirm') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="userProfile.is_mobile_verified === 0" class="modal fade" id="verifyMobileNumber" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true" aria-labelledby="staticBackdropLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="verifyMobileNumberLabel">{{ $t('verification_needed') }}!</h5>
                    </div>
                    <div class="modal-body">
                        <p class="mb-3">{{ $t('please_verify_your_mobile_number') }}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">{{ $t('close') }}</button>
                        <button type="button" class="comman-btn-2" @click="navigateToVerify">{{ $t('verify_now') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import toastr from "toastr";

    export default {
        name: 'ProfilePageSideBar',
        data() {
            return {
                profilServicesData: [],
                package_details: [],
                isVerified: '',
                isFeatured: null,
                featuredTo: null,
                featuredFrom: null,
                isActive: null,
                errorMessage: '',
                currencies: [],
                selectedCurrencyRate: {
                    rate: localStorage.getItem("preferredCurrencyRate") || 1,
                    code: localStorage.getItem("preferredCurrencyCode") || 'SGD',
                },
                amount: 0,
            }
        },
        computed: {
            ...mapState(['user', 'userProfile', 'isAuthenticated']),
            canFeatureAgent() {
                if (this.userProfile.is_business || this.userProfile.is_corporate) {
                    return this.userProfile?.package_details?.feature_agent_company_profile;
                } else {
                    return 0;
                }
            },
        },
        created()
        {
            this.fetchOptions();
            this.fetchCurrencies();
        },
        methods: {
            navigateToVerify() {
                // Remove any remaining Bootstrap modal backdrop
                document.querySelectorAll('.modal-backdrop').forEach(backdrop => {
                    backdrop.remove();
                });

                this.$router.push('/verify-number'); // Navigate after cleaning up
            },
            async fetchOptions() {

                const token = localStorage.getItem('userToken');
                // Get Features list
                try {
                    const response = await this.$axios.post('api/services-type-list',
                        {
                            user_id: this.user.id,
                            api_token: token
                        });
                    this.service_typeList = response.data.output.map(item => ({
                        value: item.id,
                        label: item.name
                    }));
                } catch (error) {
                    console.error('Error fetching options:', error);
                    await this.logout();
                }
                if (this.isAuthenticated) {
                    try {
                        const response = await this.$axios.post('/api/my-profile-detail', {
                            api_token: token,
                            user_id: this.user.id
                        });

                        var userData = response.data.output;
                        await this.$store.dispatch('profileUpdate', response.data.output);
                        var userServiceData = userData.service_details[0];
                        this.package_details = userData.package_details;
                        this.isVerified = userData?.is_admin_verified;
                        this.isFeatured = userServiceData?.is_featured;
                        this.featuredTo = userServiceData?.featured_to;
                        this.featuredFrom = userServiceData?.featured_from;
                        this.profilServicesData = userServiceData;
                        this.isActive = userData.is_active;
                            // This is not executed, as some error has thrown before
                            await this.$store.dispatch('profileUpdate', response.data.output);
                    } catch (error) {
                        console.error('Failed to fetch profile:', error);
                        toastr.error('Your session has expired. Please log in again');
                        await this.logout();
                    }
                } else {
                    toastr.error('Your session has expired. Please log in again');
                    await this.logout();
                }
            },
            async logout() {
                try {
                    await this.$store.dispatch('logout'); // Call Vuex logout action
                    localStorage.clear(); // Clear all localStorage data
                    this.$router.push('/login'); // Redirect user to login page
                } catch (error) {
                    console.error('Logout failed:', error);
                }
            },
            async fetchCurrencies() {
                try {
                    const token = localStorage.getItem('userToken');
                    const response = await this.$axios.get("/api/currencies", {
                        params: {
                            api_token: token,
                        }
                    });
                    this.currencies = response.data;

                } catch (error) {
                    console.error("Error fetching currencies:", error);
                }
            },
            saveCurrencyRate() {
                localStorage.setItem("preferredCurrencyRate", this.selectedCurrencyRate.rate);
                localStorage.setItem("preferredCurrencyCode", this.selectedCurrencyRate.code);

                this.$store.dispatch("updateCurrency", {
                    rate: this.selectedCurrencyRate.rate,
                    code: this.selectedCurrencyRate.code
                });

                toastr.success(this.selectedCurrencyRate.name);
            },
            clearError() {
                this.errorMessage = '';
            },
            async convertRewardPoint() {
                const token = localStorage.getItem('userToken');

                if (this.amount > this.userProfile.available_reward_points) {
                    this.errorMessage = "You don't have enough reward points!"
                    return this.errorMessage
                }

                if (!Number.isInteger(this.amount)) {
                    this.errorMessage = "Amount must be a whole number only!";
                    return;
                }

                const response = await this.$axios.post('api/convert-reward-point', {
                    api_token: token,
                    user_id: this.user.id,
                    amount: this.amount,
                });

                if (response.data.status === 1) {
                    toastr.success( `${this.amount} credits is added to your Credits`, 'Points Redeemed Successfully');

                    await this.fetchOptions();
                } else {
                    toastr.error('Points failed to redeem');
                }
            },
            async toggleActiveStatus() {
                const token = localStorage.getItem('userToken');
                const response = await this.$axios.post('api/change-profile-status', {
                    api_token: token,
                    is_active: this.isActive,
                });

                if (response.data.status == 1) {
                    toastr.success('Profile Status Change Successfully');

                    await this.fetchOptions();
                } else {
                    toastr.error('Profile Status Change Failed');
                }
            },
            toggleActive(event) {
                event.preventDefault();
                window.Swal.fire({
                    title: "Are you sure you want to " + (this.isActive ? 'deactivate' : 'activate') + "?",
                    showCancelButton: true,
                    confirmButtonText: "Save",
                    confirmButtonColor: "var(--primary-F6B034)",
                    cancelButtonColor: "var(--danger-color, #FF0000)",
                    denyButtonText: `Don't save`
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.isActive = !this.isActive;
                        this.toggleActiveStatus();
                    }
                });
            },
            async featureAgent() {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.post('/api/feature-agent', {
                        user_id: this.user.id,
                        api_token: token,
                    });

                    if (response.data.status === 1) {
                        toastr.success('Agent has been successfully featured!');

                        await this.fetchOptions();
                    } else {
                        toastr.error('Failed to feature', response.data.message);
                    }
                } catch (error) {
                    console.error("Error featuring agent:", error);
                    alert('Something went wrong. Please try again.');
                }
            },
        }
    }
</script>
