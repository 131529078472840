<template>    
    <section class="create-property-listing-page">
        <div class="container">
            <div class="text-center">
                <div class="comman-title">
                    <h2>{{ widget && widget.title || 'Create your property listing in minutes!' }}</h2>
                    <p>{{ widget && widget.description || 'Add quick details and sign up with your mobile number to Add your property.' }}</p>
                </div>
                <div class="d-flex justify-content-center">
                    <router-link :to="`/widget-property-post-1/${computedId}/${computedIdType}`">
                        <a href="javascript:;" class="comman-btn-2">Get Started</a>
                    </router-link>
                </div>
            </div>
        </div>
    </section> 
</template>
  
<script> 
import { mapState } from 'vuex';
import { ref } from "vue";
import { useHead } from "@unhead/vue";

export default {
    name: 'Widget0Page',
    props: {
        widget: Object,
        id: String,
        idType: String
    },
    data() {
        return {
            userWidget: {},
        }
    },
    computed: {
        ...mapState(['isAuthenticated', 'user']),
        computedId() {
            return this.id || this.$route.params.id;
        },
        computedIdType() {
            return this.idType || this.$route.params.id_type || 'Admin';
        },
    },
    methods: {
        async fetchOptions() {
            if (!this.isAuthenticated) {
                return;
            }
            try {
                const response = await this.$axios.get(`/api/widgets/default/${this.user.id}`);
                this.userWidget = response.data.widget;
            } catch (error) {
                console.error('Error fetching widget:', error.response?.data?.message || error.message);
            }
        },
        storeAgentId() {
            const agentId = this.computedId;
            if (agentId) {
                localStorage.setItem('agentId', agentId); // Store agent ID in local storage
            }
        },
    },
    mounted() {
        this.storeAgentId(); // Call storeAgentId when the component is mounted
    },
    beforeMount() {
        this.fetchOptions().then(() => {
            const title = ref(this.userWidget?.title || 'Welcome To PropFace');
            const description = ref(this.userWidget?.description || 'Add quick details and sign up with your mobile number to add your property');

            useHead({
                title: title.value,
                meta: [
                    { property: 'og:description', content: description.value },
                    { property: 'og:title', content: title.value || 'Welcome to PropFace' },
                    { property: 'og:image', content: this.userWidget?.image || '' },
                    { property: 'og:url', content: window.location.href },
                    { property: 'og:type', content: 'website' },
                ],
            })
        });
    }
}
</script>
