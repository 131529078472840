<template>
    <div class="filter-bar-se my-4">
        <div class="text-center w-25" v-if="canDownloadReport && projectsData.length > 0">
            <downloadexcel
                class="comman-btn-2"
                :fetch="exportProjectsToExcel"
                :fields="getFields('projectAnalytic')"
                :before-generate="startDownload"
                :before-finish="finishDownload"
            >
                {{ $t('download_excel') }}
            </downloadexcel>
        </div>
        <div class="filter-right-bar">
            <div class="dropdown">
                <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                    <img src="frontend/images/Sort-By.svg"> {{ $t('sort_by') }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Oldest' }"
                           @click.prevent="sort('Oldest')">{{ $t('oldest') }}</a></li>
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Newest' }"
                           @click.prevent="sort('Newest')">{{ $t('newest') }}</a></li>
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Highest View' }"
                           @click.prevent="sort('Highest View')">Highest View</a></li>
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Lowest View' }"
                           @click.prevent="sort('Lowest View')">Lowest View</a></li>
                </ul>
            </div>
        </div>
    </div>
    <template v-if="projectsData.length > 0">
        <div class="table-responsive custom-table-design">
            <table class="text-center table-bordered align-middle" >
                <thead>
                <tr>
                    <th>{{ $t('project_name') }}</th>
                    <th>{{ $t('views') }}</th>
                    <th>{{ $t('inquiries') }}</th>
                    <th>{{ $t('project_status') }}</th>
                    <th>{{ $t('created_at') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="project in projectsData" :key="project.id">
                    <td @click="redirectToProjectDetails(project.project_listing_id)" style="cursor: pointer;">{{ project.project?.project_name }}</td>
                    <td>{{ project.views }}</td>
                    <td>{{ project.inquiries }}</td>
                    <td>{{ project.project?.project_status }}</td>
                    <td>{{ formatDate(project.project?.created_at) }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </template>
    <template v-else>
        <div class="text-center mt-5 mb-3">{{ $t('no_records_found') }}</div>
    </template>
    <div class="pagination-se mt-3" v-if="projectsData.length > 0">
        <nav aria-label="...">
            <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: dataFilters.page === 1 }"
                    @click="dataFilters.page > 1 && changePage(dataFilters.page - 1)" v-if="dataFilters.page > 1">
                    <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                              alt="Previous"></a>
                </li>
                <li v-for="pageNumber in paginatedPages(dataFilters.page, totalPages)" :key="pageNumber"
                    class="page-item" :class="{ active: pageNumber === dataFilters.page }">
                    <a class="page-link"
                       @click.prevent="pageNumber !== dataFilters.page && changePage(pageNumber)">
                        {{ pageNumber }}
                    </a>
                </li>
                <li class="page-item" :class="{ disabled: dataFilters.page === totalPages }"
                    @click="dataFilters.page < totalPages && changePage(dataFilters.page + 1)"
                    v-if="dataFilters.page < totalPages">
                    <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
    import downloadexcel from "vue-json-excel3";
    import {mapState} from "vuex";
    import {getJsonFields} from "@/utils/jsonFieldsConfig";
    import toastr from "toastr";
    import {formatDate, paginatedPages} from "@/utils/helpers";

    export default {
        name: 'ProjectInsightReport',
        components: {
            downloadexcel,
        },
        computed: {
            ...mapState(['isAuthenticated', 'user', 'userProfile']),
            canDownloadReport() {
                return (this.user.is_corporate && this.user?.package?.title == 'Developer');
            },
        },
        created() {
            this.fetchData();
        },
        data() {
            return {
                projectsData: [],
                totalPages: 0,
                totalRecords: 0,
                dataFilters: {
                    page: 1,
                    sort_by_field: 'Newest',
                },
            }
        },
        methods: {
            formatDate,
            paginatedPages,
            getFields(type) {
                return getJsonFields(type);
            },
            redirectToProjectDetails(projectId) {
                this.$router.push({ name: 'projectDetail', params: { id: projectId } });
            },
            startDownload() {
                toastr.info('Preparing the download...');
            },
            finishDownload() {
                toastr.success('Download completed!');
            },
            async fetchData() {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.post('api/get-project-analytic-detail',{
                        api_token: token,
                        user_id: this.user.id,
                        sort_by_field: this.dataFilters.sort_by_field,
                        page_number: this.dataFilters.page,
                    });

                    this.projectsData = response.data.output.data.data;
                    this.totalRecords = response.data.output.total_records;
                    this.totalPages = response.data.output.total_pages;

                } catch (error) {
                    console.error('Unable to fetch data, error:', error.message);
                }
            },
            async exportProjectsToExcel() {
                // Return the array of objects to be exported
                return this.projectsData;
            },
            sort(sortBy) {
                this.dataFilters.sort_by_field = sortBy;
                this.dataFilters.page = 1;

                this.fetchData();
            },
            changePage(page) {
                if (page > 0) {
                    const totalPages = this.totalPages;
                    if (page <= totalPages) {
                        this.dataFilters.page = page;
                        this.fetchData();
                    }
                }
            },
        }
    }

</script>
