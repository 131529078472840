<template>    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3><a href="#"><i class="fa fa-angle-left"></i></a> {{ $t('create') }} Project Listing</h3>
                </div>
                <div class="progress-bar-box">
                    <div>1/4</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                        <!--<div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>-->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>                            
                            <li><a class="active" href="\post-project-listing">{{ $t('step') }} 1</a></li>
                            <li :class="isDisabledStep2"><router-link :to="`/post-project-listing-2/${itemId}`">{{ $t('step') }} 2</router-link></li>
                            <li :class="isDisabledStep3"><router-link :to="`/post-project-listing-3/${itemId}`">{{ $t('step') }} 3</router-link></li>
                            <li :class="isDisabledStep4"><router-link :to="`/post-project-listing-4/${itemId}`">{{ $t('step') }} 4</router-link></li>

                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <form @submit.prevent="submitPost">
                        <div class="property-box">
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>{{ $t('key_information') }}</h3>
                                </div>
                                <div class="form-group">
                                    <label>{{ $t('listing_type') }}</label>
                                    <select 
                                        id="group1Select" 
                                        class="form-control"
                                        v-model="form.listingType" 
                                    >
                                    <option value="" selected>{{ $t('listing_type') }}</option>
                                        <option 
                                        v-for="option in optionsGroup1" 
                                        :key="option.value" 
                                        :value="option.value"
                                        >
                                        {{ option.label }}
                                        </option>
                                    </select>
                                    <span  class="error" v-if="errors.listingType">{{ errors.listingType }}</span>
                                </div>
                                <div class="form-check mb-2">
                                    <input class="form-check-input" type="checkbox" v-model="form.qualify_for_foreigner" id="qualify_for_foreigner">
                                    <label class="form-check-label w-100" for="qualify_for_foreigner">
                                        {{ $t('qualify_for_foreigner') }}
                                    </label>
                                </div>	
                            </div>
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>{{ $t('project_address') }} / {{ $t('location') }}</h3>
                                </div>
                                <div class="form-group">
                                    <label>{{ $t('project_name') }}</label>
                                    <input 
                                        type="text" 
                                        class="form-control" 
                                        v-model="form.project_name" 
                                        placeholder="Project Name"
                                    />
                                    <span  class="error" v-if="errors.project_name">{{ errors.project_name }}</span>
                                </div>

                                <div class="col">
                                    <div class="form-group">
                                        <label>{{ $t('country') }}</label>
                                        <!-- Country Selection -->
                                        <div>
                                            <select v-model="form.country" @change="handleCountryChange" class="form-control">
                                                <option value="" disabled selected>{{ $t('select') }} {{ $t('country') }}</option>
                                                <option value="singapore">{{ $t('singapore') }}</option>
                                                <option  v-if="this.user.is_corporate && purchasedPackage === 1"  value="malaysia">{{ $t('malaysia') }}</option>
                                            </select>
                                            <span class="error" v-if="errors.country">{{ errors.country }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row row-cols-1 row-cols-md-3">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('street') }}</label>
                                            <input
                                            class="form-control"
                                            type="text"
                                            v-model="form.street"
                                            placeholder="Type to Street..."
                                            />
                                            <span  class="error" v-if="errors.street">{{ errors.street }}</span>
                                            <ul v-if="suggestions.length" class="sugesstion_ul">
                                                <li v-for="(suggestion, index) in suggestions" :key="index" @click="selectSuggestion(suggestion)">
                                                    {{ suggestion }}
                                                </li>
                                            </ul>
                                            <span  class="error" v-if="errors.street">{{ errors.street }}</span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>{{ $t('area') }} / {{ $t('hdb_estate') }}*</label>
                                        <div class="dropdown">
                                            <input
                                                id="area"
                                                type="text"
                                                v-model="form.area_hdb_estate"
                                                class="form-control"
                                                @input="fetchSuggestionsArea"
                                                @focus="dropdownVisible = true"
                                                placeholder="Select or type an Area"
                                                @blur="closeDropdown"
                                            />
                                            <ul 
                                                v-if="dropdownVisible && suggestionsArea.length > 0" 
                                                class="dropdown-menu show" 
                                                style="width: 100%; max-height: 200px; overflow-y: auto;"
                                            >
                                                <li 
                                                    v-for="(suggestion, index) in suggestionsArea" 
                                                    :key="index" 
                                                    @mousedown.prevent="selectSuggestionArea(suggestion)" 
                                                    class="dropdown-item"
                                                >
                                                    {{ suggestion }}
                                                </li>
                                            </ul>
                                            <span class="error" v-if="errors.area_hdb_estate">{{ errors.area_hdb_estate }}</span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div>
                                            <label>{{ $t('postcode') }}</label>
                                            <input v-model="form.postcode" type="text" class="form-control" placeholder="Enter Postcode" />
                                            <span class="error" v-if="errors.postcode">{{ errors.postcode }}</span>
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('district') }}</label>

                                            <div class="dropdown">
                                                <input
                                                    id="district_id"
                                                    type="text"
                                                    v-model="form.district"
                                                    class="form-control"
                                                    @input="filterDistricts"
                                                    @focus="dropdownVisible = true"
                                                    placeholder="Select or type a District"
                                                    @blur="closeDropdown"
                                                />
                                                <ul 
                                                    v-if="dropdownVisible && filteredDistrictList.length > 0" 
                                                    class="dropdown-menu show" 
                                                    style="width: 100%; max-height: 200px; overflow-y: auto;"
                                                >
                                                    <li 
                                                        v-for="option in filteredDistrictList" 
                                                        :key="option.value" 
                                                        @mousedown.prevent="selectDistrict(option.label)" 
                                                        class="dropdown-item"
                                                    >
                                                        {{ option.label }}
                                                    </li>
                                                </ul>
                                            </div>
                                            <span class="error" v-if="errors.district">{{ errors.district }}</span>
                                        </div>
                                    </div>

                                    <div v-if="form.country === 'malaysia'" class="col">
                                        <div class="form-group">
                                        <label>{{ $t('state') }}</label>
                                        <div>
                                            <select class="form-control" v-model="form.state">
                                            <option value="" disabled>{{ $t('select') }} {{ $t('state') }}</option>
                                            <!-- Loop through STATE_MY constant and display states -->
                                            <option v-for="state in stateMyOptions" :key="state.value" :value="state.value">
                                                {{ state.label }}
                                            </option>
                                            </select>
                                            <span class="error" v-if="errors.state">{{ errors.state }}</span>
                                        </div>
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div class="form-group">
                                            <label>Nearest MRT</label>
                                            <input type="text"  class="form-control" v-model="form.nearest_MRT_in_m"  placeholder="Nearest MRT">
                                            <span  class="error" v-if="errors.nearest_MRT_in_m">{{ errors.nearest_MRT_in_m }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Nearest Bus Station.</label>
                                            <input type="text"  class="form-control" v-model="form.nearest_bus_station_in_m"  placeholder="Nearest Bus Station.">
                                            <span  class="error" v-if="errors.nearest_bus_station_in_m">{{ errors.nearest_bus_station_in_m }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Nearest School.</label>
                                            <input type="text"  class="form-control" v-model="form.nearest_school_in_m"  placeholder="Nearest School.">
                                            <span  class="error" v-if="errors.nearest_school_in_m">{{ errors.nearest_school_in_m }}</span>
                                        </div>
                                    </div>
                                </div>								
                            </div>
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>{{ $t('basic_information') }}</h3>
                                </div>
                                <div class="row row-cols-1 row-cols-md-3">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('property_type') }}</label>
                                            <select class="form-control" v-model="form.selectedPropertyType">
                                                <option value="" disabled>{{ $t('select') }} {{ $t('property_type') }}</option>
                                                <RecursiveDropdown :options="propertyOptions" />
                                            </select>
                                            <span  class="error" v-if="errors.selectedPropertyType">{{ errors.selectedPropertyType }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('land_tenure') }}</label>
                                            <select id="land_tenure" v-model="form.land_tenure" class="form-control">
                                                <option value="" disabled selected>{{ $t('land_tenure') }}</option>
                                                <option v-for="option in optionsLandTenuresList" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
										<div class="form-group">
											<label>{{ $t('land_title_type') }}</label>
											<select class="form-control col" v-model="form.land_title_type">
                                                <option value="" selected> {{ $t('land_title_type') }}</option>
                                                <option v-if="form.country !== 'malaysia'" value="HDB">HDB</option>
                                                <option value="Strata">Strata</option>
                                                <option value="Landed">Landed</option>
                                            </select>
										</div>
									</div>
                                    <div class="col">
										<div class="form-group">
											<label>{{ $t('project_site') }}/{{ $t('land_area') }}</label>
											<input type="text" class="form-control" v-model="form.project_site_land_area" placeholder="Project Site/Land Area">
										</div>
									</div>

                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('total') }} {{ $t('blocks') }}</label>
                                            <input type="number" min="0" class="form-control" v-model="form.total_blocks" placeholder="Total Blocks">
                                        </div>
                                    </div>
                                    <div class="col">
										<div class="form-group">
											<label>Building Height (Levels)</label>
											<input type="text" class="form-control" v-model="form.building_height_levels" placeholder="Building Height (Levels)">
										</div>
									</div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('total') }} {{ $t('units') }}</label>
                                            <input type="number" min="0" class="form-control" v-model="form.total_units" placeholder="Total Units">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>{{ $t('total') }} {{ $t('carpark_lots') }}</label>
                                            <input type="number" min="0" class="form-control" v-model="form.total_car_parks" placeholder="Total carpark Lots">
                                        </div>
                                    </div>
                                    <div class="col">
										<div class="form-group">
											<label>No. Of Rooms</label>
                                            <input type="number" min="0" class="form-control" v-model="form.no_of_bedrooms_from" placeholder="FR Min Room Configuration">
                                            <span  class="error" v-if="errors.no_of_bedrooms_from">{{ errors.no_of_bedrooms_from }}</span>
											<!-- <select class="form-control" v-model="form.no_of_bedrooms_from">
												<option value="">FR Min Room Configuration</option>
												<option>1</option>
												<option>2</option>
												<option>3</option>
												<option>4</option>
											</select> -->
										</div>
									</div>
                                    <div class="col">
										<div class="form-group">
											<label>No. Of Rooms</label>
                                            <input type="number" min="1" class="form-control" v-model="form.no_of_bedrooms_to" placeholder="TO Max Room Configuration">
                                            <span  class="error" v-if="errors.no_of_bedrooms_to">{{ errors.no_of_bedrooms_to }}</span>
											<!-- <select class="form-control" v-model="form.no_of_bedrooms_to">
												<option>TO Max Room Configuration</option>
												<option>1</option>
												<option>2</option>
												<option>3</option>
												<option>4</option>
											</select> -->
										</div>
									</div>
                                    <div class="col">
										<div class="form-group">
											<label>No. Of Baths</label>
                                            <input type="number" min="1" class="form-control" v-model="form.no_of_baths_from" placeholder="FR Min Baths Configuration">
                                            <span  class="error" v-if="errors.no_of_baths_from">{{ errors.no_of_baths_from }}</span>
											<!-- <select class="form-control" v-model="form.no_of_baths_from">
												<option>FR Min Baths Configuration</option>
												<option>1</option>
												<option>2</option>
												<option>3</option>
												<option>4</option>
											</select> -->
										</div>
									</div>
                                    <div class="col">
										<div class="form-group">
											<label>No. Of Baths</label>
                                            <input type="number" min="1" class="form-control" v-model="form.no_of_baths_to" placeholder="TO Max Baths Configuration">
                                            <span  class="error" v-if="errors.no_of_baths_to">{{ errors.no_of_baths_to }}</span>
											<!-- <select class="form-control" v-model="form.no_of_baths_to">
												<option>TO Max Baths Configuration</option>
												<option>1</option>
												<option>2</option>
												<option>3</option>
												<option>4</option>
											</select> -->
										</div>
									</div>
                                </div>
                                <div class="row row row-cols-1 row-cols-md-2">
									<div class="col">
										<div class="form-group">
											<label>CarPark Lots</label>
                                            <input type="number" min="0" class="form-control" v-model="form.no_car_park_from" placeholder="FR Min Allocation Per Unit">
										</div>
									</div>
									<div class="col">
										<div class="form-group">
											<label>CarPark Lots</label>
                                            <input type="number" min="0" class="form-control" v-model="form.no_car_park_to" placeholder="TO Max Allocation Per Unit">
										</div>
									</div>
                                </div>
                                <div class="row row row-cols-3 row-cols-md-3">
									<div class="col">
										<div class="form-group">
											<label>Land Area (SQF/SQM)</label>
                                            <input type="number" min="0"  class="form-control col" v-model="form.land_area_from" placeholder="FR Min Allocation Per Unit">
										</div>
									</div>
                                
									<div class="col">
										<div class="form-group">
											<label>Land Area (SQF/SQM)</label>
                                            <input type="number" min="0"  class="form-control col" v-model="form.land_area_to" placeholder="TO Max Allocation Per Unit"> 
										</div>
									</div>
									<div class="col">
										<div class="form-group">
											<label>Unit Size (SQF/SQM)</label>
											<select class="form-control col" v-model="form.land_area_unit">
                                                <option>FR Min Floor Area/{{ $t('built-up') }}</option>
                                                <option value="SQF">SQF</option>
                                                <option value="SQM">SQM</option>
                                            </select>
										</div>
									</div>
								</div> 								
                                <div class="row row row-cols-3 row-cols-md-3">
									<div class="col">
										<div class="form-group">
											<label>From Min Floor Area/{{ $t('built-up') }}</label>
                                            <input type="number" min="0"  class="form-control col" v-model="form.floor_area_from" placeholder="FR Min Floor Area/Built-Up">                                            
										</div>
									</div>
                                
									<div class="col">
										<div class="form-group">
											<label>To Max Floor Area/{{ $t('built-up') }}</label>
                                            <input type="number" min="0"  class="form-control col" v-model="form.floor_area_to" placeholder="TO Max Floor Area/Built-Up">                                            
										</div>
									</div>
									<div class="col">
										<div class="form-group">
											<label>Floor Size (SQF/SQM)</label>
											<select class="form-control col" v-model="form.floor_area_unit">
                                                <option value="SQF">SQF</option>
                                                <option value="SQM">SQM</option>
                                            </select>
										</div>
									</div>
								</div> 								
                            </div>
                            <div class="property-inner-box">
								<div class="comman-title">
									<h3>{{ $t('project_status') }}</h3>
								</div>
								<div class="row row-cols-1 row-cols-md-2">
									<div class="col">
										<div class="form-group">
											<label>{{ $t('project_stage') }}</label>
											<select class="form-control" v-model="form.project_status">
												<option value="">{{ $t('project_stage') }}</option>
												<option>New Launch</option>
												<option>Under Construction</option>
												<option>Completed</option>
											</select>
										</div>
									</div>
									<div class="col">
										<div class="form-group">
											<label>{{ $t('availability_date') }}</label>
											<input type="date" class="form-control" placeholder="Availability Date" v-model="form.availability_date">											 
										</div>
									</div>
								</div>								
							</div>
                            <div class="d-flex justify-content-md-end">
                                <button class="btn comman-btn-6">{{ $t('cancel') }}</button>
                                <button class="btn comman-btn-2 ms-3" type="submit">Save & Next</button>
                            </div>
                            <div class="progress-bar-box">
                                <div>{{ $t('form_completion') }}: {{ completionPercentage }}%</div>
                            <div class="progress">
                                <div 
                                    class="progress-bar" 
                                    role="progressbar" 
                                    :style="{ width: completionPercentage + '%' }" 
                                    :aria-valuenow="completionPercentage" 
                                    aria-valuemin="0" 
                                    aria-valuemax="100">
                                </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
  
  <script>
  import { mapState } from 'vuex';
  import RecursiveDropdown from '@/components/RecursiveDropdown.vue';
  import { OPTIONS_GROUP_1,PROPERTY_TYPE,PROPERTY_TYPE_MY, STATE_MY} from '@/constants/constantsProperty';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';

  
  

  export default {
    name: 'PostPropertyListing', 
    components: {
      RecursiveDropdown
    },  
    data() {
        return {
            isDisabledStep2 :'disabled',
            isDisabledStep3 :'disabled',
            isDisabledStep4 :'disabled',
            isDisabledStep5 :'disabled',
            optionsGroup1: OPTIONS_GROUP_1,
            optionsLandTenuresList: [],
            optionsLayoutTypeList: [],
            optionsFloorLevelList: [],
            suggestions: [],
            suggestionsArea: [],
            suggestionsDistrict: [],
            optionsDistrictList: [],
            districtList: [],
            filteredDistrictList: [], 

            form: {
                project_name: '',
                qualify_for_foreigner: '0',
                street:'',
                district_id: '',
                postcode: '',
                state: '',
                country: 'singapore',
                listingType: '',
                land_tenure: '',
                layout_type_id: '',
                floor_level_id: '',
                land_title_type: '',
                main_view_id: 1,
                selectedPropertyType: '',
                land_area_from: '',
                land_area_to: '',
                floor_area_unit: 'SQF',
                land_area_unit: 'SQF',
                no_of_bedrooms_from: '',
                project_status: '',
            },
            options: this.transformData(PROPERTY_TYPE),
            optionsMy: this.transformData(PROPERTY_TYPE_MY),
            optionsStateMy: this.transformData(STATE_MY),
            errors: {}
        }
    },
    computed: {
      ...mapState(['isAuthenticated', 'user']),

      hierarchicalOptions() {        
        return this.createHierarchicalOptions(this.options);
      },

      hierarchicalOptionsMy() {        
        return this.createHierarchicalOptionsMy(this.optionsMy);
      },

      propertyOptions() {
        return this.form.country === 'malaysia' ? this.hierarchicalOptionsMy : this.hierarchicalOptions;
      },

      stateMyOptions() {
        return STATE_MY;
      },

      itemId() {
        return this.$route.params.id;
      },
      completionPercentage() {
        const fields = [
            this.form.listingType,
            this.form.project_name,
            this.form.street,
            this.form.area_hdb_estate,
            this.form.district,
            this.form.district_id,
            this.form.nearest_MRT_in_m,
            this.form.nearest_bus_station_in_m,
            this.form.nearest_school_in_m,
            
            this.form.selectedPropertyType,
            this.form.land_tenure,
            this.form.land_title_type,
            this.form.project_site_land_area,
            this.form.total_blocks,
            this.form.building_height_levels,
            this.form.total_units,
            this.form.total_car_parks,

            this.form.no_of_bedrooms_from,
            this.form.no_of_bedrooms_to,
            this.form.no_of_baths_from,
            this.form.no_of_baths_to,

            this.form.no_car_park_from,
            this.form.no_car_park_to,


            this.form.floor_area_from,
            this.form.floor_area_to,

            this.form.land_area_from,
            this.form.land_area_to,

            this.form.project_status,
        ];
        const filledFields = fields.filter(field => field != null && field !== "").length;
        return Math.round((filledFields / fields.length) * 100);
      }
    },
    mounted() {
        this.fetchOptions();
        this.fetchDistricts(); 
        this.fetchAreas();
    },
    created() {
        this.fetchOptions();
        if(this.itemId > 0){
            this.isDisabledStep2 = '';
            this.isDisabledStep3 = '';
            this.isDisabledStep4 = '';
            this.isDisabledStep5 = '';
        }
        this.checkCurrentPackage();
    },
    watch: {
        'form.project_name': function (newValue) {
            if (newValue.length >= 3) {
                this.fetchProjectDetails(newValue);
            }
        },
        'form.country'(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.resetFields();
            }
        },
        // Automatically save the completion percentage whenever it changes
        completionPercentage(newPercentage) {
            console.log('Completion percentage updated to:', newPercentage);
            this.saveCompletion();
        },
    },
    methods: {

        handleCountryChange() {
            this.fetchDistricts(); 
            this.fetchAreas(); 
            this.resetFields(); 
        },

        resetFields() {
            this.form.street = '';
            this.form.area_hdb_estate = '',
            this.form.postcode = '',
            this.form.district = '',
            this.form.state = '',
            this.form.nearest_MRT_in_m = '',
            this.form.nearest_bus_station_in_m = '',
            this.form.nearest_school_in_m = ''
        },

        createHierarchicalOptionsStateMy(options) {
            return options.map(option => ({
                value: option.value,
                label: option.label
            }));
        },

        async fetchAreas() {
            try {
                const token = localStorage.getItem("userToken");
                const parent_id = this.form.country === 'singapore' ? 93 : 7; 

                // Fetch areas using the updated API structure
                const response = await this.$axios.post("/api/location", {
                    type: "Locality",
                    parent_id: parent_id,
                    api_token: token
                });

                if (response.data.status === 1 && response.data.output.length) {
                    // Map area data to label and value
                    this.areaList = response.data.output.map((d) => ({
                        label: d.name, // Assuming 'name' holds the area value
                        value: d.name
                    }));
                } else {
                    this.areaList = [];
                    console.warn("No areas found for this country.");
                }
            } catch (error) {
                console.error("Error fetching areas:", error);
            }
        },

        filterAreas() {
            if (!this.form.area_hdb_estate) {
                this.filteredAreaList = this.areaList;
                return;
            }
            const searchText = this.form.area_hdb_estate.toLowerCase();
            this.filteredAreaList = this.areaList.filter(option =>
                option.label && option.label.toLowerCase().includes(searchText)
            );
        },

        selectArea(area) {
            this.form.area_hdb_estate = area;
            this.dropdownVisible = false;
        },

        async fetchDistricts() {
            try {
                const token = localStorage.getItem('userToken');
                const parent_id = this.form.country === 'singapore' ? 93 : 7; 

                // Fetch districts using the updated API structure
                const response = await this.$axios.post("/api/location", {
                    type: "City",
                    parent_id: parent_id,
                    api_token: token
                });

                if (response.data.status === 1 && response.data.output.length) {
                    // Map district data to label and value
                    this.districtList = response.data.output.map((d) => ({
                        label: d.name,
                        value: d.name
                    }));
                } else {
                    this.districtList = [];
                    console.warn("No districts found.");
                }
            } catch (error) {
                console.error("Error fetching districts:", error);
            }
        },

        filterDistricts() {
            if (!this.form.district) {
                this.filteredDistrictList = this.districtList;
                return;
            }
            const searchText = this.form.district.toLowerCase();
            this.filteredDistrictList = this.districtList.filter(option =>
                option.label && option.label.toLowerCase().includes(searchText)
            );
        },

        selectDistrict(district) {
            this.form.district = district;
            this.dropdownVisible = false;
        },

        async checkCurrentPackage() {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('/api/my-profile-detail', {
                    api_token: token,
                    user_id: this.user.id
                });

                let userData = response.data.output;
                
                console.log('User Data:', userData);
                console.log('Package Details:', userData?.package_details);

                // Check if package_details is not null or empty and set purchasedPackage
                this.purchasedPackage = userData?.package_details ? 1 : 0;
            } catch (error) {
                console.error('Failed to fetch profile:', error);
            }
        },

        savePageCompletion(page, percentage) {
            // Save the page completion percentage to local storage
            const completionData = JSON.parse(localStorage.getItem('projectFormCompletionData')) || {};
            completionData[page] = percentage;
            localStorage.setItem('projectFormCompletionData', JSON.stringify(completionData));
        },
        saveCompletion() {
            // Save the current page's completion percentage
            const percentage = this.completionPercentage;
            this.savePageCompletion('projectPage1', percentage);
        },
        validateForm() {
            console.log(this.form.listingType);
            this.errors = {};
            if (!this.form.listingType ) {
                this.errors.listingType = 'Listing Type is required.';
            }
            if (!this.form.project_name ) {
                this.errors.project_name = 'Property Name is required.';
            }
            if (!this.form.street ) {
                this.errors.street = 'Street is required.';
            }
            if (!this.form.area_hdb_estate ) {
                this.errors.area_hdb_estate = 'Area / HDB Estate.';
            }
            if (!this.form.selectedPropertyType ) {
                this.errors.selectedPropertyType = 'Property type is required.';
            }
            if (!this.form.district ) {
                this.errors.district = 'District is required.';
            }
            if (!this.form.nearest_MRT_in_m ) {
                this.errors.nearest_MRT_in_m = 'Nearest MRT is required.';
            }
            if (!this.form.nearest_bus_station_in_m ) {
                this.errors.nearest_bus_station_in_m = 'Nearest Bus Station is required.';
            }
            if (!this.form.nearest_school_in_m ) {
                this.errors.nearest_school_in_m = 'Nearest School is required.';
            }
            if (!this.form.postcode ) {
                this.errors.postcode = 'Postcode is required.';
            }
            if (!this.form.building_height_levels ) {
                this.errors.building_height_levels = 'Building Height (Levels)  is required.';
            }
            if (!this.form.no_of_baths_to ) {
                this.errors.no_of_baths_to = 'No. Of Baths TO is required.';
            }
            if (!this.form.no_of_bedrooms_to ) {
                this.errors.no_of_bedrooms_to = 'No. Of Rooms TO  is required.';
            }
            if (!this.form.no_of_bedrooms_from ) {
                this.errors.no_of_bedrooms_from = 'No. Of Rooms FR  is required.';
            }
            if (!this.form.no_of_baths_from ) {
                this.errors.no_of_baths_from = 'No. Of Rooms FR is required.';
            }
            if (!this.form.floor_area_from ) {
                this.errors.floor_area_from = 'FR  Floor Area is required.';
            }
            if (!this.form.floor_area_to ) {
                this.errors.floor_area_to = 'TO Floor Area is required.';
            }
            return Object.keys(this.errors).length === 0;
        },        
        async submitPost() {
            
            //this.$router.push('/post-project-listing-2/'+this.itemId); 
            if (this.validateForm()) {

                // Set country code based on the selected country
                if (this.form.country === 'singapore') {
                    this.form.countryCode = '93'; 
                } else if (this.form.country === 'malaysia') {
                    this.form.countryCode = '7';
                }

                var qualify_for_foreigner = this.form.qualify_for_foreigner == true ? 1 : 0
                const token = localStorage.getItem('userToken');
                if (this.loading) return;
                this.loading = true;
                try {
                    var project_listing_id = 0;
                    if(this.itemId > 0){
                        project_listing_id = this.itemId;
                    }
                    localStorage.setItem('project_listing_id', project_listing_id);
                    const response = await this.$axios.post('api/add-update-project', {
                        user_id :this.user.id,
                        api_token: token,
                        project_listing_id:project_listing_id,

                        country_id: this.form.countryCode ?? 0, 
                        listing_type: this.form.listingType,
                        qualify_for_foreigner:qualify_for_foreigner,
                        project_name:this.form.project_name,
                        street:this.form.street,
                        area_hdb_estate:this.form.area_hdb_estate,
                        district:this.form.district,
                        district_id:this.form.district_id ?? '',
                        postcode:this.form.postcode ?? '',
                        state:this.form.state ?? '',
                        nearest_MRT_in_m:this.form.nearest_MRT_in_m,
                        nearest_bus_station_in_m:this.form.nearest_bus_station_in_m,
                        nearest_school_in_m:this.form.nearest_school_in_m,
                        
                        property_type:this.form.selectedPropertyType,
                        land_tenure:this.form.land_tenure,
                        land_title_type:this.form.land_title_type,
                        project_site_land_area:this.form.project_site_land_area,
                        total_blocks:this.form.total_blocks,
                        building_height_levels:this.form.building_height_levels,
                        total_units:this.form.total_units,
                        total_car_parks:this.form.total_car_parks,

                        no_of_bedrooms_from:this.form.no_of_bedrooms_from,
                        no_of_bedrooms_to:this.form.no_of_bedrooms_to,
                        no_of_baths_from:this.form.no_of_baths_from,
                        no_of_baths_to:this.form.no_of_baths_to,

                        no_car_park_from:this.form.no_car_park_from,
                        no_car_park_to:this.form.no_car_park_to,


                        floor_area_from:this.form.floor_area_from,
                        floor_area_to:this.form.floor_area_to,
                        floor_area_unit:this.form.floor_area_unit,

                        land_area_from:this.form.land_area_from,
                        land_area_to:this.form.land_area_to,
                        land_area_unit:this.form.land_area_unit,

                        project_status:this.form.project_status,
                        availability_date:this.form.availability_date,

                    });
                    
                    //toastr.error(response.data.message);
                    toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                    // console.log('Login successful:', response.data);
                    // console.log('id of posted', response.data.output.id);
                    var property_listing_id = response.data.output.id
                    this.$router.push('/post-project-listing-2/'+property_listing_id);         

                } catch (error) {
                    
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },

        async fetchProjectDetails(projectName) {
            try {
                const token = localStorage.getItem('userToken');
                
                // Make an API request using axios
                const response = await this.$axios.post('/api/project-details', {
                    user_id :this.user.id,
                    api_token: token,
                    project_name: projectName,
                }, {
                });

                // Handle the response
                if (response.data.success) {
                    this.form.street = response.data.data.street || '';
                    this.form.area_hdb_estate = response.data.data.area_hdb_estate || '';
                    this.form.district_id = response.data.data.district_id || '';
                    this.form.district = response.data.data.district || '';
                }
            } catch (error) {
                console.error(error.response ? error.response.data.message : error.message);
            }
        },

        async fetchPostcodeDetails(postcode) {
            try {
                const token = localStorage.getItem('userToken');
                
                // Make an API request using axios
                const response = await this.$axios.post('/api/project-postcode-details', {
                    user_id :this.user.id,
                    api_token: token,
                    postcode: postcode,
                }, {
                });

                // Handle the response
                if (response.data.success) {
                    this.form.district_id = response.data.data.district_id || '';
                    this.form.district = response.data.data.district || '';
                    this.form.state = response.data.data.state || '';
                }
            } catch (error) {
                // Handle errors
                console.error(error.response ? error.response.data.message : error.message);
            }
        },

        clearAddressFields() {
            this.form.street = '';
            this.form.area_hdb_estate = '';
            this.form.district_id = '';
            this.form.district = '';
        },

        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },  

        transformData(data) {
            const map = {};
            const result = [];
    
            data.forEach(item => {
            map[item.id] = { ...item, children: [] };
            });
    
            data.forEach(item => {
            if (item.parent_id === 0) {
                result.push(map[item.id]);
            } else {
                map[item.parent_id].children.push(map[item.id]);
            }
            });
    
            return result;
        },

        createHierarchicalOptions(data) {
            return data.map(option => this.createOptionGroup(option));
        },

        createHierarchicalOptionsMy(data) {
            return data.map(optionMy => this.createOptionGroup(optionMy));
        },

        createOptionGroup(option) {

            if (!option.children || option.children.length === 0) {
                return {
                    type: 'option',
                    id: option.id,
                    label: option.name,
                    depth: option.depth,
                };
            }
            return {
                type: 'optgroup',
                label: option.name,
                depth: option.depth,
                children: option.children.map(child => this.createOptionGroup(child))
            };

        },

        changeDistrict() {
            const selectedValue = this.form.district_id; // Get the value from v-model
            const selectedOption = this.optionsDistrictList.find(option => option.value === selectedValue);
            
            if (selectedOption) {
                const selectedLabel = selectedOption.label;
                this.form.district = selectedLabel; // Set the district name to the form field
            }
        },

        async fetchOptions() {

            const token = localStorage.getItem('userToken');

            // Get District list
            try {
                const parent_id = this.form.country === 'singapore' ? 93 : 7; 
                const response = await this.$axios.post('api/location',
                {                    
                    type : "City",
                    keyword : this.street, 
                    parent_id: parent_id                
                });
                this.optionsDistrictList = response.data.output.map(item => ({
                    value: item.id,
                    label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }

            // Get Land Tenure list
            try {
                const response = await this.$axios.post('api/pr-land-tenures-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsLandTenuresList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Get Layout type list
            try {
                const response = await this.$axios.post('api/pr-layout-features-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsLayoutTypeList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Get Floor level
            try {
                const response = await this.$axios.post('api/pr-floor-levels-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsFloorLevelList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }

            if(this.itemId > 0){
                
                try {
                    const response = await this.$axios.post('api/project-detail',
                    {                    
                        user_id :this.user.id,
                        api_token: token,
                        project_listing_id:this.itemId,                    
                    });
                    this.editData = response.data.output[0];
                    console.log(this.editData);
                    this.form.listingType = this.editData.listing_type;
                    this.form.qualify_for_foreigner = this.editData.qualify_for_foreigner == 1 ? true : false;
                    this.form.project_name = this.editData.project_name;
                    this.form.street = this.editData.street;
                    this.form.selectedPropertyType = this.editData.property_type;
                    this.form.area_hdb_estate = this.editData.area_hdb_estate;
                    this.form.country = this.editData.country_id == 93 ? "singapore" : "malaysia";
                    this.form.district = this.editData.district;
                    this.form.district_id = this.editData.district_id ?? '';
                    this.form.land_tenure = this.editData.land_tenure;
                    this.form.land_title_type = this.editData.land_title_type;
                    this.form.nearest_MRT_in_m = this.editData.nearest_MRT_in_m;
                    this.form.nearest_bus_station_in_m = this.editData.nearest_bus_station_in_m;
                    this.form.nearest_school_in_m = this.editData.nearest_school_in_m;
                    
                    
                    this.form.project_site_land_area = this.editData.project_site_land_area;
                    this.form.total_blocks = this.editData.total_blocks;
                    this.form.building_height_levels = this.editData.building_height_levels;
                    this.form.total_car_parks = this.editData.total_car_parks;
                    this.form.total_units = this.editData.total_units;


                    this.form.no_of_baths_from = this.editData.no_of_baths_from;
                    this.form.no_of_baths_to = this.editData.no_of_baths_to;

                    this.form.no_of_bedrooms_from = this.editData.no_of_bedrooms_from;
                    this.form.no_of_bedrooms_to = this.editData.no_of_bedrooms_to;

                    this.form.no_car_park_from = this.editData.no_car_park_from;
                    this.form.no_car_park_to = this.editData.no_car_park_to;

                    this.form.land_area_from = this.editData.land_area_from;
                    this.form.land_area_to = this.editData.land_area_to;
                    this.form.land_area_unit = this.editData.land_area_unit;
                    
                    this.form.floor_area_from = this.editData.floor_area_from;
                    this.form.floor_area_to = this.editData.floor_area_to;
                    this.form.floor_area_unit = this.editData.floor_area_unit;

                    
                    

                    this.form.project_status = this.editData.project_status;
                    this.form.availability_date = this.editData.availability_date;
                } catch (error) {
                    console.error('Error fetching options:', error);
                }
            }
        },

        async fetchSuggestions() {
            if (this.form.street.length < 2) {
                this.suggestions = []; // Clear suggestions if query is too short
                return;
            }
            try {
                const parent_id = this.form.country === 'singapore' ? 93 : 7; 
                const response = await this.$axios.post('api/location',
                {                    
                    type : "Locality",
                    keyword : this.street, 
                    parent_id: parent_id
                });
                this.suggestions = response.data.output.map(item => item.name).filter(name => name.toLowerCase().includes(this.form.street.toLowerCase()));

            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        },

        async fetchSuggestionsArea() {
            if (this.form.area_hdb_estate.length < 2) {
                this.suggestionsArea = []; // Clear suggestions if query is too short
                return;
            }

            try {
                const parent_id = this.form.country === 'singapore' ? 93 : 7; 
                const response = await this.$axios.post('api/location', {
                    type: "Locality",
                    keyword: this.form.area_hdb_estate,
                    parent_id: parent_id
                });

                this.suggestionsArea = response.data.output
                    .map(item => item.name)
                    .filter(name => name.toLowerCase().includes(this.form.area_hdb_estate.toLowerCase()));

            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        },

        async fetchSuggestionsDistrict() {
            if (this.form.district.length < 2) {
                this.suggestionsDistrict = []; // Clear suggestions if query is too short
                return;
            }
            try {
                const parent_id = this.form.country === 'singapore' ? 93 : 7; 
                const response = await this.$axios.post('api/location',
                {                    
                    type : "City",
                    keyword : this.district, 
                    parent_id: parent_id
                });
                // this.suggestionsDistrict = response.data.output.map(item => item.name).filter(name => name.toLowerCase().includes(this.form.district.toLowerCase()));
                this.suggestionsDistrict = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));

            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        },

        selectSuggestion(suggestion) {
            this.form.street = suggestion;
            this.suggestions = [];
        },
        selectSuggestionArea(suggestion) {
            this.form.area_hdb_estate = suggestion;
            this.suggestionsArea = [];
        },
        selectSuggestionDistrict(suggestion) {
            this.form.district = suggestion.label;
            this.form.district_id = suggestion.value;
            this.suggestionsDistrict = [];
        },
    }
  }
  </script>
  
  <style scoped>
    .sugesstion_ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        border: 1px solid #ddd;
        position: absolute;
        background: #fff;
        z-index: 10;
    }
    .sugesstion_ul li {
        cursor: pointer;
        padding: 8px;
    }
    .sugesstion_ul li:hover {
        background-color: #f0f0f0;
    }
  </style>
  