<template>
    <section class="property-data-analytic-se">
        <div class="container">
            <div class="top-tabs-design" >
                <ul>
                    <li><a href="/profile">{{ $t('my_profile') }}</a></li>
                    <li v-if="!user.is_private_user">
                        <a href="/verify-service-detail-page">{{ $t('verify_service_details') }}</a>
                    </li>
                    <li><a href="javascript:;"  class="active">{{ $t('analytics_and_report') }}</a></li>
                    <li><a href="/wishlist">{{ $t('my_wishlist') }}</a></li>
                    <li><a href="/proxy-listing-page"> Proxy Listing </a></li>
                    <li><a href="/requested-service">{{ $t('requested_service') }}</a></li>
                    <li><a href="/messages">{{ $t('messages') }}</a></li>
                </ul>
            </div>
            <div class="row">
                <div class="col-md-9">
                    <div class="top-tabs-design" v-if="canViewProjectAnalytic">
                        <ul>
                            <li><a href="/javascript:;" class="active">{{ $t('property') }}</a></li>
                            <li><a href="/project-data-analytic">{{ $t('project') }}</a></li>
                        </ul>
                    </div>
                    <div class="dashboard">
                        <div class="container py-4">
                            <div class="row text-center mb-4">
                                <div class="col-md-4">
                                    <div class="card shadow-sm">
                                        <div class="card-body">
                                            <h5 class="card-title">{{ $t('total') }} {{ $t('views') }}</h5>
                                            <p>{{ totalViews }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="card shadow-sm">
                                        <div class="card-body">
                                            <h5 class="card-title">{{ $t('total') }} {{ $t('inquiries') }}</h5>
                                            <p>{{ totalInquiries }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="card shadow-sm">
                                        <div class="card-body">
                                            <h5 class="card-title">{{ $t('conversion_rate') }}</h5>
                                            <p>{{ conversionRate ?? 0 }}%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row text-center mb-4">
                                <div class="col-md-6">
                                    <div class="card shadow-sm">
                                        <div class="card-body">
                                            <h5 class="card-title">{{ $t('total') }} {{ $t('active_property_listings') }}</h5>
                                            <p>{{ totalActive }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card shadow-sm">
                                        <div class="card-body">
                                            <h5 class="card-title">{{ $t('total') }} {{ $t('property_leads') }}</h5>
                                            <p>{{ totalPropertyLeads }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <h4 class="mt-4">{{ $t('properties_data_insights') }}</h4>
                        <PropertyInsightReport></PropertyInsightReport>
                        <hr>
                        <h4 class="mt-4">{{ $t('rented') }}/{{ $t('sold') }} {{ $t('properties') }}</h4>
                        <PassPropertiesReport></PassPropertiesReport>
                        <hr>
                        <ServiceRequestReport></ServiceRequestReport>
                        <hr>
                        <h4 class="mt-4">{{ $t('property_leads') }}</h4>
                        <LeadReport></LeadReport>
                    </div>
                </div>
                <ProfilePageSideBar></ProfilePageSideBar>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapState} from "vuex";
    import PropertyInsightReport from "@/views/tool/reports/PropertyInsightReport.vue";
    import PassPropertiesReport from "@/views/tool/reports/PassPropertiesReport.vue";
    import LeadReport from "@/views/tool/reports/LeadReport.vue";
    import ServiceRequestReport from "@/views/tool/reports/ServiceRequestReport.vue";
    import ProfilePageSideBar from "@/views/ProfilePageSideBar.vue";

    export default {
        name: 'PropertyDataAnalytic',
        computed: {
            ...mapState(['isAuthenticated', 'user', 'userProfile']),
            canViewProjectAnalytic() {
                return !!this.user.is_corporate;
            },
        },
        components: {
            ProfilePageSideBar,
            ServiceRequestReport,
            LeadReport,
            PassPropertiesReport,
            PropertyInsightReport,
        },
        data() {
            return {
                conversionRate: null,
                totalViews: 0,
                totalInquiries: 0,
                totalActive: 0,
                totalPropertyLeads: 0,
            }
        },
        created() {
            this.fetchData();
            this.fetchConversionRate();
            this.fetchMessageDetail();
        },
        methods: {
            //Fetch Data from backend
            async fetchConversionRate() {
                const token = localStorage.getItem('userToken');
                try {
                    if (this.user) {
                        const response = await this.$axios.get(`/api/users/${this.user.id}/conversion-rate`,
                        {
                            params: {
                                api_token: token,
                            }
                        });
                        this.conversionRate = response.output;
                    }
                } catch (error) {
                    console.error('Unable to fetch conversion rate');
                }
            },
            async fetchData() {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.post('api/get-property-analytic-detail',{
                        api_token: token,
                        user_id: this.user.id,
                    });

                    this.totalViews = response.data.output.total_views;
                    this.totalInquiries = response.data.output.total_inquiries;
                    this.totalActive = response.data.output.total_active_property;

                } catch (error) {
                    console.error('Unable to fetch data, error:', error.message);
                }
            },
            async fetchMessageDetail() {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.post('api/get-message-details',{
                        api_token: token,
                        user_id: this.user.id,
                        property: 1,
                    });

                    this.totalPropertyLeads = response.data.output.total_chat;
                } catch (error) {
                    console.error('Unable to fetch data, error:', error.message);
                }
            },
        }
    }
</script>

