<template>
    <section class="requested-service-page">
        <div class="container">
            <div class="top-tabs-design">
                <ul>
                    <li><a href="/profile">{{ $t('my_profile') }}</a></li>
                    <li v-if="!user.is_private_user"><a href="/verify-service-detail-page">{{ $t('verify_service_details') }}</a></li>
                    <li v-if="!user.is_private_user"><a href="/property-data-analytic">{{ $t('analytics_and_report') }}</a></li>
                    <li><a href="/wishlist">{{ $t('my_wishlist') }}</a></li>
                    <li><a href="/proxy-listing-page"> Proxy Listing </a></li>
                    <li><a href="javascript:;" class="active">{{ $t('requested_service') }}</a></li>
                    <li><a href="/messages">{{ $t('messages') }}</a></li>
                </ul>
            </div>
            <div class="row">
                <div class="col-md-9">
                    <div class="cardbox-header">
                        <ul class="nav nav-tabs" id="Tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="pending-tab" data-bs-toggle="tab" data-bs-target="#pending" type="button" role="tab" aria-controls="pending" aria-selected="true">{{ $t('pending_review') }}</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="approved-tab" data-bs-toggle="tab" data-bs-target="#approved" type="button" role="tab" aria-controls="approved" aria-selected="false">{{ $t('accepted') }}</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="rejected-tab" data-bs-toggle="tab" data-bs-target="#rejected" type="button" role="tab" aria-controls="rejected" aria-selected="false">{{ $t('declined') }}</button>
                            </li>
                        </ul>
                    </div>

                    <div class="tab-content" id="myTabContent">
                        <!-- Pending Tab -->
                        <div class="tab-pane active" id="pending" role="tabpanel" aria-labelledby="pending-tab">
                            <div class="card-body">
                                <template v-if="pendingRequests.length > 0">
                                    <template v-for="request in pendingRequests" :key="request?.id">
                                        <div class="cardbox-1 mb-3">
                                            <p><b>{{ !isAgent ? $t('agent_name') : $t('user') }}:</b> {{ !isAgent ? request.agent?.name :
                                                request.user?.name}} </p>
                                            <p><b>{{ $t('property_name') }}:</b> {{ request.property_listing.property_name}} </p>
                                            <p><b>{{ $t('engagement_period') }}:</b> {{ request.engagement_period }} {{ $t('months') }}</p>
                                            <p><b>{{ $t('commission') }}:</b> {{ request.commission }}%</p>
                                            <p><b>{{ $t('appointment_type') }}:</b> {{ request.appointment_type_text }}</p>
                                            <p><b>{{ $t('status') }}:</b> {{ request.status_text }}</p>
                                            <button v-if="!isAgent" data-bs-toggle="modal" data-bs-target="#editModal" class="comman-btn-2 mb-3 w-100"
                                                    @click="setSelectedRequest(request)">
                                                {{ $t('edit_request') }}
                                            </button>
                                            <div v-if="request.status === CONSTANT.STATUS_TYPES.PENDING && isAgent"
                                                 class="d-flex justify-content-end gap-2">
                                                <button @click="approveRequest(request.id)" class="comman-btn-2">{{ $t('approve') }}</button>
                                                <button @click="rejectRequest(request.id)" class="comman-btn-2-danger">{{ $t('reject') }}</button>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>{{ $t('no_records_found') }}</template>
                            </div>
                        </div>

                        <!-- Approved Tab -->
                        <div class="tab-pane fade" id="approved" role="tabpanel" aria-labelledby="approved-tab">
                            <div class="card-body">
                                <template v-if="approvedRequests.length > 0">
                                    <template v-for="request in approvedRequests" :key="request.id">
                                        <div class="cardbox-1 mb-3">
                                            <p><b>{{ !isAgent ? $t('agent_name') : $t('user') }}:</b> {{ !isAgent ? request.agent?.name :
                                                request.user?.name}} </p>
                                            <p><b>{{ $t('property_name') }}:</b> {{ request.property_listing?.property_name }} </p>
                                            <p><b>{{ $t('engagement_period') }}:</b> {{ request.engagement_period }} Months</p>
                                            <p><b>{{ $t('engagement_from') }}:</b> {{ request.property_listing?.serviced_by_agents[0]?.pivot?.valid_from }}</p>
                                            <p><b>{{ $t('engagement_to') }}:</b> {{ request.property_listing?.serviced_by_agents[0]?.pivot?.valid_to }}</p>
                                            <p><b>{{ $t('commission') }}:</b> {{ request.commission }}%</p>
                                            <p><b>{{ $t('appointment_type') }}:</b> {{ request.appointment_type_text }}</p>
                                            <p><b>{{ $t('status') }}:</b> {{ request.status_text }}</p>
                                            <button v-if="!isAgent" data-bs-toggle="modal" data-bs-target="#editModal"
                                                    class="comman-btn-2 mb-3 w-100" @click="setSelectedRequest(request)">
                                                {{ $t('edit_request') }}
                                            </button>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>{{ $t('no_records_found') }}</template>
                            </div>
                        </div>

                        <!-- Rejected Tab -->
                        <div class="tab-pane fade" id="rejected" role="tabpanel" aria-labelledby="rejected-tab">
                            <div class="card-body">
                                <template v-if="declinedRequests.length > 0">
                                    <template v-for="request in declinedRequests" :key="request?.id">
                                        <div class="cardbox-1 mb-3">
                                            <p><b>{{ !isAgent ? $t('agent_name') : $t('user') }}:</b> {{ !isAgent ? request.agent?.name :
                                                request.user?.name}} </p>
                                            <p><b>{{ $t('property_name') }}:</b> {{ request.property_listing?.property_name }} </p>
                                            <p><b>{{ $t('engagement_period') }}:</b> {{ request.engagement_period }} {{ $t('months') }}</p>
                                            <p><b>{{ $t('commission') }}:</b> {{ request.commission }}%</p>
                                            <p><b>{{ $t('appointment_type') }}:</b> {{ request.appointment_type_text }}</p>
                                            <p><b>{{ $t('status') }}:</b> {{ request.status_text }}</p>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>{{ $t('no_records_found') }}</template>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade bd-example-modal-lg" id="editModal" tabindex="-1" aria-labelledby="editModal" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="editModalLabel">{{ $t('edit_request') }}</h5>
                                    <button type="button" class="border-0 bg-transparent" style="color: black" data-bs-dismiss="modal" aria-label="close">X</button>
                                </div>
                                <div class="modal-body">
                                    <form @submit.prevent="updateRequest">
                                        <div class="mb-3">
                                            <label for="appointmentTypeEdit" class="form-label">{{ $t('appointment_type') }}</label>
                                            <div v-if="selectedRequest && selectedRequest.credit_status === CONSTANT.CREDIT_STATUS.BEFORE">
                                                <select v-model="form.appointment_types" class="form-control" id="appointmentTypeEdit" required>
                                                    <option value="" disabled>{{ $t('select_type') }}</option>
                                                    <option v-for="(key, type) in CONSTANT.APPOINTMENT_TYPES" :key="type" :value="key">
                                                        {{ type }}
                                                    </option>
                                                </select>

                                                <label><b>{{ $t('engagement_period') }}:</b></label>
                                                <select v-model="form.engagement_period" class="form-control" id="engagementPeriod" required>
                                                    <option disabled selected value="">{{ $t('select_period') }}</option>
                                                    <option value="1">1 {{ $t('month') }}</option>
                                                    <option value="3">3 {{ $t('months') }}</option>
                                                    <option value="6">6 {{ $t('months') }}</option>
                                                </select>

                                                <label><b>{{ $t('fee') }}/{{ $t('commission') }}:</b></label>
                                                <input v-model="form.commission" type="number" class="form-control" id="commission" placeholder="2.00%" required/>
                                            </div>
                                            <div v-else>
                                                <select v-model="approved_form.appointment_types" class="form-control" id="appointmentTypeEdit" required>
                                                    <option value="" disabled>{{ $t('select_type') }}</option>
                                                    <option v-for="(key, type) in CONSTANT.APPOINTMENT_TYPES" :key="type" :value="key">
                                                        {{ type }}
                                                    </option>
                                                </select>
                                            </div>

                                        </div>

                                        <div class="text-center">
                                            <button type="submit" class="col-md-12 comman-btn-2" data-bs-dismiss="modal">{{ $t('update') }}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProfilePageSideBar></ProfilePageSideBar>
            </div>
        </div>
    </section>
</template>

<script>
    import {CONSTANT} from "@/constants/constants";
    import {mapState} from "vuex";
    import toastr from 'toastr';
    import ProfilePageSideBar from "@/views/ProfilePageSideBar.vue";

    export default {
        name: 'RequestedServicePage',
        components: {ProfilePageSideBar},
        data() {
            return {
                isAgent: null,
                requests:[],
                user_credit: null,
                approved_form: {
                    appointment_types: '',
                },
                form:{
                    appointment_types: '',
                    engagement_period: '',
                    commission: '',
                },
                selectedRequest: null,
            }
        },
        computed:{
            CONSTANT() {
                return CONSTANT
            },
            ...mapState(['user', 'userProfile', 'isAuthenticated']),
            pendingRequests() {
                return this.requests.filter(request => request.status === CONSTANT.STATUS_TYPES.PENDING)
            },
            approvedRequests() {
                return this.requests.filter(request => request.status === CONSTANT.STATUS_TYPES.APPROVED)
            },
            declinedRequests() {
                return this.requests.filter(request => request.status === CONSTANT.STATUS_TYPES.REJECTED)
            }
        },
        methods: {
            setSelectedRequest(request)
            {
                if (!request) {
                    console.error('No request provided');
                    return;
                }
                this.selectedRequest = request;

                this.form.appointment_types = request.appointment_type;
            },
            async checkUser()
            {
                const url = `/api/users/${this.user.id}/service-requests`;
                try {
                    const response = await this.$axios.get(url, {
                        params: {
                            api_token: localStorage.getItem('userToken'),
                            page: 1,
                            limit: 10,
                        },
                    });

                    const service_requests = response.data.output.service_requests.data;
                    const is_agent  = response.data.output.is_agent;
                    this.user_credit = response.data.output.user_credit;

                    this.isAgent = is_agent;
                    this.requests = service_requests;
                } catch (error) {
                    console.error('Failed to load requests:', error);
                }
            },
            async approveRequest(requestId)
            {
                const url = '/api/service-requests/' + requestId + '/approve';
                try {
                    const response = await this.$axios.post(url,{
                        api_token: localStorage.getItem('userToken'),
                    })

                    if (response.data.status === 1) {
                        toastr.success('Request approved successfully.', { timeOut: 3000 });

                        await this.checkUser();
                    }
                } catch (error) {
                    console.error('Failed to approve request:', error);
                }
            },
            async rejectRequest(requestId)
            {
                const url = '/api/service-requests/' + requestId + '/reject';

                try {
                    const response = await this.$axios.post(url, {
                        api_token: localStorage.getItem('userToken'),
                    });
                    if (response.data.status === 1) {
                        toastr.success('Request rejected successfully.',  { timeOut: 3000 });
                        await this.checkUser();
                    }
                } catch (error) {
                    console.error('Failed to reject request:', error);
                }
            },
            async updateRequest()
            {
                if (!this.selectedRequest) {
                    alert('No request selected for editing.');
                    return;
                }

                try {
                    let payload = [];
                    if (this.selectedRequest.credit_status === CONSTANT.CREDIT_STATUS.BEFORE) {
                        payload = {
                            api_token: localStorage.getItem('userToken'),
                            appointment_type: this.form.appointment_types,
                            engagement_period: this.form.engagement_period,
                            commission: this.form.commission,
                        };
                    } else {
                        payload = {
                            api_token: localStorage.getItem('userToken'),
                            appointment_type: this.approved_form.appointment_types
                        };
                    }


                    await this.$axios.put(`/api/service-requests/${this.selectedRequest.id}`, payload);
                    await this.checkUser();
                } catch (error) {
                    toastr.error('Failed to updated request: ',error);
                }
            },
        },
        created()
        {
            this.checkUser();
        },
    };
</script>


<style scoped>
    .container {
        margin-top: 20px;
    }

    .cardbox-1  {
        border-radius: 20px;
        border: solid 1px #dee2e6;
        padding: 20px;
    }

    .cardbox-header {
        padding: 20px;
    }

</style>