<template>    
    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3 v-if="this.itemId>0"><a href="#"><i class="fa fa-angle-left"></i></a> {{ $t('edit') }} {{ $t('property_listing') }}</h3>
                    <h3 v-if="this.itemId==0"><a href="#"><i class="fa fa-angle-left"></i></a> {{ $t('create') }} {{ $t('property_listing') }}</h3>
                </div>
                <div class="progress-bar-box">
                    <div>4/5</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <!-- <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div> -->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>
                            
                            <li><router-link :to="`/post-property-listing/${itemId}`">{{ $t('step') }} 1</router-link></li>
                            <li><router-link :to="`/post-property-listing-2/${itemId}`">{{ $t('step') }} 2</router-link></li>
                            <li><router-link :to="`/post-property-listing-3/${itemId}`">{{ $t('step') }} 3</router-link></li>
                            <li><a class="active" href="javascript:;">{{ $t('step') }} 4</a></li>                            
                            <li><router-link :to="`/post-property-listing-5/${itemId}`">{{ $t('step') }} 5</router-link></li>							
                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <form @submit.prevent="submitPost">
                        <div class="property-box mb-4">							 
                            <div class="property-inner-box mb-0">
                                <div class="comman-title">
                                    <h3>{{ $t('upload_files_and_insert_media_links') }}</h3>
                                </div>
                                <div class="row row-cols-1 row-cols-md-4">
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openBrowsePopup" data-id="up">{{ $t('actual_unit_photos') }} <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <div class="badge-list-post listofUpload_unit_photo w-100 overflow-scroll">
                                                <span v-for="option in actual_unit_photo_list" :key="option.id" :value="option.id"
                                                    :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}">
                                                    <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${option.photo_name}`" target="_blank" class="text-white"> 
                                                        {{ option.photo_original_name }} &nbsp;
                                                    </a>
                                                    <a class="fa fa-trash text-white deleteUploadACtualUnitPhoto" data-deleteType="mb" :id="option.id"></a>
                                                </span>
                                                <div class="error" v-if="errors.actual_unit_photo_list">{{ errors.actual_unit_photo_list }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <!-- <a href="#" class="comman-btn-1">{{ $t('unit_layout_and_site_plan') }} <img src="frontend/images/upload-icon.svg" alt=""></a> -->
                                            <a href="javascript:;" class="comman-btn-1 openBrowsePopup"  data-id="ul"  >{{ $t('unit_layout_and_site_plan') }} <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <div class="w-100 overflow-scroll">
                                                <span v-for="option in otherDocListUL" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                    <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white otherDocDelete"> {{option.photo_original_name}} &nbsp;</a>
                                                    <span class="fa fa-trash text-white  deleteUploadFiles" data-deleteType="mb" :id="option.id">
                                                        <span class="visually-hidden">X</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openBrowsePopup"  data-id="mb" >{{ $t('marketing_brochure') }}
                                                <img src="frontend/images/upload-icon.svg" alt="">
                                            </a>
                                            <div class="w-100 overflow-scroll">
                                                <span
                                                v-for="option in otherDocListMB" :key="option.id" :value="option.id"
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                    <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white otherDocDelete"> {{option.photo_original_name}} &nbsp;</a>
                                                    <span class="fa fa-trash text-white  deleteUploadFiles" data-deleteType="mb" :id="option.id">
                                                        <span class="visually-hidden">X</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openBrowsePopup"  data-id="vab" >
                                                {{ $t('verify_authentic_badge') }}
                                                <img src="frontend/images/upload-icon.svg" alt="">
                                            </a>
                                            <div class="w-100 overflow-scroll">
                                                <span v-for="option in otherDocListAVB" :key="option.id" :value="option.id"
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                    <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white otherDocDelete"> {{option.photo_original_name}} &nbsp;</a>
                                                    <span class="fa fa-trash text-white  deleteUploadFiles" data-deleteType="mb" :id="option.id">
                                                        <span class="visually-hidden">X</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <a href="javascript:;" class="comman-btn-6 openMedialinkPopup"> + {{ $t('add') }} {{ $t('media_link') }}</a>
                                        <div class="badge-list-post listofMedia_link w-100 overflow-scroll">
                                            <span v-for="option in mediaLinksList" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`media_addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}">
                                                <a :href="`${option.link_url}`" target="_blank" class="text-white"> {{option.link_name}} ( {{option.link_type}} ) &nbsp;</a>
                                                <a class="fa fa-trash text-white  deleteMediaLink" :id="option.id"></a>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div> 
                        </div>
                        <div class="property-box">							 
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>{{ $t('upload_documents_for_verification') }} & {{ $t('earn_verified') }}/{{ $t('trusted_badge') }}</h3>
                                </div>
                                <div class="row row-cols-1 row-cols-md-2">
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openDocVerificationPopup" data-doc_type="Property Title & Deeds" >Property Title & Deeds <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <span v-for="option in verifyDocListPTD" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`verify_addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white "> {{option.photo_original_name}} &nbsp;</a> 
                                                <a class="fa fa-trash text-white  deleteVerofyDoc" :id="option.id"></a>
                                            </span>
                                        </div>

                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openDocVerificationPopup" data-doc_type="Sale & Purchase Agreement" > {{ $t('sale_and_purchase_agreement') }} 
                                                    <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <span v-for="option in verifyDocListSPA" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`verify_addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white "> {{option.photo_original_name}} &nbsp;</a> 
                                                <a class="fa fa-trash text-white  deleteVerofyDoc" :id="option.id"></a>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openDocVerificationPopup" data-doc_type="Loan Facility Agreement" >{{ $t('loan_facility_agreement') }}  <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <span v-for="option in verifyDocListLFA" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`verify_addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white "> {{option.photo_original_name}} &nbsp;</a> 
                                                <a class="fa fa-trash text-white  deleteVerofyDoc" :id="option.id"></a>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openDocVerificationPopup" data-doc_type="Utility, Tax & Assessment Bills" >{{ $t('utility,tax_and_assessment_bills') }}  <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <span v-for="option in verifyDocListUTAB" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`verify_addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white "> {{option.photo_original_name}} &nbsp;</a> 
                                                <a class="fa fa-trash text-white  deleteVerofyDoc" :id="option.id"></a>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openDocVerificationPopup" data-doc_type="Registered Intent To Sell (For HDB Only)" >{{ $t('registered_intent_to_sell') }}  ({{ $t('for_HDB_only') }}) <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <span v-for="option in verifyDocListRIS" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`verify_addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white "> {{option.photo_original_name}} &nbsp;</a> 
                                                <a class="fa fa-trash text-white  deleteVerofyDoc" :id="option.id"></a>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openDocVerificationPopup" data-doc_type="Letter Of Appointment (Estate Agents)" >{{ $t('letter_of_appointment') }} ({{ $t('estate_agents') }}) <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <span v-for="option in verifyDocListLOA" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`verify_addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white "> {{option.photo_original_name}} &nbsp;</a> 
                                                <a class="fa fa-trash text-white  deleteVerofyDoc" :id="option.id"></a>
                                            </span>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div class="d-flex justify-content-md-end">
                                <!-- <a class="btn comman-btn-6" href="/post-property-listing-3">Previous </a> -->
                                <router-link :to="`/post-property-listing-3/${itemId}`" class="btn comman-btn-6">{{ $t('previous') }}</router-link>
                                <button class="btn comman-btn-2 ms-3" type="submit">Save & Next</button>
                            </div>
                            <div class="progress-bar-box">
                                <div>{{ $t('form_completion') }}: {{ completionPercentage }}%</div>
                            <div class="progress">
                                <div 
                                    class="progress-bar" 
                                    role="progressbar" 
                                    :style="{ width: completionPercentage + '%' }" 
                                    :aria-valuenow="completionPercentage" 
                                    aria-valuemin="0" 
                                    aria-valuemax="100">
                                </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <br>
                </div>
                
            </div>
            
        </div>

        <!-- Modal Popup -->

        <!-- upload photos modal -->
        <div class="modal fade" id="upload-photos-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">			
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                <div class="modal-body text-center">
                    <form id="file-upload-form" class="uploader">
                        <input id="file-upload" type="file" @change="onFileChange" name="fileUploadImg" multiple accept="image/*" />
                            <input type="hidden" name="type_photos"  id="type_photos" value="1">
                            <label for="file-upload" id="file-drag">
                                <img id="file-image" src="#" alt="Preview" class="hidden">
                                <div id="start">
                                    <img src="frontend/images/big-upload-icom.svg" alt="">
                                    <div>
                                        <h5 class="title_photos_upload">{{ $t('please_upload_your_site_plan') }}</h5>
                                        <p class="mb-0">&lt;Types Of File Formats&gt; JPG, PNG <br>
                                        &lt;Max Size Per File&gt; 05 - 10 PX <br>
                                        &lt;Max No. Of Files&gt; 4 </p>
                                    </div>	
                                    <div id="notimage" class="hidden">{{ $t('please_select_an_image') }}</div>
                                </div>
                                <div id="response" class="hidden">
                                    <div id="messages"></div>
                                    <progress class="progress" id="file-progress" value="0">
                                        <span>0</span>%
                                    </progress>
                                </div>
                            </label>
                        </form>
                    </div>
                </div>
            </div>
        </div>  
        
        
        <!-- upload Verify Doc modal -->
        <div class="modal fade" id="upload-verifyDoc-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">			
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                <div class="modal-body text-center">
                    <form id="verify_doc-upload-form" class="uploader">
                        <input id="file-upload-verify" type="file" @change="onFileVerifyDocChange" name="fileUpload" multiple accept="image/*,application/pdf" />
                            <input type="hidden" name="verify_doc_type"  id="verify_doc_type" value="">
                            <label for="file-upload-verify" id="file-drag">
                                <img id="file-image" src="#" alt="Preview" class="hidden">
                                <div id="start">
                                    <img src="frontend/images/big-upload-icom.svg" alt="">
                                    <div>
                                        <h5 class="title_verify_doc_type">{{ $t('please_upload_your_site_plan') }}</h5>
                                        <p class="mb-0">&lt;Types Of File Formats&gt; JPG, PNG <br></p>
                                    </div>	
                                    <div id="notimage" class="hidden">{{ $t('please_select_an_image') }}</div>
                                </div>
                                <div id="response" class="hidden">
                                    <div id="messages"></div>
                                    <progress class="progress" id="file-progress" value="0">
                                        <span>0</span>%
                                    </progress>
                                </div>
                            </label>
                        </form>
                    </div>
                </div>
            </div>
        </div>  

        <!--  Add Media linkst -->
        <div class="modal fade" id="add-media-link-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                    <div class="modal-body">                           
                       
                        <div class="comman-title">
                            <h3>{{ $t('add') }} {{ $t('media_link') }}</h3>
                        </div>
                        <form @submit.prevent="submitAddMediaLink">
                            <div class="row row-cols-md-1">
                                <div class="col">
                                    <div class="form-group">
                                        <label>{{ $t('link_type') }}</label>
                                        <select class="form-control" v-model="form.link_type">
                                            <option value="" disabled>{{ $t('link_type') }}</option>
                                            <option>OutsideLink</option>
                                            <option>{{ $t('embed') }}</option>
                                        </select>
                                        <span  class="error" v-if="errors.link_type">{{ errors.link_type }}</span>
                                    </div>
                                </div>                                   
                                <div class="col">
                                    <div class="form-group">
                                        <label>{{ $t('link_name') }}</label>
                                        <input id="link_name" class="form-control" type="text" v-model="form.link_name" placeholder="Link name">
                                        <span  class="error" v-if="errors.link_name">{{ errors.link_name }}</span>
                                    </div>
                                </div>                                   
                                <div class="col">
                                    <div class="form-group">
                                        <label>{{ $t('link_url') }}</label>
                                        <input id="link_url" class="form-control" type="text" v-model="form.link_url" placeholder="Link URL">
                                        <span  class="error" v-if="errors.link_url">{{ errors.link_url }}</span>
                                    </div>
                                </div>                                   
                            </div> 
                            <button class="btn comman-btn-2" type="submit">{{ $t('save') }}</button>   
                        </form>    
                    </div>
                </div>
            </div>
        </div>

    </section>

</template>
  
  <script>
//   import  Modal  from 'bootstrap';
  import { CONSTANT } from '@/constants/constants';
  import { OPTIONS_GROUP_1 } from '@/constants/constantsProperty';
  import { mapState } from 'vuex';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';

//   import $ from 'jquery';
//   import 'frontend/js/bootstrap.bundle.min.js';
// import e from 'express';
  export default {
    name: 'PostPropertyListingStep4', 
    data() {
        return {
            CONSTANT,
            otherDocList: [],
            otherDocListMB: [],
            otherDocListUL: [],
            otherDocListAVB: [],
            mediaLinksList: [],
            actual_unit_photo_list: [],

            verifyDocListPTD: [],
            verifyDocListSPA: [],
            verifyDocListLFA: [],
            verifyDocListUTAB: [],
            verifyDocListRIS: [],
            verifyDocListLOA: [],

            selectedGroup1: '',
            optionsGroup1: OPTIONS_GROUP_1,
            unitiPhotos: [],
            form: {
                link_type:'',
                link_name:''
            },
            errors: {}

        }
    },
    computed: {
        ...mapState(['isAuthenticated', 'user']),
        itemId() {
            return this.$route.params.id;
        },
        completionPercentage() {
            const fields = [
                this.actual_unit_photo_list.length > 0,
                this.otherDocListUL.length > 0,
                this.otherDocListMB.length > 0,
                this.otherDocListAVB.length > 0,
                this.verifyDocListPTD.length > 0,
                this.verifyDocListSPA.length > 0,
                this.verifyDocListLFA.length > 0,
                this.verifyDocListUTAB.length > 0,
                this.verifyDocListRIS.length > 0,
                this.verifyDocListLOA.length > 0,
            ];

            // Count fields that are true (i.e., considered "filled")
            const filledFields = fields.filter(field => field).length;

            return Math.round((filledFields / fields.length) * 100);
        }
    },
    created() {
        this.fetchOptions();
    },
    watch: {
        // Automatically save the completion percentage whenever it changes
        completionPercentage(newPercentage) {
            // Trigger logic when the percentage is 0%
            if (newPercentage === 0) {
                // You can trigger an action here, such as:
                this.handleZeroCompletion();
            }
            
            this.saveCompletion();
        },
    },
    methods: {
        savePageCompletion(page, percentage) {
            // Save the page completion percentage to local storage
            const completionData = JSON.parse(localStorage.getItem('formCompletionData')) || {};
            completionData[page] = percentage;
            localStorage.setItem('formCompletionData', JSON.stringify(completionData));
        },

        // Example method to handle 0% completion
        handleZeroCompletion() {
            // Trigger actions when completion is 0%, such as resetting forms, showing alerts, etc.
            // Example: Display an alert or reset form fields
            alert('The form completion is 0%. Please complete the form.');
        },

        saveCompletion() {
            // Save the current page's completion percentage
            const percentage = this.completionPercentage;
            this.savePageCompletion('page4', percentage);
        },
        validateForm() {
            // Check if any photos are uploaded
            if (this.actual_unit_photo_list.length === 0) {
                this.errors.actual_unit_photo_list = "This field is required.";
                return false; // If no photos, prevent form submission
            }
            
            // Clear the error if photos are present
            this.errors.actual_unit_photo_list = "";
            return true; // Continue with form submission
        },
        validateAddMediaLink() {            
            this.errors = {};
            if (!this.form.link_type) {
                this.errors.link_type = 'Link type  is required.';
            }
            if (!this.form.link_name) {
                this.errors.link_name = 'Link name  is required.';
            }
            if (!this.form.link_url) {
                this.errors.link_url = 'Link URL  is required.';
            }
            return Object.keys(this.errors).length === 0;
        },
        async submitPost() {         
            // Validate the form before submitting
            if (this.validateForm()) {
                this.$router.push('/post-property-listing-5/'+this.itemId);
                // Proceed with form submission (e.g., make an API call or update state)
            }
        },  
        async submitAddMediaLink() {
            if (this.form.link_name || this.form.link_type) {
                try {
                    const token = localStorage.getItem('userToken');
                    const response = await this.$axios.post('api/add-property-listing-media-link', {
                        user_id:this.user.id,
                        api_token:token,
                        property_listing_id: this.itemId,
                        link_type: this.form.link_type,
                        link_name: this.form.link_name,
                        link_url: this.form.link_url
                    });
                    var res_data = response.data.output;
                    toastr.success('Media Link added', 'Success', {progressBar: true, closeButton: true});
                    setTimeout(function(){
                        window.$(".btn-close").trigger('click');
                        var html = '<span  class="badge bg-secondary rounded-pill media_addi_'+res_data.id+' position-relative featurelist-badge" value="27">\
                                    <a  href="'+res_data.link_url+'" target="_blank" class="text-white">\
                                            '+res_data.link_name+' ('+res_data.link_type+') &nbsp;</a>\
                                    <a  class="fa fa-trash text-white deleteMediaLink" id="'+res_data.id+'"></a>\
                                </span>';
                        window.$(".listofMedia_link").append(html);
                    },500);
                } catch (error) {

                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
        async onFileChange(event) {
            var type_photos = window.$("#type_photos").val();

            if(type_photos == 'up'){

                const files = event.target.files;
                this.selectedFiles = Array.from(files);
                if (this.selectedFiles) {
                    
                    const token = localStorage.getItem('userToken');
                    const formData = new FormData();

                    // Append files to FormData
                    this.selectedFiles
                    .forEach(file => {
                        formData.append('actual_unit_photos[]', file);
                    });
                    formData.append('dataJson', JSON.stringify({
                    user_id: this.user.id,
                    api_token: token,
                    property_listing_id: this.itemId,
                    }));


                    try {
                        const response = await this.$axios.post('api/add-actual-unit-photos', formData, {
                            headers: {
                            'Content-Type': 'multipart/form-data',
                            },
                        });
                        if(response.data.status == 1){
                            var res_data = response.data.output;
                            this.actual_unit_photo_list = res_data;
                            toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                            setTimeout(function(){
                                window.$(".btn-close").trigger('click');
                                // var html = '<span  class="badge bg-secondary rounded-pill addi_'+res_data.id+' position-relative featurelist-badge" value="'+res_data.id+'">\
                                //                 <a  href="'+CONSTANT.IMAGE_URL+'imagesPropertyActualUnit/'+res_data.photo_name+'" target="_blank" class="text-white">\
                                //                         '+res_data.photo_original_name+' &nbsp;</a>\
                                //                 <span  class="fa fa-trash text-white deleteUploadFiles" data-deletetype="mb" id="'+res_data.id+'"></span>\
                                //             </span>';
                                // window.$(".listofUpload_unit_photo").append(html);

                            },500);
                        }else{
                            toastr.error(response.data.message, 'Error11', { timeOut: 3000 });
                        }
                    } catch (error) {                
                        console.log(error);
                        if (error.response && error.response.data) {
                            toastr.error(error.response.data.errors, 'Error', { timeOut: 3000 });
                            this.errors = this.extractErrors(error.response.data.errors || {});
                        } else {
                            console.error('An unexpected error occurred:', error);
                        }
                    } finally {
                    this.loading = false;
                    }
                }

            }else{
                var doc_type_val = '';
                if(type_photos == 'ul'){
                    doc_type_val = 'Unit Layout & Site Plan';
                }
                if(type_photos == 'mb'){
                    doc_type_val = 'Marketing Brochure';
                }
                if(type_photos == 'vab'){
                    doc_type_val = 'Verify Authentic Badge';
                }
                const files = event.target.files;
                this.selectedFiles = Array.from(files);
                if (this.selectedFiles) {
                    
                    const token = localStorage.getItem('userToken');
                    const formData = new FormData();

                    // Append files to FormData
                    this.selectedFiles
                    .forEach(file => {
                        formData.append('doc_other_photo[]', file);
                    });
                    formData.append('dataJson', JSON.stringify({
                    user_id: this.user.id,
                    api_token: token,
                    property_listing_id: this.itemId,
                    doc_type: doc_type_val,
                    }));


                    try {
                        const response = await this.$axios.post('api/add-documents-other-photos', formData, {
                            headers: {
                            'Content-Type': 'multipart/form-data',
                            },
                        });

                        if(response.data.status == 1){
                            res_data = response.data.output;
                            this.otherDocListMB = res_data.filter(doc => doc.doc_type === 'Marketing Brochure');
                            this.otherDocListAVB =  res_data.filter(doc => doc.doc_type === 'Verify Authentic Badge');
                            this.otherDocListUL =  res_data.filter(doc => doc.doc_type === 'Unit Layout & Site Plan'); 
                            toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                            setTimeout(function(){
                                window.$(".btn-close").trigger('click');
                            },500);
                        }else{
                            toastr.error(response.data.message, 'Error11', { timeOut: 3000 });
                        }
                    } catch (error) {                
                        if (error.response && error.response.data) {
                            this.errors = this.extractErrors(error.response.data.errors || {});
                        } else {
                            console.error('An unexpected error occurred:', error);
                        }
                    } finally {
                    this.loading = false;
                    }
                }
            }

        },
        async onFileVerifyDocChange(event) {
            
            var doc_type = window.$("#verify_doc_type").val();
            const files = event.target.files;
            this.selectedFiles = Array.from(files);
            if (this.selectedFiles) {
                
                const token = localStorage.getItem('userToken');
                const formData = new FormData();

                // Append files to FormData
                this.selectedFiles
                .forEach(file => {
                    formData.append('verification_doc[]', file);
                });
                formData.append('dataJson', JSON.stringify({
                user_id: this.user.id,
                api_token: token,
                property_listing_id: this.itemId,
                doc_type: doc_type,
                }));


                try {
                    const response = await this.$axios.post('api/add-verification-documents', formData, {
                        headers: {
                        'Content-Type': 'multipart/form-data',
                        },
                    });
                    if(response.data.status == 1){
                        var res_data = response.data.output;
                        this.verifyDocListPTD =  res_data.filter(doc => doc.doc_type === 'Property Title & Deeds');
                        this.verifyDocListSPA =  res_data.filter(doc => doc.doc_type === 'Sale & Purchase Agreement');
                        this.verifyDocListLFA =  res_data.filter(doc => doc.doc_type === 'Loan Facility Agreement');                    
                        this.verifyDocListUTAB = res_data.filter(doc => doc.doc_type === 'Utility, Tax & Assessment Bills');                    
                        this.verifyDocListRIS =  res_data.filter(doc => doc.doc_type === 'Registered Intent To Sell (For HDB Only)');                    
                        this.verifyDocListLOA =  res_data.filter(doc => doc.doc_type === 'Letter Of Appointment (Estate Agents)'); 
                        toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                        setTimeout(function(){
                            window.$(".btn-close").trigger('click');
                        },500);
                    }else{
                        toastr.error(response.data.message, 'Error11', { timeOut: 3000 });
                    }
                } catch (error) {                
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                this.loading = false;
                }
            }
        },
        async onFileChangeSingle(event) {
            
            this.selectedFile = event.target.files[0];
            if (this.selectedFile) {
                // Generate a preview URL
                this.imageUrl = URL.createObjectURL(this.selectedFile);
                this.computedImageSrc = URL.createObjectURL(this.selectedFile);
                
                const token = localStorage.getItem('userToken');
                // Create a FormData object
                const formData = new FormData();
                formData.append('actual_unit_photos[]', this.selectedFile);
                formData.append('dataJson', JSON.stringify({
                user_id: this.user.id,
                api_token: token,
                property_listing_id: this.itemId,
                }));


                try {
                const response = await this.$axios.post('api/add-actual-unit-photos', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                });
                toastr.success(response.data.message, 'Success', { timeOut: 3000 });


                    const response_profile = await this.$axios.post('/api/profile', {
                    api_token: token
                    }); 

                    await this.$store.dispatch('profileUpdate', response_profile.data);
                    
                //await this.$store.dispatch('profileUpdate', response.data);
                } catch (error) {                
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                this.loading = false;
                }
            }
        },
        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },

        async fetchOptions() {

            const token = localStorage.getItem('userToken');
            if(this.itemId > 0){                
                try {
                    const response = await this.$axios.post('api/property-detail',
                    {                    
                        user_id :this.user.id,
                        api_token: token,
                        property_listing_id:this.itemId,                    
                    });
                    this.editData = response.data.output[0];
                    this.actual_unit_photo_list =  this.editData.actual_unit_photo;

                    this.otherDocList = this.editData.doc_other_photos;
                    this.otherDocListMB =  this.otherDocList.filter(doc => doc.doc_type === 'Marketing Brochure');
                    this.otherDocListAVB =  this.otherDocList.filter(doc => doc.doc_type === 'Verify Authentic Badge');
                    this.otherDocListUL =  this.otherDocList.filter(doc => doc.doc_type === 'Unit Layout & Site Plan');                    
                    this.mediaLinksList = this.editData.media_links;

                    this.verifyDocList = this.editData.verification_document;
                    this.verifyDocListPTD =  this.verifyDocList.filter(doc => doc.doc_type === 'Property Title & Deeds');
                    this.verifyDocListSPA =  this.verifyDocList.filter(doc => doc.doc_type === 'Sale & Purchase Agreement');
                    this.verifyDocListLFA =  this.verifyDocList.filter(doc => doc.doc_type === 'Loan Facility Agreement');                    
                    this.verifyDocListUTAB =  this.verifyDocList.filter(doc => doc.doc_type === 'Utility, Tax & Assessment Bills');                    
                    this.verifyDocListRIS =  this.verifyDocList.filter(doc => doc.doc_type === 'Registered Intent To Sell (For HDB Only)');                    
                    this.verifyDocListLOA =  this.verifyDocList.filter(doc => doc.doc_type === 'Letter Of Appointment (Estate Agents)');                    



                    // this.otherDocList = this.editData.doc_other_photos.map(item => ({
                    //     id: item.id,
                    //     doc_type: item.doc_type,
                    //     photo_name: item.photo_name,
                    //     photo_original_name: item.photo_original_name
                    // }));

                } catch (error) {
                    console.error('Error fetching options:', error);
                }
            }
        },
        async deleteDocUnitPhoto(id) {
            const token = localStorage.getItem('userToken');
            try {
                await this.$axios.post('api/delete-actual-unit-photo',
                {                    
                    api_token: token,
                    user_id:this.user.id,
                    photo_id:id,


                });
                window.$(".addi_"+id).remove();
                
            } catch (error) {
                toastr.error('Error fetching options:')
            }
        },
        
        async deleteDocFiles(id) {            
            const token = localStorage.getItem('userToken');
            try {
                await this.$axios.post('api/delete-documents-other-photo',
                {                    
                    api_token: token,
                    user_id:this.user.id,
                    doc_photo_id:id,
                });
                window.$(".addi_"+id).remove();
                
            } catch (error) {
                toastr.error('Error fetching options:')
            }
        },
        
        async deleteMediaLink(id) {            
            const token = localStorage.getItem('userToken');
            try {
                await this.$axios.post('api/delete-property-listing-media-link',
                {                    
                    api_token: token,
                    user_id:this.user.id,
                    medialink_id:id,
                });
                window.$(".media_addi_"+id).remove();
                
            } catch (error) {
                toastr.error('Error fetching options:')
            }
        },
        
        async deleteVerofyDoc(id) {            
            const token = localStorage.getItem('userToken');
            try {
                await this.$axios.post('api/delete-verification-document',
                {                    
                    api_token: token,
                    user_id:this.user.id,
                    doc_id:id,
                });
                window.$(".verify_addi_"+id).remove();
                
            } catch (error) {
                toastr.error('Error fetching options:')
            }
        },
        setupEventListeners() {
            const self = this; // Save reference to `this`
            
            window.$(document).on('click', '.deleteUploadACtualUnitPhoto', function() {        
                const id = window.$(this).attr('id');
                window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.deleteDocUnitPhoto(id);
                }
                });
            });
            
            window.$(document).on('click', '.deleteUploadFiles', function() {        
                const id = window.$(this).attr('id');
                window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.deleteDocFiles(id);
                }
                });
            });
            
            window.$(document).on('click', '.deleteMediaLink', function() {        
                const id = window.$(this).attr('id');
                window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.deleteMediaLink(id);
                }
                });
            });
            
            window.$(document).on('click', '.deleteVerofyDoc', function() {        
                const id = window.$(this).attr('id');
                window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.deleteVerofyDoc(id);
                }
                });
            });
        }
    },
    mounted() {
        this.setupEventListeners();
    } 
  }

  window.$(document).on("click",".openMedialinkPopup",function(){        
    window.$('#add-media-link-modal').modal('show');
    window.$('#link_name').val('');
    window.$('#link_url').val('');
  });

  window.$(document).on("click",".openBrowsePopup",function(){    
    var typeOfPhotos = window.window.$(this).data("id");
    window.$("#type_photos").val(typeOfPhotos);
    
    window.$("input[name='fileUploadImg']").attr('accept',"image/*,application/pdf");
    if(typeOfPhotos == 'up'){
        window.$("input[name='fileUploadImg']").attr('accept',"image/*");
        window.$(".title_photos_upload").html("Please Upload Unit Photos");
    }else if(typeOfPhotos == 'ul'){
        window.$(".title_photos_upload").html("Please Upload  Unit Layout & Site Plan");
    }else if(typeOfPhotos == 'mb'){
        window.$(".title_photos_upload").html("Please Upload  Marketing Brochure ");
    }else{
        window.$(".title_photos_upload").html("Please Upload  Verify Authentic Badge ");
    }
    window.$('#upload-photos-modal').modal('show');
    // const myModal = new Modal(document.getElementById('upload-photos-modal'));
    // myModal.show();

  })

  window.$(document).on("click",".openDocVerificationPopup",function(){    
    var typeOfPhotos = window.window.$(this).data("doc_type");
    window.$("#verify_doc_type").val(typeOfPhotos);
    window.$('#upload-verifyDoc-modal').modal('show');
    window.$(".title_verify_doc_type").html(typeOfPhotos);    

  })
  </script>
  
  <style scoped>
  .deleteUploadFiles{
    cursor: pointer;
  }
  .badge{
    margin-right: 1px;
  }
  /* Add styles specific to the About Us page here */
  </style>
  