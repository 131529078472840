<template>
    <div class="filter-bar-se my-4">
        <div class="text-center w-25" v-if="(canDownloadReport || canDownloadLeads) && messageDetails && messageDetails.length > 0">
            <downloadexcel
                class="comman-btn-2"
                :fetch="exportChatDetailsToExcel"
                :fields="getFields('chatDetails')"
                :before-generate="startDownload"
                :before-finish="finishDownload"
            >
                {{ $t('download_excel') }}
            </downloadexcel>
        </div>
        <div class="filter-right-bar">
            <div class="dropdown">
                <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                    <img src="frontend/images/Sort-By.svg"> {{ $t('sort_by') }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Oldest' }"
                           @click.prevent="sort('Oldest')">{{ $t('oldest') }}</a></li>
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Newest' }"
                           @click.prevent="sort('Newest')">{{ $t('newest') }}</a></li>
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Warm' }"
                           @click.prevent="sort('Warm')">{{ $t('warm')}}</a></li>
                    <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Cold' }"
                           @click.prevent="sort('Cold')">{{ $t('cold')}}</a></li>
                </ul>
            </div>
        </div>
    </div>
    <template v-if="messageDetails && messageDetails.length > 0">
        <div class="table-responsive custom-table-design mt-4">
            <table class="text-center table-bordered align-middle" >
                <thead>
                <tr>
                    <th>{{ $t('sender') }} ID</th>
                    <th>{{ $t('sender_name') }}</th>
                    <th>{{ $t('project_listing') }} ID</th>
                    <th>{{ $t('project_name') }}</th>
                    <th>{{ $t('land_area') }}</th>
                    <th>{{ $t('price') }}</th>
                    <th>{{ $t('developer') }}</th>
                    <th>{{ $t('last_chatted') }}</th>
                    <th>{{ $t('reactivity') }}</th>
                    <th>{{ $t('action') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="message in messageDetails" :key="message.id">
                    <td>{{ message.latest_chat?.sender_id }}</td>
                    <td>{{ message.latest_chat?.sender.name }}</td>
                    <td>{{ message.chat_details?.project_listing_id ?? 'N/A' }}</td>
                    <td>{{ message.chat_details?.project_listing?.project_name ?? 'N/A'  }}</td>
                    <td>{{ parseInt(message.chat_details?.project_listing?.land_area_from) ?? 'N/A'}} {{message.chat_details?.project_listing?.land_area_unit}} {{ $t('to') }} {{ parseInt(message.chat_details?.project_listing?.land_area_to) ?? 'N/A'}} {{message.chat_details?.project_listing?.land_area_unit}}</td>
                    <td>RM {{ formatPrice(message.chat_details?.project_listing?.listing_price_from) ?? 'N/A'}} to RM {{ formatPrice(message.chat_details?.project_listing?.listing_price_to) ?? 'N/A'}}</td>
                    <td>{{ message.chat_details?.project_listing?.developer_info_name ?? 'N/A'}}</td>
                    <td>{{ formatDate(message.latest_chat?.created_at) ?? 'N/A'}}</td>
                    <td>{{ message.latest_chat?.is_warm_text }}</td>
                    <td><button class="comman-btn-2" type="submit" @click="setSelectedUser(message.latest_chat)" data-bs-target="#editReactivity" data-bs-toggle="modal">{{ $t('change') }} {{ $t('reactivity') }}</button></td>
                </tr>
                </tbody>
            </table>
        </div>
    </template>
    <template v-else>
        <div class="text-center mt-4 mb-3">{{ $t('no_records_found') }}</div>
    </template>
    <div class="pagination-se mt-3" v-if="messageDetails && messageDetails.length > 0">
        <nav aria-label="...">
            <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: dataFilters.page === 1 }"
                    @click="dataFilters.page > 1 && changePage(dataFilters.page - 1)" v-if="dataFilters.page > 1">
                    <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                              alt="Previous"></a>
                </li>
                <li v-for="pageNumber in paginatedPages(dataFilters.page, totalPages)" :key="pageNumber"
                    class="page-item" :class="{ active: pageNumber === dataFilters.page }">
                    <a class="page-link"
                       @click.prevent="pageNumber !== dataFilters.page && changePage(pageNumber)">
                        {{ pageNumber }}
                    </a>
                </li>
                <li class="page-item" :class="{ disabled: dataFilters.page === totalPages }"
                    @click="dataFilters.page < totalPages && changePage(dataFilters.page + 1)"
                    v-if="dataFilters.page < totalPages">
                    <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="modal fade" id="editReactivity" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">{{ $t('change') }} {{ $t('reactivity') }}</h5>
                </div>
                <div v-if="selectedUser" class="modal-body">
                    {{ $t('mark') }} <b>{{ selectedUser?.sender.name }}'s</b> {{ $t('reactivity') }} {{ $t('to') }} <b>{{selectedUser.is_warm ? $t('cold') : $t('warm')}}</b>
                </div>
                <div class="modal-footer">
                    <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">{{ $t('close') }}</button>
                    <button type="button" class="comman-btn-2" @click="changeUserReactivity" data-bs-dismiss="modal">{{ $t('change') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import downloadexcel from "vue-json-excel3";
import {mapState} from "vuex";
import {formatDate, formatPrice, paginatedPages} from "@/utils/helpers";
import {getJsonFields} from "@/utils/jsonFieldsConfig";
import toastr from "toastr";

export default {
    name: 'LeadProjectReport',
    components: {
        downloadexcel,
    },
    computed: {
        ...mapState(['isAuthenticated', 'user', 'userProfile']),
        canDownloadReport() {
            return (this.user.is_corporate && this.user?.package?.title == 'Developer');
        },
        canDownloadLeads() {
            return (this.user.is_corporate && this.user?.package?.title == 'Developer') || (this.user.is_business && this.user?.package?.title == 'Platinum');
        }
    },
    created() {
        this.fetchMessageDetail();
    },
    data() {
        return {
            messageDetails: [],
            totalPages: 0,
            totalRecords: 0,
            dataFilters: {
                page: 1,
                sort_by_field: 'Newest',
            },
            selectedUser: '',
        }
    },
    methods: {
        formatPrice,
        //Excel related functions
        formatDate,
        paginatedPages,
        getFields(type) {
            return getJsonFields(type);
        },
        startDownload() {
            toastr.info('Preparing the download...');
        },
        finishDownload() {
            toastr.success('Download completed!');
        },
        async exportChatDetailsToExcel() {
            return this.messageDetails;
        },
        setSelectedUser(message) {
            this.selectedUser = message;
        },
        async changeUserReactivity() {
            const token =  localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/change-user-reactivity',{
                    api_token: token,
                    user_id: this.user.id,
                    sender_id: this.selectedUser.sender_id,
                    reactivity_status: this.selectedUser.is_warm,
                });

                if (response.data.status === 1) {
                    await this.fetchMessageDetail();

                    toastr.success('Reactivity changed successfully');
                }
            } catch (error) {
                toastr.error('Unable to change reactivity, error:', error.message);
            }
        },

        //Fetching Data
        async fetchMessageDetail() {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/get-message-details',{
                    api_token: token,
                    user_id: this.user.id,
                    property: 0,
                    sort_by_field: this.dataFilters.sort_by_field,
                    page_number: this.dataFilters.page,
                });

                this.messageDetails = response.data.output.message_details;
                this.totalRecords = response.data.output.total_records;
                this.totalPages = response.data.output.total_pages;
            } catch (error) {
                console.error('Unable to fetch data, error:', error.message);
            }
        },

        //Sort and Paginate functions
        sort(sortBy) {
            this.dataFilters.sort_by_field = sortBy;
            this.dataFilters.page = 1;

            this.fetchMessageDetail();
        },
        changePage(page) {
            if (page > 0) {
                const totalPages = this.totalPages;
                if (page <= totalPages) {
                    this.dataFilters.page = page;
                    this.fetchMessageDetail();
                }
            }
        },
    }
}

</script>


