<template>
    <div class="container my-4">
        <div class="top-tabs-design mb-0">
            <ul>
                <li><a href="/profile">{{ $t('my_profile') }}</a>
                </li>
                <li v-if="!user.is_private_user">
                    <a href="/verify-service-detail-page">{{ $t('verify_service_details') }}</a>
                </li>
                <li v-if="!user.is_private_user"><a href="/property-data-analytic">{{ $t('analytics_and_report') }}</a></li>
                <li><a href="/wishlist">{{ $t('my_wishlist') }}</a></li>
                <li><a href="/proxy-listing-page"> Proxy Listing </a></li>
                <li><a href="/requested-service">{{ $t('requested_service') }}</a></li>
                <li><a href="javascript:;" class="active">{{ $t('messages') }}</a></li>
            </ul>
        </div>

        <div class="row mt-5">
            <div class="mb-3 flex align-items-center text-center">
        <span>
            {{ counter > 0 ? `Refetching messages in ${counter} seconds` : 'Counter will start soon' }}
        </span>
                <a type="button" class="mx-3" @click="pauseCounter">
                    <i v-if="!pause" class="fa fa-pause"></i>
                    <i v-else-if="pause" class="fa fa-play"></i>
                </a>
            </div>
            <div class="col-md-9">
                <vue-advanced-chat id="chatBubbles" :current-user-id="user.id" :load-first-room="false" :rooms="JSON.stringify(rooms)"
                                   :rooms-loaded="roomsLoading" :messages="JSON.stringify(messages)" :messages-loaded="messagesLoaded"
                                   :message-actions="JSON.stringify(messageActions)" @fetch-messages="fetchMessages" @send-message="sendMessage"
                                   :show-emojis="false" :show-audio="false" :show-files="false" :show-add-room="false" :room-id="roomId" :auto-scroll="autoScroll" style="height: 100%">
                    <div v-for="message in messages" :key="message._id" :slot="'message_' + message._id" class="vac-message-wrapper">
                        <div class="vac-message-box my-1" v-if="message.property">
                            <div class="vac-message-container vac-message-container-offset">
                                <div class="vac-message-card" :class="{'vac-message-current': message.senderId == user.id}">
                                    <div class="vac-format-message-wrapper">
                                        <div class="markdown">
                                            <p>{{ $t('property') }}: {{ message.property.property_name }}</p>
                                            <router-link target='_blank' :to="`/property-detail/${message.property.id}`">{{ $t('view_property') }}</router-link>
                                        </div>
                                    </div>
                                    <div class="vac-text-timestamp"><span>{{ message.timestamp }}</span></div>
                                    <div class="vac-message-actions-wrapper">
                                        <div class="vac-options-container" style="display: initial; width: 45px;"><span></span></div>
                                    </div>
                                </div><span></span>
                            </div>
                        </div>
                        <div class="vac-message-box my-1" v-else-if="message.project">
                            <div class="vac-message-container vac-message-container-offset">
                                <div class="vac-message-card" :class="{'vac-message-current': message.senderId == user.id}">
                                    <div class="vac-format-message-wrapper">
                                        <div class="markdown">
                                            <p>{{ $t('project') }}: {{ message.project.project_name }}</p>
                                            <router-link target='_blank' :to="`/project-detail/${message.project.id}`">{{ $t('view_project') }}</router-link>
                                        </div>
                                    </div>
                                    <div class="vac-text-timestamp"><span>{{ message.timestamp }}</span></div>
                                    <div class="vac-message-actions-wrapper">
                                        <div class="vac-options-container" style="display: initial; width: 45px;"><span></span></div>
                                    </div>
                                </div><span></span>
                            </div>
                        </div>
                        <div class="vac-message-box my-1" v-if="message.include_service">
                            <div class="vac-message-container vac-message-container-offset">
                                <div class="vac-message-card" :class="{'vac-message-current': message.senderId == user.id}">
                                    <div class="vac-format-message-wrapper">
                                        <div class="markdown">
                                            <p>{{ message.content }}</p>
                                            <a @click="requestService(message)" style="color: var(--primary-DD8D00)">Request Service</a>
                                        </div>
                                    </div>
                                    <div class="vac-text-timestamp"><span>{{ message.timestamp }}</span></div>
                                    <div class="vac-message-actions-wrapper">
                                        <div class="vac-options-container" style="display: initial; width: 45px;"><span></span></div>
                                    </div>
                                </div><span></span>
                            </div>
                        </div>
                    </div>
                </vue-advanced-chat>
            </div>
            <ProfilePageSideBar></ProfilePageSideBar>
        </div>
    </div>

</template>

<script>
    import { mapState } from 'vuex';
    import toastr from 'toastr';
    import { register } from 'vue-advanced-chat'
    import ProfilePageSideBar from "@/views/ProfilePageSideBar.vue";
    export default {
        components: {ProfilePageSideBar},
        data() {
            return {
                rooms: [],
                roomsLoading: false,
                messages: [],
                messagesLoaded: false,
                messageActions: [],

                // for auto pull
                currentChatUser: null,
                timeoutSeconds: 10,
                counter: 0,
                interval: null,
                pause: false,
                autoScroll: '{ "send": { "new": true, "newAfterScrollUp": true }, "receive": { "new": true, "newAfterScrollUp": true } }'
            };
        },
        computed: {
            ...mapState(['user']),
            roomId() {
                if (this.currentChatUser !== null) {
                    return '' + this.currentChatUser + '';
                }
                return null;
            },
        },
        mounted() {
            register();
            this.getMessagesList();
        },
        watch: {
            messagesLoaded: {
                handler(newValue) {
                    // auto fetch messages when user is in a chatroom
                    if (newValue === true && !this.pause && this.currentChatUser !== null) {
                        this.countdown();
                    }
                },
                immediate: true,
            }
        },
        methods: {
            requestService(message) {
                if (!message.senderId) {
                    toastr.error('Invalid recipient');
                    return;
                }
                this.$router.push({ name: 'AgentDetail', params: { id: message.senderId }, query: { request_service: true }})
            },
            //linkify(text) {
            //    const urlRegex = /(https?:\/\/[^\s]+)/g;
            //    return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
            //},
            pauseCounter() {
                this.pause = !this.pause;
                if (this.pause) {
                    clearInterval(this.interval);
                    this.interval = null;
                } else {
                    this.countdown();
                }
            },
            countdown() {
                if (this.interval) {
                    return;
                }

                this.interval = setInterval(() => {
                    if (this.pause) {
                        clearInterval(this.interval);
                        this.interval = null;
                        return;
                    }

                    if (this.counter > 0) {
                        this.counter--;
                    } else {
                        clearInterval(this.interval); // Stop when counter reaches 0
                        this.interval = null;
                        this.getMessagesList(this.currentChatUser);
                    }
                }, 1000);
            },
            formatTime(datetime) {
                let date = new Date(datetime);
                return date.toLocaleTimeString("en-SG", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    timeZone: "Asia/Singapore"
                });
            },
            async sendMessage(event) {
                let newMessage = event.detail[0].content;
                let chatUser = event.detail[0].roomId;
                this.currentChatUser = chatUser;

                try {
                    let params = {
                        api_token: localStorage.getItem('userToken'),
                        content: newMessage,
                        sender_id: this.user.id,
                        recipient_id: chatUser
                    }
                    await this.$axios.post(`api/users/${this.user.id}/messages`, params);
                } catch (error) {
                    toastr.error('Failed to send message', 'Error', { timeOut: 3000 });
                } finally {
                    this.getMessagesList(this.currentChatUser);
                }
            },
            async fetchMessages(event = null, paramChatUserId = null) {
                const token = localStorage.getItem('userToken');
                try {
                    this.messagesLoaded = false;
                    this.messages = [];
                    let chatUserId = paramChatUserId ? paramChatUserId : event.detail[0].room.roomId
                    this.currentChatUser = chatUserId;

                    const response = await this.$axios.get(`/api/users/${this.user.id}/messages/${chatUserId}`,
                        {
                            params: {
                                api_token: token
                            }
                        });

                    if (response.data.output) {
                        this.messages = response.data.output.map(message => ({
                            _id: message.id,
                            content: message.content,
                            senderId: '' + message.sender_id + '',
                            username: message.sender.name,
                            date: new Date(message.created_at).toJSON().slice(0, 10).replace(/-/g, '-'),
                            timestamp: this.formatTime(message.created_at),
                            seen: message.read_status,
                            system: false,
                            disableActions: true,
                            disableReactions: true,
                            property: message?.property_listing,
                            project: message?.project_listing,
                            include_service: message?.include_service || false,
                        }))
                    } else {
                        toastr.error('Failed to fetch messages', 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    toastr.error('Failed to fetch messages', 'Error', { timeOut: 3000 });
                } finally {
                    this.messagesLoaded = true;
                    this.counter = this.timeoutSeconds;
                    this.getMessagesList();
                }
            },
            async getMessagesList(chatUser = null) {
                const token = localStorage.getItem('userToken');
                try {
                    this.roomsLoading = true;

                    const response = await this.$axios.get(`/api/users/${this.user.id}/messages`,
                        {
                            params: {
                                api_token: token
                            }
                        });

                    if (response.data.output?.messages) {
                        const rooms = []
                        let chatRooms = response.data.output.messages;
                        for (let i = 0; i < chatRooms.length; i++) {
                            let chatRoom = chatRooms[i];
                            let lastMessageObject = chatRoom['last_message'] || {};
                            let participants = [];

                            chatRoom['users'].forEach(chatRoomUser => {
                                participants.push({
                                    _id: chatRoomUser['id'],
                                    username: chatRoomUser['name'],
                                })
                            });

                            rooms.push({
                                roomId: '' + chatRoom['user_id'] + '',
                                roomName: chatRoom['user_name'],
                                unreadCount: chatRoom['unread_count'],
                                lastMessage: {
                                    _id: lastMessageObject.id,
                                    content: lastMessageObject.content,
                                    senderId: lastMessageObject.sender_id,
                                    username: lastMessageObject.sender.name,
                                    timestamp: new Date(lastMessageObject.created_at).toJSON().slice(0, 10).replace(/-/g, '-') + ' ' + this.formatTime(lastMessageObject.created_at),
                                },
                                users: participants
                            })
                        }
                        this.rooms = rooms
                    } else {
                        toastr.error('Failed to fetch messages', 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    toastr.error('Failed to fetch messages', 'Error', { timeOut: 3000 });
                } finally {
                    this.roomsLoading = false;
                    if (chatUser) {
                        this.fetchMessages(null, chatUser)
                    }
                }
            }
        }
    };
</script>

<style scoped>
    .container {
        padding-top: 70px;
    }
</style>