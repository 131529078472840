<template>
    <div>
      <HeaderPart/>
        <div style="min-height: calc(100vh - 435px)">
            <router-view />
        </div>
      <FooterPart v-if="showHeaderAndFooter" />
    </div>
  </template>
  
  <script>  
import HeaderPart from '../components/HeaderPart.vue';
import FooterPart from '../components/FooterPart.vue';
import { computed } from 'vue'
import { useRoute } from 'vue-router'

  export default {
    name: 'MainLayout',
    components: {
      HeaderPart,
      FooterPart
    },
    setup() {
        const route = useRoute()

        // Determine if header and footer should be shown
        const showHeaderAndFooter = computed(() => {
        // List the paths where the header and footer should not be shown
        const pathsWithoutHeaderAndFooter = ['/sign-in']
        return !pathsWithoutHeaderAndFooter.includes(route.path)
        })

        return {
        showHeaderAndFooter
        }
    }
  }
  </script>
  
