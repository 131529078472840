<template>
    <section class="property-data-analytic-se">
        <div class="container">
            <div class="top-tabs-design">
                <ul>
                    <li><a href="/profile">{{ $t('my_profile') }}</a></li>
                    <li v-if="!user.is_private_user">
                        <a href="/verify-service-detail-page">{{ $t('verify_service_details') }}</a>
                    </li>
                    <li><a href="javascript:;"  class="active">{{ $t('analytics_and_report') }}</a></li>
                    <li><a href="/wishlist">{{ $t('my_wishlist') }}</a></li>
                    <li><a href="/proxy-listing-page"> Proxy Listing </a></li>
                    <li><a href="/requested-service">{{ $t('requested_service') }}</a></li>
                    <li><a href="/messages">{{ $t('messages') }}</a></li>
                </ul>
            </div>
            <div class="top-tabs-design">
                <ul>
                    <li><a href="/property-data-analytic">{{ $t('property') }}</a></li>
                    <li><a href="javascript:;" class="active">{{ $t('project') }}</a></li>
                </ul>
            </div>
            <div class="dashboard">
                <div class="container py-4">
                    <div class="row text-center mb-4">
                        <div class="col-md-6">
                            <div class="card shadow-sm">
                                <div class="card-body">
                                    <h5 class="card-title">{{ $t('total') }} {{ $t('views') }}</h5>
                                    <p>{{ totalViews }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card shadow-sm">
                                <div class="card-body">
                                    <h5 class="card-title">{{ $t('total') }} {{ $t('inquiries') }}</h5>
                                    <p>{{ totalInquiries }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row text-center mb-4">
                        <div class="col-md-6">
                            <div class="card shadow-sm">
                                <div class="card-body">
                                    <h5 class="card-title">{{ $t('total') }} Active Project Listing</h5>
                                    <p>{{ totalActiveProject }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card shadow-sm">
                                <div class="card-body">
                                    <h5 class="card-title">{{ $t('total') }} Project Leads</h5>
                                    <p>{{ totalProjectLeads }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 class="mb-3">Projects Data Insights</h4>
                <ProjectInsightReport></ProjectInsightReport>
                <hr>
                <h4 class="mt-4">{{ $t('project_leads') }}</h4>
                <LeadProjectReport></LeadProjectReport>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapState} from "vuex";
    import ProjectInsightReport from "@/views/tool/reports/ProjectInsightReport.vue";
    import LeadProjectReport from "@/views/tool/reports/LeadProjectReport.vue";

    export default {
        name: 'ProjectDataAnalytic',
        computed: {
            ...mapState(['isAuthenticated', 'user', 'userProfile']),
        },
        components: {
            ProjectInsightReport,
            LeadProjectReport,
        },
        data() {
            return {
                totalViews: 0,
                totalInquiries: 0,
                totalActiveProject: 0,
                totalProjectLeads: 0,
            }
        },
        created() {
            this.fetchData();
            this.fetchMessageDetail();
        },
        methods: {
            async fetchData() {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.post('api/get-project-analytic-detail',{
                        api_token: token,
                        user_id: this.user.id,
                    });

                    this.totalViews = response.data.output.total_views;
                    this.totalInquiries = response.data.output.total_inquiries;
                    this.totalActiveProject = response.data.output.total_active_project;
                } catch (error) {
                    console.error('Unable to fetch data, error:', error.message);
                }
            },
            async fetchMessageDetail() {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.post('api/get-message-details',{
                        api_token: token,
                        user_id: this.user.id,
                        property: 0,
                    });

                    this.totalProjectLeads = response.data.output.total_chat;
                } catch (error) {
                    console.error('Unable to fetch data, error:', error.message);
                }
            },

        }
    }


</script>



