<template>    
    <LoaderCust :isLoading="isLoading" />
    <section class="property-se">
			<div class="container">
				<div class="property-title-bar">
					<div class="comman-title mb-0">
						<h3><a href="#"><i class="fa fa-angle-left"></i></a> {{ $t('create') }} Project Listing</h3>
					</div>
					<div class="progress-bar-box">
						<div>3/4</div>
						<div class="progress">
							<div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
							<div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
							<div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
							<!--<div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
							<div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>-->							
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-2">
						<div class="property-sidebar">
							<ul>
                                <li><router-link :to="`/post-project-listing/${itemId}`">{{ $t('step') }} 1</router-link></li>
                                <li><router-link :to="`/post-project-listing-2/${itemId}`">{{ $t('step') }} 2</router-link></li>
                                <li><router-link :to="`/post-project-listing-3/${itemId}`" class="active">{{ $t('step') }} 3</router-link></li>
                                <li><router-link :to="`/post-project-listing-4/${itemId}`">{{ $t('step') }} 4</router-link></li>
							</ul>
						</div>
					</div>
					<div class="col-md-10">
						<div class="property-box">
                            <form @submit.prevent="submitPost">
                                <div class="property-inner-box">
                                    <div class="comman-title">
                                        <h3>Pricing Details Terms & Payment Options</h3>
                                    </div>								
                                    <div class="row row-cols-2">
                                        <div class="col">
                                            <div class="form-group">
                                                <label>{{ $t('listing_price_from') }}</label>
                                                <input type="number" class="form-control" placeholder="FR Lowest" v-model="form.listing_price_from">
                                                <span  class="error" v-if="errors.listing_price_from">{{ errors.listing_price_from }}</span>
                                            </div>
                                        </div>									 
                                        <div class="col">
                                            <div class="form-group">
                                                <label>{{ $t('listing_price_to') }}</label>
                                                <input type="number" class="form-control" placeholder="To Highest" v-model="form.listing_price_to">
                                                <span  class="error" v-if="errors.listing_price_to">{{ errors.listing_price_to }}</span>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Discount From Listing Sale Price (%)</label>
                                                <input
                                                    class="form-control"
                                                    type="number"
                                                    id="numberInput"
                                                    v-model="form.listing_price_discount"
                                                    :min="0"
                                                    :max="100"
                                                    @input="validateInput_d1"
                                                    @keypress="handleKeypress"
                                                    placeholder="Discount From Listing Sale Price (%)"
                                                    />
                                                    <span v-if="errorMessage" class="error">{{ errorMessage }}</span>
                                                    <span  class="error" v-if="errors.listing_price_discount">{{ errors.listing_price_discount }}</span>
                                                <!-- <input type="number" min="0" max="100" class="form-control" placeholder="Discount From Listing Sale Price (%)" v-model="form.listing_price_discount"> -->
                                                <!-- <span  class="error" v-if="errors.listing_price_discount">{{ errors.listing_price_discount }}</span> -->
                                                <!-- <select class="form-control">
                                                    <option>Discount From Listing Sale Price (%)</option>
                                                </select>											 -->
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Rebate From SP&A Price (%)</label>
                                                <input
                                                    class="form-control"
                                                    type="number"
                                                    id="numberInput"
                                                    v-model="form.listing_price_rebate"
                                                    :min="0"
                                                    :max="100"
                                                    @input="validateInput_d2"
                                                    @keypress="handleKeypress_d2"
                                                    placeholder="Rebate From SP&A Price (%)"
                                                    />
                                                    <span v-if="errorMessage" class="error">{{ errorMessage }}</span>
                                                    <span  class="error" v-if="errors.listing_price_rebate">{{ errors.listing_price_rebate }}</span>
                                                <!-- <input type="number" min="0" max="100" class="form-control" placeholder="Rebate From SP&A Price (%)" v-model="form.listing_price_rebate"> -->
                                                <!-- <span  class="error" v-if="errors.listing_price_rebate">{{ errors.listing_price_rebate }}</span> -->
                                                <!-- <select class="form-control">
                                                    <option>Rebate From SP&A Price (%)</option>
                                                </select>											 -->
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label>{{ $t('payment_mode_acceptance') }}</label>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.mode_of_payment" value="Cheque-Bank-Transfer" id="Cheque-Bank-Transfer">
                                                    <label class="form-check-label w-100" for="Cheque-Bank-Transfer">
                                                        Cheque/Bank Transfer 
                                                    </label>
                                                </div>	
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.mode_of_payment" value="Debit-Credit-Card" id="Debit-Credit-Card">
                                                    <label class="form-check-label w-100" for="Debit-Credit-Card">
                                                        Debit/Credit Card
                                                    </label>
                                                </div>	
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.mode_of_payment" value="eWallet" id="eWallet">
                                                    <label class="form-check-label w-100" for="eWallet">
                                                        eWallet
                                                    </label>
                                                </div>	
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.mode_of_payment" value="Digital-Currency"  id="Digital-Currency">
                                                    <label class="form-check-label w-100" for="Digital-Currency">
                                                        Digital Currency
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Sale Package Offer</label>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.sale_package_offer" value="ABSD-Rebate" id="ABSD-Rebate">
                                                    <label class="form-check-label w-100" for="ABSD-Rebate">
                                                        ABSD Rebate
                                                    </label>
                                                </div>	
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.sale_package_offer" value="Stay-Then-Pay" id="Stay-Then-Pay">
                                                    <label class="form-check-label w-100" for="Stay-Then-Pay">
                                                        Stay-Then-Pay
                                                    </label>
                                                </div>	
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.sale_package_offer" value="Deferred-Payment-Scheme-DPS" id="Deferred-Payment-Scheme-DPS">
                                                    <label class="form-check-label w-100" for="Deferred-Payment-Scheme-DPS">
                                                        Deferred Payment Scheme (DPS)
                                                    </label>
                                                </div>	
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.sale_package_offer" value="Experiential-Leasing-Scheme-Rent-To-Own" id="Experiential-Leasing-Scheme-Rent-To-Own">
                                                    <label class="form-check-label w-100" for="Experiential-Leasing-Scheme-Rent-To-Own">
                                                        Experiential Leasing Scheme (Rent-To-Own)
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>								 			
                                </div>
<!--                                <div class="property-inner-box">-->
<!--                                    <div class="comman-title">-->
<!--                                        <h3>Universal Listing {{ $t('feature') }}</h3>-->
<!--                                    </div>-->
<!--                                    <p>Property Developer (Marketing Team) will be able to submit or receive requests to assign this project to multiple Estate Agents with our digital-appointment widget, enhancing the reach and accelerating speed-to-market via the entire agency network with leveraged resources, amplifying the success rate while maintaining control of the original Project micro-webpage.</p>-->
<!--                                    <div class="form-group">-->
<!--                                        <label>Sale Package Offer</label>-->

<!--                                        <div class="form-check">-->
<!--                                            <input class="form-check-input" type="radio" v-model="form.hybrid_listing_type" value="2" id="Assign-To-Recommended-Estate-Agent">-->
<!--                                            <label class="form-check-label w-100" for="Assign-To-Recommended-Estate-Agent">-->
<!--                                                Assign To Recommended Estate Agents (Area/Project Specialist)-->
<!--                                            </label>-->
<!--                                        </div>-->
<!--                                        <div class="form-check">-->
<!--                                            <input class="form-check-input" type="radio" v-model="form.hybrid_listing_type" value="3" id="Find-My-Own">-->
<!--                                            <label class="form-check-label w-100" for="Find-My-Own">-->
<!--                                                Find My Own/Co-Broking Estate Agent-->
<!--                                            </label>-->
<!--                                        </div>-->
<!--                                        <div class="form-check">-->
<!--                                            <input class="form-check-input" type="radio" v-model="form.hybrid_listing_type" value="1" id="Attend-To-Inquiry">-->
<!--                                            <label class="form-check-label w-100" for="Attend-To-Inquiry">-->
<!--                                                Attend To Enquiry By Internal Marketing Team-->
<!--                                            </label>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
                                <div class="property-inner-box">
                                    <div class="comman-title">
                                        <h3>Unit Layout</h3>
                                    </div>
                                    <div class="comman-title">
                                        <h4>{{ $t('configuration') }}</h4>
                                    </div>
                                    <div class="listofunitdata ">
                                        <table class="table border text-center table-bordered align-middle">
                                            <thead>
                                                <tr>
                                                    <th>Unit Layout ID</th>
                                                    <th>Unit Layout Name</th>
                                                    <th>Unit Size</th>
                                                    <th>{{ $t('listing_price') }}</th>
                                                    <th>{{ $t('action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="unit_layoutData in unit_layoutList" :key="unit_layoutData.value" :value="unit_layoutData.value">
                                                    <td>{{ unit_layoutData.id }}</td>
                                                    <td>{{ unit_layoutData.unit_layout_name }}</td>
                                                    <td>{{ unit_layoutData.unit_size }}</td>
                                                    <td>{{ unit_layoutData.listing_price }}</td>
                                                    <td>
                                                        <button class="btn-small-custom btn-theme" type="button" @click.prevent="editUnitLayout(unit_layoutData)"><i class="fa fa-pencil"></i></button>&nbsp;
                                                        <button class="btn-small-custom btn-red" @click.prevent="deleteUnitLayout(unit_layoutData)" :id="unit_layoutData.id" type="button"><i class="fa fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <span class="error" v-if="errors.unit_layoutList">Please add a unit layout</span>
                                    </div>
                                    <div>
                                        <form @submit.prevent="submitPostUnitLayout">
                                            <div class="row row-cols-3">
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>Unit Layout Name</label>
                                                        <input type="text" class="form-control" placeholder="Unit Layout Name" v-model="form.unit_layout_name">
                                                        <span  class="error" v-if="errors.unit_layout_name">{{ errors.unit_layout_name }}</span>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>Unit Size (SQF/SQM)</label>
                                                        <input type="number" class="form-control" placeholder="FR Min Floor Area/Built-Up" v-model="form.unit_size">
                                                        <span  class="error" v-if="errors.unit_size">{{ errors.unit_size }}</span>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>{{ $t('listing_price') }}</label>
                                                        <input type="number" class="form-control" placeholder="FR Lowest" v-model="form.listing_price">
                                                        <span  class="error" v-if="errors.listing_price">{{ errors.listing_price }}</span>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>{{ $t('rooms') }}</label>
                                                        <input type="number" class="form-control" placeholder="Rooms" v-model="form.no_of_rooms">
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>Baths</label>
                                                        <input type="number" class="form-control" placeholder="Baths" v-model="form.no_of_baths">
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>Car Park Lots</label>
                                                        <input type="number" class="form-control" placeholder="Car Park Lots" v-model="form.no_car_park_lots">
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>{{ $t('total') }} {{ $t('units') }}</label>
                                                        <input type="number" class="form-control" placeholder="Total Units" v-model="form.total_units">
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>{{ $t('layout_type') }}</label>
                                                        <select id="layout_type_id" v-model="form.layout_type_id" class="form-control">
                                                            <option value="0" disabled selected>{{ $t('layout_type') }}</option>
                                                            <option v-for="option in optionsLayoutTypeList" :key="option.value" :value="option.value">
                                                                {{ option.label }}
                                                            </option>
                                                        </select>	
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>{{ $t('floor_level') }}</label>
                                                        <select id="floor_level_id" v-model="form.floor_level_id" class="form-control">
                                                            <option value="0" disabled selected>{{ $t('floor_level') }}</option>
                                                            <option v-for="option in optionsFloorLevelList" :key="option.value" :value="option.value">
                                                                {{ option.label }}
                                                            </option>
                                                        </select>	
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>{{ $t('main_view') }}</label>
                                                        <select id="main_view_id" v-model="form.main_view_id" class="form-control">
                                                            <option value="0" disabled selected>{{ $t('main_view') }}</option>
                                                            <option v-for="option in optionsMainList" :key="option.value" :value="option.value">
                                                                {{ option.label }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
<!--                                                <div class="col">-->
<!--                                                    <div class="form-group">-->
<!--                                                        <label>Unit Layout Features</label>-->
<!--                                                        <select id="" class="form-control">-->
<!--                                                            <option>10+</option>-->
<!--                                                        </select>	-->
<!--                                                    </div>-->
<!--                                                </div>-->
                                                <div class="col" v-if="currentEditingUnitLayout">
                                                    <div class="form-group">
                                                        <label>Unit Layout Images</label>
                                                        <div class="form-group">
                                                            <button type="button" class="btn comman-btn-1 w-100" data-bs-toggle="modal" data-bs-target="#upload-unit-layout-photos-modal">Upload Unit Layout <img src="frontend/images/upload-icon.svg" alt=""></button>
                                                            <span v-for="option in form.actual_unit_photos" :key="option.id" :value="option.id" :class="{'badge': true,
                                                            'bg-secondary': true, 'rounded-pill': true, [`addi_${option.id}`]: true, 'position-relative': true,
                                                            'featurelist-badge': true}">
                                                                    <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white otherDocDelete"> {{option.photo_original_name}} &nbsp;</a>
                                                                    <span class="fa fa-trash text-white" @click.prevent="deleteUnitLayoutImage(option)" :id="option.id">
                                                                        <span class="visually-hidden">X</span>
                                                                    </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
<!--                                                <div class="col" style="display: none">-->
<!--                                                    <div class="form-group">-->
<!--                                                        <label></label>-->
<!--                                                        <button class="btn comman-btn-2 w-100"><b>{{ $t('add') }} Features</b> <img src="frontend/images/plus-circal.svg" alt=""></button>-->
<!--                                                    </div>-->
<!--                                                </div>-->
                                            </div>
                                            <div class="d-flex justify-content-md-end">
                                                <button class="btn btn-custom ms-3" type="button" @click.prevent="resetUnitLayoutFields"><i class="fa fa-rotate-right"></i> Reset</button>&nbsp;
                                            </div>
                                            <div class="comman-title">
                                                <h4>Enriched Listing (Bonus) - Unit Layout Orientation For FengShui Mapping</h4>
                                            </div>
                                            <div class="">
                                                <label>{{ $t('facing_direction') }}</label>
                                                <div class="row row-cols-3">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <select class="form-control" v-model="form.FSM_property_facing_orientation_building_id">
                                                                <option value="0" disabled>{{ $t('main_block') }}/{{ $t('building_facing') }}</option>
                                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                                            </select>
                                                            <span  class="error" v-if="errors.FSM_property_facing_orientation_building_id">{{ errors.FSM_property_facing_orientation_building_id }}</span>	
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <select class="form-control" v-model="form.FSM_property_facing_orientation_main_entrance_id">
                                                                <option value="0" disabled>{{ $t('main_door_facing') }}</option>
                                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                                            </select>
                                                            <span  class="error" v-if="errors.FSM_property_facing_orientation_main_entrance_id">{{ errors.FSM_property_facing_orientation_main_entrance_id }}</span>	
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <select class="form-control" v-model="form.FSM_property_facing_orientation_main_balcony_window_id">
                                                                <option value="0" disabled>{{ $t('main_balcony') }}/{{ $t('window_facing') }}</option>
                                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                                            </select>
                                                            <span  class="error" v-if="errors.FSM_property_facing_orientation_main_balcony_window_id">{{ errors.FSM_property_facing_orientation_main_balcony_window_id }}</span>	
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="">
                                                <label>{{ $t('space_sector') }}</label>
                                                <div class="row row-cols-2">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <select class="form-control" v-model="form.FSM_space_sectors_main_door_entrance_sector_id">
                                                                <option value="0" disabled>{{ $t('main_door') }}/{{ $t('entrance_sector') }}</option>
                                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                                            </select>
                                                            <span  class="error" v-if="errors.FSM_space_sectors_main_door_entrance_sector_id">{{ errors.FSM_space_sectors_main_door_entrance_sector_id }}</span>	
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <select class="form-control" v-model="form.FSM_space_sectors_main_living_hall_hall_sector_id">
                                                                <option value="0" disabled>{{ $t('main_living-hall_sector') }}</option>
                                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                                            </select>
                                                            <span  class="error" v-if="errors.FSM_space_sectors_main_living_hall_hall_sector_id">{{ errors.FSM_space_sectors_main_living_hall_hall_sector_id }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <select class="form-control" v-model="form.FSM_space_sectors_kitchen_sector_id">
                                                                <option value="0" disabled>{{ $t('kitchen_sector') }}</option>
                                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                                            </select>
                                                            <span  class="error" v-if="errors.FSM_space_sectors_kitchen_sector_id">{{ errors.FSM_space_sectors_kitchen_sector_id }}</span>	
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <select class="form-control" v-model="form.FSM_space_sectors_main_master_bedroom_sector_id">
                                                                <option value="0" disabled>{{ $t('main') }}/{{ $t('master') }} {{ $t('room_sector') }}</option>
                                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                                            </select>
                                                            <span  class="error" v-if="errors.FSM_space_sectors_main_master_bedroom_sector_id">{{ errors.FSM_space_sectors_main_master_bedroom_sector_id }}</span>	
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-md-end">
                                                <!-- <router-link :to="`/post-project-listing-2/${itemId}`" class="btn comman-btn-6">{{ $t('previous') }}</router-link> -->
                                                <button class="btn btn-custom ms-3" type="submit">{{ currentEditingUnitLayout ? 'Update ' :  'Add '}} Unit Layout</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-md-end">
                                    <router-link :to="`/post-project-listing-2/${itemId}`" class="btn comman-btn-6">{{ $t('previous') }}</router-link>
                                    <button class="btn comman-btn-2 ms-3" type="submit">Save & Next</button>
                                </div>

                                <div class="progress-bar-box">
                                <div>{{ $t('form_completion') }}: {{ completionPercentage }}%</div>
                                <div class="progress">
                                    <div 
                                        class="progress-bar" 
                                        role="progressbar" 
                                        :style="{ width: completionPercentage + '%' }" 
                                        :aria-valuenow="completionPercentage" 
                                        aria-valuemin="0" 
                                        aria-valuemax="100">
                                    </div>
                                    </div>
                                </div>
                            </form>
						</div>
					</div>
				</div>
			</div>
            <!-- upload unit layout photos modal -->
            <div class="modal fade" id="upload-unit-layout-photos-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                    <div class="modal-body text-center">
                        <form id="file-upload-form" class="uploader">
                            <input id="file-upload" type="file" @change="uploadUnitLayoutPhotos" name="fileUploadImg" multiple accept="image/*" />
                                <input type="hidden" name="type_photos"  id="type_photos" value="1">
                                <label for="file-upload" id="file-drag">
                                    <img id="file-image" src="#" alt="Preview" class="hidden">
                                    <div id="start">
                                        <img src="frontend/images/big-upload-icom.svg" alt="">
                                        <div>
                                            <h5 class="title_photos_upload">Please Upload Your Unit Layout Photos</h5>
                                            <p class="mb-0">&lt;Types Of File Formats&gt; JPG, PDF, PNG <br>
                                            &lt;Max Size Per File&gt; 05 - 10 PX <br>
                                            &lt;Max No. Of Files&gt; 4 </p>
                                        </div>	
                                        <div id="notimage" class="hidden">{{ $t('please_select_an_image') }}</div>
                                    </div>
                                    <div id="response" class="hidden">
                                        <div id="messages"></div>
                                        <progress class="progress" id="file-progress" value="0">
                                            <span>0</span>%
                                        </progress>
                                    </div>
                                </label>
                            </form>
                        </div>
                    </div>
                </div>
            </div>  
		</section>
</template>
  
  <script>
  import LoaderCust from '@/components/LoaderCust.vue';
  import { mapState } from 'vuex';
  import RecursiveDropdown from '@/components/RecursiveDropdown.vue';
  import { FUNG_SHUI_CARDINAL } from '@/constants/constantsProperty';
  import toastr from 'toastr';
  import {CONSTANT} from "@/constants/constants";
  export default {
    inheritAttrs: false,
    name: 'PostPropertyListingStep3', 
    components: {
        RecursiveDropdown,
        LoaderCust,
    },
    data() {
        return { 
            isLoading: false,    
            errorMessage :'',    
            options: this.transformData(FUNG_SHUI_CARDINAL),   
            optionsLayoutTypeList: [],   
            optionsMainList: [],
            optionsFloorLevelList: [],
            unit_layoutList: [],
            form: {
                main_view_id : '0',
                layout_type_id : '0',
                floor_level_id : '0',
                listing_price_from : '',
                listing_price_to : '',
                listing_price_discount : '',
                listing_price_rebate : '',
                sale_package_offer : [],
                hybrid_listing_type : '1',
                additional_description:'',
                asking_price_sale_rent:'',
                pr_master_furnishing_fitting_id:'',
                mode_of_payment:[],

                FSM_property_facing_orientation_building_id: 0,
                FSM_property_facing_orientation_main_entrance_id: 0,
                FSM_property_facing_orientation_main_balcony_window_id: 0,
                FSM_space_sectors_main_door_entrance_sector_id: 0,
                FSM_space_sectors_main_living_hall_hall_sector_id: 0,
                FSM_space_sectors_kitchen_sector_id: 0,
                FSM_space_sectors_main_master_bedroom_sector_id: 0,

                actual_unit_photos: [],
            },            
            errors: {},
            currentEditingUnitLayout: null
        }
    },
    computed: {
        CONSTANT() {
            return CONSTANT
        },
      ...mapState(['isAuthenticated', 'user']),
      hierarchicalOptions() {        
            return this.createHierarchicalOptions(this.options);
        },
      itemId() {
        return this.$route.params.id;
      },
      completionPercentage() {
        const fields = [
            this.form.listing_price_from,
            this.form.listing_price_to,
            this.form.listing_price_discount,
            this.form.listing_price_rebate,
            this.form.mode_of_payment.length > 0,
            this.form.sale_package_offer.length > 0,

            this.form.unit_layout_name,
            this.form.unit_size,
            this.form.listing_price,
            this.form.no_of_rooms,
            this.form.no_of_baths,
            this.form.no_car_park_lots,
            this.form.total_units,
            this.form.layout_type_id,
            this.form.floor_level_id,
            this.form.main_view_id,
            // this.form.actual_unit_photos,

            this.form.FSM_property_facing_orientation_building_id,
            this.form.FSM_property_facing_orientation_main_entrance_id,
            this.form.FSM_property_facing_orientation_main_balcony_window_id,
            this.form.FSM_space_sectors_main_door_entrance_sector_id,
            this.form.FSM_space_sectors_main_living_hall_hall_sector_id,
            this.form.FSM_space_sectors_kitchen_sector_id,
            this.form.FSM_space_sectors_main_master_bedroom_sector_id
        ];
        // Count fields that are true (i.e., considered "filled")
        const filledFields = fields.filter(field => field).length;

        return Math.round((filledFields / fields.length) * 100);
      },
    },
    created() {
        this.fetchOptions();
    },
    watch: {
        // Automatically save the completion percentage whenever it changes
        completionPercentage(newPercentage) {
            console.log('Completion percentage updated to:', newPercentage);
            this.saveCompletion();
        },
    },
    methods: {
        savePageCompletion(page, percentage) {
            // Save the page completion percentage to local storage
            const completionData = JSON.parse(localStorage.getItem('projectFormCompletionData')) || {};
            completionData[page] = percentage;
            localStorage.setItem('projectFormCompletionData', JSON.stringify(completionData));
        },

        saveCompletion() {
            // Save the current page's completion percentage
            const percentage = this.completionPercentage;
            this.savePageCompletion('projectPage3', percentage);
        },
        validateForm() {
             this.errors = {};
             if (this.form.listing_price_from == 0) {
                 this.errors.listing_price_from = 'From Listing Price is required.';
             }
             if (this.unit_layoutList.length == 0) {
                 this.errors.unit_layoutList = 'From Listing Price is required.';
             }
             if (this.form.listing_price_to == 0) {
                 this.errors.listing_price_to = 'From Listing Price is required.';
             }
             if (!this.form.listing_price_discount) {
                 this.errors.listing_price_discount = 'Discount From Listing Sale Price is required.';
             }
             if (!this.form.listing_price_rebate) {
                 this.errors.listing_price_rebate = 'Rebate From SP&A  Price is required.';
             }
            return Object.keys(this.errors).length === 0;
            //return 1
        }, 
        validateUnitLayoutForm() {
            
             this.errors = {};
             if (!this.form.unit_layout_name) {
                 this.errors.unit_layout_name = 'Unit Layout Name is required.';
             }
             if (!this.form.listing_price) {
                 this.errors.listing_price = 'Listing Price is required.';
             }
             if (!this.form.unit_size) {
                 this.errors.unit_size = 'Listing Price Price is required.';
             }
            return Object.keys(this.errors).length === 0;
            //return 1
        }, 
        async submitPost() {
            
            //this.$router.push('/post-property-listing-3/'+this.itemId);         
            if (this.validateForm()) {
                //var qualify_for_foreigner = this.form.qualify_for_foreigner == true ? 1 : 0
                const token = localStorage.getItem('userToken');
                if (this.isLoading) return;
                this.isLoading = true;
                try {
                    
                    await this.$axios.post('api/update-pricing-and-other-details', {
                        user_id :this.user.id,
                        api_token: token,
                        project_listing_id:this.itemId,                        
                        listing_price_from : this.form.listing_price_from,
                        listing_price_to : this.form.listing_price_to,
                        listing_price_discount : this.form.listing_price_discount,
                        listing_price_rebate : this.form.listing_price_rebate,
                        mode_of_payment : this.form.mode_of_payment,
                        sale_package_offer : this.form.sale_package_offer,
                        hybrid_listing_type : this.form.hybrid_listing_type
                    })
                    .then(response => {
                        toastr.success(response.data.message, 'Success', {progressBar: true, closeButton: true});
                        this.$router.push('/post-project-listing-4/'+this.itemId); 
                    })
                    

                } catch (error) {
                    
                    toastr.error('Something went wrong in API',{progressBar: true, closeButton: true})

                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.isLoading = false;
                }
            }
        }, 
        async submitPostUnitLayout() {
            if (this.validateUnitLayoutForm()) {
                const token = localStorage.getItem('userToken');                
                try {
                    toastr.info('Submitting Unit Layout', 'Info', {progressBar: true, closeButton: true});

                    const response = await this.$axios.post('api/add-update-unit-layout', {
                        user_id :this.user.id,
                        api_token: token,
                        project_listing_id:this.itemId,                        
                        
                        project_unit_layout_id: this.currentEditingUnitLayout ? this.currentEditingUnitLayout.id : 0,
                        unit_layout_name: this.form.unit_layout_name ? this.form.unit_layout_name : '',
                        unit_size : this.form.unit_size ? this.form.unit_size : '',
                        listing_price : this.form.listing_price ? this.form.listing_price : 0,
                        no_of_rooms : this.form.no_of_rooms ? this.form.no_of_rooms : 0,
                        no_of_baths : this.form.no_of_baths ? this.form.no_of_baths : 0,
                        no_car_park_lots : this.form.no_car_park_lots ? this.form.no_car_park_lots : 0,
                        total_units : this.form.total_units ? this.form.total_units : 0,
                        layout_type_id : this.form.layout_type_id ? this.form.layout_type_id : 0,
                        floor_level_id : this.form.floor_level_id ? this.form.floor_level_id : 0,
                        main_view_id : this.form.main_view_id ? this.form.main_view_id : 0,
                        actual_unit_photos: this.form.actual_unit_photos ? this.form.actual_unit_photos : [],
                        feature_ids : '0',
                        // feature_ids : this.form.feature_ids,
                        FSM_property_facing_orientation_building_id:this.form.FSM_property_facing_orientation_building_id ? this.form.FSM_property_facing_orientation_building_id : 0,
                        FSM_property_facing_orientation_main_entrance_id : this.form.FSM_property_facing_orientation_main_entrance_id ? this.form.FSM_property_facing_orientation_main_entrance_id : 0,
                        FSM_property_facing_orientation_main_balcony_window_id : this.form.FSM_property_facing_orientation_main_balcony_window_id ? this.form.FSM_property_facing_orientation_main_balcony_window_id : 0,
                        FSM_space_sectors_main_door_entrance_sector_id : this.form.FSM_space_sectors_main_door_entrance_sector_id ? this.form.FSM_space_sectors_main_door_entrance_sector_id : 0,
                        FSM_space_sectors_main_living_hall_hall_sector_id : this.form.FSM_space_sectors_main_living_hall_hall_sector_id ? this.form.FSM_space_sectors_main_living_hall_hall_sector_id : 0,
                        FSM_space_sectors_kitchen_sector_id : this.form.FSM_space_sectors_kitchen_sector_id ? this.form.FSM_space_sectors_kitchen_sector_id : 0,
                        FSM_space_sectors_main_master_bedroom_sector_id : this.form.FSM_space_sectors_main_master_bedroom_sector_id ? this.form.FSM_space_sectors_main_master_bedroom_sector_id : 0,
                    });
                    
                    toastr.success(response.data.message, 'Success', {progressBar: true, closeButton: true});
                    this.unit_layoutList = response.data.output;
                    this.resetUnitLayoutFields();
                } catch (error) {                    

                    toastr.error('Something went wrong in API',{progressBar: true, closeButton: true})
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }

                } finally {
                    this.isLoading = false;
                }
            }
            
        }, 
        resetUnitLayoutFields() {
            this.form.unit_layout_name = null;
            this.form.unit_size = 0;
            this.form.listing_price = 0;
            this.form.no_of_rooms = 0;
            this.form.no_of_baths = 0;
            this.form.no_car_park_lots = 0;
            this.form.total_units = 0;
            this.form.layout_type_id = 0;
            this.form.floor_level_id = 0;
            this.form.main_view_id = 0;
            this.form.actual_unit_photos = [];

            this.form.FSM_property_facing_orientation_building_id = 0;
            this.form.FSM_property_facing_orientation_main_entrance_id = 0;
            this.form.FSM_property_facing_orientation_main_balcony_window_id = 0;
            this.form.FSM_space_sectors_main_door_entrance_sector_id = 0;
            this.form.FSM_space_sectors_main_living_hall_hall_sector_id = 0;
            this.form.FSM_space_sectors_kitchen_sector_id = 0;
            this.form.FSM_space_sectors_main_master_bedroom_sector_id = 0;
            this.currentEditingUnitLayout = null;
        },
        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        }, 
        validateInput_d1() {
            if (this.form.listing_price_discount === '') {
                this.errorMessage = '';
                return;
            }
            const num = parseFloat(this.form.listing_price_discount);
            if (isNaN(num) || num < 0 || num > 100) {
                this.errorMessage = `Please enter a number between 0 and 100.`;
            } else {
                this.errorMessage = '';
            }
        },
        validateInput_d2() {
            if (this.form.listing_price_rebate === '') {
                this.errorMessage = '';
                return;
            }
            const num = parseFloat(this.form.listing_price_rebate);
            if (isNaN(num) || num < 1 || num > 100) {
                this.errorMessage = `Please enter a number between 0 and 100.`;
            } else {
                this.errorMessage = '';
            }
        },
        handleKeypress(event) {
            const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
            if (!/^\d$/.test(event.key) && !allowedKeys.includes(event.key)) {
                event.preventDefault();
            }
        },        
        handleKeypress_d2(event) {
            const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
            if (!/^\d$/.test(event.key) && !allowedKeys.includes(event.key)) {
                event.preventDefault();
            }
        },    
        transformData(data) {
            const map = {};
            const result = [];
    
            data.forEach(item => {
            map[item.id] = { ...item, children: [] };
            });
    
            data.forEach(item => {
            if (item.parent_id === 0) {
                result.push(map[item.id]);
            } else {
                map[item.parent_id].children.push(map[item.id]);
            }
            });
    
            return result;
        },
        createHierarchicalOptions(data) {
            return data.map(option => this.createOptionGroup(option));
        },
        createOptionGroup(option) {
                if (!option.children || option.children.length === 0) {
                    return {
                        type: 'option',
                        id: option.id,
                        label: option.name,
                        depth: option.depth,
                    };
                }
                return {
                    type: 'optgroup',
                    label: option.name,
                    depth: option.depth,
                    children: option.children.map(child => this.createOptionGroup(child))
                };
        },    
        async fetchOptions() {
            this.isLoading = true;
            // Get Layout type list
            try {
                const response = await this.$axios.post('api/pr-layout-features-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsLayoutTypeList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }

            // Get Main View
            try {
                const response = await this.$axios.post('api/pr-main-view-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_property:1,
                    last_record_number : 0                     
                });
                this.optionsMainList = response.data.output.map(item => ({
                    value: item.id,
                    label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }

            // Get Floor level
            try {
                const response = await this.$axios.post('api/pr-floor-levels-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsFloorLevelList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            // If Edit
            if (this.itemId > 0) {                    
                this.fetchProjectDetails();
            }
            
        },
        async fetchProjectDetails(reclickEditUnitLayout) {
            try {
                const response = await this.$axios.post('api/project-detail',
                {                    
                    user_id :this.user.id,
                    api_token: localStorage.getItem('userToken'),
                    project_listing_id:this.itemId,                    
                });
                this.editData = response.data.output[0];
                this.unit_layoutList = this.editData.unit_layout
                this.form.listing_price_from = this.editData.listing_price_from;
                this.form.listing_price_to = this.editData.listing_price_to;
                this.form.listing_price_discount = this.editData.listing_price_discount;
                this.form.listing_price_rebate = this.editData.listing_price_rebate;
                if (this.editData.mode_of_payment) {
                    this.form.mode_of_payment = JSON.parse(this.editData.mode_of_payment);
                }
                if (this.editData.sale_package_offer) {
                    this.form.sale_package_offer = JSON.parse(this.editData.sale_package_offer);
                }

                this.isLoading = false;

            } catch (error) {
                console.error('Error fetching project details:', error);
            } finally {
                if (reclickEditUnitLayout) {
                    const currentUnitLayout = this.unit_layoutList.find(list => list.id === this.currentEditingUnitLayout.id);
                    if (currentUnitLayout) {
                        this.editUnitLayout(currentUnitLayout);
                    }
                }
            }
        },
        editUnitLayout(unitLayout) {
            this.currentEditingUnitLayout = unitLayout;
            this.form.unit_layout_name = this.currentEditingUnitLayout.unit_layout_name;
            this.form.unit_size = this.currentEditingUnitLayout.unit_size;
            this.form.listing_price = this.currentEditingUnitLayout.listing_price;
            this.form.no_of_rooms = this.currentEditingUnitLayout.no_of_rooms;
            this.form.no_of_baths = this.currentEditingUnitLayout.no_of_baths;
            this.form.no_car_park_lots = this.currentEditingUnitLayout.no_car_park_lots;
            this.form.total_units = this.currentEditingUnitLayout.total_units;
            this.form.layout_type_id = this.currentEditingUnitLayout.layout_type;
            this.form.floor_level_id = this.currentEditingUnitLayout.floor_level;
            this.form.main_view_id = this.currentEditingUnitLayout.main_view;
            this.form.actual_unit_photos = this.currentEditingUnitLayout.actual_unit_photos;

            // enriched listing bonus section
            this.form.FSM_property_facing_orientation_building_id = this.currentEditingUnitLayout.FSM_property_facing_orientation_building_id?.id ?? this.currentEditingUnitLayout.FSM_property_facing_orientation_building_id;
            this.form.FSM_property_facing_orientation_main_entrance_id = this.currentEditingUnitLayout.FSM_property_facing_orientation_main_entrance_id?.id ?? this.currentEditingUnitLayout.FSM_property_facing_orientation_main_entrance_id;
            this.form.FSM_property_facing_orientation_main_balcony_window_id = this.currentEditingUnitLayout.FSM_property_facing_orientation_main_balcony_window_id;
            this.form.FSM_space_sectors_main_door_entrance_sector_id = this.currentEditingUnitLayout.FSM_space_sectors_main_door_entrance_sector_id?.id ?? this.currentEditingUnitLayout.FSM_space_sectors_main_door_entrance_sector_id;
            this.form.FSM_space_sectors_main_living_hall_hall_sector_id = this.currentEditingUnitLayout.FSM_space_sectors_main_living_hall_hall_sector_id;
            this.form.FSM_space_sectors_kitchen_sector_id = this.currentEditingUnitLayout.FSM_space_sectors_kitchen_sector_id;
            this.form.FSM_space_sectors_main_master_bedroom_sector_id = this.currentEditingUnitLayout.FSM_space_sectors_main_master_bedroom_sector_id;
        },
        deleteUnitLayout(unitLayout) {
            window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete Unit Layout`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    try {
                        this.$axios.post(`api/unit-layouts/${unitLayout.id}/delete`, {
                            api_token: localStorage.getItem('userToken')             
                        });
                        toastr.success('Unit layout deleted!', 'success', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                        });
                    } catch (error) {
                        toastr.error('Error deleting unit layout: ' + error, 'error', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                        });
                    } finally {
                        this.fetchOptions();
                    }
                }
            });
        },
        deleteUnitLayoutImage(image) {
            window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete Unit Layout Image`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    try {
                        this.$axios.post(`api/delete-documents-other-photo`, {
                            api_token: localStorage.getItem('userToken'),
                            user_id: this.user.id,
                            doc_photo_id: image.id,
                        });
                        toastr.success('Unit layout image deleted!', 'success', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                        });
                    } catch (error) {
                        toastr.error('Error deleting unit layout: ' + error, 'error', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                        });
                    } finally {
                        this.fetchProjectDetails(true);
                    }
                }
            });
        },
        async uploadUnitLayoutPhotos(event) {
            const files = event.target.files;
            this.selectedFiles = Array.from(files);
            if (this.selectedFiles) {
                const token = localStorage.getItem('userToken');
                const formData = new FormData();

                // Append files to FormData
                this.selectedFiles.forEach(file => {
                    formData.append('doc_other_photo[]', file);
                });
                formData.append('dataJson', JSON.stringify({
                    user_id: this.user.id,
                    api_token: token,
                    property_listing_id: this.currentEditingUnitLayout.id,
                    doc_type: 'Unit Layout & Site Plan',
                }));

                try {
                    const response = await this.$axios.post('api/add-documents-other-photos', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    if (response.data.status == 1){
                        toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                        setTimeout(function(){
                            window.$(".btn-close").trigger('click');
                        },500);
                        const res_data = response.data.output;
                        let unitLayout = this.unit_layoutList.find(layout => layout.id === this.currentEditingUnitLayout.id);
                        unitLayout.actual_unit_photos =  res_data.filter(doc => doc.doc_type === 'Unit Layout & Site Plan'); 
                    }else{
                        toastr.error(response.data.message, 'Error', { timeOut: 3000 });
                    }
                } catch (error) {                
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.fetchProjectDetails(true);
                }
            }
        },
    }
  }
  </script>
  
  <style scoped>
  /* Add styles specific to the About Us page here */
  </style>
  