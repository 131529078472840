<template>
    <section class="property-slider-se">
        <LoaderCust :isLoading="loading"/>
        <div :class="['container', loading ? 'd-none' : '']">
            <div class="property-slider w-100">
                <div class="property-slider-left w-100">
                    <div class="property-slider-f-slider owl-carousel owl-theme" v-if="projectImages.length>0">
                        <div class="item" v-for="pimage in projectImages" :key="pimage.property_listing_id">
                            <div class="property-slider-images">
                                <img :src="`${CONSTANT.IMAGE_URL}/imagesProjectListing/${pimage?.photo_name}`" :alt="pimage?.photo_name" >
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="item">
                            <div class="property-slider-images">
                                <img :src="firstImageImgSrc">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="imageModal" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="imageModalLabel">{{ $t('project_images') }}</h5>
                            </div>
                            <div class="modal-body">
                                <div class="image-list d-flex flex-column overflow-auto text-center" style="max-height: 70vh;">
                                    <div v-for="(image, index) in projectImages" :key="index" class="image-item mb-3">
                                        <img :src="`${CONSTANT.IMAGE_URL}/imagesProjectListing/${image.photo_name}`"
                                             :alt="image.photo_name" class="img-fluid rounded">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="property-details-se">
                <div class="row">
                    <div class="col-md-8">
                        <div class="property-title-se d-flex align-items-center">
                            <div class="d-flex flex-grow-1 align-items-center">
                                <h3 class="mb-0 text-wrap d-flex align-items-center">
                                    {{ propertyDetail.property_name }}
                                    <img src="frontend/images/varify-user.svg" alt="">
                                    <img src="frontend/images/file-check.svg" alt="">
                                    <img src="frontend/images/compass-icon.svg" alt="">
                                </h3>
                            </div>
                            <div class="col-auto text-end ms-3">
                                <span class="property-price">S$ {{ formatPrice(propertyDetail.asking_price_sale_rent) }}</span>
                            </div>
                        </div>
                        <div class="property-meta-se row">
                            <ul>
                                <li><i class="fa fa-map-pin"></i><span><b>{{ formattedAddress }}</b></span></li>
                                <li><i class="fa fa-home"></i><span><b>{{ $t('listing_reference_id') }}:{{propertyDetail.id }}</b></span></li>
                            </ul>
                        </div>
                        <PropertyFengShui :property-detail="propertyDetail"></PropertyFengShui>
                    </div>
                    <div class="col-md-4">
                        <div class="property-siderbar-boxs">
                            <div class="profile-box">
                                <img :src="computedProfileImgSrc" >
                                <div>
                                    <h5>{{projectUserDetail.name ?? '-'}} <img src="frontend/images/varify-user.svg" alt=""></h5>
                                </div>
                            </div>
                            <div class="list-box">
                                <ul>
                                    <li>{{ $t('project_name') }}:<span><b>{{projectDetail.project_name ?? '-'}}</b></span></li>
                                    <li>{{ $t('project_status') }}:<span><b>{{projectDetail.project_status ?? '-'}}</b></span></li>
                                    <li>Developer Name:<span><b>{{projectDetail.developer_info_name ?? '-'}}</b></span></li>
                                    <li v-if="projectUserDetail">{{ $t('cea_registration_no') }}. <span><b>{{ projectUserDetail.user_service_details?.agency_registration_no ?? '-'}}</b></span></li>
                                    <li v-if="projectUserDetail">{{ $t('agency_name') }} <span><b>{{projectUserDetail.user_service_details?.agency_name  ?? '-'}}</b></span></li>
                                    <li v-if="projectUserDetail">{{ $t('agency_license_no') }}. <span><b>{{projectUserDetail.user_service_details?.agency_license ?? '-'}}</b></span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// Import Lightbox2 CSS and JS
import 'lightbox2/dist/css/lightbox.min.css';
import 'lightbox2/dist/js/lightbox-plus-jquery.min.js';
import LoaderCust from '@/components/LoaderCust.vue';
import toastr from "toastr";
import { CONSTANT } from '@/constants/constants';
import { mapState } from 'vuex';
import { formatPrice } from "@/utils/helpers";
import PropertyFengShui from "@/views/listing/PropertyFengShui.vue";

export default {
    name: 'PropertyDetail',
    components: {PropertyFengShui, LoaderCust},
    data() {
        return {
            CONSTANT,
            projectImages: [],
            projectDetail: [],
            projectUserDetail: [],
            otherDocList: [],
            mediaLinksList: [],
            propertyDetail: [''],
            propertyUserDetail: [],
            user_service_details: [],
            morePhotocount: 0,
            firstImage: [],
            secondImage: [],
            featuresList: [],
            add_featuresList: [],
            facilitiyList: [],
            add_facilitiyList: [],
            mode_of_payment: [],
            loading: false,
            response : {},
        }
    },
    created() {
        this.fetchOptions();
        this.fetchProjectInfo();
    },
    computed: {
        ...mapState(['isAuthenticated', 'user']),
        itemId() {
            return this.$route.params.id;
        },
        computedProfileImgSrc() {
            return this.projectUserDetail?.profile_photo_name ? `${CONSTANT.IMAGE_URL}/imagesProfile/${this.projectUserDetail.profile_photo_name}` : CONSTANT.DEFAULT_IMAGE;
        },
        firstImageImgSrc() {
            if (this.firstImage) {
                return this.firstImage.photo_name ? `${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${this.firstImage.photo_name}` : CONSTANT.DEFAULT_NO_IMAGE;
            } else {
                return CONSTANT.DEFAULT_NO_IMAGE;
            }
        },
        formattedAddress() {
            let addressParts = [
                this.projectDetail.street,
                this.projectDetail.area_hdb_estate,
                this.projectDetail.district
            ];
            let address = addressParts.filter(part => part !== null && part !== undefined).join(', ');
            return address || '-';
        }
    },
    methods: {
        formatPrice,
        async fetchOptions() {
            const token = localStorage.getItem('userToken');
            try {
                this.loading = true;
                const response = await this.$axios.post('api/property-detail',
                    {
                        user_id: this.user.id,
                        api_token: token,
                        property_listing_id: this.itemId,
                        with: 'reviews.user'
                    });
                this.propertyDetail = response.data.output[0];
                this.propertyUserDetail = response.data.output[0].user_id_details;
                this.user_service_details = response.data.output[0].user_id_details?.user_service_details;
                this.morePhotocount = response.data.output[0].actual_unit_photo.length;
                this.firstImage = response.data.output[0].actual_unit_photo[1];
                this.secondImage = response.data.output[0].actual_unit_photo[2];
                this.featuresList = response.data.output[0].features;
                this.add_featuresList = response.data.output[0].features_additional;
                this.facilitiyList = response.data.output[0].facilities;
                this.add_facilitiyList = response.data.output[0].facility_additional;
                this.otherDocList = response.data.output[0].doc_other_photos;
                this.mediaLinksList = response.data.output[0].media_links;

                setTimeout(function () {

                    window.$('.property-slider-f-slider').owlCarousel({
                        loop: false,
                        margin: 0,
                        nav: true,
                        dots: false,
                        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                        responsive: {
                            0: {
                                items: 1,
                            },
                            600: {
                                items: 1
                            },
                            1000: {
                                items: 1
                            }
                        }
                    })

                }, 1000);

                // If not JSON, use it as is
                try {
                    this.mode_of_payment = JSON.parse(response.data.output[0].mode_of_payment);
                } catch (e) {
                    this.mode_of_payment = response.data.output[0].mode_of_payment;
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            } finally {
                this.loading = false;
            }
        },
        async fetchProjectInfo()
        {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/property-to-project-detail',{
                    api_token: token,
                    property_listing_id: this.itemId,
                });

                this.projectDetail = response.data.output.project;
                this.projectUserDetail = response.data.output.user_service_detail;
                this.projectImages = response.data.output.project_photos;

            } catch (error) {
                toastr.error('Failed to retrieve Project Detail', error);
            }
        }
    }
}
</script>

<style scoped>
.opacity-0 {
    opacity: 0
}

/* Add styles specific to the About Us page here */
</style>