<template>
    <section class="payment-se">
        <div class="container">
            <div class="comman-title">
                <h3>Payment Method</h3>
            </div>
            <div class="payment-box">
                <div class="text-end mb-2">
                    <img src="frontend/images/visa.jpg" alt=""><img src="frontend/images/master-card.png" alt="">
                </div>
                <div id="card-element" class="my-3"></div>
                <p class="mb-0">You will not be charged until admin approves your package purchase request.</p>
            </div>
            <div class="form-group">
                    <input v-model="form.referral_code" id="referralCode" type="text" class="form-control" placeholder="Referral Code"/>
                    <span class="error" v-if="errors.referral_code">{{ errors.referral_code }}</span>
                </div>
            <div class="d-md-flex protect-payment">
                <div class="d-flex align-items-center">
                    <img src="frontend/images/lock.svg" class="me-3">
                    <p class="mb-0">We protect your payment information using<br> encryption to provide bank-level
                        security.</p>
                </div>
                <div class="ms-auto">
                    <button class="comman-btn-2" data-bs-toggle="modal" data-bs-target="#otpCodeModal">
                        Pay S${{
                        parseFloat(packageDetails.current_annual_subscription).toFixed(packageDetails.current_annual_subscription %
                        1 !==
                        0 ? 2 : 0) }}
                    </button>
                    <div class="modal fade" id="otpCodeModal" data-bs-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="otpCodeModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">OTP Verification</h5>
                                </div>
                                <div class="modal-body">
                                    <div><a href="#" @click.prevent="sendOtp">Click here to send OTP</a></div>
                                    <small>OTP will be sent to your registered mobile number <b>{{ user.mobile_number }}</b>.</small>
                                    <p v-if="otpCodeSent">OTP sent</p>
                                    <input type="text" v-model="otpCode" placeholder="Enter OTP" class="form-control mt-3">
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('cancel') }}</button>
                                    <button type="button" class="btn btn-primary" @click="verifyOtp">{{ $t('submit') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import toastr from "toastr";
    import { loadStripe } from '@stripe/stripe-js';

    export default {
        data() {
            return {
                packageDetails: {},
                packageId: '',
                form: {
                    referral_code: '',
                },
                errors: {},
                // stripe fields
                stripe: null,
                elements: null,
                card: null,

                otpCodeSent: false,
                otpCode: "",
                lockoutTime: 0,
            };
        },
        computed: {
            ...mapState(['isAuthenticated', 'user']),
        },
        async created() {
            this.packageId = this.$route.query.packageId;

            if (!this.packageId) {
                this.$router.push('/packages');
            }

            await this.fetchPackage();
        },
        async mounted() {
            if (!window.STRIPE_KEY) {
                toastr.error('Stripe key not found!', 'Error', { timeOut: 3000 });
            }
            this.stripe = await loadStripe(window.STRIPE_KEY);
            this.elements = this.stripe.elements();

            const options = {
                hidePostalCode: true,
                iconStyle: "solid"
            }
            this.card = this.elements.create('card', options);
            this.card.mount('#card-element');
        },
        methods: {
            async fetchPackage() {
                try {
                    const packagesResponse = await this.$axios.post('/api/package-list-based-on-id', {
                        api_token: localStorage.getItem('userToken'),
                        paf_product_id: this.packageId,
                    });

                    this.packageDetails = packagesResponse.data.output[0];
                } catch (error) {
                    console.error('Error fetching package:', error);
                }
            },

            async sendOtp() {
                try {
                    if (this.lockoutTime > 0) {
                        toastr.error('You are timed out. Please try again later.')
                        return;
                    }
                    const response = await this.$axios.post(`/api/code/send/${this.user.id}`, {
                        api_token: localStorage.getItem('userToken'),
                    });

                    if (response.status === 200) {
                        toastr.success('OTP sent to your mobile.', 'Success', { timeOut: 3000 });
                        this.otpCodeSent = true;
                    } else {
                        toastr.error('Failed to send OTP.', 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    toastr.error('Error sending OTP: ' + error.message, 'Error', { timeOut: 3000 });
                }
            },

            async verifyOtp() {
                try {
                    const response = await this.$axios.post(`/api/code/verify/${this.user.id}`, {
                        api_token: localStorage.getItem('userToken'),
                        otp: this.otpCode,
                    });

                    if (response.status === 200) {
                        this.createPaymentRequest();
                    } else {
                        toastr.error('Invalid OTP. Please try again.', 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    if (error.response.status === 429) {
                        this.lockoutTime = error.response.data.retry_after;
                        toastr.error(`Too many attempts. Try again in ${this.lockoutTime} seconds.`, 'Locked Out');

                        let interval = setInterval(() => {
                            if (this.lockoutTime > 0) {
                                this.lockoutTime--;
                            } else {
                                clearInterval(interval);
                            }
                        }, 1000);
                    } else if (error.response.status === 422) {
                        toastr.warning(`Incorrect OTP. ${error.response.data.remaining_attempts} attempts left.`, 'Warning');
                    } else {
                        toastr.error('Error verifying OTP: ' + error.message, 'Error', { timeOut: 3000 });
                    }
                }
            },

            async createPaymentRequest() {
                try {
                    const { paymentMethod, error } = await this.stripe.createPaymentMethod({
                        type: 'card',
                        card: this.card,
                    });

                    if (error) {
                        toastr.error(error.message, 'Error', { timeOut: 3000 });
                        return;
                    }
                    const response = await this.$axios.post(`api/users/${this.user.id}/packages/payment`, {
                        api_token: localStorage.getItem('userToken'),
                        package_id: this.packageId,
                        referral_code: this.referral_code,
                        payment_method: paymentMethod.id,
                    });

                    if (response.status === 200) {
                        toastr.success('Package purchased successfully! Waiting for approval', 'Success', { timeOut: 3000 });
                        this.$router.push('/packages');
                    } else {
                        toastr.error('Error purchasing package!' + response?.data?.error, 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    let message = error?.response?.data?.error || error;
                    toastr.error('Error purchasing package:' + message, 'Error', { timeOut: 3000 });
                    console.error('Error purchasing package:', error);
                } finally {
                    this.card.clear();
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                }
            },
        }
    };
</script>