<template>
    <section class="agent-details-se">
        <LoaderCust :isLoading="loading"/>
        <div :class="['container', loading ? 'd-none' : '']">
            <div class="row">
                <div class="col-md-9">
                    <div class="agent-details-box">
                        <div class="agent-photo-box">
                            <div class="agent-photo">
                                <img :src="getUserProfileImage(agent?.profile_photo_name)" />
                                <div class="logo">
                                    <img src="frontend/images/logo.png">
                                </div>
                            </div>
                            <div class="online-status">
                                <span class="badge bg-success"></span>
                            </div>
                        </div>
                        <div class="agent-details">
                            <h4>{{ agent?.name }}</h4>
                            <p><span>@{{ agent?.name }}</span></p>
                            <ul class="icon-list">
                                <li><img src="frontend/images/star.svg" alt=""> {{
                                    agent?.service_detail?.total_rating_points }}
                                    Ratings</li>
                                <li><img src="frontend/images/thumbs-up-star.svg" alt=""> {{
                                    agent?.service_detail?.total_reviews
                                    }} reviews</li>
                                <li><img src="frontend/images/eye.svg" alt=""> {{
                                    agent?.service_detail?.total_views }} {{ $t('views') }}</li>
                            </ul>
                            <ul class="text-list">
                                <li>CEA reg. no:<b>{{ agent?.service_detail?.agency_registration_no || '-' }}</b></li>
                                <li>{{ $t('company_name') }}:<b>{{ agent?.service_detail?.company_name || '-' }}</b></li>
                                <li>Agency Licence:<b>{{ agent?.service_detail?.agency_license || '-' }}</b></li>
                                <li>{{ $t('position') }}:<b>{{ agent?.service_detail?.position_held || '-' }}</b></li>
                                <li>Member Type:<b>{{ agent?.service_detail?.agency_registration_no || '-' }}</b></li>
                                <li>Member Since:<b>{{ yearsAgo(agent?.service_detail?.personal_working_since)
                                        }}</b></li>
                            </ul>
                        </div>
                    </div>
                    <div class="agent-info-box profile-rating">
                        <div class="comman-title">
                            <h4>{{ $t('profile_rating') }}</h4>
                        </div>
                        <div class="agent-details">
                            <ul class="text-list">
                                <li>No. Of Active Listings<b>{{ agent?.service_detail?.no_of_active_listings }}</b></li>
                                <!-- <li>No. Of Enquiries<b>{{ agent?.service_detail?.no_of_enquiries }}</b></li>
                                <li>No. Of Assignments<b>{{ agent?.service_detail?.no_of_assignments }}</b></li> -->
                                <li>No. Of Service Requests<b>{{ agent?.service_detail?.no_of_service_requests }}</b></li>
                                <!-- <li>Comments <b>{{ agent?.service_detail?.comments }}</b></li> -->
                                <li>Average Property Listing Score<b>{{ agent?.service_detail?.average_property_listing_score }}</b></li>
                            </ul>
                        </div>
                    </div>
                    <div class="agent-info-box">
                        <div class="comman-title">
                            <h4>About Me</h4>
                        </div>
                        <p class="mb-0">{{ agent?.about_you || '-' }}</p>
                    </div>
                    <div class="agent-info-box">
                        <div class="comman-title">
                            <h4>{{ $t('my_services') }}</h4>
                        </div>
                        <p class="mb-0">I am an Agent</p>
                    </div>
                    <div class="accordion custom-accordion" id="accordion-one">
                        <div class="accordion-item">
                            <div class="accordion-header" id="heading11">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#Reviews" aria-expanded="true" aria-controls="Reviews">
                                    {{ $t('reviews') }}
                                </button>
                            </div>
                            <div id="Reviews" class="accordion-collapse collapse" aria-labelledby="heading11">
                                <div class="accordion-body">
                                    <div v-if="computedReviews.length > 0">
                                        <div class="row row-cols-1" v-for="(review, key) in computedReviews"
                                            :key="'review-'+key">
                                            <div class="col">
                                                <div class="light-info-box" :class="{ 'review-blur-overlay': !review.visibility }">
                                                    <div class="d-flex justify-content-between">
                                                        <label class="w-auto me-3">{{ review.user?.name }}<i class="fa fa-star mx-1"
                                                            v-for="(rating, index) in review.rating_point" :key="'rating-star-'+index"
                                                            style="color: #FFD600;"></i></label>
                                                        <label class="w-auto me-3" v-if="isOwner">
                                                            <i :class="review.visibility ? 'fa fa-eye-slash' : 'fa fa-eye'" class="mx-1"
                                                                style="color: #FFD600; cursor: pointer;" @click="changeVisibility(review)"></i>
                                                        </label>
                                                    </div>
                                                    <h5>{{ review.rating_remarks }}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <span>{{ $t('no_reviews_found') }}.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="review-form px-1" v-if="!isOwner">
                        <div class="comman-title">
                            <h3>{{ $t('leave_a_review') }}</h3>
                        </div>
                        <div class="review-form-box">
                            <form>
                                <div class="form-group d-flex align-items-center">
                                    <label class="w-auto me-3"><b>{{ $t('rating') }}</b></label>
                                    <div class="rating">
                                        <input type="radio" name="rating" value="5" id="5" v-model="reviewForm.rating_point">
                                        <label for="5"><i class="fa fa-star-o"></i></label>
            
                                        <input type="radio" name="rating" value="4" id="4" v-model="reviewForm.rating_point">
                                        <label for="4"><i class="fa fa-star-o"></i></label>
            
                                        <input type="radio" name="rating" value="3" id="3" v-model="reviewForm.rating_point">
                                        <label for="3"><i class="fa fa-star-o"></i></label>
            
                                        <input type="radio" name="rating" value="2" id="2" v-model="reviewForm.rating_point">
                                        <label for="2"><i class="fa fa-star-o"></i></label>
            
                                        <input type="radio" name="rating" value="1" id="1" v-model="reviewForm.rating_point">
                                        <label for="1"><i class="fa fa-star-o"></i></label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control" rows="5" placeholder="Writer message here"
                                            v-model="reviewForm.rating_remarks"></textarea>
                                </div>
                                <button class="comman-btn-2 w-100" @click.prevent="submitReview">{{ $t('submit') }}</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="agent-btn-box">
                        <a @click="wishlistAgent" class="comman-btn-5 w-100 mb-3">
                            <template v-if="agent?.is_in_wishlist" >
                                <img src="frontend/images/star.svg" alt="">
                                Unsave
                                <span></span>
                            </template>
                            <template v-else>
                                <img src="frontend/images/star-non-filled.svg" alt="">
                                Save
                            </template>
                        </a>
                        <button v-if="this.user.id != agentId"
                            id="requestServiceButton" 
                            ref="requestServiceButton"  
                            class="comman-btn-5 w-100 mb-3" 
                            data-bs-toggle="modal" 
                            data-bs-target="#requestModal">
                            <img src="frontend/images/handshakes.svg" 
                            alt=""> 
                            {{ $t('request_my_service') }}
                        </button>
                        <SendMessageComponent type="agent" v-if="agent && (this.user.id != agentId)" :recipient="agent" :includePropertyCheckbox="false"/>
                    </div>
                    <div class="agent-info-box">
                        <div class="comman-title">
                            <h4>{{ $t('availability') }}</h4>
                        </div>
                        <ul v-if="agent?.service_detail" class="d-flex flex-wrap gap-1 list-unstyled m-0">
                            <li class="mb-0 pb-0" v-if="agent?.service_detail?.is_available_mon">
                                <span class="badge comman-btn-2 text-dark px-1 py-1 m-0">{{ $t('mon') }}</span>
                            </li>
                            <li class="mb-0 pb-0" v-if="agent?.service_detail?.is_available_tue">
                                <span class="badge comman-btn-2 text-dark px-1 py-1 m-0">{{ $t('tue') }}</span>
                            </li>
                            <li class="mb-0 pb-0" v-if="agent?.service_detail?.is_available_wed">
                                <span class="badge comman-btn-2 text-dark px-1 py-1 m-0">{{ $t('wed') }}</span>
                            </li>
                            <li class="mb-0 pb-0" v-if="agent?.service_detail?.is_available_thu">
                                <span class="badge comman-btn-2 text-dark px-1 py-1 m-0">{{ $t('thu') }}</span>
                            </li>
                            <li class="mb-0 pb-0" v-if="agent?.service_detail?.is_available_fri">
                                <span class="badge comman-btn-2 text-dark px-1 py-1 m-0">{{ $t('fri') }}</span>
                            </li>
                            <li class="mb-0 pb-0" v-if="agent?.service_detail?.is_available_sat">
                                <span class="badge comman-btn-2 text-dark px-1 py-1 m-0">{{ $t('sat') }}</span>
                            </li>
                            <li class="mb-0 pb-0" v-if="agent?.service_detail?.is_available_sun">
                                <span class="badge comman-btn-2 text-dark px-1 py-1 m-0">{{ $t('sun') }}</span>
                            </li>
                        </ul>
                        <p v-else>{{ 'N/A' }}</p>
                        <div class="comman-title mt-4">
                            <h4>{{ $t('working_hours') }}</h4>
                        </div>
                        <p v-if="agent?.service_detail">{{ agent?.service_detail?.working_hours ?? 'N/A'}}</p>
                        <div class="comman-title mt-4">
                            <h4>{{ $t('contacts') }}</h4>
                        </div>
                        <p>{{ agent?.contact_phone ?? 'N/A' }}</p>
                        <p>{{ agent?.email ?? 'N/A' }}</p>
                        <div class="comman-title">
                            <h4>{{ $t('my_social_medias') }}</h4>
                        </div>
                        <div class="social">
                            <a :href="agent?.twitter_url" v-if="agent?.twitter_url" target="_blank">
                                <img src="frontend/images/tweet-ico.svg">
                            </a>
                            <a :href="agent?.facebook_url" v-if="agent?.facebook_url" target="_blank">
                                <img src="frontend/images/fb-ico.svg">
                            </a>
                            <a :href="agent?.tiktok_url" v-if="agent?.tiktok_url" target="_blank">
                                <img src="frontend/images/tiktok-icon.svg">
                            </a>
                            <a :href="agent?.instagram_url" v-if="agent?.instagram_url" target="_blank">
                                <img  class="my-auto mx-auto" src="frontend/images/instagram-icon.svg">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="custom-listing">
        <div :class="['container', loading ? 'd-none' : '']">
            <div class="filter-bar-se">
                <div class="comman-title mb-0">
                    <h3>{{ agent?.name }}’s {{ $t('listings') }}</h3>
                </div>
                <div class="filter-right-bar">
                    <div class="dropdown">
                        <a href="/filter" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown"
                           aria-expanded="true">
                            <img src="frontend/images/filter-ico.svg"> {{ $t('sort_by') }}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                            <li>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Newest' }"
                                    @click.prevent="sortProperties('Newest')">{{ $t('newest') }}</a>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Oldest' }"
                                    @click.prevent="sortProperties('Oldest')">{{ $t('oldest') }}</a>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Highest Price' }"
                                    @click.prevent="sortProperties('Highest Price')">{{ $t('highest_price') }}</a>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Lowest Price' }"
                                    @click.prevent="sortProperties('Lowest Price')">{{ $t('lowest_price') }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-sm-2">
                <div v-if="properties && properties.length === 0">
                    {{ $t('no_records_found') }}.
                </div>
                <div v-else class="col" v-for="property in properties" :key="property.id">
                    <div class="project-bx h-100">
                        <div class="property-carousel agent-owl owl-carousel owl-theme">
                            <picture style="position: relative;">
                                <a class="wishlist-icon-in-pic" @click="wishlistProperty(property)" title="Wishlist Property">
                                    <img v-if="property.is_in_wishlist" src="frontend/images/heart-filled-ico.svg" alt="Remove"/>
                                    <img v-else src="frontend/images/heart-ico.svg" alt="Remove"/>
                                </a>
                                <img :src="getPropertyImage(property.actual_unit_photo?.[0]?.photo_name || null)" @error="handleImageError"
                                    alt="Property Image" />
                            </picture>
                        </div>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a :href="`/property-detail/${property.id}`">{{ property.property_name }}</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>S$</small> {{ formatPrice(property.asking_price_sale_rent) }}</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"
                                        alt="Location Icon"></i>{{
                                property.street }}, {{
                                property.district
                                }}</p>
                            <div class="pro-specify">
                                <span><i><img src="frontend/images/spec-ico1.svg" alt="Rooms"></i>{{
                                    property.no_of_rooms }}</span>
                                <span><i><img src="frontend/images/spec-ico2.svg" alt="Baths"></i>{{
                                    property.no_of_baths }}</span>
                                <span><i><img src="frontend/images/spec-ico3.svg" alt="Parking"></i>{{
                                    property.no_car_park_lots
                                    }}</span>
                                <span><i><img src="frontend/images/spec-ico4.svg" alt="Area"></i>{{ formatZeros(property.floor_area)
                                    }} {{
                                    property.floor_area_unit }}</span>
                            </div>
                            <div class="pro-tag">
                                <a v-for="tag in property.pro_tag" :key="tag" href="#">{{ tag }}</a>
                            </div>
                            <ul>
                                <li v-for="spec in property.specifications" :key="spec.id">
                                    <figure>
                                        <img :src="spec.icon" alt="Specification Icon">
                                    </figure>
                                    <small>{{ spec.description }}</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="custom-listing">
        <div :class="['container', loading ? 'd-none' : '']">
            <div class="filter-bar-se">
                <div class="comman-title mb-0">
                    <h3>{{ agent?.name }}’s {{ $t("hybrid_listings") }}</h3>
                </div>
                <div class="filter-right-bar">
                    <div class="dropdown">
                        <a href="/filter" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown"
                           aria-expanded="true">
                            <img src="frontend/images/filter-ico.svg"> {{ $t('sort_by') }}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                            <li>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Newest' }"
                                    @click.prevent="sortProperties('Newest')">{{ $t('newest') }}</a>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Oldest' }"
                                    @click.prevent="sortProperties('Oldest')">{{ $t('oldest') }}</a>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Highest Price' }"
                                    @click.prevent="sortProperties('Highest Price')">{{ $t('highest_price') }}</a>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Lowest Price' }"
                                    @click.prevent="sortProperties('Lowest Price')">{{ $t('lowest_price') }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-sm-2">
                <div v-if="hybrid_properties && hybrid_properties.length === 0">
                    {{ $t('no_records_found') }}.
                </div>
                <div v-else class="col" v-for="hybrid_property in hybrid_properties" :key="hybrid_property.id">
                    <div class="project-bx h-100">
                        <div class="property-carousel agent-owl owl-carousel owl-theme">
                            <picture style="position: relative;">
                                <a class="wishlist-icon-in-pic" @click="wishlistProperty(hybrid_property)" title="Wishlist Property">
                                    <img v-if="hybrid_property.is_in_wishlist" src="frontend/images/heart-filled-ico.svg" alt="Remove"/>
                                    <img v-else src="frontend/images/heart-ico.svg" alt="Remove"/>
                                </a>
                                <img :src="getPropertyImage(hybrid_property.actual_unit_photo?.[0]?.photo_name || null)" @error="handleImageError"
                                    alt="Property Image" />
                            </picture>
                        </div>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a :href="`/property-detail/${hybrid_property.id}`">{{ hybrid_property.property_name }}</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>S$</small> {{ formatPrice(hybrid_property.asking_price_sale_rent) }}</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"
                                        alt="Location Icon"></i>{{
                                hybrid_property.street }}, {{
                                hybrid_property.district
                                }}</p>
                            <div class="pro-specify">
                                <span><i><img src="frontend/images/spec-ico1.svg" alt="Rooms"></i>{{
                                    hybrid_property.no_of_rooms }}</span>
                                <span><i><img src="frontend/images/spec-ico2.svg" alt="Baths"></i>{{
                                    hybrid_property.no_of_baths }}</span>
                                <span><i><img src="frontend/images/spec-ico3.svg" alt="Parking"></i>{{
                                    hybrid_property.no_car_park_lots
                                    }}</span>
                                <span><i><img src="frontend/images/spec-ico4.svg" alt="Area"></i>{{ formatZeros(hybrid_property.floor_area)
                                    }} {{
                                    hybrid_property.floor_area_unit }}</span>
                            </div>
                            <div class="pro-tag">
                                <a v-for="tag in hybrid_property.pro_tag" :key="tag" href="#">{{ tag }}</a>
                            </div>
                            <ul>
                                <li v-for="spec in hybrid_property.specifications" :key="spec.id">
                                    <figure>
                                        <img :src="spec.icon" alt="Specification Icon">
                                    </figure>
                                    <small>{{ spec.description }}</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

        <!-- Bootstrap Modal -->
        <div class="modal fade bd-example-modal-lg" id="requestModal" tabindex="-1" aria-labelledby="requestModal" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="requestModalLabel">{{ $t('request_my_service') }}</h5>
                        <button type="button" class="border-0 bg-transparent" style="color: black" data-bs-dismiss="modal" aria-label="close" @click="resetError">X</button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="submitRequest">
                            <div class="property-details">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="property-info">
                                            <h4 class="mb-3 text-warning">{{ $t('property_details') }}</h4>
                                            <LoaderCust :isLoading="loading" />
                                            <div v-if="!loading && !items.length" class="alert alert-warning">{{ $t('no_approved_properties_found') }}.</div>

                                            <select class="form-control mb-3" v-model="selected_property">
                                                <option disabled selected value="">{{ $t('service_request_custom_select_property') }}</option>
                                                <option v-for="item in items.filter(i => i.user_id === user.id)" :key="item.id" :value="item">
                                                    {{ item.property_name }}
                                                </option>
                                            </select>

                                            <div v-if="selected_property" class="d-flex flex-wrap align-items-center gap-4">
                                                <div class="flex-grow-1">
                                                    <label><b>{{ $t('listing_type') }}: </b></label>
                                                    <span>{{ listingTypeName }}</span>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <label><b>{{ $t('property_type') }}:</b></label>
                                                    <span>{{ propertyTypeName }}</span>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <label><b>Asking Price:</b></label>
                                                    <span>{{ formatPrice(selected_property.asking_price_sale_rent) }}</span>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <label><b>Listing ID:</b></label>
                                                    <span>{{ selected_property.id }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="selected_property" class="terms-proposal mt-4">
                                            <h5>Terms Sheet Proposal</h5>
                                            <div class="d-flex flex-wrap align-items-center gap-3">
                                                <div class="mb-3 flex-grow-1">
                                                    <label><b>{{ $t('engagement_period') }}:</b></label>
                                                    <select v-model="form.engagement_period" class="form-control" id="engagementPeriod" required>
                                                        <option disabled selected value="">{{ $t('select') }} Period</option>
                                                        <option value="1">1 Month</option>
                                                        <option value="3">3 Months</option>
                                                        <option value="6">6 Months</option>
                                                    </select>
                                                </div>

                                                <div class="mb-3 flex-grow-1">
                                                    <label><b>{{ $t('fee') }}/{{ $t('commission') }}:</b></label>
                                                    <input v-model="form.commission" type="number" class="form-control" id="commission" placeholder="2.00%" required />
                                                </div>

                                                <div class="mb-3 flex-grow-1">
                                                    <label><b>{{ $t('appointment_type') }}:</b></label>
                                                    <select v-model="form.appointment_types" class="form-control" id="appointmentType" required>
                                                        <option disabled selected value="">{{ $t('select') }} Type</option>
                                                        <option v-for="(key, type) in CONSTANT.APPOINTMENT_TYPES" :key="type" :value="key">
                                                            {{ type }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <span v-if="serviceError" class="error">{{ serviceError }}</span>

                                            <div>
                                                <h5>{{ $t('available_credits') }}</h5>
                                                <p><b>{{ userCredit }} {{ $t('credits') }}</b></p>

                                                <div v-if="userCredit < 10">
                                                    <label><b>Oh no, you need 10 credits to request a service</b></label>
                                                    <a class="comman-btn-2" href="/top-up" @click="hideModal">
                                                        <img src="/frontend/images/Choose-plan.svg" alt="topUp" />
                                                        {{ $t('top_up') }}
                                                    </a>
                                                </div>

                                                <div v-else class="actions mt-3">
                                                    <button type="button" class="comman-btn-2-danger col-md-12 mb-2" data-bs-dismiss="modal" @click="resetError">{{ $t('close') }}</button>
                                                    <button type="submit" class="comman-btn-2 col-md-12" data-bs-dismiss="modal" >Request</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
</template>

<style>
    .profile-rating ul.text-list {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

    .profile-rating ul.text-list li {
        color: var(--grey-4B4A4A);
        display: flex;
        align-items: center;
        gap: 10px;
        min-width: 40%;
        font-size: 14px;
    }
</style>

<script>
import { mapState } from 'vuex';
import { CONSTANT } from "@/constants/constants";
import { formatPrice, formatZeros, formatPhoneNumber, getPropertyImage, getUserProfileImage, yearsAgo } from "@/utils/helpers";
import toastr from 'toastr';
import SendMessageComponent from './SendMessageComponent.vue';
import LoaderCust from "@/components/LoaderCust.vue";
import {getConstListingTypes, getConstPropertyTypes} from "@/utils/apiHelpers";

export default {
    components: {
        LoaderCust,
        SendMessageComponent
    },
    data() {
        return {
            agent: [],
            sort_by: 'Newest',
            properties: [],
            hybrid_properties: [],
            loading: false,
            items: [],
            selected_property: null,
            options: {
                listing_types: [],
                property_types: [],
            },
            form: {
                engagement_period: "",
                commission: "",
                appointment_types: "",
            },
            agentId : this.$route.params.id,
            forceShowModal: false,
            userCredit: '',
            reviewForm: {
                rating_point: 0,
                rating_remarks: null
            },
            serviceError: '',
        };
    },
    computed: {
        CONSTANT() {
            return CONSTANT
        },
        ...mapState(['isAuthenticated', 'user']),
        listingTypeName() {
            if (!this.selected_property.listing_type) {
                return 'Unknown';
            }
            const listingType = this.options.listing_types?.find(
                option => option.id === this.selected_property.listing_type
            );

            return listingType ? listingType.name : 'Unknown';
        },
        propertyTypeName() {
            const propertyTypeID = this.selected_property.property_type;

            if (!propertyTypeID) {
                return "Unknown";
            }

            const propertyType = this.findPropertyTypeById(
                this.options.property_types,
                propertyTypeID
            );

            return propertyType ? propertyType.name : "Unknown";
        },
        isOwner() {
            return this.isAuthenticated && this.user && this.agent && this.user.id == this.agent.id;
        },
        computedReviews() {
            if (this.agent?.reviews && this.agent.reviews.length > 0) {
                return this.isOwner ? this.agent.reviews : this.agent.reviews.filter(r => r.visibility == true);
            }

            return [];
        }

    },
    async created() {
        await this.fetchOptions();
        await this.fetchAgentDetails();
    },

    methods: {
        formatPrice,
        formatZeros,
        getPropertyImage,
        getUserProfileImage,
        yearsAgo,
        formatPhoneNumber,

        resetError() {
            this.serviceError = '';
        },

        openModal() {
            window.$('#requestModal').modal('show');
        },

        hideModal() {
            window.$('#requestModal').modal('hide');
        },

        async wishlistAgent() {
            try {
                const agentId = this.$route.params.id;
                if (!this.user?.id) {
                    toastr.error('Please log in to wishlist', 'error', {
                        progressBar: true,
                        closeButton: true,
                        timeOut: 1500,
                    });
                    return;
                }
                const wishlistResponse = await this.$axios.post(`api/users/${this.user.id}/wishlist/agents/${agentId}`, {
                    api_token: localStorage.getItem('userToken'),
                });

                if (wishlistResponse.status === 200) {
                    toastr.success(wishlistResponse.data.message, 'Success', {
                        progressBar: true,
                        closeButton: true,
                        timeOut: 1500,
                        onHidden: () => {
                            this.fetchAgentDetails();
                        }
                    });
                }
            } catch (error) {
                console.error('Error wishlisting agent:', error);
            }
        },
        async submitRequest()
        {
            try {
                const payload = {
                    api_token: localStorage.getItem('userToken'),
                    user_id: this.user.id,
                    agent_id: this.$route.params.id,
                    property_listing_id: this.selected_property.id,
                    engagement_period: this.form.engagement_period,
                    commission: this.form.commission,
                    appointment_type: this.form.appointment_types
                };

                const submitResponse = await this.$axios.post('/api/service-requests', payload);

                if (submitResponse.data.status === 1) {
                    toastr.success('Service Request Submitted Successfully');
                } else if (submitResponse.data.status === 2) {
                    toastr.error('Failed To Submit Service Request')
                    this.serviceError = submitResponse.data.message;
                } else if (submitResponse.data.status === 3) {
                    toastr.error('Failed To Submit Service Request')
                    this.serviceError = submitResponse.data.message;
                }

            } catch (error) {
                console.error(error);
            }
        },

        async changeVisibility(review) {
            if (!this.isOwner) {
                toastr.error('Invalid action', 'error', { timeOut: 3000 });
                return;
            }

            const result = await window.Swal.fire({
                title: `Are you sure you want to ${review.visibility ? 'hide' : 'unhide'} the review?`,
                showCancelButton: true,
                confirmButtonText: "Save",
                confirmButtonColor: "var(--primary-F6B034)",
                cancelButtonColor: "var(--danger-color, #FF0000)",
            });

            if (!result.isConfirmed) return;

            try {
                const response = await this.$axios.post(`api/user-service-ratings/${review.id}/visibility`, {
                    api_token: localStorage.getItem('userToken'),
                });

                if (response.status === 200 && response?.data?.output) {
                    this.agent.reviews = [ ... response.data.output];
                    toastr.success(response.data.message, 'Success', {
                        progressBar: true,
                        closeButton: true,
                        timeOut: 1500
                    });
                }
            } catch (error) {
                toastr.error('Error changing review visibility: ' + error, 'Error', { timeOut: 3000 });
            }
        },

        async submitReview() {
            try {
                if (!this.isAuthenticated || !this.user) {
                    toastr.error('Unauthenticated', 'error', { timeOut: 3000 });
                    return;
                }

                if (this.user.id === this.agent.id) {
                    toastr.error('Invalid user to leave review', 'error', { timeOut: 3000 });
                    return;
                }

                this.reviewForm.user_id = this.user.id;
                this.reviewForm.api_token = localStorage.getItem('userToken');
                const reviewResponse = await this.$axios.post(`api/agents/${this.agent.id}/review`, this.reviewForm);

                if (reviewResponse.status === 200) {
                    toastr.success(reviewResponse.data.message, 'Success', {
                        progressBar: true,
                        closeButton: true,
                        timeOut: 1500,
                        onHidden: () => {
                            this.fetchAgentDetails();
                            this.resetReviewForm();
                        }
                    });
                }
            } catch (error) {
                console.error('Error submitting review:', error);
            }
        },

        resetReviewForm() {
            this.reviewForm = {
                rating_point: 0,
                rating_remarks: null
            }
        },

        findPropertyTypeById(types, id) {
            const typeArray = Array.isArray(types) ? types : Object.values(types).flat();

            for (const type of typeArray) {
                if (type.children) {
                    const found = this.findPropertyTypeById(type.children, id);
                    if (found) {
                        return found;
                    }
                }

                if (type.id === id) {
                    return type;
                }
            }

            return null;
        },
        async fetchOptions() {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/property-list',
                    {
                        user_id : this.user.id,
                        api_token : token,
                        sort_by_field : 'Newest',
                        limit: 50,
                    });
                this.items  = response.data.output.myArray;
                this.userCredit = response.data.output.available_credit_points;
            } catch (error) {
                console.error('Error fetching options:', error);
            }finally {
                this.isLoading = false;
            }
        },
        async wishlistProperty(property) {
            try {
                if (!this.user?.id) {
                    toastr.error('Please log in to wishlist', 'error', {
                        progressBar: true,
                        closeButton: true,
                        timeOut: 1500,
                    });
                    return;
                }
                const wishlistResponse = await this.$axios.post(`api/users/${this.user.id}/wishlist/properties/${property.id}`, {
                    api_token: localStorage.getItem('userToken'),
                });

                if (wishlistResponse.status === 200) {
                    toastr.success(wishlistResponse.data.message, 'Success', {
                        progressBar: true,
                        closeButton: true,
                        timeOut: 1500,
                        onHidden: () => {
                            this.fetchAgentDetails();
                        }
                    });
                }
            } catch (error) {
                console.error('Error wishlisting property:', error);
            }
        },

        async fetchAgentDetails() {
            const agentId = this.$route.params.id;
            try {
                this.loading = true;
                const response = await this.$axios.post('/api/agent-detail', {
                    user_id: this.user.id,
                    agent_id: agentId,
                    sort_by: this.sort_by,
                });

                this.agent = response.data.output;
                this.properties = this.agent.property_details;
                this.hybrid_properties = this.agent.hybrid_property_details
                this.$nextTick(() => {
                    this.initializeOwlCarousel();
                });
            } catch (error) {
                console.error('Error fetching agent details:', error);
            } finally {
                this.loading = false;
                if (this.forceShowModal) {
                    this.openModal();
                }
            }
        },

        async fetchListingTypes() {
            try {
                this.options.listing_types = await getConstListingTypes();
            } catch (error) {
                console.error("Error fetching listing types:", error);
            }
        },

        async fetchPropertyTypes() {
            try {
                this.options.property_types = await getConstPropertyTypes();
            } catch (error) {
                console.error("Error fetching property types:", error);
            }
        },

        sortProperties(sortBy) {
            this.sort_by = sortBy;
            this.fetchAgentDetails();
        },
        initializeOwlCarousel() {
            window.$('.property-carousel').owlCarousel({
                loop: false,
                margin: 0,
                nav: true,
                dots: true,
                navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                responsive: {
                    0: {
                        items: 1,
                        margin: 0
                    },
                    600: {
                        items: 1
                    },
                    1000: {
                        items: 1
                    }
                }
            });
        },
        triggerRequestService() {
            const button = this.$refs.requestServiceButton;
            if (!button) {
                console.error('Request Service button is not available');
                return;
            }

            const propertyListingId = localStorage.getItem('property_listing_id');
            if (propertyListingId) {
                button.click();
            }
        },
    },

    mounted() {
        this.forceShowModal = this.$route.query.request_service ?? false;

        const hybridListingType = localStorage.getItem('hybrid_listing_type');
        const propertyListingId = localStorage.getItem('property_listing_id');

        if (hybridListingType === "2" && propertyListingId) {
            this.triggerRequestService();
        }

        // Fetch options and populate dropdown
        this.fetchOptions();
        this.fetchListingTypes();
        this.fetchPropertyTypes();

        // Set selected_property if propertyListingId is not null
        if (propertyListingId) {
            this.$nextTick(() => {
                this.selected_property = this.items.find(item => item.id == propertyListingId) || null;
            });
        }
    },

    watch: {
        items: {
            handler(newItems) {
                const propertyListingId = localStorage.getItem('property_listing_id');
                if (propertyListingId) {
                    this.selected_property = newItems.find(item => item.id == propertyListingId) || null;
                } else {
                    // Reset to default if no valid property_listing_id
                    this.selected_property = null;
                }
            },
            immediate: true, // Trigger immediately if `items` already have data
        },
    },

};
</script>

<style scoped>
    .dropdown-item:hover,
    .dropdown-item.selected {
        background-color: #ffe6b9;
    }
</style>