<template>
    <section class="agent-details-se">
        <LoaderCust :isLoading="loading"/>
        <div :class="['container', loading ? 'd-none' : '']">
            <div class="row">
                <div class="col-md-9">
                    <div class="agent-details-box">
                        <div class="agent-photo-box">
                            <div class="agent-photo">
                                <img :src="getUserProfileImage(agent?.profile_photo_name)" />
                                <div class="logo">
                                    <img src="frontend/images/logo.png">
                                </div>
                            </div>
                            <div class="online-status">
                                <span class="badge bg-success"></span>
                            </div>
                        </div>
                        <div class="agent-details">
                            <h4>{{ developer?.name }}</h4>
                            <p><span>@{{ developer?.name }}</span></p>
                            <ul class="icon-list">
                                <li><img src="frontend/images/eye.svg" alt=""> {{
                                    developer?.user_service_details?.total_views }} {{ $t('views') }}</li>
                            </ul>
                            <ul class="text-list">
                                <li>CEA reg. no:<b>{{ developer?.user_service_details?.agency_registration_no }}</b></li>
                                <li>{{ $t('company_name') }}:<b>{{ developer?.user_service_details?.company_name }}</b></li>
                                <li>Agency Licence:<b>{{ developer?.user_service_details?.agency_license }}</b></li>
                                <li>{{ $t('position') }}:<b>{{ developer?.user_service_details?.position_held }}</b></li>
                                <li>Member Type:<b>{{ developer?.user_service_details?.agency_registration_no }}</b></li>
                                <li>Member Since:<b>{{ yearsAgo(developer?.user_service_details?.personal_working_since)
                                        }}</b></li>
                            </ul>
                        </div>
                    </div>
                    <div class="agent-info-box profile-rating">
                        <div class="comman-title">
                            <h4>Active Listings & Projects</h4>
                        </div>
                        <div class="agent-details">
                            <ul class="text-list">
                                <li>No. Of Projects<b>{{ developer?.user_service_details?.no_of_active_listings }}</b></li>
                                <li>No. Of Active Listings<b>{{ developer?.user_service_details?.no_of_active_listings }}</b></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="agent-btn-box">
                        <a @click="wishlistDeveloper" class="comman-btn-5 w-100 mb-3">
                            <template v-if="developer?.is_in_wishlist" >
                                <img src="frontend/images/star.svg" alt="">
                                Unsave
                                <span></span>
                            </template>
                            <template v-else>
                                <img src="frontend/images/star-non-filled.svg" alt="">
                                Save
                            </template>
                        </a>
                        <SendMessageComponent type="agent" v-if="developer && (this.user.id != developerId)" :recipient="developer" :includePropertyCheckbox="false"/>
                    </div>
                    <div class="agent-info-box">
                        <div class="comman-title mt-4">
                            <h4>{{ $t('contacts') }}</h4>
                        </div>
                        <p>{{ formatPhoneNumber(developer?.mobile_number) }}</p>
                        <p>{{ developer?.email ?? 'N/A' }}</p>
                        <div class="comman-title">
                            <h4>{{ $t('my_social_medias') }}</h4>
                        </div>
                        <div class="social">
                            <a :href="developer?.twitter_url" v-if="developer?.twitter_url" target="_blank">
                                <img src="frontend/images/tweet-ico.svg">
                            </a>
                            <a :href="developer?.facebook_url" v-if="developer?.facebook_url" target="_blank">
                                <img src="frontend/images/fb-ico.svg">
                            </a>
                            <a :href="developer?.tiktok_url" v-if="developer?.tiktok_url" target="_blank">
                                <img src="frontend/images/tiktok-icon.svg">
                            </a>
                            <a :href="developer?.instagram_url" v-if="developer?.instagram_url" target="_blank">
                                <img  class="my-auto mx-auto" src="frontend/images/instagram-icon.svg">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="custom-listing">
        <div :class="['container', loading ? 'd-none' : '']">
            <div class="filter-bar-se">
                <div class="comman-title mb-0">
                    <h3>{{ developer?.name }}’s Projects</h3>
                </div>
                <div class="filter-right-bar">
                    <div class="dropdown">
                        <a href="/filter" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown"
                           aria-expanded="true">
                            <img src="frontend/images/filter-ico.svg"> {{ $t('sort_by') }}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                            <li>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Newest' }"
                                    @click.prevent="sortProperties('Newest')">{{ $t('newest') }}</a>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Oldest' }"
                                    @click.prevent="sortProperties('Oldest')">{{ $t('oldest') }}</a>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Highest Price' }"
                                    @click.prevent="sortProperties('Highest Price')">{{ $t('highest_price') }}</a>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Lowest Price' }"
                                    @click.prevent="sortProperties('Lowest Price')">{{ $t('lowest_price') }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
      
            <div class="row row-cols-lg-4 row-cols-sm-2">
                <div v-if="projects && projects.length === 0">
                    {{ $t('no_records_found') }}.
                </div>
                <div v-else class="col" v-for="project in projects" :key="project.id">
                    <div class="project-bx h-100">
                        <div class="property-carousel agent-owl owl-carousel owl-theme">
                            <picture style="position: relative;">
                                <a class="wishlist-icon-in-pic" @click="wishlistProperty(project)" title="Wishlist Property">
                                    <img v-if="project.is_in_wishlist" src="frontend/images/heart-filled-ico.svg" alt="Remove"/>
                                    <img v-else src="frontend/images/heart-ico.svg" alt="Remove"/>
                                </a>
                                <img :src="getPropertyImage(project.actual_unit_photo?.[0]?.photo_name || null)" @error="handleImageError"
                                    alt="Project Image" />
                            </picture>
                        </div>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a :href="`/project-detail/${project.id}`">{{ project.project_name }}</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>S$</small> {{ formatPrice(project.asking_price_sale_rent) }}</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"
                                        alt="Location Icon"></i>{{
                                project.street }}, {{
                                project.district
                                }}</p>
                            <div class="pro-specify">
                                <span><i><img src="frontend/images/spec-ico1.svg" alt="Rooms"></i>{{
                                    project.no_of_rooms }}</span>
                                <span><i><img src="frontend/images/spec-ico2.svg" alt="Baths"></i>{{
                                    project.no_of_baths }}</span>
                                <span><i><img src="frontend/images/spec-ico3.svg" alt="Parking"></i>{{
                                    project.no_car_park_lots
                                    }}</span>
                                <span><i><img src="frontend/images/spec-ico4.svg" alt="Area"></i>{{ project.floor_area
                                    }} {{
                                    project.floor_area_unit }}</span>
                            </div>
                            <div class="pro-tag">
                                <a v-for="tag in project.pro_tag" :key="tag" href="#">{{ tag }}</a>
                            </div>
                            <ul>
                                <li v-for="spec in project.specifications" :key="spec.id">
                                    <figure>
                                        <img :src="spec.icon" alt="Specification Icon">
                                    </figure>
                                    <small>{{ spec.description }}</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="custom-listing">
        <div :class="['container', loading ? 'd-none' : '']">
            <div class="filter-bar-se">
                <div class="comman-title mb-0">
                    <h3>{{ developer?.name }}’s {{ $t('listings') }}</h3>
                </div>
                <div class="filter-right-bar">
                    <div class="dropdown">
                        <a href="/filter" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown"
                           aria-expanded="true">
                            <img src="frontend/images/filter-ico.svg"> {{ $t('sort_by') }}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                            <li>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Newest' }"
                                    @click.prevent="sortProperties('Newest')">{{ $t('newest') }}</a>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Oldest' }"
                                    @click.prevent="sortProperties('Oldest')">{{ $t('oldest') }}</a>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Highest Price' }"
                                    @click.prevent="sortProperties('Highest Price')">{{ $t('highest_price') }}</a>
                                <a class="dropdown-item" href="#" :class="{ 'selected': sort_by === 'Lowest Price' }"
                                    @click.prevent="sortProperties('Lowest Price')">{{ $t('lowest_price') }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-sm-2">
                <div v-if="properties && properties.length === 0">
                    {{ $t('no_records_found') }}.
                </div>
                <div v-else class="col" v-for="property in properties" :key="property.id">
                    <div class="project-bx h-100">
                        <div class="property-carousel agent-owl owl-carousel owl-theme">
                            <picture style="position: relative;">
                                <a class="wishlist-icon-in-pic" @click="wishlistProperty(property)" title="Wishlist Property">
                                    <img v-if="property.is_in_wishlist" src="frontend/images/heart-filled-ico.svg" alt="Remove"/>
                                    <img v-else src="frontend/images/heart-ico.svg" alt="Remove"/>
                                </a>
                                <img :src="getPropertyImage(property.actual_unit_photo?.[0]?.photo_name || null)" @error="handleImageError"
                                    alt="Property Image" />
                            </picture>
                        </div>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a :href="`/property-detail/${property.id}`">{{ property.property_name }}</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>S$</small> {{ formatPrice(property.asking_price_sale_rent) }}</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"
                                        alt="Location Icon"></i>{{
                                property.street }}, {{
                                property.district
                                }}</p>
                            <div class="pro-specify">
                                <span><i><img src="frontend/images/spec-ico1.svg" alt="Rooms"></i>{{
                                    property.no_of_rooms }}</span>
                                <span><i><img src="frontend/images/spec-ico2.svg" alt="Baths"></i>{{
                                    property.no_of_baths }}</span>
                                <span><i><img src="frontend/images/spec-ico3.svg" alt="Parking"></i>{{
                                    property.no_car_park_lots
                                    }}</span>
                                <span><i><img src="frontend/images/spec-ico4.svg" alt="Area"></i>{{ formatZeros(property.floor_area)
                                    }} {{
                                    property.floor_area_unit }}</span>
                            </div>
                            <div class="pro-tag">
                                <a v-for="tag in property.pro_tag" :key="tag" href="#">{{ tag }}</a>
                            </div>
                            <ul>
                                <li v-for="spec in property.specifications" :key="spec.id">
                                    <figure>
                                        <img :src="spec.icon" alt="Specification Icon">
                                    </figure>
                                    <small>{{ spec.description }}</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="modal fade bd-example-modal-lg" id="requestModal" tabindex="-1" aria-labelledby="requestModal" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="requestModalLabel">{{ $t('request_my_service') }}</h5>
                    <button type="button" class="border-0 bg-transparent" style="color: black" data-bs-dismiss="modal" aria-label="close">X</button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="submitRequest">
                        <div class="property-details">
                            <div class="card">
                                <div class="card-body">
                                    <div class="property-info">
                                        <h4 class="mb-3 text-warning">{{ $t('property_details') }}</h4>
                                        <label><b>{{ $t('property_name') }}:</b></label>
                                        <LoaderCust :isLoading="loading" />
                                        <div v-if="!loading && !items.length" class="alert alert-warning">{{ $t('no_approved_properties_found') }}.</div>
                                        <select class="form-control mb-3" v-model="selected_property">
                                            <option disabled selected value="">
                                                {{ $t('service_request_custom_select_property') }}
                                            </option>
                                            <option  v-for="item in items.filter(i => i.user_id === user.id)" :key="item.id" :value="item">
                                                {{ item.property_name }}
                                            </option>
                                        </select>
                                        <div v-if="selected_property" class="d-flex flex-wrap align-items-center gap-4">
                                            <div class="flex-grow-1">
                                                <label><b>{{ $t('listing_type') }}: </b></label>
                                                <span>{{ listingTypeName }}</span>
                                            </div>
                                            <div class="flex-grow-1">
                                                <label><b>{{ $t('property_type') }}:</b></label>
                                                <span>{{ propertyTypeName }}</span>
                                            </div>
                                            <div class="flex-grow-1">
                                                <label><b>Asking Price:</b></label>
                                                <span>{{ formatPrice(selected_property.asking_price_sale_rent) }}</span>
                                            </div>
                                            <div class="flex-grow-1">
                                                <label><b>Listing ID:</b></label>
                                                <span>{{ selected_property.id }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="selected_property" class="terms-proposal mt-4">
                                        <h5>Terms Sheet Proposal</h5>
                                        <div class="d-flex flex-wrap align-items-center gap-3">
                                            <div class="mb-3 flex-grow-1">
                                                <label><b>{{ $t('engagement_period') }}:</b></label>
                                                <select v-model="form.engagement_period" class="form-control" id="engagementPeriod" required>
                                                    <option disabled selected value="">{{ $t('select') }} Period</option>
                                                    <option value="1">1 Month</option>
                                                    <option value="3">3 Months</option>
                                                    <option value="6">6 Months</option>
                                                </select>
                                            </div>

                                            <div class="mb-3 flex-grow-1">
                                                <label><b>{{ $t('fee') }}/{{ $t('commission') }}:</b></label>
                                                <input v-model="form.commission" type="number" class="form-control" id="commission" placeholder="2.00%" required/>
                                            </div>

                                            <div class="mb-3 flex-grow-1">
                                                <label><b>{{ $t('appointment_type') }}:</b></label>
                                                <select v-model="form.appointment_types" class="form-control" id="appointmentType" required>
                                                    <option disabled selected value="">{{ $t('select') }} Type</option>
                                                    <option v-for="(key, type) in CONSTANT.APPOINTMENT_TYPES" :key="type" :value="key" >
                                                        {{ type }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <span v-if="serviceError" class="error">{{ serviceError }}</span>
                                        <div>
                                            <h5>{{ $t('available_credits') }}</h5>
                                            <p><b>{{ userCredit }} {{ $t('credits') }}</b></p>
                                            <div v-if="userCredit < 10">
                                                <label><b>Oh no ,you need 10 credit to request for a service</b></label>
                                                <a class="comman-btn-2" href="/top-up" onclick="$('#requestModal').modal('hide')">
                                                    <img src="/frontend/images/Choose-plan.svg" alt="topUp">
                                                    {{ $t('top_up') }}
                                                </a>
                                            </div>
                                            <div v-else class="actions mt-3">
                                                <button type="button" class="comman-btn-2-danger col-md-12 mb-2" data-bs-dismiss="modal" @click="resetError">{{ $t('close') }}</button>
                                                <button type="submit" class="comman-btn-2 col-md-12">Request</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .profile-rating ul.text-list {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

    .profile-rating ul.text-list li {
        color: var(--grey-4B4A4A);
        display: flex;
        align-items: center;
        gap: 10px;
        min-width: 40%;
        font-size: 14px;
    }
</style>

<script>
import { mapState } from 'vuex';
import { CONSTANT } from "@/constants/constants";
import { formatPrice, formatZeros, formatPhoneNumber, getPropertyImage, getUserProfileImage, yearsAgo } from "@/utils/helpers";
import SendMessageComponent from './SendMessageComponent.vue';
import LoaderCust from "@/components/LoaderCust.vue";
import {getConstListingTypes, getConstPropertyTypes} from "@/utils/apiHelpers";

export default {
    components: {
        LoaderCust,
        SendMessageComponent
    },
    data() {
        return {
            developer: [],
            sort_by: 'Newest',
            properties: [],
            projects: [],
            loading: false,
            items: [],
            selected_property: null,
            options: {
                listing_types: [],
                property_types: [],
            },
            form: {
                engagement_period: "",
                commission: "",
                appointment_types: "",
            },
            developerId : this.$route.params.id,
            userCredit: '',
            reviewForm: {
                rating_point: 0,
                rating_remarks: null
            },
            serviceError: '',
        };
    },
    computed: {
        CONSTANT() {
            return CONSTANT
        },
        ...mapState(['isAuthenticated', 'user']),
        listingTypeName() {
            if (!this.selected_property.listing_type) {
                return 'Unknown';
            }
            const listingType = this.options.listing_types?.find(
                option => option.id === this.selected_property.listing_type
            );

            return listingType ? listingType.name : 'Unknown';
        },
        propertyTypeName() {
            const propertyTypeID = this.selected_property.property_type;

            if (!propertyTypeID) {
                return "Unknown";
            }

            const propertyType = this.findPropertyTypeById(
                this.options.property_types,
                propertyTypeID
            );

            return propertyType ? propertyType.name : "Unknown";
        },
        isOwner() {
            return this.isAuthenticated && this.user && this.developer && this.user.id == this.developer.id;
        },
    },
    async created() {
        await this.fetchProperties();
        await this.fetchDeveloperDetails();
    },

    methods: {
        formatPrice,
        formatZeros,
        getPropertyImage,
        getUserProfileImage,
        yearsAgo,
        formatPhoneNumber,

        resetError() {
            this.serviceError = '';
        },

        findPropertyTypeById(types, id) {
            const typeArray = Array.isArray(types) ? types : Object.values(types).flat();

            for (const type of typeArray) {
                if (type.children) {
                    const found = this.findPropertyTypeById(type.children, id);
                    if (found) {
                        return found;
                    }
                }

                if (type.id === id) {
                    return type;
                }
            }

            return null;
        },

        async fetchProperties() {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/property-list',
                    {
                        user_id : this.user.id,
                        api_token : token,
                        sort_by_field : 'Newest',
                        limit: 50,
                    });
                this.items  = response.data.output.myArray;
                this.userCredit = response.data.output.available_credit_points;
            } catch (error) {
                console.error('Error fetching options:', error);
            }finally {
                this.isLoading = false;
            }
        },

        async fetchDeveloperDetails() {
            const developerId = this.$route.params.id;
            try {
                this.loading = true;
                const response = await this.$axios.post('/api/developer-detail', {
                    user_id: this.user.id,
                    developer_id: developerId,
                    sort_by: this.sort_by,
                });

                this.developer = response.data.output;
                this.properties = this.developer.property_details;
                this.projects = this.developer.project_details;
    
                this.$nextTick(() => {
                    this.initializeOwlCarousel();
                });
            } catch (error) {
                console.error('Error fetching developer details:', error);
            } finally {
                this.loading = false;
            }
        },

        async fetchListingTypes() {
            try {
                this.options.listing_types = await getConstListingTypes();
            } catch (error) {
                console.error("Error fetching listing types:", error);
            }
        },

        async fetchPropertyTypes() {
            try {
                this.options.property_types = await getConstPropertyTypes();
            } catch (error) {
                console.error("Error fetching property types:", error);
            }
        },

        sortProperties(sortBy) {
            this.sort_by = sortBy;
            this.fetchDeveloperDetails();
        },

        initializeOwlCarousel() {
            window.$('.property-carousel').owlCarousel({
                loop: false,
                margin: 0,
                nav: true,
                dots: true,
                navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                responsive: {
                    0: {
                        items: 1,
                        margin: 0
                    },
                    600: {
                        items: 1
                    },
                    1000: {
                        items: 1
                    }
                }
            });
        },

        triggerRequestService() {
            const button = this.$refs.requestServiceButton;
            if (!button) {
                console.error('Request Service button is not available');
                return;
            }

            const propertyListingId = localStorage.getItem('property_listing_id');
            if (propertyListingId) {
                button.click();
            } else {
                console.log('Property Listing ID is null. No action triggered.');
            }
        },
    },

    mounted() {
        const propertyListingId = localStorage.getItem('property_listing_id');

        // Fetch options and populate dropdown
        this.fetchProperties();
        this.fetchListingTypes();
        this.fetchPropertyTypes();

        // Set selected_property if propertyListingId is not null
        if (propertyListingId) {
            this.$nextTick(() => {
                this.selected_property = this.items.find(item => item.id == propertyListingId) || null;
            });
        }
    },

    watch: {
        items: {
            handler(newItems) {
                const propertyListingId = localStorage.getItem('property_listing_id');
                if (propertyListingId) {
                    this.selected_property = newItems.find(item => item.id == propertyListingId) || null;
                } else {
                    // Reset to default if no valid property_listing_id
                    this.selected_property = null;
                }
            },
            immediate: true, // Trigger immediately if `items` already have data
        },
    },

};
</script>

<style scoped>
    .dropdown-item:hover,
    .dropdown-item.selected {
        background-color: #ffe6b9;
    }
</style>