<template>
    <section class="signin-sec">
        <div class="container">
            <div class="step-process-outer">
                <ol class="d-flex justify-content-center">
                    <li>
                        <figure>
                            <img src="frontend/images/signup-step-ico1.svg">
                        </figure>	
                        <p class="mb-0">Just register for FREE Sign-Up & start listing your properties.</p>
                    </li>
                    <li>
                        <figure>
                            <img src="frontend/images/signup-step-ico2.svg">
                        </figure>	
                        <p class="mb-0">Decide on options to upgrade & select packages according to your needs.</p>
                    </li>
                    <li>
                        <figure>
                            <img src="frontend/images/signup-step-ico3.svg">
                        </figure>	
                        <p class="mb-0">Complete your profile & payment to access exclusive tools & privileges.</p>
                    </li>
                </ol>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-6 d-md-flex align-items-stretch">
                    <div class="private-why-outr">

                        <div class="top-tabs-design">
                            <ul>
                                <li><a href="javascript:;" class="active">Estate Agent & Property Manager</a></li>
                                <li><a href="/sign-up-corporate">Estate Agency & Property Manager / {{ $t('developer') }}</a></li>
                                <li><a href="/sign-up">Property Owner-Landlord / Buyer & Renter-Tenant</a></li>
                            </ul>
                        </div>

                        <div class="comman-title">
                            <h3>Why Sign Up As Business?</h3>
                            <p>Business (Estate Agent & Property Manager)</p>
                        </div>
                        <ol>
                            <li>
                                <div class="">
                                    <h4>Automated Sourcing Of Property;</h4>
                                    <p>Let the prospective clients be your partner, get assigned to listing of properties that are legitimately sourced, posted and consented by the direct owner with a virtual handshake on agreed terms.</p>
                                </div>
                            </li>
                            <li>
                                <div class="">
                                    <h4>Al-Enabler & Leads Referral Model;</h4>
                                    <p>Generate Al-Creatives & Referral Code that can interpolate with eMails, Social Medias or Messenger Apps, trace leads from its source, earn trust and reputation, build a profile like your own brand.</p>
                                </div>	
                            </li>
                            <li>
                                <div class="">
                                    <h4>Digital Co-Broking & Developer Project Assignments;</h4>
                                    <p>To see and to be seen, submit request or get invited to participate in developer's projects, or initiate co- broking arrangements incorporating option to accept or dedline with tracked engagement terms.</p>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 d-md-flex align-items-stretch">
                    <div class="signin-bx full-width ">
                        <h1>Sign up</h1>
                        <nav>
                            <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                <button class="nav-link active comman-btn-2" id="nav-mobile-tab" data-bs-toggle="tab" data-bs-target="#nav-mobile" type="button" role="tab" aria-controls="nav-mobile" aria-selected="true">Mobile SignUp</button>
                                <button class="nav-link comman-btn-2" id="nav-password-tab" data-bs-toggle="tab" data-bs-target="#nav-password" type="button" role="tab" aria-controls="nav-password" aria-selected="true">Email SignUp</button>
                            </div>
                        </nav>
                        <div class="tab-content mt-3" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-mobile" role="tabpanel" aria-labelledby="nav-mobile-tab">
                                <div class="input-group">
                                    <select v-model="form.country_code" class="form-control" style="max-width: 120px;">
                                        <option value="+60">🇲🇾 (+60)</option>
                                        <option value="+65">🇸🇬 (+65)</option>
                                    </select>
                                    <input
                                        v-model="check_mobile_number"
                                        id="mobile_number"
                                        type="text"
                                        class="form-control"
                                        placeholder="Phone Number"
                                        @input="checkMobileNumber"
                                    />
                                </div>
                                <span class="error" v-if="errors.check_mobile_number">{{ errors.check_mobile_number }}</span>
                                <div class="property-siderbar-boxs" v-if="preRegUser">
                                    <div class="profile-box">
                                        <div class="list-box">
                                            <p>This Number Has A Pre-Registered Account</p>
                                            <p>User: {{ preRegUser }}</p>
                                            <p>Reg No: {{ preRegUserRegNum }}</p>
                                            <label>
                                                <input type="checkbox" v-model="claimRegistration" />
                                                Claim this registration
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <form @submit.prevent="verifyCode" class="mt-4">
                                    <div class="form-group">
                                        <input type="number" v-model="verification_code" placeholder="Verification code"
                                               class="form-control" required>
                                        <span class="error" v-if="errors.verification_code">{{ errors.verification_code }}</span>
                                    </div>
                                    <div class="form-group">
                                        <p><b>Timer:
                                            <a href="javascript:;" v-if="isButtonDisabled">
                                                <span>{{ formattedTime }}</span>
                                            </a>
                                            <a href="javascript:;" v-else @click="sendCode" :class="{disabled: isButtonDisabled}">
                                                <span>Send Code</span>
                                            </a>
                                        </b></p>
                                    </div>
                                    <div class="form-group">
                                        <div class="g-recaptcha" :data-sitekey="recaptcha.siteKey"></div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn comman-btn-2 w-100">{{ $t('sign_up') }}</button>
                                    </div>
                                </form>
                            </div>
                            <div class="tab-pane fade" id="nav-password" role="tabpanel" aria-labelledby="nav-password-tab">
                                <form @submit.prevent="register">
                                    <div class="form-group">
                                        <input v-model="form.name" id="name" type="text" class="form-control" placeholder="Name" />
                                        <span  class="error" v-if="errors.name">{{ errors.name }}</span>
                                    </div>
                                    <div class="form-group">
                                        <input v-model="form.email" id="email" type="text" class="form-control" placeholder="Email Address" />
                                        <span  class="error" v-if="errors.email">{{ errors.email }}</span>
                                    </div>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <select v-model="form.country_code" class="form-control" style="max-width: 120px;">
                                                <option value="+60">🇲🇾 (+60)</option>
                                                <option value="+65">🇸🇬 (+65)</option>
                                            </select>
                                            <input
                                                v-model="form.mobile_number"
                                                id="mobile_number"
                                                type="text"
                                                class="form-control"
                                                placeholder="Phone Number"
                                                @input="validateMobileNumber"
                                            />
                                        </div>
                                        <span class="error" v-if="errors.mobile_number">{{ errors.mobile_number }}</span>
                                    </div>
                                    <div class="form-group">
                                        <input v-model="form.password" id="password" type="password" class="form-control" placeholder="Password" />
                                        <span  class="error" v-if="errors.password">{{ errors.password }}</span>
                                    </div>
                                    <div class="form-group">
                                        <input v-model="form.confirmPassword" id="confirmPassword" type="password" class="form-control" placeholder="Repeat Password" />
                                        <span  class="error" v-if="errors.confirmPassword">{{ errors.confirmPassword }}</span>
                                    </div>
                                    <div class="form-group">
                                        <input v-model="form.referral_code" id="referralCode" type="text" class="form-control" placeholder="Referral Code"/>
                                        <span class="error" v-if="errors.referral_code">{{ errors.referral_code }}</span>
                                    </div>
                                    <div class="form-group align-items-center">
                                        <small>
                                            Have account?  <a href="/sign-in"><b>Log in</b></a>
                                        </small>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn comman-btn-2 w-100">Sign Up</button>
                                    </div>

                                    <div class="form-group">
                                        <!-- <img src="frontend/images/capcha-2.png" alt=""> -->
                                        <div class="g-recaptcha" :data-sitekey="recaptcha.siteKey"></div>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="otr-foen-ta-1">
                                        <label class="form-check-label w-100" for="otr-foen-ta-1">
                                            By continuing, I agree to PROPFACE's <br><a href="/terms-and-conditions">Terms of Use</a> & <a href="/privacy-policy">Privacy Policy.</a>
                                        </label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
</template>

<script>
    import 'toastr/build/toastr.min.css';
    import toastr from 'toastr';
    import { CONSTANT } from '@/constants/constants';
    import { mapActions } from 'vuex';

    export default {
        name: 'SignUpBusiness',
        data() {
            return {
                verification_code: '',
                form: {
                    name: '',
                    email: '',
                    country_code: '+60', //default
                    mobile_number: '',
                    password: '',
                    confirmPassword: '',
                    referral_code: '',
                },
                check_mobile_number: '',
                preRegUser: '',
                preRegUserRegNum: '',
                errors: {},
                // Define the reCAPTCHA site key
                recaptcha: {
                    siteKey: CONSTANT.GOOGLE.SITE_KEY,
                    token: '',
                    isScriptLoaded: false
                },
                timer: 0,
                intervalId: null,
                isButtonDisabled: false,
                lockDuration: 240,
                regularDuration: 240,
                claimRegistration: false,
            };
        },
        computed: {
            ...mapActions(['setAuthenticated', 'setUser']),
            isFormValid() {
                return !this.emailError && !this.passwordError && this.email && this.password;
            },
            mobile_number() {
                return this.form.country_code + this.form.mobile_number;
            },
            formattedTime() {
                const minutes = Math.floor(this.timer / 60);
                const seconds = this.timer % 60;
                return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
            },
        },
        methods: {
            startTimer(duration) {
                this.isButtonDisabled = true;
                this.timer = duration; // Set the timer to the provided duration

                if (this.intervalId) {
                    clearInterval(this.intervalId);
                }

                this.intervalId = setInterval(() => {
                    if (this.timer > 0) {
                        this.timer -= 1;
                    } else {
                        this.isButtonDisabled = false;
                        clearInterval(this.intervalId);
                    }
                }, 1000);
            },
            async sendCode() {
                if (!this.check_mobile_number) {
                    return this.errors.check_mobile_number = 'Phone Number is Required';
                }

                this.startTimer(this.regularDuration);
                try {
                    const response = await this.$axios.post('api/code/send', {
                        is_using_mobile: 1,
                        mobile_number: this.check_mobile_number,
                    });

                    if (response.data.status == 1) {
                        toastr.success('Verification code sent successfully!', 'Success');
                        await this.startTimer(this.regularDuration);
                    }
                } catch (error) {
                    console.error('Error sending code:', error);
                    // Handle error (e.g., show an error message)
                }
            },
            async checkMobileNumber() {
                //@TODO UI for pre-reg user

                if (this.check_mobile_number.startsWith("0")) {
                    this.check_mobile_number = this.check_mobile_number.substring(1);
                }

                try {
                    const response = await this.$axios.post('api/check-mobile-number', {
                        mobile_number : this.check_mobile_number,
                    });

                    if (response.data.status == 1) {
                        this.preRegUser = response.data.output.user ?? null
                        this.preRegUserRegNum = response.data.output.reg_no ?? null
                    }

                } catch (error) {
                    console.error('Fail to retrieve pre-registered account:', error);
                }
            },
            async verifyCode() {
                try {
                    // Ensure reCAPTCHA is loaded
                    if (typeof window.grecaptcha === 'undefined') {
                        toastr.error('reCAPTCHA script is not loaded correctly.', 'Error', { timeOut: 3000 });
                        return;
                    }

                    const recaptchaResponse = window.grecaptcha.getResponse();
                    if (!recaptchaResponse.length) {
                        toastr.error('Please complete reCAPTCHA verification.', 'Error', { timeOut: 3000 });
                        return;
                    }

                    this.recaptcha.token = recaptchaResponse;

                    const response = await this.$axios.post('api/code/verify', {
                        is_using_mobile: 1,
                        mobile_number : this.check_mobile_number,
                        code: this.verification_code,
                    });

                    if (response.data.status == 1) {
                        toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                        await this.login();
                    }
                } catch (error) {
                    if (error.response && error.response.status === 429) {
                        const retryAfter = error.response.data.retry_after || this.lockDuration;
                        this.startTimer(retryAfter); // Start timer based on lock duration
                        toastr.error(
                            `Too many invalid attempts. Please try again after ${Math.ceil(retryAfter / 60)} minutes.`,
                            'Error',
                            { timeOut: 5000 }
                        );
                    } else if (error.response && error.response.status === 422) {
                        toastr.error(
                            `${error.response.data.message} Remaining attempts: ${error.response.data.remaining_attempts}`,
                            'Error',
                            { timeOut: 5000 }
                        );
                    } else {
                        toastr.error('An unexpected error occurred. Please try again.', 'Error', { timeOut: 3000 });
                    }
                }
            },
            async login() {
                if (this.claimRegistration) {
                    const response_login = await this.$axios.post('api/login', {
                        is_using_mobile: 1,
                        is_login_via_claim: 1,
                        mobile_number: this.check_mobile_number,
                    });

                    await this.$store.dispatch('login', response_login.data);
                    this.$router.push('/proxy-listing-page');
                } else {
                    try {
                        //@TODO check mobile_isd_code
                        // Use the globally configured Axios instance
                        const response = await this.$axios.post('api/register', {
                            is_using_mobile: 1,
                            mobile_isd_code: this.form.country_code,
                            mobile_number:  this.form.country_code + '' + this.check_mobile_number,
                            is_business: '1',
                            is_corporate: '0',
                            is_private_user: '0',
                        });

                        if (response.data.status == 1) {
                            toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                        } else {
                            toastr.error(response.data.message,'Failed', {timeOut: 3000});
                            return;
                        }

                        const referralCode = response?.data?.referral_code;

                        if (referralCode) {
                            toastr.info(`Your referral code is: ${referralCode}`, 'Referral Code', { timeOut: 5000 });
                        }
                        //@TODO check mobile_isd_code
                        const response_login = await this.$axios.post('api/login', {
                            is_using_mobile: 1,
                            mobile_number:  this.form.country_code + '' + this.check_mobile_number,
                        });

                        if (!response_login.data?.user) {
                            toastr.error(response_login.data.message,'Failed to Login', {timeOut: 3000});
                            return;
                        }

                        await this.$store.dispatch('login', response_login.data);
                        this.$router.push('/profile');
                    } catch (error) {
                        if (error.response && error.response.data) {
                            // Extract and set errors from the response
                            this.errors = this.extractErrors(error.response.data.errors || {});
                            toastr.error(error.response.data.message, 'Error', { timeOut: 3000 });
                        } else {
                            // Handle unexpected errors
                            console.error('An unexpected error occurred:', error);
                        }
                        // Handle login errors
                        //this.errorMessage = 'Login failed. Please check your credentials.';
                        //console.error('Login error:', error);
                    } finally {
                        this.loading = false;
                    }
                }
            },
            async validateMobileNumber() {
                if (this.form.mobile_number.startsWith("0")) {
                    this.form.mobile_number = this.form.mobile_number.substring(1);
                }
            },
            validateForm() {
                this.errors = {};

                if (!this.form.name) {
                    this.errors.name = 'Name is required.';
                }
                if (!this.form.mobile_number) {
                    this.errors.mobile_number = 'Phone number is required.';
                }

                if (!this.form.email) {
                    this.errors.email = 'Email is required.';
                } else if (!this.validEmail(this.form.email)) {
                    this.errors.email = 'Invalid email address.';
                }

                if (!this.form.password || 
                    this.form.password.length < 8 || 
                    !/[A-Z]/.test(this.form.password) || 
                    !/[a-z]/.test(this.form.password) || 
                    !/\d/.test(this.form.password) || 
                    !/[!@#$%^&*(),.?":{}|<>]/.test(this.form.password)) {
                        
                    this.errors.password = 'Password must be at least 8 characters long and include a mix of uppercase, lowercase, numbers, and special characters.';
                }

                if (!this.form.confirmPassword) {
                    this.errors.confirmPassword = 'Confirm password is required.';
                } else if (this.form.password !== this.form.confirmPassword) {
                    this.errors.confirmPassword = 'Passwords do not match.';
                }

                return Object.keys(this.errors).length === 0;
            },

            validEmail(email) {
                // Basic email validation regex
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(email);
            },

            async register() {
                // Ensure reCAPTCHA is loaded
                if (typeof window.grecaptcha === 'undefined') {
                    toastr.error('reCAPTCHA script is not loaded correctly.', 'Error', { timeOut: 3000 });
                    return;
                }
                const response = window.grecaptcha.getResponse();
                this.recaptcha.token = response;
                if (this.recaptcha.token) {
                    if (this.validateForm()) {
                        if (this.emailError || this.passwordError) {
                            return; // Prevent form submission if there are validation errors
                        }

                        if (this.loading) return;
                        this.loading = true;
                        try {
                            // Use the globally configured Axios instance
                            const response = await this.$axios.post('api/register', {
                                name: this.form.name,
                                email: this.form.email,
                                password: this.form.password,
                                mobile_isd_code: this.form.country_code,
                                mobile_number:  this.mobile_number,
                                referral_code: this.form.referral_code,
                                is_business: '1',
                                is_corporate: '0',
                                is_private_user: '0',
                                business_name: ''
                            });

                            toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                            const referralCode = response.data.referral_code;

                            if (referralCode) {
                                toastr.info(`Your referral code is: ${referralCode}`, 'Referral Code', { timeOut: 5000 });
                            }

                            const response_login = await this.$axios.post('api/login', {
                                email: this.form.email,
                                password: this.form.password
                            });

                            await this.$store.dispatch('login', response_login.data);
                            await this.sendEmailCode(response_login.data.user.id, response_login.data.user.email);
                            this.$router.push('/verify-email');
                        } catch (error) {
                            if (error.response && error.response.data) {
                                // Extract and set errors from the response
                                this.errors = this.extractErrors(error.response.data.errors || {});
                                toastr.error(error.response.data.message, 'Error', { timeOut: 3000 });
                            } else {
                                // Handle unexpected errors
                                console.error('An unexpected error occurred:', error);
                            }
                            // Handle login errors
                            //this.errorMessage = 'Login failed. Please check your credentials.';
                            //console.error('Login error:', error);
                        } finally {
                            this.loading = false;
                        }
                    }
                } else {
                    toastr.error('reCAPTCHA is not completed', 'error', { timeOut: 3000 });
                }
            },

            async sendEmailCode(userId, userEmail) {
                try {
                    await this.$axios.post(`api/send-verification-email/${userId}`, {
                        email: userEmail,
                    });
                    toastr.success('Verification link sent successfully!', 'Success');
                } catch (error) {
                    toastr.error('Verification link failed', 'Failed');
                    console.error('Error sending verification link:', error);
                    // Handle error (e.g., show an error message)
                }
            },

            extractErrors(errors) {
                // Extract the first error message from each field's errors array
                const formattedErrors = {};
                for (const [field, messages] of Object.entries(errors)) {
                    if (Array.isArray(messages) && messages.length > 0) {
                        formattedErrors[field] = messages[0]; // Get the first error message
                    }
                }
                return formattedErrors;
            },
            // Function to dynamically load the reCAPTCHA script
            loadRecaptchaScript() {
                return new Promise((resolve, reject) => {
                    const script = document.createElement('script');
                    script.src = 'https://www.google.com/recaptcha/api.js';
                    script.async = true;
                    script.defer = true;

                    script.onload = () => {
                        resolve();
                    };

                    script.onerror = () => {
                        reject(new Error('Failed to load reCAPTCHA script'));
                    };

                    document.head.appendChild(script);
                });
            },

            // Helper function to initialize reCAPTCHA
            async initializeRecaptcha() {
                try {
                    await this.loadRecaptchaScript();
                    this.isScriptLoaded = true;
                } catch (error) {
                    console.error('Error loading reCAPTCHA script:', error);
                }
            },
        },
        mounted() {
            this.initializeRecaptcha();
        },
    }
</script>