// src/constants/constantsProperty.js

export const OPTIONS_GROUP_1 = [
    { value:1, label:	'Resale / Subsale (Pre-Owned)'},
    { value:2, label:	'Rent (Entire Unit)'},
    { value:3, label:	'Rent (Rooms / Co-Living / Co-Working)'},
    { value:4, label:	'Developer (New Launch / Off-The-Plan)'},
    { value:5, label:	'Developer (TOP / Completed)'},
    { value:6, label:	'Classified (Hybrid-Listing - FSBO / FRBO)'}
];

export const RENTAL_PERIOD = [
    
    { value:6, label:	'6 Months'},
    { value:12, label:	'1 Year'},
    { value:24, label:	'2 Years'},
    { value:36, label:	'3 Years'},
    { value:0, label:	'Flexible'}
];

// export const PROPERTY_TYPE = [
//     { id: 1, name: 'Residential', parent_id: 0 },
//     { id: 2, name: 'Commercial & Industrial', parent_id: 0 },
//     { id: 3, name: 'All HDB', parent_id: 1 },
//     { id: 4, name: 'All Non-Landed (Private)', parent_id: 1 },
//     { id: 5, name: 'All Landed', parent_id: 1 },
//     { id: 6, name: 'HDB Flat', parent_id: 3 },
//     { id: 7, name: 'HDB Executive', parent_id: 3 },
//     { id: 8, name: 'Apartment', parent_id: 4 },
//     { id: 9, name: 'Walk-Up Apartment', parent_id: 4 },
//     { id: 10, name: 'Condominium', parent_id: 4 },
//     { id: 11, name: 'Executive Condominium', parent_id: 4 },
//     { id: 12, name: 'Terrace / Link House', parent_id: 5 },
//     { id: 13, name: 'Town House', parent_id: 5 },
//     { id: 14, name: 'Cluster House', parent_id: 5 },
//     { id: 15, name: 'Conservative House', parent_id: 5 },
//     { id: 16, name: 'Semi-Detached/Zero-Lot', parent_id: 5 },
//     { id: 17, name: 'Detached/Bungalow', parent_id: 5 },
//     { id: 18, name: 'Good Class Bungalow (GCB)', parent_id: 5 },
//     { id: 19, name: 'Vacant Land', parent_id: 5 },
//     { id: 20, name: 'All Commercial', parent_id: 2 },
//     { id: 21, name: 'All Industrial', parent_id: 2 },
//     { id: 22, name: 'Retail', parent_id: 20 },
//     { id: 23, name: 'Office', parent_id: 20 },
//     { id: 24, name: 'Hotel / Hostel', parent_id: 20 },
//     { id: 25, name: 'Shopping Mall', parent_id: 20 },
//     { id: 26, name: 'Medical Suite', parent_id: 20 },
//     { id: 27, name: 'ShopHouse', parent_id: 20 },
//     { id: 28, name: 'HDB ShopHouse', parent_id: 20 },
//     { id: 29, name: 'Vacant Land', parent_id: 20 },
//     { id: 30, name: 'Office', parent_id: 21 },
//     { id: 31, name: 'Warehouse (B1)', parent_id: 20 },
//     { id: 32, name: 'Factory (B2)', parent_id: 21 },
//     { id: 33, name: 'Vacant Land', parent_id: 20 }
//   ];

  export const PROPERTY_TYPE = [

        { id: 1,depth:'', name: 'Residential', parent_id: 0 },

            { id: 3,depth:'-', name: 'All HDB', parent_id: 1 },

                { id: 6,depth:'--', name: 'HDB Flat', parent_id: 3 },
                { id: 7,depth:'--', name: 'HDB Executive', parent_id: 3 },

            { id: 4,depth:'-', name: 'All Non-Landed (Private)', parent_id: 1 },
                { id: 8,depth:'--', name: 'Apartment', parent_id: 4 },
                { id: 9,depth:'--', name: 'Walk-Up Apartment', parent_id: 4 },
                { id: 10,depth:'--', name: 'Condominium', parent_id: 4 },
                { id: 11,depth:'--', name: 'Executive Condominium', parent_id: 4 },

            { id: 5,depth:'-', name: 'All Landed', parent_id: 1 },
                { id: 12,depth:'--', name: 'Terrace / Link House', parent_id: 5 },
                { id: 13,depth:'--', name: 'Town House', parent_id: 5 },
                { id: 14,depth:'--', name: 'Cluster House', parent_id: 5 },
                { id: 15,depth:'--', name: 'Conservative House', parent_id: 5 },
                { id: 16,depth:'--', name: 'Semi-Detached/Zero-Lot', parent_id: 5 },
                { id: 17,depth:'--', name: 'Detached/Bungalow', parent_id: 5 },
                { id: 18,depth:'--', name: 'Good Class Bungalow (GCB)', parent_id: 5 },
                { id: 19,depth:'--', name: 'Vacant Land', parent_id: 5 },

        { id: 2,depth:'', name: 'Commercial & Industrial', parent_id: 0 },

            { id: 20,depth:'-', name: 'All Commercial', parent_id: 2 },
                { id: 22,depth:'--', name: 'Retail', parent_id: 20 },
                { id: 23,depth:'--', name: 'Office', parent_id: 20 },
                { id: 24,depth:'--', name: 'Hotel / Hostel', parent_id: 20 },
                { id: 25,depth:'--', name: 'Shopping Mall', parent_id: 20 },
                { id: 26,depth:'--', name: 'Medical Suite', parent_id: 20 },
                { id: 27,depth:'--', name: 'ShopHouse', parent_id: 20 },
                { id: 28,depth:'--', name: 'HDB ShopHouse', parent_id: 20 },
                { id: 29,depth:'--', name: 'Vacant Land', parent_id: 20 },
            { id: 21,depth:'-', name: 'All Industrial', parent_id: 2 },
                { id: 30,depth:'--', name: 'Office', parent_id: 21 },
                { id: 31,depth:'--', name: 'Warehouse (B1)', parent_id: 21 },
                { id: 32,depth:'--', name: 'Factory (B2)', parent_id: 21 },
                { id: 33,depth:'--', name: 'Vacant Land', parent_id: 21 }
    ];

    export const PROPERTY_TYPE_MY = [

        { id: 1, depth: '', name: 'Residential', parent_id: 0 },
    
        { id: 4, depth: '-', name: 'All Non-Landed (Private)', parent_id: 1 },
            { id: 8, depth: '--', name: 'Apartment', parent_id: 4 },
            { id: 10, depth: '--', name: 'Condominium', parent_id: 4 },
            { id: 11, depth: '--', name: 'Executive Condominium', parent_id: 4 },
    
        { id: 5, depth: '-', name: 'All Landed', parent_id: 1 },
            { id: 12, depth: '--', name: 'Terrace / Link House', parent_id: 5 },
            { id: 13, depth: '--', name: 'Town House', parent_id: 5 },
            { id: 14, depth: '--', name: 'Cluster House', parent_id: 5 },
            { id: 16, depth: '--', name: 'Semi-Detached/Zero-Lot', parent_id: 5 },
            { id: 17, depth: '--', name: 'Detached/Bungalow', parent_id: 5 },
            { id: 19, depth: '--', name: 'Vacant Land', parent_id: 5 },
    
        { id: 2, depth: '', name: 'Commercial & Industrial', parent_id: 0 },
    
        { id: 20, depth: '-', name: 'All Commercial', parent_id: 2 },
            { id: 22, depth: '--', name: 'Retail', parent_id: 20 },
            { id: 23, depth: '--', name: 'Office', parent_id: 20 },
            { id: 24, depth: '--', name: 'Hotel / Hostel', parent_id: 20 },
            { id: 25, depth: '--', name: 'Shopping Mall', parent_id: 20 },
            { id: 26, depth: '--', name: 'Medical Suite', parent_id: 20 },
            { id: 27, depth: '--', name: 'ShopHouse', parent_id: 20 },
            { id: 29, depth: '--', name: 'Vacant Land', parent_id: 20 },
    
        { id: 21, depth: '-', name: 'All Industrial', parent_id: 2 },
            { id: 30, depth: '--', name: 'Office', parent_id: 21 },
            { id: 31, depth: '--', name: 'Warehouse (B1)', parent_id: 21 },
            { id: 32, depth: '--', name: 'Factory (B2)', parent_id: 21 },
            { id: 33, depth: '--', name: 'Vacant Land', parent_id: 21 }

    ]; //for MY properties

    export const STATE_MY = [
        { value: 'selangor', label: 'Selangor' },
        { value: 'kuala_lumpur', label: 'Kuala Lumpur' },
        { value: 'penang', label: 'Penang' },
        { value: 'johor', label: 'Johor' },
        { value: 'melaka', label: 'Melaka' },
        { value: 'negeri_sembilan', label: 'Negeri Sembilan' },
        { value: 'perak', label: 'Perak' },
        { value: 'perlis', label: 'Perlis' },
        { value: 'pahang', label: 'Pahang' },
        { value: 'terengganu', label: 'Terengganu' },
        { value: 'kelantan', label: 'Kelantan' },
        { value: 'sabah', label: 'Sabah' },
        { value: 'sarawak', label: 'Sarawak' },
        { value: 'labuan', label: 'Labuan' },
        { value: 'putrajaya', label: 'Putrajaya' },
    ];
    
    export const FUNG_SHUI_CARDINAL = [
        { "id": 1, "parent_id": 0,"depth": "", "name": "North (337.6 - 22.5)",  "sub-cardinal": "", "degree_from": 337.6, "degree_to": 22.5, "info": null },
        { "id": 2, "parent_id": 0,"depth": "", "name": "North-East (22.6 - 67.5)",  "sub-cardinal": "", "degree_from": 22.6, "degree_to": 67.5, "info": null },
        { "id": 3, "parent_id": 0,"depth": "", "name": "East (67.6 - 112.5)",  "sub-cardinal": "", "degree_from": 67.6, "degree_to": 112.5, "info": null },
        { "id": 4, "parent_id": 0,"depth": "", "name": "South-East (112.6 - 157.5)",  "sub-cardinal": "", "degree_from": 112.6, "degree_to": 157.5, "info": null },
        { "id": 5, "parent_id": 0,"depth": "", "name": "South (157.6 - 202.5)",  "sub-cardinal": "", "degree_from": 157.6, "degree_to": 202.5, "info": null },
        { "id": 6, "parent_id": 0,"depth": "", "name": "South-West (202.6 - 247.5)",  "sub-cardinal": "", "degree_from": 202.6, "degree_to": 247.5, "info": null },
        { "id": 7, "parent_id": 0,"depth": "", "name": "West (247.6 - 292.5)",  "sub-cardinal": "", "degree_from": 247.6, "degree_to": 292.5, "info": null },
        { "id": 8, "parent_id": 0,"depth": "", "name": "North-West (292.6 - 337.5)",  "sub-cardinal": "", "degree_from": 292.6, "degree_to": 337.5, "info": null },
        
        { "id": 9, "parent_id": 1,"depth": "-", "name": "N1 (337.6 - 352.5)",  "sub-cardinal": "N1", "degree_from": 337.6, "degree_to": 352.5, "info": null },
        { "id": 10, "parent_id": 1,"depth": "-", "name": "N2 (352.6 - 7.5)",  "sub-cardinal": "N2", "degree_from": 352.6, "degree_to": 7.5, "info": null },
        { "id": 11, "parent_id": 1,"depth": "-", "name": "N3 (7.6 - 22.5)",  "sub-cardinal": "N3", "degree_from": 7.6, "degree_to": 22.5, "info": null },
        
        { "id": 12, "parent_id": 2,"depth": "-", "name": "NE1 (22.6 - 37.5)",  "sub-cardinal": "NE1", "degree_from": 22.6, "degree_to": 37.5, "info": null },
        { "id": 13, "parent_id": 2,"depth": "-", "name": "NE2 (37.6 - 52.5)",  "sub-cardinal": "NE2", "degree_from": 37.6, "degree_to": 52.5, "info": null },
        { "id": 14, "parent_id": 2,"depth": "-", "name": "NE3 (52.6 - 67.5)",  "sub-cardinal": "NE3", "degree_from": 52.6, "degree_to": 67.5, "info": null },
        
        { "id": 15, "parent_id": 3,"depth": "-", "name": "E1 (67.6 - 82.5)",  "sub-cardinal": "E1", "degree_from": 67.6, "degree_to": 82.5, "info": null },
        { "id": 16, "parent_id": 3,"depth": "-", "name": "E2 (82.6 - 97.5)",  "sub-cardinal": "E2", "degree_from": 82.6, "degree_to": 97.5, "info": null },
        { "id": 17, "parent_id": 3,"depth": "-", "name": "E3 (97.6 - 112.5)",  "sub-cardinal": "E3", "degree_from": 97.6, "degree_to": 112.5, "info": null },
        
        { "id": 18, "parent_id": 4,"depth": "-", "name": "SE1 (112.6 - 127.5)",  "sub-cardinal": "SE1", "degree_from": 112.6, "degree_to": 127.5, "info": null },
        { "id": 19, "parent_id": 4,"depth": "-", "name": "SE2 (127.6 - 142.5)",  "sub-cardinal": "SE2", "degree_from": 127.6, "degree_to": 142.5, "info": null },
        { "id": 20, "parent_id": 4,"depth": "-", "name": "SE3 (142.6 - 157.5)",  "sub-cardinal": "SE3", "degree_from": 142.6, "degree_to": 157.5, "info": null },
        
        { "id": 21, "parent_id": 5,"depth": "-", "name": "S1 (157.6 - 172.5)",  "sub-cardinal": "S1", "degree_from": 157.6, "degree_to": 172.5, "info": null },
        { "id": 22, "parent_id": 5,"depth": "-", "name": "S2 (172.6 - 187.5)",  "sub-cardinal": "S2", "degree_from": 172.6, "degree_to": 187.5, "info": null },
        { "id": 23, "parent_id": 5,"depth": "-", "name": "S3 (187.6 - 202.5)",  "sub-cardinal": "S3", "degree_from": 187.6, "degree_to": 202.5, "info": null },
        
        { "id": 24, "parent_id": 6,"depth": "-", "name": "SW1 (202.6 - 217.5)",  "sub-cardinal": "SW1", "degree_from": 202.6, "degree_to": 217.5, "info": null },
        { "id": 25, "parent_id": 6,"depth": "-", "name": "SW2 (217.6 - 232.5)",  "sub-cardinal": "SW2", "degree_from": 217.6, "degree_to": 232.5, "info": null },
        { "id": 26, "parent_id": 6,"depth": "-", "name": "SW3 (232.6 - 247.5)",  "sub-cardinal": "SW3", "degree_from": 232.6, "degree_to": 247.5, "info": null },
        
        { "id": 27, "parent_id": 7,"depth": "-", "name": "W1 (247.6 - 262.5)",  "sub-cardinal": "W1", "degree_from": 247.6, "degree_to": 262.5, "info": null },
        { "id": 28, "parent_id": 7,"depth": "-", "name": "W2 (262.6 - 277.5)",  "sub-cardinal": "W2", "degree_from": 262.6, "degree_to": 277.5, "info": null },
        { "id": 29, "parent_id": 7,"depth": "-", "name": "W3 (277.6 - 292.5)",  "sub-cardinal": "W3", "degree_from": 277.6, "degree_to": 292.5, "info": null },
        
        { "id": 30, "parent_id": 8,"depth": "-", "name": "NW1 (292.6 - 307.5)",  "sub-cardinal": "NW1", "degree_from": 292.6, "degree_to": 307.5, "info": null },
        { "id": 31, "parent_id": 8,"depth": "-", "name": "NW2 (307.6 - 322.5)",  "sub-cardinal": "NW2", "degree_from": 307.6, "degree_to": 322.5, "info": null },
        { "id": 32, "parent_id": 8,"depth": "-", "name": "NW3 (322.6 - 337.5)",  "sub-cardinal": "NW3", "degree_from": 322.6, "degree_to": 337.5, "info": null }
    ];

    // export const FUNG_SHUI_CARDINAL = [
    //     { "id": 1, "depth": "", "name": "North", "parent_id": 0 },
    //     { "id": 2, "depth": "", "name": "North-East", "parent_id": 0 },
    //     { "id": 3, "depth": "", "name": "East", "parent_id": 0 },
    //     { "id": 4, "depth": "", "name": "South-East", "parent_id": 0 },
    //     { "id": 5, "depth": "", "name": "South", "parent_id": 0 },
    //     { "id": 6, "depth": "", "name": "South-West", "parent_id": 0 },
    //     { "id": 7, "depth": "", "name": "West", "parent_id": 0 },
    //     { "id": 8, "depth": "", "name": "North-West", "parent_id": 0 },
    
    //     { "id": 9, "depth": "-", "name": "N1", "parent_id": 1 },
    //     { "id": 10, "depth": "-", "name": "N2", "parent_id": 1 },
    //     { "id": 11, "depth": "-", "name": "N3", "parent_id": 1 },
    
    //     { "id": 12, "depth": "-", "name": "NE1", "parent_id": 2 },
    //     { "id": 13, "depth": "-", "name": "NE2", "parent_id": 2 },
    //     { "id": 14, "depth": "-", "name": "NE3", "parent_id": 2 },
    
    //     { "id": 15, "depth": "-", "name": "E1", "parent_id": 3 },
    //     { "id": 16, "depth": "-", "name": "E2", "parent_id": 3 },
    //     { "id": 17, "depth": "-", "name": "E3", "parent_id": 3 },
    
    //     { "id": 18, "depth": "-", "name": "SE1", "parent_id": 4 },
    //     { "id": 19, "depth": "-", "name": "SE2", "parent_id": 4 },
    //     { "id": 20, "depth": "-", "name": "SE3", "parent_id": 4 },
    
    //     { "id": 21, "depth": "-", "name": "S1", "parent_id": 5 },
    //     { "id": 22, "depth": "-", "name": "S2", "parent_id": 5 },
    //     { "id": 23, "depth": "-", "name": "S3", "parent_id": 5 },
    
    //     { "id": 24, "depth": "-", "name": "SW1", "parent_id": 6 },
    //     { "id": 25, "depth": "-", "name": "SW2", "parent_id": 6 },
    //     { "id": 26, "depth": "-", "name": "SW3", "parent_id": 6 },
    
    //     { "id": 27, "depth": "-", "name": "W1", "parent_id": 7 },
    //     { "id": 28, "depth": "-", "name": "W2", "parent_id": 7 },
    //     { "id": 29, "depth": "-", "name": "W3", "parent_id": 7 },
    
    //     { "id": 30, "depth": "-", "name": "NW1", "parent_id": 8 },
    //     { "id": 31, "depth": "-", "name": "NW2", "parent_id": 8 },
    //     { "id": 32, "depth": "-", "name": "NW3", "parent_id": 8 }
    // ]