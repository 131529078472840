<template>
    <div class="accordion custom-accordion" id="accordion-one">
        <div class="accordion-item">
            <div class="accordion-header" id="heading1">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#key-information' + propertyDetail?.id" aria-expanded="true" aria-controls="key-information">
                    {{ $t('key_information') }}
                </button>
            </div>
            <div :id="'key-information' + propertyDetail?.id" class="accordion-collapse collapse show" aria-labelledby="heading1">
                <div class="accordion-body">
                    {{ $t('key_information') }}
                    <div class="row row-cols-1 row-cols-sm-4">
                        <div class="col">
                            <div class="light-info-box">
                                <span>{{ $t('listing_type') }}</span>
                                <h5>{{ propertyDetail?.listing_type_details?.name || '-' }}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box">
                                <span>{{ $t('property_district') }}</span>
                                <h5>{{ propertyDetail.district || '-' }}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box">
                                <span>{{ $t('street') }}</span>
                                <h5>{{ propertyDetail.street || '-' }}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box">
                                <span>{{ $t('area') }} / {{ $t('hdb_estate') }}</span>
                                <h5>{{ propertyDetail.area_hdb_estate || '-' }}</h5>
                            </div>
                        </div>
                    </div>
                    {{ $t('basic_information') }}
                    <div class="row row-cols-1 row-cols-sm-4">
                        <div class="col">
                            <div class="light-info-box">
                                <span>{{ $t('property_type') }}</span>
                                <h5>{{ propertyDetail?.property_type_details?.name || '-' }}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box">
                                <span>{{ $t('rent_tenure') }}</span>
                                <h5>{{propertyDetail.land_tenure || '-' }}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box">
                                <span>{{ $t('floor_area') }} ({{propertyDetail.floor_area_unit}})</span>
                                <h5>{{propertyDetail.floor_area || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box">
                                <span>{{ $t('land_area') }}</span>
                                <h5>{{propertyDetail.land_area || '-'}} {{propertyDetail.land_area_unit}}
                                </h5>
                            </div>
                        </div>
                    </div>
                    {{ $t('configuration') }}
                    <div class="row row-cols-1 row-cols-sm-4">
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/room-icon.svg" alt="">
                                <span class="d-inline-block w-100">{{ $t('rooms') }}</span>
                                <h5>{{propertyDetail.no_of_rooms || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/bathrooms-icon.svg" alt="">
                                <span class="d-inline-block w-100">{{ $t('bathrooms') }}</span>
                                <h5>{{propertyDetail.no_of_baths || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/carpark-lots-icon.svg" alt="">
                                <span class="d-inline-block w-100">{{ $t('carpark_lots') }}</span>
                                <h5>{{propertyDetail.no_car_park_lots || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/unit-type-icon.svg" alt="">
                                <span class="d-inline-block w-100">{{ $t('unit_type') }}</span>
                                <h5>{{propertyDetail.unit_no || '-' }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading4">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#property-details' + propertyDetail?.id" aria-expanded="true" aria-controls="property-details">
                    {{ $t('property_details') }}
                </button>
            </div>
            <div :id="'property-details' + propertyDetail?.id" class="accordion-collapse collapse show" aria-labelledby="heading4">
                <div class="accordion-body">
                    <div class="accordion custom-accordion-2" id="accordion-two">
                        <div class="accordion-item">
                            <p class="mt-2">{{ $t('key_features') }}</p>
                            <div id="key-features" class="accordion-collapse collapse show" aria-labelledby="heading-1"
                                data-bs-parent="#accordion-two">
                                <div class="accordion-body">
                                    <ul class="key-features-green-check">
                                        <li v-for="option in featuresList" :key="option.pr_master_feature_id"
                                            :value="option.pr_master_feature_id">
                                            <img src="frontend/images/green-check.svg" alt="">
                                            {{option.additional_feature_name}}
                                        </li>
                                        <li v-for="option in add_featuresList" :key="option.pr_master_feature_id"
                                            :value="option.pr_master_feature_id">
                                            <img src="frontend/images/green-check.svg" alt="">
                                            {{option.additional_feature_name}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <p class="mt-2">{{ $t('facilities') }}</p>
                            <div class="accordion-body">
                                <div class="row row-cols-1 row-cols-sm-4">
                                    <div class="col" v-for="option in facilitiyList"
                                         :key="option.pr_master_facilty_id" :value="option.pr_master_facilty_id">
                                        <div class="light-info-box text-center">
                                            <img src="frontend/images/ac-icon.svg" alt="">
                                            <h5>{{option.additional_facility_name}}</h5>
                                        </div>
                                    </div>
                                    <div class="col" v-for="option in add_facilitiyList"
                                         :key="option.pr_master_feature_id" :value="option.pr_master_feature_id">
                                        <div class="light-info-box text-center">
                                            <img src="frontend/images/ac-icon.svg" alt="">
                                            <h5>{{option.additional_facility_name}}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading5">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#property-status' + propertyDetail?.id" aria-expanded="true" aria-controls="property-status">
                    {{ $t('property_status') }}
                </button>
            </div>
            <div :id="'property-status' + propertyDetail?.id" class="accordion-collapse collapse show" aria-labelledby="heading5">
                <div class="accordion-body">
                    <div class="row row-cols-1 row-cols-sm-4 flex">
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('current_occupancy') }}</span>
                                <h5>{{propertyDetail.current_occupancy || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('current_monthly_rental_rate') }}</span>
                                <h5>{{propertyDetail.current_tenancy_rental_rate || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('current_tenancy_expiry') }}</span>
                                <h5>{{propertyDetail.current_tenancy_expiration_date || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('availability_date') }}</span>
                                <h5>{{formatDate(propertyDetail.availability_date)}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading6">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#pricing-terms' + propertyDetail?.id" aria-expanded="true" aria-controls="pricing-terms">
                    {{ $t('pricing_terms_and_payment_options') }}
                </button>
            </div>
            <div :id="'pricing-terms' + propertyDetail?.id" class="accordion-collapse collapse" aria-labelledby="heading6">
                <div class="accordion-body">
                    <div class="light-info-box">
                        <span class="d-inline-block w-100">{{ $t('listing_price') }}</span>
                        <h5>{{ formatPrice(propertyDetail.asking_price_sale_rent) }}
                            <small>({{computedSQF(propertyDetail)}}/{{propertyDetail.floor_area_unit}})</small>
                        </h5>
                    </div>
                    <div class="comman-title mt-4">
                        <h4>{{ $t('payment_mode_acceptance') }}</h4>
                    </div>
                    <div class="property-meta-se mb-0">
                        <ul v-if="Array.isArray(mode_of_payment)">
                            <li v-for="option in mode_of_payment" :key="option">
                                <span>{{option}}</span>
                            </li>
                        </ul>
                        <span v-else>{{ mode_of_payment?.replace(/\|/g, ', ') }}</span>
                    </div>
                    <div id="property-payment-mode" class="row row-cols-1 row-cols-sm-4 flex">
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('rental_period') }} / {{ $t('preferred_tenancy') }}
                                    ({{ $t('if_applicable') }})</span>
                                <h5>{{ rentalPeriodLabel || '-'}}
                                </h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('security_deposit') }}</span>
                                <h5>{{formatPrice(propertyDetail.rental_security_deposit)}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('utility_deposit') }}</span>
                                <h5>{{formatPrice(propertyDetail.rental_utility_deposit)}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('lump-sum_pre-pay_rent') }}</span>
                                <h5>{{formatPrice(propertyDetail.rental_lumpsum_pre_pay_in_months_rent)}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('proposed_pre-pay_rebate') }}</span>
                                <h5>{{propertyDetail.rental_proposed_pre_pay_rebate_in_percentage || '-'}}
                                </h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('diversity_friendly') }}</span>
                                <h5 v-if="propertyDetail.rental_diversity_friendly">All renters are
                                    welcome regardless of race, ethnicity, religion, age, gender
                                    identity, sexual orientation or physical ability.</h5>
                                <h5 v-if="!propertyDetail.rental_diversity_friendly">{{ $t('no') }}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('pet_policy') }}</span>
                                <h5 v-if="propertyDetail.rental_pet_allowed == 1">{{ $t('pets_allowed') }}</h5>
                                <h5 v-else-if="propertyDetail.rental_pet_allowed == 2">{{ $t('pets_not_allowed') }} </h5>
                                <h5 v-else>-</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading9">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#Additional-Links' + propertyDetail?.id" aria-expanded="true" aria-controls="Additional-Links">
                    {{ $t('additional_links') }}/{{ $t('attachments') }}
                </button>
            </div>
            <div :id="'Additional-Links' + propertyDetail?.id" class="accordion-collapse collapse" aria-labelledby="heading9">
                <div class="accordion-body">
                    <div class="row row-cols-1 row-cols-sm-3" v-if="otherDocList.length>0">
                        <div class="col form-group" v-for="option in otherDocList" :key="option.id" :value="option.id">
                            <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`"
                                class="comman-btn-6" target="_blank">
                                {{option.photo_original_name}}
                                <img src="frontend/images/upload-orange.svg" alt=""></a>
                        </div>
                    </div>
                    <div v-else><span>{{ $t('no_additional_attachment_found') }}</span></div>
                    <div class="row row-cols-1 row-cols-sm-3">
                        <div class="col form-group" v-for="option in mediaLinksList" :key="option.id"
                            :value="option.id">
                            <a :href="`${option.link_url}`" class="comman-btn-6" target="_blank">
                                {{option.link_name}} ( {{option.link_type}} )
                                <img src="frontend/images/upload-orange.svg" alt=""></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading10">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#Descriptions' + propertyDetail?.id" aria-expanded="true" aria-controls="Descriptions">
                    {{ $t('description') }}
                </button>
            </div>
            <div :id="'Descriptions' + propertyDetail?.id" class="accordion-collapse collapse" aria-labelledby="heading10">
                <div class="accordion-body">
                    {{propertyDetail.additional_description || $t('no_description_found') }}
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading11">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#Reviews' + propertyDetail?.id" aria-expanded="true" aria-controls="Reviews">
                    {{ $t('reviews') }}
                </button>
            </div>
            <div :id="'Reviews' + propertyDetail?.id" class="accordion-collapse collapse" aria-labelledby="heading11">
                <div class="accordion-body">
                    <div v-if="computedReviews.length > 0">
                        <div class="row row-cols-1" v-for="(review, key) in computedReviews"
                            :key="'review-'+key">
                            <div class="col">
                                <div class="light-info-box" :class="{ 'review-blur-overlay': !review.visibility }">
                                    <div class="d-flex justify-content-between">
                                        <label class="w-auto me-3">{{ review.user?.name }}<i class="fa fa-star mx-1"
                                            v-for="(rating, index) in review.ratings" :key="'rating-star-'+index"
                                            style="color: #FFD600;"></i></label>
                                        <label class="w-auto me-3" v-if="isOwner">
                                            <i :class="review.visibility ? 'fa fa-eye-slash' : 'fa fa-eye'" class="mx-1"
                                                style="color: #FFD600; cursor: pointer;" @click="changeVisibility(review)"></i>
                                        </label>
                                    </div>
                                    <h5>{{ review.feedback }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <span>{{ $t('no_reviews_found') }}.</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="review-form px-1" v-if="!isOwner">
            <div class="comman-title">
                <h3>{{ $t('leave_a_review') }}</h3>
            </div>
            <div class="review-form-box">
                <form>
                    <div class="form-group d-flex align-items-center">
                        <label class="w-auto me-3"><b>{{ $t('rating') }}</b></label>
                        <div class="rating">
                            <input type="radio" name="rating" value="5" id="5" v-model="reviewForm.ratings">
                            <label for="5"><i class="fa fa-star-o"></i></label>

                            <input type="radio" name="rating" value="4" id="4" v-model="reviewForm.ratings">
                            <label for="4"><i class="fa fa-star-o"></i></label>

                            <input type="radio" name="rating" value="3" id="3" v-model="reviewForm.ratings">
                            <label for="3"><i class="fa fa-star-o"></i></label>

                            <input type="radio" name="rating" value="2" id="2" v-model="reviewForm.ratings">
                            <label for="2"><i class="fa fa-star-o"></i></label>

                            <input type="radio" name="rating" value="1" id="1" v-model="reviewForm.ratings">
                            <label for="1"><i class="fa fa-star-o"></i></label>
                        </div>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" rows="5" placeholder="Writer message here"
                            v-model="reviewForm.feedback"></textarea>
                    </div>
                    <button class="comman-btn-2 w-100" @click.prevent="submitReview">{{ $t('submit') }}</button>
                </form>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading13">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#map-section' + propertyDetail?.id"
                    aria-expanded="true" aria-controls="map-section">
                    {{ $t('map') }}
                </button>
            </div>
            <div :id="'map-section' + propertyDetail?.id" class="accordion-collapse collapse" aria-labelledby="heading13">
                <div class="accordion-body">
                    <div v-if="!mapError" id="map" class="map-se" style="width: 100%; height: 325px;"></div>
                    <div v-else>{{ $t('location_cannot_be_found_on_map') }}.</div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading13">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#Transaction-history-' + propertyDetail?.id"  aria-expanded="true" aria-controls="Transaction-history">
                    {{ $t('transaction_history') }}
                </button>
            </div>
            <div :id="'Transaction-history-' + propertyDetail?.id" class="accordion-collapse collapse" aria-labelledby="heading13">
                <div class="accordion-body">
                    <div class="table-responsive transaction-history-table">
                        <table>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>{{ $t('unit_area') }}</th>
                                <th>{{ $t('transaction_type') }}</th>
                                <th>{{ $t('rent_tenure') }} ({{ $t('months') }})</th>
                                <th>{{ $t('date') }}</th>
                                <th>{{ $t('price') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="listing in property_transaction_histories" :key="listing.id">
                                <td>{{ listing.id }}</td>
                                <td>{{ listing.floor_area }}/ {{ listing.floor_area_unit }}</td>
                                <td>{{ listing.transaction_type }}</td>
                                <td>{{ listing.rent_tenure  ?? '-' }}</td>
                                <td>{{ listing.rent_date ?? listing.sold_date}}</td>
                                <td>{{ formatPrice(listing.rent_price ?? listing.sold_price) ?? 'No detail Provided'}}</td>
                            </tr>
                            <tr v-if="property_transaction_histories && property_transaction_histories.length == 0">
                                <td colspan="6" class="text-center">{{ $t('no_transaction_found') }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    
                    <div class="table-responsive transaction-history-table my-4 py-4">
                        <div class="project-info mb-2" v-if="isAuthenticated && imported_property_transaction_histories.length > 0 && propertyDetail.is_below_mean_value">
                            <ul>
                                <li>
                                    <figure>
                                        <img src="frontend/images/Down-arrow.svg">
                                    </figure>
                                    <small>BMV</small>
                                </li>
                            </ul>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>{{ $t('project_name') }}</th>
                                    <th>{{ $t('street') }}</th>
                                    <th>{{ $t('price') }}</th>
                                    <th>{{ $t('unit_area') }}</th>
                                    <th>{{ $t('price_per_unit') }}</th>
                                    <th>{{ $t('date') }}</th>
                                    <th>{{ $t('type_of_area') }}</th>
                                    <th>{{ $t('tenure') }}</th>
                                    <th>{{ $t('postal_district') }}</th>
                                    <th>{{ $t('floor_level') }}</th>
                                </tr>
                            </thead>
                            <tbody v-if="isAuthenticated">
                                <tr v-for="imported_history in imported_property_transaction_histories" :key="imported_history.id">
                                    <td>{{ imported_history.project_name }}</td>
                                    <td>{{ imported_history.street }}</td>
                                    <td>{{ formatPrice(imported_history.transacted_price) }}</td>
                                    <td>
                                        <span v-if="propertyDetail.floor_area_unit == 'SQF'">
                                            {{ formatZeros(imported_history.area_sqft) }}
                                        </span>
                                        <span v-else-if="propertyDetail.floor_area_unit == 'SQM'">
                                            {{ formatZeros(imported_history.area_sqm) }}
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="propertyDetail.floor_area_unit == 'SQF'">
                                            {{ formatPrice(imported_history.unit_price_per_sqft) }}
                                        </span>
                                        <span v-else-if="propertyDetail.floor_area_unit == 'SQM'">
                                            {{ formatPrice(imported_history.unit_price_per_sqm) }}
                                        </span>
                                    </td>
                                    <td>{{ formatDate(imported_history.sale_date) || '-' }}</td>
                                    <td>{{ imported_history.type_of_area || '-' }}</td>
                                    <td>{{ imported_history.tenure || '-' }}</td>
                                    <td>{{ imported_history.postal_district || '-' }}</td>
                                    <td>{{ imported_history.floor_level || '-' }}</td>
                                </tr>
                                <tr v-if="imported_property_transaction_histories && imported_property_transaction_histories.length == 0">
                                    <td colspan="10" class="text-center">{{ $t('no_transaction_found') }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="10" class="text-center">Please login to check transaction history and BMV</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import toastr from 'toastr';
    import { CONSTANT } from '@/constants/constants';
    import { mapState } from 'vuex';
    import { RENTAL_PERIOD } from '@/constants/constantsProperty';
    import { formatDate, formatPrice, formatZeros } from "@/utils/helpers";

    export default {
        name: 'PropertyBasicInformation',

        props: {
            propertyDetail: {
                type: Object,
                required: true,
            },
            propertyUserDetail: {
                type: Object,
                required: true,
            },
            otherDocList: {
                type: Array,
                required: true,
            },
            mode_of_payment: {
                type: [Array, String],
                required: true,
            },
            mediaLinksList: {
                type: Array,
                required: true,
            },
            add_facilitiyList: {
                type: Array,
                required: true,
            },
            facilitiyList: {
                type: Array,
                required: true,
            },
            featuresList: {
                type: Array,
                required: true,
            },
            add_featuresList: {
                type: Array,
                required: true,
            },
            property_transaction_histories: {
                type: Array,
                required: true,
            }
        },

        data() {
            return {
                CONSTANT,
                rentalPeriodList: RENTAL_PERIOD,
                reviewForm: {
                    ratings: 0,
                    feedback: null
                },
                mapError: false,
                imported_property_transaction_histories: [],
                isMasked: true,
            }
        },

        computed: {
            ...mapState(['isAuthenticated', 'user']),

            rentalPeriodLabel() {
                const item = this.rentalPeriodList.find(option => option.value === this.propertyDetail.rental_period_preferred_tenancy);
                return item ? item.label : '-';
            },

            isOwner() {
                return this.isAuthenticated && this.user && this.propertyDetail && this.user.id == this.propertyDetail.user_id;
            },

            computedReviews() {
                if (this.propertyDetail?.reviews && this.propertyDetail.reviews.length > 0) {
                    return this.isOwner ? this.propertyDetail.reviews : this.propertyDetail.reviews.filter(r => r.visibility == true);
                }

                return [];
            },

            shouldShowAgentSection() {
                const isWidgetProperty = this.propertyDetail.is_widget_property === 1;
                const isHybridListing = this.propertyDetail.hybrid_listing_type === 1;

                return isWidgetProperty && isHybridListing; // Returns true or false
            },
        },

        methods: {
            formatDate,
            formatPrice,
            formatZeros,

            computedSQF(property) {
                return property.asking_price_sale_rent > 0 ? Math.ceil(property.asking_price_sale_rent / property.floor_area) : 0;
            },
            
            maskedNumber(value) {
                if (value) {
                    const start = value.slice(0, 6);
                    const end = value.slice(-3); 
                    return `${start}****${end}`; 
                }
            },
            toggleMask() {
                this.isMasked = !this.isMasked;
            },
            async initializeMap() {
                try {
                    const google = await this.loadGoogleMapsApi();
                    const geocoder = new google.maps.Geocoder();
                    const geocodeAddress = async (address) => {
                        return new Promise((resolve, reject) => {
                            geocoder.geocode({ address }, (results, status) => {
                                if (status === 'OK' && results && results.length > 0) {
                                    resolve(results);
                                } else {
                                    reject(status);
                                }
                            });
                        });
                    };

                    let addressParts = [
                        this.propertyDetail.unit_no,
                        this.propertyDetail.street,
                        this.propertyDetail.area_hdb_estate,
                        this.propertyDetail.district
                    ];
                    let address = addressParts.filter(part => part !== null && part !== undefined).join(', ');
                    const results = await geocodeAddress(address).catch(async (status) => {
                        if (status === 'ZERO_RESULTS') {
                            address = `${this.propertyDetail.area_hdb_estate}, ${this.propertyDetail.district}`;
                            return geocodeAddress(address);
                        } else {
                            this.mapError = true;
                            throw new Error('Geocode failed: ' + status);
                        }
                    });

                    // Ensure results were returned
                    if (results && results.length > 0) {
                        const location = results[0].geometry.location;
                        const map = new google.maps.Map(document.getElementById('map'), {
                            zoom: 13,
                            center: location,
                        });
                        new google.maps.Marker({
                            map: map,
                            position: location,
                        });
                        this.mapError = false;
                    } else {
                        this.mapError = true;
                        console.error('No valid results found for the address.');
                    }
                } catch (error) {
                    this.mapError = true;
                    console.error('Error initializing map:', error);
                }
            },

            async loadGoogleMapsApi() {
                return new Promise((resolve, reject) => {
                    if (!process.env?.VUE_APP_GOOGLE_API_KEY) {
                        return reject(new Error('API Key is missing'));
                    }

                    const script = document.createElement('script');
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}`;
                    script.async = true;
                    script.defer = true;

                    script.onload = () => resolve(window.google);
                    script.onerror = () => reject(new Error('Google Maps API failed to load.'));

                    document.head.appendChild(script);
                });
            },

            async changeVisibility(review) {
                if (!this.isOwner) {
                    toastr.error('Invalid action', 'error', { timeOut: 3000 });
                    return;
                }

                const result = await window.Swal.fire({
                    title: `Are you sure you want to ${review.visibility ? 'hide' : 'unhide'} the review?`,
                    showCancelButton: true,
                    confirmButtonText: "Save",
                    confirmButtonColor: "var(--primary-F6B034)",
                    cancelButtonColor: "var(--danger-color, #FF0000)",
                });

                if (!result.isConfirmed) return;

                try {
                    const response = await this.$axios.post(`api/properties/${this.propertyDetail.id}/reviews/${review.id}/visibility`, {
                        api_token: localStorage.getItem('userToken'),
                    });

                    if (response.status === 200 && response?.data?.output) {
                        this.$emit('update-property-reviews', { reviews: response.data.output });
                        toastr.success(response.data.message, 'Success', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500
                        });
                    }
                } catch (error) {
                    toastr.error('Error changing review visibility: ' + error, 'Error', { timeOut: 3000 });
                }
            },

            async submitReview() {
                try {
                    if (!this.isAuthenticated || !this.user) {
                        toastr.error('Unauthenticated', 'error', { timeOut: 3000 });
                        return;
                    }

                    if (this.user.id === this.propertyDetail.user_id) {
                        toastr.error('Property owner is not allowed to leave review', 'error', { timeOut: 3000 });
                        return;
                    }

                    if (!this.reviewForm.feedback) {
                        toastr.error('Feedback is required.', 'error', { timeOut: 3000 });
                        return;
                    }
                    this.reviewForm.property_listing_id = this.propertyDetail.id;
                    this.reviewForm.user_id = this.user.id;
                    this.reviewForm.api_token = localStorage.getItem('userToken')
                    const reviewResponse = await this.$axios.post(`api/properties/${this.propertyDetail.id}/review`, this.reviewForm);

                    if (reviewResponse.status === 200) {
                        toastr.success(reviewResponse.data.message, 'Success', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                            onHidden: () => {
                                this.$emit('refetch-property');
                                this.resetReviewForm();
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error submitting review:', error);
                }
            },

            resetReviewForm() {
                this.reviewForm = {
                    ratings: 0,
                    feedback: null
                }
            },

            async retrieveImportedTransactionHistories() {
                if (!this.isAuthenticated) {
                    return;
                }
                this.imported_property_transaction_histories = [];
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.get(`/api/properties/${this.propertyDetail.id}/imported-transaction-history`,
                    {
                        params: {
                            api_token: token,
                        }
                    });

                    if (response?.data?.data) {
                        this.imported_property_transaction_histories = response.data.data ?? [];
                    } else {
                        toastr.error('Failed to fetch transaction history', 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                        toastr.error('Failed to fetch transaction history', 'Error', { timeOut: 3000 });
                    console.error('Error fetching transaction history:', error);
                }
            }
        },

        watch: {
            propertyDetail: {
                handler() {
                    this.initializeMap();
                    this.retrieveImportedTransactionHistories();
                },
                deep: true
            },
        },
    }
</script>

<style scoped>
#property-status .light-info-box, #property-payment-mode .light-info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
</style>