<template>
  <div>
    <div v-if="loading">Loading...</div>
    <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      loading: true,
      errorMessage: '', // Holds the error message to display
    };
  },

  async mounted() {
    // Extract email and password from URL
    const urlParams = new URLSearchParams(window.location.search);
    this.email = urlParams.get('email');
    this.password = urlParams.get('password');

    // Validate email and password before proceeding
    if (!this.email || !this.password) {
      this.loading = false; // Stop loading if parameters are missing
      this.errorMessage = 'Missing email or password in the URL parameters.'; // Show error
      return;
    }

    try {
      // Send encrypted password to backend for decryption
      const response = await this.$axios.post('/api/decrypt-password', {
        password: this.password,
      });

      if (response.status !== 200) {
        throw new Error('Decryption failed');
      }

      const data = response.data; // Assuming the backend returns { status: 'success', password: 'decrypted_password' }
      this.password = data.password; // Update the password with the decrypted version

      // Proceed to login
      const loginResponse = await this.$axios.post('/api/login', {
        email: this.email,
        password: this.password,
      });

      // Dispatch Vuex action for storing user data if needed
      await this.$store.dispatch('login', loginResponse.data);

      // Redirect to the homepage or desired route
      this.$router.push('/');
    } catch (error) {
      this.errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred.'; // Show error message
    } finally {
      // Stop loading regardless of success or failure
      this.loading = false;
    }
  }
};
</script>