<template>
    <div class="project-bx h-100">
<!--        <div class="heart-pro">-->
<!--            <a href="#" :class="{'active': item.is_in_wishlist == '1'}"><i class="fa fa-heart-o"></i></a>-->
<!--        </div>                            -->

        <picture v-if="item.actual_unit_photo && item.actual_unit_photo.length > 0">
            <img :src="getImageUrl(item.actual_unit_photo[0].photo_name)" @error="handleImageError" alt="Property Image" />
        </picture>
        <picture v-else>
            <img :src="CONSTANT.DEFAULT_NO_IMAGE"  @error="handleImageError" alt="Property Image" />
        </picture>
        <div class="project-info">
            <a :href="'/project-detail/' + item.id" class="text-decoration-none">
                <div class="mb-2 align-items-center">
                    <h3 style="color: black;">{{ item.project_name }}</h3>
                    <div class="price-pro">
                        <span><small>$</small> {{ formatPrice(item.listing_price_from) ?? '-' }} to <small>$</small> {{ formatPrice(item.listing_price_to) ?? '-'}}</span>
                    </div>
                </div>
            </a>
            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{ item.street }},{{ item.area_hdb_estate }},{{ item.district }}</p>
            <div class="pro-specify">
                <span><i><img src="frontend/images/spec-ico1.svg"></i>{{item.no_of_bedrooms_from}} - {{ item.no_of_bedrooms_to }}</span>
                <span><i><img src="frontend/images/spec-ico2.svg"></i>{{ item.no_of_baths_from }} - {{ item.no_of_baths_to }}</span>
                <span><i><img src="frontend/images/spec-ico3.svg"></i>{{item.no_car_park_from}} - {{ item.no_car_park_to }}</span>
                <span><i><img src="frontend/images/spec-ico4.svg"></i>{{item.floor_area_from}} {{item.floor_area_unit}} - {{ item.land_area_from }} {{ item.land_area_unit }}</span>
            </div>
            <div class="pro-tag">
                <a v-if="getListingTypeTag(item.listing_type)">{{ getListingTypeTag(item.listing_type) }}</a>
                <a style="cursor:default" v-if="item.property_type_details?.parent_id_top_level_1_code">{{ item.property_type_details?.parent_id_top_level_1_code }}</a>
                <a style="cursor:default" v-if="item.property_type_details?.code">{{ item.property_type_details?.code }}</a>
                <a style="cursor:default" v-if="item.land_title_type">{{ item.land_title_type }}</a>
            </div>
            <ul>
                <li v-if="item.is_direct_owner">
                    <figure>
                        <img src="frontend/images/specify-ico1.png">
                    </figure>
                    <small>{{ $t('direct') }}<br>{{ $t('owner') }}</small>
                </li>
                <li  v-if="item.is_sing_pass || item.is_corp_pass ">
                    <figure>
                        <img src="frontend/images/specify-ico2.png">
                    </figure>
                    <small>{{ $t('singpass') }}<br>{{ $t('verified') }} </small>
                </li>
                <li v-if="item.is_document_approved">
                    <figure>
                        <img src="frontend/images/specify-ico3.png">
                    </figure>
                    <small>{{ $t('documents') }}<br>{{ $t('verified') }}</small>
                </li>
                <li v-if="item.is_FSM_linked">
                    <figure>
                        <img src="frontend/images/specify-ico4.png">
                    </figure>
                    <small>Fengshui<br>Map</small>
                </li>
                <li v-if="item.is_assigned_to_the_agent">
                    <figure>
                        <img src="frontend/images/specify-ico5.png">
                    </figure>
                    <small>{{ $t('hybrid_listing') }}<br>Owners </small>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { CONSTANT } from '@/constants/constants';
import { getListingTypeTag, formatPrice } from "../../utils/helpers";

export default {
    name: 'ProjectBox',

    props: {
        item: {
            type: Object,
            Required: true
        }
    },

    data() {
        return {     
            CONSTANT   
        }
    },

    methods: {
        formatPrice,
        getListingTypeTag,
        getImageUrl(photoName) {        
            return photoName ? `${CONSTANT.IMAGE_URL}/imagesProjectListing/${photoName}` : CONSTANT.DEFAULT_IMAGE;
        },
    }
}
</script>