<template>
    <div class="accordion custom-accordion" id="accordion-one">
        <div class="accordion-item">
            <div class="accordion-header" id="heading1">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#key-information" aria-expanded="true" aria-controls="key-information">
                    {{ $t('key_information') }}
                </button>
            </div>
            <div id="key-information" class="accordion-collapse collapse show" aria-labelledby="heading1" >
                <div class="accordion-body">
                    {{ $t('key_information') }}
                    <div class="row row-cols-1 row-cols-sm-4">
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span>{{ $t('listing_type') }}</span>
                               <h5>{{ projectDetail.listing_type_details?.name || '-' }} </h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span>Property Address</span>
                                <h5>{{projectDetail.district}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span>{{ $t('street') }}</span>
                                <h5>{{projectDetail.street}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span>{{ $t('area') }} / {{ $t('hdb_estate') }}</span>
                                <h5>{{projectDetail.area_hdb_estate}}</h5>
                            </div>
                        </div>
                    </div>
                    {{ $t('basic_information') }}
                    <div class="row row-cols-1 row-cols-sm-4">
                        <div class="col">
                            <div class="light-info-box">
                                <span>{{ $t('property_type') }}</span>
                                <h5>{{ getPropertyType(projectDetail.property_type) }} </h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box">
                                <span>{{ $t('land_tenure') }}</span>
                                <h5>{{projectDetail.land_tenure}}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box">
                                <span >{{ $t('floor_area') }} ({{projectDetail.floor_area_unit}})</span>
                                <h5>{{projectDetail.floor_area}}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box">
                                <span>{{ $t('land_area') }}</span>
                                <h5>{{projectDetail.land_area}} {{projectDetail.land_area_unit}}</h5>
                            </div>
                        </div>
                    </div>
                    {{ $t('configuration') }}
                    <div class="row row-cols-1 row-cols-sm-4">
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/room-icon.svg" alt="">
                                <span class="d-inline-block w-100">{{ $t('rooms') }}</span>
                                <h5>{{projectDetail.no_of_bedrooms_from}} - {{projectDetail.no_of_bedrooms_to}}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/bathrooms-icon.svg" alt="">
                                <span class="d-inline-block w-100">{{ $t('bathrooms') }}</span>
                                <h5>{{projectDetail.no_of_baths_from}} - {{projectDetail.no_of_baths_to}}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/carpark-lots-icon.svg" alt="">
                                <span class="d-inline-block w-100">{{ $t('carpark_lots') }}</span>
                                <h5>{{projectDetail.no_car_park_from}} - {{projectDetail.no_car_park_to}} </h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/unit-type-icon.svg" alt="">
                                <span class="d-inline-block w-100">{{ $t('unit_type') }}</span>
                                <h5>{{projectDetail.total_units}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading4">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#property-details" aria-expanded="true" aria-controls="property-details">
                    Project Details
                </button>
            </div>
            <div id="property-details" class="accordion-collapse collapse show" aria-labelledby="heading4" >
                <div class="accordion-body">
                    <div class="accordion custom-accordion-2" id="accordion-two">
                        <div class="accordion-item">
                            <p class="mt-2">{{ $t('key_features') }}</p>
                            <div class="accordion-body">
                                <ul class="key-features-green-check">
                                    <li  v-for="option in featuresList" :key="option.pr_master_feature_id" :value="option.pr_master_feature_id">
                                        <img src="frontend/images/green-check.svg" alt=""> {{option.additional_feature_name}}</li>
                                    <li  v-for="option in add_featuresList" :key="option.pr_master_feature_id" :value="option.pr_master_feature_id">
                                        <img src="frontend/images/green-check.svg" alt=""> {{option.additional_feature_name}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <p class="mt-2">{{ $t('facilities') }}</p>
                            <div class="accordion-body">
                                <div class="row row-cols-1 row-cols-sm-4">
                                    <div class="col" v-for="option in facilitiyList" :key="option.pr_master_facilty_id" :value="option.pr_master_facilty_id">
                                        <div class="light-info-box text-center">
                                            <img src="frontend/images/ac-icon.svg" alt="">
                                            <h5>{{option.additional_facility_name}}</h5>
                                        </div>
                                    </div>
                                    <div class="col" v-for="option in add_facilitiyList" :key="option.pr_master_feature_id" :value="option.pr_master_feature_id">
                                        <div class="light-info-box text-center">
                                            <img src="frontend/images/ac-icon.svg" alt="">
                                            <h5>{{option.additional_facility_name}}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="unit-layout-heading">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#unit-layout" aria-expanded="true" aria-controls="property-details">
                    Project Unit Layouts
                </button>
            </div>
            <div id="unit-layout" class="accordion-collapse collapse" aria-labelledby="unit-layout-heading" >
                <div class="accordion-body">
                    <div class="property-listing">
                        <template v-if="unit_layouts.length > 0">
                            <div class="property-listing-box" v-for="unit_layout in unit_layouts" :key="unit_layout.id">
                                <div class="property-listing-img" style="min-width: 250px !important;">
                                    <picture>
                                        <img v-if="unit_layout.actual_unit_photos.length > 0" :src="getPropertyOtherDocumentsAndPhoto(unit_layout.actual_unit_photos?.[0]?.photo_name || null)" alt="Property Image" />
                                        <img v-else :src="getPropertyOtherDocumentsAndPhoto(null)" alt="Property Image" />
                                    </picture>
                                    <div class="mark-logo">
                                        <img src="frontend/images/logo.png" alt="logo">
                                    </div>
                                </div>
                                <div class="property-listing-contant">
                                    <div class="project-info">
                                        <h3>{{unit_layout.unit_layout_name}}</h3>
                                        <p class="loct-info" v-if="unit_layout.street || unit_layout.district">
                                            <img src="frontend/images/map-gol-ico.svg">
                                            {{unit_layout.street}}, {{unit_layout.district}}
                                        </p>
                                        <div class="price-pro ms-md-auto">
                                            <span>Starting From S$ {{formatPrice(unit_layout.listing_price)}}
                                                <small> ${{computedSQF(unit_layout.listing_price, unit_layout.unit_size)}} per SQF</small></span>
                                        </div>
                                        <div class="pro-specify">
                                            <span><i><img src="frontend/images/spec-ico1.svg"></i> {{unit_layout.no_of_rooms}}</span>
                                            <span><i><img src="frontend/images/spec-ico2.svg"></i> {{unit_layout.no_of_baths}}</span>
                                            <span><i><img src="frontend/images/spec-ico3.svg"></i> {{unit_layout.no_car_park_lots}}</span>
                                            <span><i><img src="frontend/images/spec-ico4.svg"></i> {{unit_layout.unit_size}}</span>
                                        </div>
                                    </div>
                                    <div class="property-listing-img">
                                        <a class="comman-btn-2" @click="redirectToUnitLayoutDetails(unit_layout)">View Unit Layout</a>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-center">No unit listing found</div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading5">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#project-status" aria-expanded="true" aria-controls="project-status">
                    Project Status
                </button>
            </div>
            <div id="project-status" class="accordion-collapse collapse" aria-labelledby="heading5" >
                <div class="accordion-body">
                    <div class="row row-cols-1 row-cols-sm-4">
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('current_occupancy') }}</span>
                                <h5>{{projectDetail.current_occupancy || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('current_monthly_rental_rate') }}</span>
                                <h5>{{projectDetail.current_tenancy_rental_rate || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('current_tenancy_expiry') }}</span>
                                <h5>{{projectDetail.current_tenancy_expiration_date || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('availability_date') }}</span>
                                <h5>{{projectDetail.availability_date || '-'}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading6">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pricing-terms" aria-expanded="true" aria-controls="pricing-terms">
                    {{ $t('pricing_terms_and_payment_options') }}
                </button>
            </div>
            <div id="pricing-terms" class="accordion-collapse collapse" aria-labelledby="heading6" >
                <div class="accordion-body">
                    <div class="light-info-box">
                        <span class="d-inline-block w-100">{{ $t('listing_price') }}</span>
                        <h5>S$ {{ formatPrice(projectDetail.listing_price_from) }} - S$ {{ formatPrice(projectDetail.listing_price_to) }}  
                            <small>(S$ {{computedSQF(projectDetail.listing_price_from, projectDetail.floor_area_from)}}/{{projectDetail.floor_area_unit || 'SQF'}} - S$ {{computedSQF(projectDetail.listing_price_to, projectDetail.floor_area_to)}}/{{projectDetail.floor_area_unit || 'SQF'}})</small>
                        </h5>
                    </div>
                    <div class="comman-title mt-4">
                        <h4>{{ $t('payment_mode_acceptance') }}</h4>
                    </div>
                    <div class="property-meta-se mb-0">
                        <ul v-if="Array.isArray(mode_of_payment)">
                            <li v-for="option in mode_of_payment" :key="option">
                                <span>{{option}}</span>
                            </li>
                        </ul>
                        <span v-else>{{ mode_of_payment?.replace(/\|/g, ', ') }}</span>
                    </div>
                    <div id="project-payment-mode" class="row row-cols-1 row-cols-sm-4">
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('rental_period') }} / {{ $t('preferred_tenancy') }} ({{ $t('if_applicable') }})</span>
                                <h5>{{ getRentalPeriod(projectDetail.rental_period_preferred_tenancy)}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('security_deposit') }}</span>
                                <h5>{{formatPrice(projectDetail.rental_security_deposit) || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('utility_deposit') }}</span>
                                <h5>{{formatPrice(projectDetail.rental_utility_deposit) || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('lump-sum_pre-pay_rent') }}</span>
                                <h5>{{formatPrice(projectDetail.rental_lumpsum_pre_pay_in_months_rent) || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('proposed_pre-pay_rebate') }}</span>
                                <h5>{{projectDetail.rental_proposed_pre_pay_rebate_in_percentage || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('diversity_friendly') }}</span>
                                <h5 v-if="projectDetail.rental_diversity_friendly">{{ $t('all_renters_welcome') }}.</h5>
                                <h5 v-if="!projectDetail.rental_diversity_friendly">{{ $t('no') }}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">{{ $t('pet_policy') }}</span>
                                <h5 v-if="projectDetail.rental_pet_allowed == 1">{{ $t('pets_allowed') }}</h5>
                                <h5 v-if="projectDetail.rental_pet_allowed == 2">{{ $t('pets_not_allowed') }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="accordion-item">-->
<!--            <div class="accordion-header" id="heading7">-->
<!--                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Hybrid-Listing" aria-expanded="true" aria-controls="Hybrid-Listing">-->
<!--                    {{ $t('hybrid_listing') }}-->
<!--                </button>-->
<!--            </div>-->
<!--            <div id="Hybrid-Listing" class="accordion-collapse collapse" aria-labelledby="heading7" >-->
<!--                <div class="accordion-body">-->
<!--                    <div class="row align-items-center">-->
<!--                        <div class="col-4">-->
<!--                            <div class="light-info-box ">-->
<!--                                <span class="d-inline-block w-100 mt-3" v-if="projectDetail.hybrid_listing_type==1">Attend To Inquiry By Myself</span>-->
<!--                                <span class="d-inline-block w-100 mt-3" v-if="projectDetail.hybrid_listing_type==2">Assign To Recommended Estate Agent</span>-->
<!--                                <span class="d-inline-block w-100 mt-3" v-if="projectDetail.hybrid_listing_type==3">Find My Own/Co-Broking Estate Agent</span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-8">-->
<!--                            <div class="light-info-box">-->
<!--                                <span class="d-inline-block w-100">{{ $t('contact_information') }}</span>-->
<!--                                <div class="d-flex" v-if="projectDetail.display_my_contact_information">-->
<!--                                    <h5 class="me-2"><img src="frontend/images/email-icon.svg" alt=""> {{projectUserDetail?.email}}</h5>-->
<!--                                    <h5 class="me-2"><img src="frontend/images/phone-icon.svg" alt=""> {{projectUserDetail?.mobile_number}}</h5>-->
<!--                                </div>-->
<!--                                <div class="d-flex" v-else>-</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="accordion-item">
            <div class="accordion-header" id="heading8">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Auto-Map" aria-expanded="true" aria-controls="Auto-Map">
                    Nearby Amenities (Auto-Map)
                </button>
            </div>
            <div id="Auto-Map" class="accordion-collapse collapse" aria-labelledby="heading8" >
                <div class="accordion-body">
                    <div class="row row-cols-1 row-cols-sm-3">
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/Nearest-MRT-icon.svg" alt="">
                                <h5>500M to</h5>
                                <span class="d-inline-block w-100">Nearest MRT</span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/Nearest-Bus-Station-icon.svg" alt="">
                                <h5>100M to</h5>
                                <span class="d-inline-block w-100">Nearest Bus Station</span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/Nearest-School-icon.svg" alt="">
                                <h5>3KM to</h5>
                                <span class="d-inline-block w-100">Nearest School</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading9">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Additional-Links" aria-expanded="true" aria-controls="Additional-Links">
                    {{ $t('additional_links') }}/{{ $t('attachments') }}
                </button>
            </div>
            <div id="Additional-Links" class="accordion-collapse collapse" aria-labelledby="heading9" >
                <div class="accordion-body">
                    <div class="row row-cols-1 row-cols-sm-3" v-if="otherDocList.length > 0">
                        <div class="col form-group" v-for="option in otherDocList" :key="option.id" :value="option.id" >
                            <a :href="`${CONSTANT.IMAGE_URL}/imagesProjectDocumentAndOtherPhotos/${option.photo_name}`" class="comman-btn-6" target="_blank">
                                {{option.photo_original_name}}
                                <img src="frontend/images/upload-orange.svg" alt=""></a>
                        </div>
                    </div>
                    <div v-else><span>{{ $t('no_additional_attachment_found') }}</span></div>
                    <div class="row row-cols-1 row-cols-sm-3">
                        <div class="col form-group" v-for="option in mediaLinksList" :key="option.id" :value="option.id" >
                            <a :href="`${option.link_url}`" class="comman-btn-6" target="_blank">
                                {{option.link_name}} ( {{option.link_type}} )
                                <img src="frontend/images/upload-orange.svg" alt=""></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading10">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Descriptions" aria-expanded="true" aria-controls="Descriptions">
                    {{ $t('description') }}
                </button>
            </div>
            <div id="Descriptions" class="accordion-collapse collapse" aria-labelledby="heading10" >
                <div class="accordion-body">
                    {{projectDetail.additional_description || $t('no_description_found') }}
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading11">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#Reviews" aria-expanded="true" aria-controls="Reviews">
                    {{ $t('reviews') }}
                </button>
            </div>
            <div id="Reviews" class="accordion-collapse collapse" aria-labelledby="heading11">
                <div class="accordion-body">
                    <div v-if="computedReviews.length > 0">
                        <div class="row row-cols-1" v-for="(review, key) in computedReviews"
                             :key="'review-'+key">
                            <div class="col">
                                <div class="light-info-box" :class="{ 'review-blur-overlay': !review.visibility }">
                                    <div class="d-flex justify-content-between">
                                        <label class="w-auto me-3">{{ review.user?.name }}<i class="fa fa-star mx-1"
                                            v-for="(rating, index) in review.ratings" :key="'rating-star-'+index"
                                            style="color: #FFD600;"></i></label>
                                        <label class="w-auto me-3" v-if="isOwner">
                                            <i :class="review.visibility ? 'fa fa-eye-slash' : 'fa fa-eye'" class="mx-1"
                                                style="color: #FFD600; cursor: pointer;" @click="changeVisibility(review)"></i>
                                        </label>
                                    </div>
                                    <h5>{{ review.feedback }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <span>{{ $t('no_reviews_found') }}.</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="review-form px-1" v-if="!isOwner">
            <div class="comman-title">
                <h3>{{ $t('leave_a_review') }}</h3>
            </div>
            <div class="review-form-box">
                <form>
                    <div class="form-group d-flex align-items-center">
                        <label class="w-auto me-3"><b>{{ $t('rating') }}</b></label>
                        <div class="rating">
                            <input type="radio" name="rating" value="5" id="5" v-model="reviewForm.ratings">
                            <label for="5"><i class="fa fa-star-o"></i></label>

                            <input type="radio" name="rating" value="4" id="4" v-model="reviewForm.ratings">
                            <label for="4"><i class="fa fa-star-o"></i></label>

                            <input type="radio" name="rating" value="3" id="3" v-model="reviewForm.ratings">
                            <label for="3"><i class="fa fa-star-o"></i></label>

                            <input type="radio" name="rating" value="2" id="2" v-model="reviewForm.ratings">
                            <label for="2"><i class="fa fa-star-o"></i></label>

                            <input type="radio" name="rating" value="1" id="1" v-model="reviewForm.ratings">
                            <label for="1"><i class="fa fa-star-o"></i></label>
                        </div>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" rows="5" placeholder="Writer message here"
                                  v-model="reviewForm.feedback"></textarea>
                    </div>
                    <button class="comman-btn-2 w-100" @click.prevent="submitReview">{{ $t('submit') }}</button>
                </form>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading12">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Disclaimer" aria-expanded="true" aria-controls="Disclaimer">
                    {{ $t('disclaimer') }}
                </button>
            </div>
            <div id="Disclaimer" class="accordion-collapse collapse" aria-labelledby="heading12" >
                <div class="accordion-body">
                    <p>This property listing may include features and content enhanced by Feng Shui insights, Hybrid listings, and AI-generated content. These elements are provided for general informational purposes only and should not be taken as expert advice. Property details and recommendations based on Feng Shui are not to be construed as a guarantee of increased value, prosperity, or suitability. Users are encouraged to conduct their own due diligence and consult with professionals where appropriate.”</p>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="map-heading">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#map-section"
                        aria-expanded="true" aria-controls="map-section">
                    {{ $t('map') }}
                </button>
            </div>
            <div id="map-section" class="accordion-collapse collapse" aria-labelledby="map">
                <div class="accordion-body">
                    <div v-if="!mapError" id="map" class="map-se" style="width: 100%; height: 325px;"></div>
                    <div v-else>{{ $t('location_cannot_be_found_on_map') }}.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { formatPrice, getPropertyOtherDocumentsAndPhoto } from "@/utils/helpers";
    import { CONSTANT } from "@/constants/constants";
    import toastr from "toastr";
    import { mapState } from "vuex";

    export default {
        name: 'ProjectBasicInformation',
        computed: {
            ...mapState(['isAuthenticated', 'user']),
            CONSTANT() {
                return CONSTANT
            },
            isOwner() {
                return this.isAuthenticated && this.user && this.projectDetail && this.user.id == this.projectDetail.user_id;
            },
            computedReviews() {
                if (this.projectDetail?.reviews && this.projectDetail.reviews.length > 0) {
                    return this.isOwner ? this.projectDetail.reviews : this.projectDetail.reviews.filter(r => r.visibility == true);
                }

                return [];
            }
        },
        props: {
            projectDetail:{
                type: Object,
                required: true,
            },
            projectUserDetail:{
                type: Object,
                required: true,
            },
            featuresList:{
                type: Array,
                required: true,
            },
            add_featuresList:{
                type: Array,
                required: true,
            },
            facilitiyList:{
                type: Array,
                required: true,
            },
            add_facilitiyList:{
                type: Array,
                required: true,
            },
            unit_layouts:{
                type: Array,
                required: true,
            },
            mode_of_payment:{
                type: [String, Array, null],
                required: false,
            },
            otherDocList:{
                type: Array,
                required: true,
            },
            mediaLinksList: {
                type: Array,
                required: true,
            },
            propertyType:{
                type: Array,
                required: true,
            },
            rentalPriodLIst:{
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                reviewForm: {
                    ratings: 0,
                    feedback: null
                },
                mapError: false
            }
        },
        methods: {
            formatPrice,
            getPropertyOtherDocumentsAndPhoto,
            getLabelByValue(value) {
                const item = this.listingType.find(option => option.value === value);
                return item ? item.label : 'Not found';
            },
            getPropertyType(value) {
                const item = this.propertyType.find(option => option.id === value);
                return item ? item.name : 'Not found';
            },
            getRentalPeriod(value) {
                const item = this.rentalPriodLIst.find(option => option.value === value);
                return item ? item.label : 'Not found';
            },
            redirectToUnitLayoutDetails(property) {
                window.location.href = window.FRONTEND_URL + `/unit-layout/${property.id}`;
            },
            computedSQF(price, size) {
                return price > 0 && size > 0 ? Math.ceil(price / size) : 0;
            },
            async changeVisibility(review) {
                if (!this.isOwner) {
                    toastr.error('Invalid action', 'error', { timeOut: 3000 });
                    return;
                }

                const result = await window.Swal.fire({
                    title: `Are you sure you want to ${review.visibility ? 'hide' : 'unhide'} the review?`,
                    showCancelButton: true,
                    confirmButtonText: "Save",
                    confirmButtonColor: "var(--primary-F6B034)",
                    cancelButtonColor: "var(--danger-color, #FF0000)",
                });

                if (!result.isConfirmed) return;

                try {
                    const response = await this.$axios.post(`api/projects/${this.projectDetail.id}/reviews/${review.id}/visibility`, {
                        api_token: localStorage.getItem('userToken'),
                    });

                    if (response.status === 200 && response?.data?.output) {
                        this.$emit('update-project-reviews', { reviews: response.data.output });
                        toastr.success(response.data.message, 'Success', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500
                        });
                    }
                } catch (error) {
                    toastr.error('Error changing review visibility: ' + error, 'Error', { timeOut: 3000 });
                }
            },
            async submitReview() {
                try {
                    if (!this.isAuthenticated || !this.user) {
                        toastr.error('Unauthenticated', 'error', { timeOut: 3000 });
                        return;
                    }

                    if (this.user.id === this.projectDetail.user_id) {
                        toastr.error('Project owner is not allowed to leave review', 'error', { timeOut: 3000 });
                        return;
                    }

                    if (!this.reviewForm.feedback) {
                        toastr.error('Feedback is required.', 'error', { timeOut: 3000 });
                        return;
                    }
                    this.reviewForm.project_listing_id = this.projectDetail.id;
                    this.reviewForm.user_id = this.user.id;
                    this.reviewForm.api_token = localStorage.getItem('userToken')
                    const reviewResponse = await this.$axios.post(`api/projects/${this.projectDetail.id}/review`, this.reviewForm,);

                    if (reviewResponse.status === 200) {
                        toastr.success(reviewResponse.data.message, 'Success', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                            onHidden: () => {
                                this.$emit('refetch-project');
                                this.resetReviewForm();
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error submitting review:', error);
                }
            },

            resetReviewForm() {
                this.reviewForm = {
                    ratings: 0,
                    feedback: null
                }
            },
            async initializeMap() {
                try {
                    const google = await this.loadGoogleMapsApi();
                    const geocoder = new google.maps.Geocoder();
                    const geocodeAddress = async (address) => {
                        return new Promise((resolve, reject) => {
                            geocoder.geocode({ address }, (results, status) => {
                                if (status === 'OK' && results && results.length > 0) {
                                    resolve(results);
                                } else {
                                    reject(status);
                                }
                            });
                        });
                    };

                    let addressParts = [
                        this.projectDetail.unit_no,
                        this.projectDetail.street,
                        this.projectDetail.area_hdb_estate,
                        this.projectDetail.district
                    ];
                    let address = addressParts.filter(part => part !== null && part !== undefined).join(', ');
                    const results = await geocodeAddress(address).catch(async (status) => {
                        if (status === 'ZERO_RESULTS') {
                            address = `${this.projectDetail.area_hdb_estate}, ${this.projectDetail.district}`;
                            return geocodeAddress(address);
                        } else {
                            this.mapError = true;
                            throw new Error('Geocode failed: ' + status);
                        }
                    });

                    // Ensure results were returned
                    if (results && results.length > 0) {
                        const location = results[0].geometry.location;
                        const map = new google.maps.Map(document.getElementById('map'), {
                            zoom: 13,
                            center: location,
                        });
                        new google.maps.Marker({
                            map: map,
                            position: location,
                        });
                        this.mapError = false;
                    } else {
                        this.mapError = true;
                        console.error('No valid results found for the address.');
                    }
                } catch (error) {
                    this.mapError = true;
                    console.error('Error initializing map:', error);
                }
            },
            async loadGoogleMapsApi() {
                return new Promise((resolve, reject) => {
                    if (!process.env?.VUE_APP_GOOGLE_API_KEY) {
                        return reject(new Error('API Key is missing'));
                    }

                    const script = document.createElement('script');
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}`;
                    script.async = true;
                    script.defer = true;

                    script.onload = () => resolve(window.google);
                    script.onerror = () => reject(new Error('Google Maps API failed to load.'));

                    document.head.appendChild(script);
                });
            },
        },
        watch: {
            projectDetail: {
                handler() {
                    this.initializeMap();
                },
                deep: true
            },
        },
    }
</script>

<style scoped>

</style>