<template>    
   <section class="listing-se">
        <div class="container">
            
            <div class="filter-bar-se mb-0">
                <div class="comman-title mb-0">
                    <h3 class="mb-2">{{ $t('my_projects') }}</h3>
                    <!-- <span>{{ $t('showing') }} 10 properties</span> -->
                </div>
                <div class="filter-right-bar">
                    <!-- <div class="dropdown">
                        <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                            <img src="frontend/images/filter-ico.svg"> Filter By
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                            <li><a class="dropdown-item" href="#">111</a></li>
                            <li><a class="dropdown-item" href="#">111</a></li>								
                        </ul>
                    </div> -->
                    <div class="dropdown">
                        <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                            <img src="frontend/images/Sort-By.svg"> {{ $t('sort_by') }} : {{ selectedSortOption }}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                            <li v-for="option in sortOptions" :key="option.value">
                                <a 
                                class="dropdown-item sortBy" 
                                href="#" 
                                @click.prevent="sortData(option.value)"
                                >
                                {{ option.label }}
                                </a>
                            </li>							
                        </ul>
                    </div>
                </div>
            </div>
            <LoaderCust :isLoading="isLoading" />
            <h5 class="mt-5">{{ $t('your_active_listings') }}</h5>
            <div v-if="!isLoading && !items.length" class="alert alert-warning">{{ $t('no_items_found') }}.</div>
            <div class="row row-cols-lg-3 row-cols-sm-2">
                <div class="col d-flex" v-for="item in items" :key="item.id">
                    <router-link :to="`/project-detail/${item.id}`" class="w-100 d-flex" style="display: flex; flex-direction: column; height: 100%;">
                        <div class="project-bx d-flex flex-column w-100" style="flex: 1; height: 100%;">
                            <div class="edit-pro">
                                <router-link :to="`/post-project-listing/${item.id}`">
                                    <i class="fa fa-pencil"></i>
                                </router-link>
                            </div>
                            <!--<div class="heart-pro">
								<a href="#" :class="{'active': item.is_in_wishlist == '1'}"><i class="fa fa-heart-o"></i></a>
							</div>-->
                            

                            <picture v-if="item.actual_unit_photo && item.actual_unit_photo.length > 0">
                                <img :src="getImageUrl(item.actual_unit_photo[0].photo_name)" @error="handleImageError" alt="Property Image" />
                            </picture>
                            <picture v-else>
                                <img :src="CONSTANT.DEFAULT_NO_IMAGE"  @error="handleImageError" alt="Property Image" />
                            </picture>
                            <div class="project-info">
                                <div class="d-md-flex mb-2 align-items-center">
                                    <h3><a href="">{{ item.project_name }}</a></h3>
                                    <div class="price-pro ms-md-auto">
                                        <span> {{ formatPrice(item.listing_price_from) }}</span>
                                    </div>
                                </div>
                                <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{ item.street }},{{ item.area_hdb_estate }},{{ item.district }}</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>{{ $t('from') }} {{item.no_of_bedrooms_from}} {{ $t('to') }} {{item.no_of_bedrooms_to}}</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>{{ $t('from') }} {{item.no_of_baths_from}} {{ $t('to') }} {{item.no_of_baths_to}}</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>{{ $t('from') }} {{item.no_car_park_from}} {{ $t('to') }} {{item.no_car_park_to}}</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>{{ $t('from') }} {{ item.floor_area_from}} {{ $t('to') }} {{item.floor_area_to}}{{item.land_area_unit}}</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#" v-if="item.property_type_details?.parent_id_top_level_1_code">{{item.property_type_details.parent_id_top_level_1_code}}</a>
                                    <a href="#" v-if="item.property_type_details?.code">{{item.property_type_details.code}}</a>
                                    <a href="#" v-if="getlandTenue(item.land_tenure)"> {{ getlandTenue(item.land_tenure)}} </a>
                                </div>
                                <ul>
                                    <li v-if="item.is_direct_owner">
                                        <figure>
                                            <img src="frontend/images/specify-ico1.png">
                                        </figure>
                                        <small>{{ $t('direct') }}<br>{{ $t('owner') }}</small>
                                    </li>
                                    <li  v-if="item.is_sing_pass || item.is_corp_pass ">
                                        <figure>
                                            <img src="frontend/images/specify-ico2.png">
                                        </figure>
                                        <small>{{ $t('singpass') }}<br>{{ $t('verified') }} </small>
                                    </li>
                                    <li v-if="item.is_document_approved">
                                        <figure>
                                            <img src="frontend/images/specify-ico3.png">
                                        </figure>
                                        <small>{{ $t('documents') }}<br>{{ $t('verified') }}</small>
                                    </li>
                                    <!--<li v-if="item.is_FSM_linked">
                                        <figure>
                                            <img src="frontend/images/specify-ico4.png">
                                        </figure>
                                        <small>Fengshui<br>Map</small>
                                    </li>
                                    <li v-if="item.is_assigned_to_the_agent">
                                        <figure>
                                            <img src="frontend/images/specify-ico5.png">
                                        </figure>
                                        <small>{{ $t('hybrid_listing') }}<br>Owners </small>
                                    </li>-->
                                </ul>
                            </div>
                        </div>
                    </router-link>
                </div>                
            </div>

            <h5 class="mt-5">{{ $t('pending_approval') }}</h5>
            <div v-if="!isLoading && !pendingListings.length" class="alert alert-warning">{{ $t('no_items_found') }}.</div>
            <div class="row row-cols-lg-3 row-cols-sm-2">
                <div class="col d-flex" v-for="item in pendingListings" :key="item.id">
                    <router-link :to="`/project-detail/${item.id}`" class="w-100 d-flex" style="display: flex; flex-direction: column; height: 100%;">
                        <div class="project-bx d-flex flex-column w-100" style="flex: 1; height: 100%;">
                            <div class="edit-pro d-flex justify-content-between">
                                <router-link :to="`/post-project-listing/${item.id}`">
                                    <i class="fa fa-pencil"></i>
                                </router-link>
                                <a type="button" data-bs-toggle="modal" data-bs-target="#deleteProject" @click="setSelectedProject(item.id)">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </div>
                            <!--<div class="heart-pro">
                                <a href="#" :class="{'active': item.is_in_wishlist == '1'}"><i class="fa fa-heart-o"></i></a>
                            </div>-->

                            <picture v-if="item.actual_unit_photo && item.actual_unit_photo.length > 0">
                                <img :src="getImageUrl(item.actual_unit_photo[0].photo_name)" @error="handleImageError" alt="Property Image" />
                            </picture>
                            <picture v-else>
                                <img :src="CONSTANT.DEFAULT_NO_IMAGE"  @error="handleImageError" alt="Property Image" />
                            </picture>
                            <div class="project-info">
                                <div class="d-md-flex mb-2 align-items-center">
                                    <h3><a href="">{{ item.project_name }}</a></h3>
                                    <div class="price-pro ms-md-auto">
                                        <span> {{ formatPrice(item.listing_price_from) }}</span>
                                    </div>
                                </div>
                                <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{ item.street }},{{ item.area_hdb_estate }},{{ item.district }}</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>{{ $t('from') }} {{item.no_of_bedrooms_from}} {{ $t('to') }} {{item.no_of_bedrooms_to}}</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>{{ $t('from') }} {{item.no_of_baths_from}} {{ $t('to') }} {{item.no_of_baths_to}}</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>{{ $t('from') }} {{item.no_car_park_from}} {{ $t('to') }} {{item.no_car_park_to}}</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>{{ $t('from') }} {{ item.floor_area_from}} {{ $t('to') }} {{item.floor_area_to}}{{item.land_area_unit}}</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">{{item.property_type_details.parent_id_top_level_1_code}}</a>
                                    <a href="#">{{item.property_type_details.code}}</a>
                                    <a href="#" v-if="getlandTenue(item.land_tenure)"> {{ getlandTenue(item.land_tenure)}} </a>
                                </div>
                                <ul>
                                    <li v-if="item.is_direct_owner">
                                        <figure>
                                            <img src="frontend/images/specify-ico1.png">
                                        </figure>
                                        <small>{{ $t('direct') }}<br>{{ $t('owner') }}</small>
                                    </li>
                                    <li  v-if="item.is_sing_pass || item.is_corp_pass ">
                                        <figure>
                                            <img src="frontend/images/specify-ico2.png">
                                        </figure>
                                        <small>{{ $t('singpass') }}<br>{{ $t('verified') }} </small>
                                    </li>
                                    <li v-if="item.is_document_approved">
                                        <figure>
                                            <img src="frontend/images/specify-ico3.png">
                                        </figure>
                                        <small>{{ $t('documents') }}<br>{{ $t('verified') }}</small>
                                    </li>
                                    <!--<li v-if="item.is_FSM_linked">
                                        <figure>
                                            <img src="frontend/images/specify-ico4.png">
                                        </figure>
                                        <small>Fengshui<br>Map</small>
                                    </li>
                                    <li v-if="item.is_assigned_to_the_agent">
                                        <figure>
                                            <img src="frontend/images/specify-ico5.png">
                                        </figure>
                                        <small>{{ $t('hybrid_listing') }}<br>Owners </small>
                                    </li>-->
                                </ul>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <h5 class="mt-5">{{ $t('expired_listings') }}</h5>
            <div v-if="!isLoading && !expiredListings.length" class="alert alert-warning">{{ $t('no_items_found') }}.</div>
            <div class="row row-cols-lg-3 row-cols-sm-2">
                <div class="col d-flex" v-for="item in expiredListings" :key="item.id">
                    <router-link :to="`/project-detail/${item.id}`" class="w-100 d-flex" style="display: flex; flex-direction: column; height: 100%;">
                        <div class="project-bx d-flex flex-column w-100" style="flex: 1; height: 100%;">
                            <div class="edit-pro d-flex justify-content-between">
                                <router-link :to="`/post-project-listing/${item.id}`">
                                    <i class="fa fa-pencil"></i>
                                </router-link>
                                <a type="button" data-bs-toggle="modal" data-bs-target="#deleteProject" @click="setSelectedProject(item.id)">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </div>
                            <!--<div class="heart-pro">
                                <a href="#" :class="{'active': item.is_in_wishlist == '1'}"><i class="fa fa-heart-o"></i></a>
                            </div>-->


                            <picture v-if="item.actual_unit_photo && item.actual_unit_photo.length > 0">
                                <img :src="getImageUrl(item.actual_unit_photo[0].photo_name)" @error="handleImageError" alt="Property Image" />
                            </picture>
                            <picture v-else>
                                <img :src="CONSTANT.DEFAULT_NO_IMAGE"  @error="handleImageError" alt="Property Image" />
                            </picture>
                            <div class="project-info">
                                <div class="d-md-flex mb-2 align-items-center">
                                    <h3><a href="">{{ item.project_name }}</a></h3>
                                    <div class="price-pro ms-md-auto">
                                        <span> {{ formatPrice(item.listing_price_from) }}</span>
                                    </div>
                                </div>
                                <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{ item.street }},{{ item.area_hdb_estate }},{{ item.district }}</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>{{ $t('from') }} {{item.no_of_bedrooms_from}} {{ $t('to') }} {{item.no_of_bedrooms_to}}</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>{{ $t('from') }} {{item.no_of_baths_from}} {{ $t('to') }} {{item.no_of_baths_to}}</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>{{ $t('from') }} {{item.no_car_park_from}} {{ $t('to') }} {{item.no_car_park_to}}</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>{{ $t('from') }} {{ item.floor_area_from}} {{ $t('to') }} {{item.floor_area_to}}{{item.land_area_unit}}</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">{{item.property_type_details.parent_id_top_level_1_code}}</a>
                                    <a href="#">{{item.property_type_details.code}}</a>
                                    <a href="#" v-if="getlandTenue(item.land_tenure)"> {{ getlandTenue(item.land_tenure)}} </a>
                                </div>
                                <ul>
                                    <li v-if="item.is_direct_owner">
                                        <figure>
                                            <img src="frontend/images/specify-ico1.png">
                                        </figure>
                                        <small>{{ $t('direct') }}<br>{{ $t('owner') }}</small>
                                    </li>
                                    <li  v-if="item.is_sing_pass || item.is_corp_pass ">
                                        <figure>
                                            <img src="frontend/images/specify-ico2.png">
                                        </figure>
                                        <small>{{ $t('singpass') }}<br>{{ $t('verified') }} </small>
                                    </li>
                                    <li v-if="item.is_document_approved">
                                        <figure>
                                            <img src="frontend/images/specify-ico3.png">
                                        </figure>
                                        <small>{{ $t('documents') }}<br>{{ $t('verified') }}</small>
                                    </li>
                                    <!--<li v-if="item.is_FSM_linked">
                                        <figure>
                                            <img src="frontend/images/specify-ico4.png">
                                        </figure>
                                        <small>Fengshui<br>Map</small>
                                    </li>
                                    <li v-if="item.is_assigned_to_the_agent">
                                        <figure>
                                            <img src="frontend/images/specify-ico5.png">
                                        </figure>
                                        <small>{{ $t('hybrid_listing') }}<br>Owners </small>
                                    </li>-->
                                </ul>
                            </div>
                        </div>
                    </router-link>
                    <button class="comman-btn-2 mt-2 w-100" data-bs-toggle="modal" data-bs-target="#renewProject" @click="setSelectedProject(item.id)">Republish Project</button>
                </div>
            </div>
            <h5 class="mt-5">{{ $t('draft_listings') }}</h5>
            <div v-if="!isLoading && !draftListings.length" class="alert alert-warning">{{ $t('no_items_found') }}.</div>
            <div class="row row-cols-lg-3 row-cols-sm-2">
                <div class="col d-flex" v-for="item in draftListings" :key="item.id">
                    <router-link :to="`/project-detail/${item.id}`" class="w-100 d-flex" style="display: flex; flex-direction: column; height: 100%;">
                        <div class="project-bx d-flex flex-column w-100" style="flex: 1; height: 100%;">
                            <div class="edit-pro d-flex justify-content-between">
                                <router-link :to="`/post-project-listing/${item.id}`">
                                    <i class="fa fa-pencil"></i>
                                </router-link>
                                <a type="button" data-bs-toggle="modal" data-bs-target="#deleteProject" @click="setSelectedProject(item.id)">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </div>
                            <!--<div class="heart-pro">
                                <a href="#" :class="{'active': item.is_in_wishlist == '1'}"><i class="fa fa-heart-o"></i></a>
                            </div>-->


                            <picture v-if="item.actual_unit_photo && item.actual_unit_photo.length > 0">
                                <img :src="getImageUrl(item.actual_unit_photo[0].photo_name)" @error="handleImageError" alt="Property Image" />
                            </picture>
                            <picture v-else>
                                <img :src="CONSTANT.DEFAULT_NO_IMAGE"  @error="handleImageError" alt="Property Image" />
                            </picture>
                            <div class="project-info">
                                <div class="d-md-flex mb-2 align-items-center">
                                    <h3><a href="">{{ item.project_name }}</a></h3>
                                    <div class="price-pro ms-md-auto">
                                        <span> {{ formatPrice(item.listing_price_from) }}</span>
                                    </div>
                                </div>
                                <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{ item.street }},{{ item.area_hdb_estate }},{{ item.district }}</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>{{ $t('from') }} {{item.no_of_bedrooms_from}} {{ $t('to') }} {{item.no_of_bedrooms_to}}</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>{{ $t('from') }} {{item.no_of_baths_from}} {{ $t('to') }} {{item.no_of_baths_to}}</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>{{ $t('from') }} {{item.no_car_park_from}} {{ $t('to') }} {{item.no_car_park_to}}</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>{{ $t('from') }} {{ item.floor_area_from}} {{ $t('to') }} {{item.floor_area_to}}{{item.land_area_unit}}</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">{{item.property_type_details.parent_id_top_level_1_code}}</a>
                                    <a href="#">{{item.property_type_details.code}}</a>
                                    <a href="#" v-if="getlandTenue(item.land_tenure)"> {{ getlandTenue(item.land_tenure)}} </a>
                                </div>
                                <ul>
                                    <li v-if="item.is_direct_owner">
                                        <figure>
                                            <img src="frontend/images/specify-ico1.png">
                                        </figure>
                                        <small>{{ $t('direct') }}<br>{{ $t('owner') }}</small>
                                    </li>
                                    <li  v-if="item.is_sing_pass || item.is_corp_pass ">
                                        <figure>
                                            <img src="frontend/images/specify-ico2.png">
                                        </figure>
                                        <small>{{ $t('singpass') }}<br>{{ $t('verified') }} </small>
                                    </li>
                                    <li v-if="item.is_document_approved">
                                        <figure>
                                            <img src="frontend/images/specify-ico3.png">
                                        </figure>
                                        <small>{{ $t('documents') }}<br>{{ $t('verified') }}</small>
                                    </li>
                                    <!--<li v-if="item.is_FSM_linked">
                                        <figure>
                                            <img src="frontend/images/specify-ico4.png">
                                        </figure>
                                        <small>Fengshui<br>Map</small>
                                    </li>
                                    <li v-if="item.is_assigned_to_the_agent">
                                        <figure>
                                            <img src="frontend/images/specify-ico5.png">
                                        </figure>
                                        <small>{{ $t('hybrid_listing') }}<br>Owners </small>
                                    </li>-->
                                </ul>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
       <div class="renew-project-modal">
           <div class="modal fade" id="renewProject" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
               <div class="modal-dialog">
                   <div class="modal-content">
                       <div v-if="userCredit < 500">
                           <div class="modal-header">
                               <h5 class="modal-title" id="staticBackdropLabel">{{ $t('insufficient_credits') }}</h5>
                           </div>
                           <div class="modal-body">
                               <b>{{ $t('republishing_project_requires_500_credits') }}</b><br>
                               {{ $t('your_current') }} {{ $t('credit') }}: <b>{{ userCredit }}</b>
                           </div>
                           <div class="modal-footer">
                               <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">{{ $t('close') }}</button>
                               <a class="comman-btn-2" href="/top-up" onclick="$('#renewProject').modal('hide')">
                                   <img src="/frontend/images/Choose-plan.svg" alt="topUp">
                                   {{ $t('top_up') }}
                               </a>
                           </div>
                       </div>
                       <div v-else>
                           <div class="modal-header">
                               <h5 class="modal-title" id="staticBackdropLabel">{{ $t('republish_project_listing') }}</h5>
                           </div>
                           <div class="modal-body">
                               {{ $t('clicking_confirm_will_request_to_republish_this_current_listing') }}. <br>
                               {{ $t('this_action_will_cost_you_500_credits') }}. <br>
                               {{ $t('your_current') }} {{ $t('credit') }}: <b>{{ userCredit }}</b>
                           </div>
                           <div class="modal-footer">
                               <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">{{ $t('close') }}</button>
                               <button type="button" class="comman-btn-2"  @click="renewProject()" data-bs-dismiss="modal">{{ $t('confirm') }}</button>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
    </section>
    <div class="delete-project-modal">
        <div class="modal fade" id="deleteProject" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">{{ $t('are_you_sure') }}?</h5>
                    </div>
                    <div class="modal-body">
                        {{ $t('clicking_confirm_will_delete_this_current_listing') }}. <br>
                        {{ $t('please_reconsider_your_action') }}.
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">{{ $t('close') }}</button>
                        <button type="button" class="comman-btn-2" @click="deleteProject()" data-bs-dismiss="modal">{{ $t('confirm') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
  <script>  
  import LoaderCust from '@/components/LoaderCust.vue';

  import { CONSTANT } from '@/constants/constants';
  import { mapState } from 'vuex';
  import {formatPrice} from "@/utils/helpers";
  import toastr from "toastr";
  export default {
    name: 'MyProject',
    components: {
        LoaderCust,
    },
    data() {
        return {   
            isLoading: true,  
            CONSTANT, 
            items: [],
            userCredit: null,
            draftListings: [],
            pendingListings: [],
            expiredListings: [],
            optionsLandTenuresList: [], 
            selectedSortOption: 'Newest',
            sortOptions: [
                { label: 'Newest', value: 'Newest' },
                { label: 'Lowest Price From', value: 'Lowest Price' },
                { label: 'Highest Price From', value: 'Highest Price' },
                { label: 'Lowest Floor Area From', value: 'Lowest Floor Area' },
                { label: 'Highest Floor Area From', value: 'Highest Floor Area' },
            ],
            selectedProjectId: null,
        }
    },
    computed: {
      ...mapState(['isAuthenticated', 'user']),
    },
    created() {
       this.fetchOptions();
    },
    methods: {
        formatPrice,
        async fetchOptions() {
            // Get Land Tenure list
            try {
                const response = await this.$axios.post('api/pr-land-tenures-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_property:1,
                    last_record_number : 0                     
                });
                this.optionsLandTenuresList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }

            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/project-list',
                {                    
                    user_id : this.user.id,
                    api_token : token, 
                    sort_by_field : 'Newest', 
                });
                this.items  = response.data.output.myArray;
                this.draftListings = response.data.output.unpublishedArray;
                this.pendingListings = response.data.output.publishedArray;
                this.expiredListings = response.data.output.expiredArray;
                this.userCredit = response.data.output.available_credit_points;
            } catch (error) {
                console.error('Error fetching options:', error);
            } finally {
                this.isLoading = false;
            }
            
        },
        getImageUrl(photoName) {        
            return photoName ? `${CONSTANT.IMAGE_URL}/imagesProjectListing/${photoName}` : CONSTANT.DEFAULT_IMAGE;
        },
        
        handleImageError(event) {
            event.target.src = CONSTANT.DEFAULT_IMAGE;
        },

        getlandTenue(value) {
            const item = this.optionsLandTenuresList.find(option => option.value === value);
            return item ? item.label : null;
        },

        async sortData(sortBy) {
            this.isLoading = true;
            this.items = [];
            this.selectedSortOption = sortBy; 
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/project-list',
                {                    
                    user_id : this.user.id,
                    api_token : token, 
                    sort_by_field : sortBy, 
                });
                this.items  = response.data.output.myArray;
                this.draftListings = response.data.output.unpublishedArray;
                this.pendingListings = response.data.output.publishedArray;
                this.expiredListings = response.data.output.expiredArray;
            } catch (error) {
                console.error('Error fetching options:', error);
            }finally {
                this.isLoading = false;
            }
        },
        setSelectedProject(project_id) {
            if (!project_id) {
                console.error('No request provided');
                return;
            }
            this.selectedProjectId = project_id;
        },
        async renewProject() {
            const token = localStorage.getItem('userToken');
            try {
                await this.$axios.post('api/renew-project-listing', {
                    api_token : token,
                    project_listing_id : this.selectedProjectId,
                });

                await this.fetchOptions();
            } catch (error) {
                console.error('Error Renew Project:', error);
            }
        },
        async deleteProject() {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/delete-project-listing',{
                    api_token : token,
                    project_listing_id : this.selectedProjectId,
                });

                if (response.data.status === 1) {
                    toastr.success('Listing Deleted Successfully');

                    await this.fetchOptions();
                }

            } catch (error){
                console.error('Error fetching options:', error);
            }
        },
      
    }   
    
  }
  </script>
  
  <style scoped>
  /* Add styles specific to the About Us page here */
  </style>
  