<template>
    <div class="dropdown me-lg-3">
        <a class="comman-btn-1" href="javascript:;" id="localeDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{ formatTitleCase($t(selectedLanguage)) }} 
        </a>
        <ul class="dropdown-menu" aria-labelledby="localeDropdown" style="width: 100px;">
            <li v-for="language in languages" :key="language.value">
                <a class="dropdown-item" @click="setLocale(language)" :class="{'selected': selectedLanguage === language.name}">{{ formatTitleCase($t(language.name)) }}</a>
            </li>
        </ul>
    </div>	
</template>
  <script>
import { formatTitleCase } from '@/utils/helpers';

  export default {
    name: 'LanguageLocaleDropdown',
    data() {
       return {
           languages: [{name: 'english', value: 'en'}, {name: 'chinese', value: 'zh'}],
           selectedLanguage: 'english'
       }
    },
    mounted() {
        this.getLocale();
    },
    methods: {
        formatTitleCase,
        getLocale() {
            let storageLocale = localStorage.getItem('locale') || 'en';
            let language = this.languages.find(language => language.value == storageLocale);
            this.selectedLanguage = language.name || 'english';
        },
        setLocale(language) {
            this.$i18n.local = language.value;
            this.selectedLanguage = language.name;
            localStorage.setItem('locale', language.value);
            window.location.reload();
        },
    }
  }
  </script>

<style>
    .dropdown:hover .dropdown-menu {
      display: block;
    }

    .dropdown .dropdown-menu {
      right: 0;
      left: auto;
    }

    .dropdown-item:hover,
    .dropdown-item.selected {
        background-color: #ffe6b9;
    }
</style>

  