<template>
    <div class="unlock-account">
      <h1>Unlocking Your Account...</h1>
      <p v-if="loading">Please wait...</p>
      <p v-if="message">{{ message }}</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: true,
        message: '',   
        token: '',   
      };
    },
    mounted() { 
      const urlParams = new URLSearchParams(window.location.search);
      this.token = urlParams.get('token');
      
      if (this.token) {
        this.unlockAccount(this.token);
      } else {
        this.message = 'Invalid token!';
        this.loading = false;
      }
    },
    methods: {
      async unlockAccount(token) {
        this.loading = true;
        console.log('Unlock Account method called with token:', token);
        
        try {
          const response = await this.$axios.post('/api/unlock-account', { token });
          
          this.message = response.data.message || 'Your account has been unlocked successfully!';
          this.$router.push('/');

        } catch (error) {
          this.message = error.response?.data?.message || 'Failed to unlock your account. Please try again.';
        } finally {
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .unlock-account {
    text-align: center;
    margin-top: 50px;
  }
  </style>
  